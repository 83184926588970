import React from "react";
import { Container, Col, Row, Table, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/formatter/dateFormatter";

const mock_data = [
    {
        url: "https://mock.com",
        purchases_count: 3,
        clicks: 8,
        views: 400,
        date: new Date().getTime() / 1000
    }
];

export default function LinkTrackingTable({ links = mock_data }) {
    const { t } = useTranslation("profile_screen");

    return (
        <div>
            <h4>{t("Payouts")}</h4>
            {links.length > 0 ? (
                <Table striped={true} bordered={true} hover={true}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t("Link")}</th>
                            <th>{t("Purchases")}</th>
                            <th>{t("Clicks")}</th>
                            <th>{t("Views")}</th>
                            <th>{t("Date")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {links.map((link, i) => (
                            <tr key={link?._id ?? i}>
                                <td>{i}</td>
                                <td>{link.url}</td>
                                <td>{link?.purchases_count}</td>
                                <td>{link?.clicks}</td>
                                <td>{link?.views}</td>
                                <td>{formatDate(link?.date)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <div>No earnings available</div>
            )}
        </div>
    );
}
