import axios from "axios";
import { config } from "../utils/config";

class AnalyticsAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/analytics"
        });
    }

    createPageView({ url, ip, country, fp }) {
        const data = { ip, country, fp, url };
        return this.axios.post("/view", data, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    getUserProfileViews(username) {
        return this.axios.get(`/profile_views?username=${username}`);
    }

    getCharacterProfileViews(character_id) {
        return this.axios.get(`/character_views?character_id=${character_id}`);
    }

}

export const AnalyticsAPIInstance = new AnalyticsAPI();

export default AnalyticsAPI;
