import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import orangeLogo from "../../assets/images/logo/orangeMark.png";
import redchatLogo from "../../assets/images/logo/redbit.png";
import LanguageDropDown from "./LanguageDropDown";
import { getBrowserCurrentUrl } from "../../utils/getCurrentUrl";

//Similar to Navbar.js but with different links

function AuthHeader(props) {
    const [topLogo, setTopLogo] = useState(orangeLogo);
    const { onModalOpen } = props;
    const {t} = useTranslation('header');
    const currentUrl = getBrowserCurrentUrl();

    useEffect(() => {
        if (currentUrl.includes("redchat")) {
            setTopLogo(redchatLogo);
        } else {
            setTopLogo(orangeLogo);
        }
    }, [currentUrl]);

    return (
        <div className="container-xxl">
            <div className="row align-items-center">
                <div className="col">
                    <div className="d-flex">
                        <a
                            href={process.env.PUBLIC_URL + "/"}
                            className="d-flex align-items-center mb-0 brand-icon me-3"
                        >
                            <span className="logo-icon me-2">
                            <img className="orangeLogo" src={topLogo} alt="" />
                            </span>
                        </a>
                        {/* <Link to={process.env.PUBLIC_URL + "/"} className="d-flex align-item-center">
                            <i className="fa fa-gg-circle fs-3"></i>
                            <h5 className="mb-0 mt-1 mx-2">{t('redbit')}</h5>
                        </Link> */}
                       {/*  <a href="market-list" className="btn btn-link text-decoration-none">
                            {t('markets')}
                        </a> */}
                        {/* <a href="sign-in?rd=wallet-page" className="btn btn-link text-decoration-none">
                            {t('wallet')}
                        </a> */}
                    </div>
                </div>
                <div className="col-auto">
                    <div className="d-flex align-item-center">
                        <LanguageDropDown autoSetLanguage={true}/>
                       
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AuthHeader;
