import axios from "axios";
import { readCookie } from "../utils/cookie";
import { encrypt } from "../utils/encryption";
import { config } from "../utils/config";
import { httpTokenInterceptor } from "./common";

class AuthAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/users"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    async login(password, email, fp = "") {
        try {
            const encryptedEmail = await encrypt(email.trim());
            const encryptedPassword = await encrypt(password);

            const response = await this.axios.post(
                "/login",
                {
                    email: encryptedEmail,
                    password: encryptedPassword
                },
                {
                    timeout: 53000,
                    headers: {
                        fingerprint: fp
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async logout() {
        try {
            await this.axios.post("/logout");
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async register(password, username, email, fingerPrint = "", inviteCode) {
        try {
            const encryptedPassword = await encrypt(password);
            const encryptedUsername = await encrypt(username);
            const encryptedEmail = await encrypt(email.trim());
            
            const response = await this.axios.post(
                "/register",
                {
                    password: encryptedPassword,
                    username: encryptedUsername,
                    email: encryptedEmail,
                    ...(inviteCode ? { inviteCode } : {})
                },
                { headers: { fingerPrint } }
            );
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getCurrentUserProfile() {
        try {
            const response = await this.axios.get("/me", {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async forgetPassword({ email, tOtp }) {
        try {
            const response = await this.axios.post("/forget_password", { email, tOtp });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async resetPassword({ updated_password, token }) {
        try {
            const response = await this.axios.post("/reset_password", { updated_password, token });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    requestResetPassword(email) {
        return this.axios.post("/v2/forget_password", {
            email
        });
    }

    resetPasswordV2(password, token) {
        return this.axios.post("/v2/reset_password", {
            updated_password: password,
            token
        });
    }

    async getLoginHistory() {
        try {
            const response = await this.axios.get("/get_user_sessions", {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });

            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async enableTwoFactor() {
        try {
            const response = await this.axios.post(
                "/auth/2fa",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${readCookie("token")}`
                    }
                }
            );

            return response.data;
        } catch (error) {
            console.error(error?.message ?? "Error in enable two factor");
        }
    }

    async disableTwoFactorAuth(password) {
        try {
            password = await encrypt(password);
            const response = await this.axios.patch(
                "/auth/2fa/disable",
                { password },
                {
                    headers: {
                        Authorization: `Bearer ${readCookie("token")}`
                    }
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    async completeEnableTwoFactor(otp) {
        try {
            const response = await this.axios.patch(
                "/auth/2fa",
                {
                    tOtp: otp
                },
                {
                    headers: {
                        Authorization: `Bearer ${readCookie("token")}`
                    }
                }
            );

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async loginByOtp(otp, token) {
        return await this.axios.post("/login_otp", {
            token,
            otp
        });
    }

    async updateBankInfo(token, bankInfo) {
        return await this.axios.patch("/bank_account_info", bankInfo, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getReferralEarnings(token) {
        return this.axios.get("/referral_earnings", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    payoutReferralEarnings(token) {
        return this.axios.post(
            "/payout_referral_earnings",
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    getPayouts(token) {
        return this.axios.get("/payout_referral_earnings", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    getCharacterEarnings(token) {
        return this.axios.get("/character_earnings", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    sendPhoneVerificationCode(phoneNumber) {
        return this.axios.post("/phone/send_code", {
            phoneNumber
        });
    }

    updateLanguage(lang_code) {
        return this.axios.patch(
            "/language_settings",
            {
                lang_code
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    googleLogin() {
        return this.axios.get("/auth/web/google/login");
    }

    updatePassword(current_password, new_password) {
        return this.axios.post(
            "/update_password",
            {
                current_password,
                updated_password: new_password
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    requestEmailVerification() {
        return this.axios.post(
            "/email_verification",
            {},
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    completeEmailVerification(verification_code) {
        return this.axios.patch(
            "/email_verification",
            {
                verification_code
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    requestPhoneVerification(phone) {
        return this.axios.post(
            "/kyc/phone_verification",
            {
                phoneContact: phone
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    completePhoneVerification(phone, verification_code) {
        return this.axios.patch(
            "/kyc/phone_verification",
            {
                phoneContact: phone,
                code: verification_code
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    updateUserRegion(countryCode) {
        return this.axios.patch(
            "/update_region",
            {
                countryCode
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    updateAllowAdultContent(allowAdultContent) {
        return this.axios.patch(
            "/update_allow_adult_content",
            {
                allowAdultContent
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getFollowing() {
        return this.axios.get("/following", {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    addPaymentMethod(token) {
        return this.axios.post(
            "/payment_method/",
            {
                token
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getPaymentMethod() {
        return this.axios.get("/payment_method", {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    removePaymentMethod() {
        return this.axios.delete("/payment_method", {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    updateUserProfile(body) {
        return this.axios.patch("/info/", body, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getUserPublicProfile(userId, username) {
        let query = "";
        if (userId !=="" && userId != null) {
            query = `userId=${userId}`
        } else if (username !=="" && username != null) {
            query = `username=${username}`;
        }
        console.log("getUserPublicProfile query:", query);
        return this.axios.get(`/public_profile/?${query}`);
    }

    updateSpicyChatPreference(status) {
        return this.axios.patch("/spicy_chat_preference/", {status}, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    savePaddleCustomer() {
        return this.axios.post(
            "/paddle_customer/",
            {},
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getFollowers(userId) {
        return this.axios.get(`/followers/${userId}`);
    }

    getFollowingStatus(userId) {
        return this.axios.get(`/following_status/${userId}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getAllUserPublicProfile() {
        return this.axios.get(`/public_info/`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getCreatorsPublicProfile() {
        return this.axios.get(`/creators_public_profile`);
    }
    
    follow_user(follow_user_id) {
        return this.axios.post(
            "/follow_user/",
            { follow_user_id },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    setAutoTranslateMessages(status = false) {
        return this.axios.post(
            "/autoTranslateMessages/",
            { status },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }
}

export const authAPIInstance = new AuthAPI();

export default AuthAPI;
