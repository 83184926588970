import { changeSocketConnectionStatus, SocketConnectionStatus } from "../../Redux/Reducers/ChatReducer";
import { socketConstants } from "./index";

export const socketCheckerInterval = (webSocket , dispatch, usedWhere) => {

    if(webSocket) {
        const timeOutFn = setInterval(() => {
            //console.log("socketCheckerInterval readyState:", webSocket.readyState, usedWhere);
            if (webSocket.readyState === 1) {
                dispatch(changeSocketConnectionStatus(SocketConnectionStatus.CONNECTED));
            } else {
                dispatch(changeSocketConnectionStatus(SocketConnectionStatus.DISCONNECTED));
            }
        }, 10000);
    }

};