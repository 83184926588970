import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Avatar from "../../../Component/Comman/Avatar";
import TopConversations from "../../../Component/Explore/TopConversations";
import { selectCurrentUser } from "../../../Redux/selectors/auth";
import { AIApiInstance } from "../../../apis/AIAPI";
import { AnalyticsAPIInstance } from "../../../apis/AnalyticsAPI";
import { authAPIInstance } from "../../../apis/AuthAPI";
import { isRedbitUrl } from "../../../utils/checkRedbitUrl";
import { getUserImageUrl } from "../../../utils/images";
import CreatedCharacters from "./components/CreatedCharacters";
import { Modal } from "../../../Component/Modal/Modal";
import "./creatorProfile.css";
import Followers from "./components/Followers";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-30%",
        transform: "translate(-50%, -50%)",
        minWidth: "30%",
        backgroundColor: "rgba(0,0,0,0.6)",
        borderColor: "transparent",
        borderRadius: "12px",
        height: "80vh",
        overflowY: "auto"
    },
    overlay: {
        backgroundColor: "rgba(4, 4, 35, .5)",
        backdropFilter: "blur(3px)"
    }
};


export function UserCard({ containerStyle = {}, userInfo = {}, followingStatus = false, followersCount = 0, handleFollowClick = () => {} }) {
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const [editEnabled, seteditEnabled] = useState(false);
    const loggedInUserID = useSelector((state) => state.AuthReducer?.user?.userId);
    const [showSocialMediaModal, setShowSocialMediaModal] = useState(false);

    useEffect(() => {
        if (user && user?.userId === userInfo?._id) {
            seteditEnabled(true);
        } else {
            seteditEnabled(false);
        }
    }, [userInfo]);

    const handleEditClick = () => {
        navigate('/edit-profile');
    }

    const messageUser = () => {
        navigate(`/chat?type=user&id=${userInfo._id}`)
    }

    const handleCancel = () => {
        setShowSocialMediaModal(false);
    }

    const handleSocialsClick = () => {
        setShowSocialMediaModal(true);
    };

    const handleLinkClick = (link) => {
        window.open(link, '_blank');
    }

    return (
        <div className="user-card-container-box m-4" style={containerStyle}>
            <div className="d-flex flex-row p-4 gap-4 align-items-center align-self-start">
                <div>
                    <Avatar
                        name={userInfo?.username}
                        avatarUrl={getUserImageUrl(userInfo?.avatar_url)}
                        className="pointer character-card-avatar-lg"
                    />
                </div>
                <h4>{userInfo?.username}</h4>
            </div>
            <div className="d-flex flex-row px-4 gap-4 align-items-center justify-content-between">
                <span>{followersCount} followers</span>
                <Button
                    style={{ backgroundColor: "#7E71C0" }}
                    onClick={handleFollowClick}
                    className="d-flex gap-1 align-items-center"
                >
                    {followingStatus ? <i class="fa-regular fa-thumbs-up"></i> : <i class="fa-solid fa-thumbs-up"></i>}
                    {followingStatus ? 'Unfollow': 'Follow'}
                </Button>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center p-4">
                <p>{userInfo?.description}</p>
                {/* <span>Views: {userInfo?.views}</span> */}
            </div>
            <div className="d-flex align-items-center justify-content-center pb-3">
                <Button
                        style={{ backgroundColor: "#7E71C0" }}
                        onClick={handleSocialsClick}
                        className="d-flex justify-content-center align-items-center p-2"
                >
                    Social Medial Links
                </Button>
            </div>

            {editEnabled && (
                <div className="d-flex justify-content-center m-2">
                    <Button onClick={handleEditClick} className="transparent-orange-gradient-btn py-3 mb-3">
                        Edit
                        <i className="fa-solid fa-pen-to-square mx-2"></i>
                    </Button>
                </div>
            )}

            <div className="d-flex justify-content-center m-2">
                {loggedInUserID !== userInfo?._id ? <Button onClick={messageUser}>Message</Button> : null}
            </div>
            <Modal
                setIsOpen={setShowSocialMediaModal}
                modalIsOpen={showSocialMediaModal}
                showClose={false}
                styles={customStyles}
                body={
                    <div className="d-flex flex-column gap-2 justify-content-between schedueledMessages-container p-2 py-4 h-100">
                        <div className="d-flex flex-column align-items-center gap-3">
                            <h4>Social Media Links</h4>
                            {userInfo?.socialLinks && userInfo.socialLinks.map((linkObject, index) => (
                                <div className="d-flex flex-column w-100 justify-content-center align-items-center position-relative links-border"
                                    key={index}
                                    onClick={() => handleLinkClick(linkObject.link)}>
                                    <p className="m-2">{linkObject?.label}</p>
                                </div>
                            ))}
                        </div>
                        <Button onClick={handleCancel} className="orange-gradient-btn py-2" style={{width: "100%"}}>Close</Button>
                    </div>
                }
            >
            </Modal>
        </div>
    );
}

const tabsEnum = {
    services: "Services",
    public_chats: "public_chats",
    favorite_characters: "favorite_characters",
    followers: "followers"
};

const tabs = [
    { text: "My Characters", category: tabsEnum.services, disabled: false },
    { text: "Public Chats", category: tabsEnum.public_chats, disabled: false },
    { text: "Favorite Characters", category: tabsEnum.favorite_characters, disabled: false },
    { text: "Followers", category: tabsEnum.followers, disabled: false }
];

function ProfileTabs({characters = [], chats = [], favoriteCharacters = [], followers = []}) {
    const [selectedtab, setselectedtab] = useState(tabsEnum.favorite_characters);

    const tabsContent = {
        [tabsEnum.services]: <CreatedCharacters characters={characters}/>,
        [tabsEnum.public_chats]: <TopConversations conversations={chats}/>,
        [tabsEnum.favorite_characters]: <CreatedCharacters characters={favoriteCharacters}/>,
        [tabsEnum.followers]: <Followers followers={followers}/>
    };

    const selectTab = (tab) => {
        if(!tab.disabled){
            setselectedtab(tab.category);
        }
    };

    return (
        <div className="profiles-tab-container">
            <div className="profiles-tab-container-tabs">
                {tabs.map((tab, i) => (
                    <div
                        id={`user-profiles-tab-${i}`}
                        key={`user-profiles-tab-${i}`}
                        onClick={() => {
                            selectTab(tab);
                        }}
                        style={tab.disabled ? { pointerEvents: "none", opacity: 0.4 } : {}}
                        className={`profiles-tab ${tab.category === selectedtab ? "profiles-selected-tab" : ""}`}
                    >
                        {tab.text}
                    </div>
                ))}
            </div>
            <div className="profiles-tab-content">{tabsContent[selectedtab]}</div>
        </div>
    );
}

export default function ViewCreatorProfile({}) {

    const params = useParams();
    const isMyProfile = params?.username ? false : true;
    const user = useSelector(selectCurrentUser)
    const [userInfo, setUserInfo] = useState({});
    const [characters, setCharacters] = useState([]);
    const [favoriteCharacters, setfavoriteCharacters] = useState([]);
    const [followers, setfollowers] = useState([]);
    const [chats, setChats] = useState([]);
    const [error, setError] = useState(false);
    const [followingStatus, setFollowingStatus] = useState(false);
    const charactersLoaded =  useRef(false);
    const viewsLoaded =  useRef(false);

    useEffect(() => {
        let userId = userInfo?._id;
        if (userId && userId !== typeof 'undefined') {
            AIApiInstance.getCreatedCharacters(isRedbitUrl(), userId)
                .then((res) => {
                    charactersLoaded.current = true;
                    setCharacters(res?.data?.data?.created_characters ?? []);
                })
                .catch(console.error);
            AIApiInstance.getUserPublicChats(userId)
                .then((res) => {
                    setChats(res?.data?.data?.topUserChats ?? []);
                })
                .catch(console.error);
            authAPIInstance
                .getFollowers(userId)
                .then((res) => {
                    setfollowers(res.data?.data?.followers ?? []);
                })
                .catch((e) => {
                    console.error(e);
                    //setError(true);
                });
            authAPIInstance
                .getFollowingStatus(userId)
                .then((res) => {
                    setFollowingStatus(res.data?.data?.status ?? []);
                })
                .catch((e) => {
                    console.error(e);
                    //setError(true);
                });
        }
        if (userInfo?.username && !viewsLoaded.current) {
            AnalyticsAPIInstance.getUserProfileViews(userInfo?.username)
                .then((res) => {
                    viewsLoaded.current =  true;
                    setUserInfo((prev) => ({ ...prev, views: res?.data?.data?.views ?? 0 }));
                })
                .catch(console.error);
        }
    }, [userInfo]);

    useEffect(() => {
        if (isMyProfile) {
            setUserInfo({ ...user, _id: user?.userId, views: 0 });
            AIApiInstance.getFavoriteCharacters()
            .then(res => {
                setfavoriteCharacters(res?.data?.data?.characters ?? []);
            }).catch(console.error)
        } else {
            const username = params?.username?.split("@")?.[1];
            if (!username) {
                //setError(true);
                return;
            }
            console.log('usernamegetUserPublicProfile : ', username)
            authAPIInstance
                .getUserPublicProfile(null, username)
                .then((res) => {
                    console.log("setUserInfo: ", res)
                    setUserInfo({...res?.data?.data, views: 0 });
                })
                .catch((e) => {
                    console.error("getUserPublicProfile error: ",e);
                    //setError(true);
                });
            setfavoriteCharacters([]);
        }
    }, [isMyProfile, params]);

    const handleFollowClick = () => {
        authAPIInstance.follow_user(userInfo?._id).then(_ => {
            setFollowingStatus(prev => !prev);
        }).catch(e => {
            alert(e?.response?.data?.data?.message ?? 'Something went wrong.');
        })
    }

    return (
        <>
            {error ? (
                <div style={{ height: "50vh" }} className="d-flex flex-column align-items-center justify-content-center">
                    <h2>User Not Found</h2>
                </div>
            ) : (
                <div className="view-creator-profile">
                    <UserCard handleFollowClick={handleFollowClick} userInfo={userInfo} followingStatus={followingStatus} followersCount={followers?.length}/>
                    <ProfileTabs characters={characters} chats={chats} favoriteCharacters={favoriteCharacters} followers={followers}/>
                </div>
            )}
        </>
    );
}
