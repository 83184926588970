import React, { useState } from 'react';
import "./index.css";

export default function ThreeFactorAuth({ transaction_id, onSubmit }) {
    // const 
    const [password, setpassword] = useState("");
    const [tOtp, settOtp] = useState("");
    const [emailOtp, setemailOtp] = useState("");
    const [passwordVerified, setpasswordVerified] = useState(false);
    const [tOtpVerified, settOtpVerified] = useState(false);
    const [emailOtpVerified, setemailOtpVerified] = useState(false);

    const _onSubmit = async () => {
        let params = {};
        if(!passwordVerified){
            params = { ...params, password };
        }
        if(!tOtpVerified){
            params = { ...params, tOtp };
        }
        if(!emailOtpVerified){
            params = { ...params, emailOtp };
        }
        const res = await onSubmit(params);
        // console.log(res);
        if(res?.message?.toLowerCase()?.includes("password")){
            if (res.code === 202) {
                setpasswordVerified(true);
            } else {
                setpasswordVerified(false);
            }
        }
        else if (res?.message?.toLowerCase()?.includes("two factor")){
            if (res.code === 202) {
                settOtpVerified(true);
            } else {
                settOtpVerified(false);
            }
        }
        else if (res?.message?.toLowerCase()?.includes("email otp")){
            if (res.code === 202) {
                setemailOtpVerified(true);
            } else {
                setemailOtpVerified(false);
            }
        }
    }

    const onPasswordChange = (e) => {
        const value = e.target.value;
        setpassword(value);
    }

    const ontOtpChange = (e) => {
        const value = e.target.value;
        settOtp(value);
    }
    
    const onEmailOtpChange = (e) => {
        const value = e.target.value;
        setemailOtp(value);
    }

    return (
        <div className='threeFactorModalBody'>
            <h5>Complete withdraw</h5>
            <div className="col-sm-12 input-container">
                <label className="form-label">Password</label>
                <div className="input-group">
                    <input type="password" className="form-control" onChange={onPasswordChange} disabled={passwordVerified} />
                    <button className="btn btn-outline-secondary" type="button" onClick={_onSubmit} disabled={passwordVerified}>Next</button>
                </div>
            </div>
            <div className="col-sm-12">
                <label className="form-label">2 factor code</label>
                <div className="input-group">
                    <input type="text" className="form-control" onChange={ontOtpChange} disabled={!passwordVerified || tOtpVerified}/>
                    <button className="btn btn-outline-secondary" type="button" onClick={_onSubmit} disabled={!passwordVerified || tOtpVerified}>Next</button>
                </div>
            </div>
            <div className="col-sm-12">
                <label className="form-label">Email OTP</label>
                <div className="input-group">
                    <input type="text" className="form-control" onChange={onEmailOtpChange} disabled={!tOtpVerified}/>
                    <button className="btn btn-outline-secondary" type="button" onClick={_onSubmit} disabled={!tOtpVerified || emailOtpVerified}>Next</button>
                </div>
            </div>
            <div className="col-sm-12">
                <button type="submit" onClick={_onSubmit} disabled={!emailOtpVerified} className="btn flex-fill btn-light-warning py-2 fs-5 text-uppercase px-5">Confirm</button>
            </div>
        </div>
    )
}