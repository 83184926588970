import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useRef, useState, useMemo } from "react";
import { Modal as BModal, Button, FormControl, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MessageReactions } from "../../../Screen/AICharacterScreen/components/MessageReaction";
import { AIApiInstance } from "../../../apis/AIAPI";
import { removeChatPrefix } from "../../../utils/customCharacter";
import { getAiCharacterImageUrl, getMessageAudioUrl, getSchedueledImageUrl } from "../../../utils/images";
import Avatar from "../../Comman/Avatar";
import { MoreOverlay } from "./MoreOverlay";
import AudioImage from '../../../assets/icons/playAudio.png';
import ExpressionsFormattedMessage from "../Chat/ExpressionsFormattedMessage";
import { VoiceAPIInstance } from "../../../apis/VoiceAPI";

dayjs.extend(relativeTime);

export const ChatItem = React.memo(
    ({ chatName, avatar, onSelect, index, message, onUpdateMessage, onRegenerateMessage, chatId, currentCharacter }) => {
        const { sentByUser, _id, messageContent, timeStamp, makeChatPublic, translatedMessage,
            showTranslatedMessage } = message;
        const [imageModal, setImageModal] = useState(false);
        const [editMessageModal, setEditMessageModal] = useState(false);
        const [messageInput, setMessageInput] = useState("");
        const [audioLoading, setAudioLoading] = useState(false);
        const [audioPlaying, setAudioPlaying] = useState(false);
        const audioRef = useRef();

        const messageToDisplay = showTranslatedMessage ? translatedMessage : messageContent;
        
        const prefixRemovedMessage = useMemo(() => {
          return removeChatPrefix(messageToDisplay)
        }, [messageToDisplay]);
        
        const toggleImageModal = () => {
            setImageModal(!imageModal);
        };

        const toggleEditMessageModal = () => {
            setEditMessageModal((prev) => !prev);
        };

        const openEditMessageModal = (message) => {
            setMessageInput(message);
            setEditMessageModal(true);
        };

        const handleMessageChange = (e) => {
            const value = e.target.value;
            setMessageInput(value);
        };

        const handleEditMessage = async () => {
            try {
                if (messageInput === "") {
                    return;
                }
                toggleEditMessageModal();
                await AIApiInstance.updateAiChatMessage(message?._id ?? "", messageInput);
                onUpdateMessage(message?._id, messageInput);
            } catch (error) {
                console.error(error);
                alert("Something went wrong.");
            }
        };

        const navigate = useNavigate();

        const handleAvatarClick = () => {
            navigate(`/character/${chatId}`, { state: { character: currentCharacter?.character } });
        };

        const handleAudioPlay = async () => {
            try {
                if (!audioPlaying) {
                    let audioUrl = "";
                    if (message?.audioUrl) {
                        audioUrl = getMessageAudioUrl(message?.audioUrl);
                    } else if (audioRef.current.src) {
                        audioUrl = audioRef.current.src;
                    } else {
                        setAudioLoading(true);
                        const res = await VoiceAPIInstance.getMessageAudio(message?._id, false); //not groupchat
                        audioUrl = getMessageAudioUrl(res?.data?.audioFileName);
                        setAudioLoading(false);
                    }
                    document.querySelectorAll("audio").forEach((el) => el.pause());
                    audioRef.current.src = audioUrl;
                    audioRef.current
                        .play()
                        .then(() => {})
                        .catch(console.error);
                    audioRef.current.addEventListener("ended", function () {
                        console.log("Audio playback has ended");
                        setAudioPlaying(false);
                    });
                    setAudioPlaying(true);
                } else {
                    setAudioPlaying(false);
                    await audioRef.current.pause();
                }
            } catch (error) {
                setAudioLoading(false);
                alert("Something went wrong.");
            }
        };

        return (
            <li className={`mb-3 d-flex ${sentByUser ? "flex-row-reverse" : "flex-row"} align-items-end`} style={{maxWidth:'100%'}}>
                {!sentByUser && <audio className="d-none" ref={audioRef} controls={true}></audio>}
                {message !== undefined && (
                    <div className={`max-width-70 ${!sentByUser ? "" : "text-right"}`}>
                        <div className="user-info mb-1">
                            {!sentByUser ? (
                                <Avatar
                                    name={chatName}
                                    avatarUrl={
                                        avatar !== "" && (avatar?.includes("http") ? avatar : getAiCharacterImageUrl(avatar))
                                    }
                                    className="lg rounded-circle me-1 br-50"
                                    onClick={handleAvatarClick}
                                />
                            ) : null}
                            <span className="text-muted small">{dayjs(timeStamp * 1000).fromNow()}</span>
                        </div>
                        <div className={`card border-0 p-3 text-light ${sentByUser ? 'chat-sent-by-user' : 'chat-received-by-user'}`}>
                            <div className="align-self-end pb-2 d-flex gap-2">
                                { audioLoading ?  <Spinner animation="border" size="sm" /> : audioPlaying ? 
                                <i onClick={handleAudioPlay} class="fa-solid fa-volume-xmark"></i> : <img className={sentByUser ? 'd-none': ""} onClick={handleAudioPlay} style={{ width: "2em" }} 
                                src={AudioImage} />}
                                <MoreOverlay
                                    message={message}
                                    onUpdateMessage={onUpdateMessage}
                                    onRegenerateMessage={onRegenerateMessage}
                                    handleEditPress={openEditMessageModal}
                                />
                            </div>

                            {message?.imageUrl && (
                                <div style={{ width: "150px" }}>
                                    <img
                                        style={{ width: "100%", height: "100%" }}
                                        src={getSchedueledImageUrl(message?.imageUrl)}
                                        alt={message?.imageUrl}
                                        onClick={toggleImageModal}
                                    />
                                </div>
                            )}
                            {_id === -1 ? (
                                <div className="d-flex flex-row align-items-center my-2">
                                    <div className="me-2">
                                     <ExpressionsFormattedMessage message={messageToDisplay} />
                                    </div>
                                    <Spinner animation="border" variant="secondary" size="sm" />
                                </div>
                            ) : (
                                <ExpressionsFormattedMessage message={prefixRemovedMessage} />
                            )}
                            {makeChatPublic && (
                                <div className="d-flex justify-content-between mt-3 w-75">
                                    <MessageReactions message={message} handleSelectedMessage={onSelect} i={index} />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <BModal show={imageModal} onHide={toggleImageModal} size="xl" centered>
                    <BModal.Body style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img
                            style={{ maxWidth: "100%", maxHeight: "100vh" }}
                            src={getSchedueledImageUrl(message?.imageUrl)}
                            alt={message?.imageUrl}
                        />
                    </BModal.Body>
                    <BModal.Footer>
                        <Button variant="primary" className="orange-gradient-btn" onClick={toggleImageModal}>
                            Close
                        </Button>
                    </BModal.Footer>
                </BModal>
                {/*  */}
                <BModal show={editMessageModal} onHide={toggleEditMessageModal}>
                    <BModal.Footer>
                        <Button variant="primary" onClick={toggleEditMessageModal}>
                            Close
                        </Button>
                    </BModal.Footer>
                    <BModal.Body
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1em"
                        }}
                    >
                        <h5>Update Message</h5>
                        <FormControl
                            type="text"
                            value={messageInput}
                            onChange={handleMessageChange}
                            placeholder="Enter new Message Text"
                        />
                        <Button onClick={handleEditMessage}>Submit</Button>
                    </BModal.Body>
                </BModal>
            </li>
        );
    }
);
