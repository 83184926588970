import React from "react";
import { getCouponImageUrl } from "../../../utils/images";

export const CouponAd = React.memo(({ adData }) => {
    const couponImage = adData?.couponPhoto
        ? adData.couponPhoto
        : adData?.couponPhotos && adData?.couponPhotos.length > 0
        ? adData.couponPhotos[0]
        : "";

    return (
        <li className={`mb-3 d-flex flex-row align-items-end`}>
            <div className={"max-width-70"}>
                <div className="card border-0 p-3 text-light" style={{ backgroundColor: "#FF731D" }}>
                    Btw here's a coupon
                </div>
                <a href={`/coupon/${adData?._id}`}>
                    <div className="mt-3 card p-3">
                        <h6 className="mb-4">{adData?.couponName}</h6>
                        <img alt={adData?.couponName} src={getCouponImageUrl(couponImage)} height="200" />
                    </div>
                </a>
            </div>
        </li>
    );
});