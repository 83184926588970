
import { socketConstants } from "../../../../apis/SocketApis";
import { imageGenDefaultParams } from "../../../../apis/SocketApis/imageGenDefaultParams";

export const generateImageHelper = (imageParams, characterData, ws, userId) => {
    console.log("generateImageHelper: ", imageGenDefaultParams);

    let imgPromptDefaults = imageGenDefaultParams;
    imgPromptDefaults.prompt = imageParams.current.prompt;
    imgPromptDefaults.negative_prompt = imageParams.current.negative_prompt;
    imgPromptDefaults.width = imageParams.current.image_size;
    imgPromptDefaults.height = imageParams.current.image_size;
    let gen_type = imageParams.current.gen_type;
    let base64ImageForImg2Img = imageParams.current.base64FileImage;

    let messageContent = "Positive Prompt: " + imageParams.current.prompt + "\n\n" + "Negative Prompt: " + imageParams.current.negative_prompt;
    
    let messageData = {
        userId: userId,
        gen_type: gen_type,
        base64ImageForImg2Img: base64ImageForImg2Img,
        type: socketConstants.CLIENT.IMAGE_GEN.MESSAGE,
        prompt: imgPromptDefaults,
        customAIModelId: characterData._id,
        device: "website",
        messageContent
    };

    ws.send(JSON.stringify(messageData));   
}

export const groupGenerateImageHelper = (imageParams, characterData, groupId, userId, ws) => {
    console.log("characterData._id: ", characterData._id);

    let imgPromptDefaults = imageGenDefaultParams;
    imgPromptDefaults.prompt = imageParams.current.prompt;
    imgPromptDefaults.negative_prompt = imageParams.current.negative_prompt;
    imgPromptDefaults.width = imageParams.current.image_size;
    imgPromptDefaults.height = imageParams.current.image_size;
    let gen_type = imageParams.current.gen_type;
    let base64ImageForImg2Img = imageParams.current.base64FileImage;

    let messageContent = "Positive Prompt: " + imageParams.current.prompt + "\n\n" + "Negative Prompt: " + imageParams.current.negative_prompt;
    
    let messageData = {
        userId: userId,
        gen_type: gen_type,
        base64ImageForImg2Img: base64ImageForImg2Img,
        type: socketConstants.CLIENT.IMAGE_GEN.GROUP_MESSAGE,
        prompt: imgPromptDefaults,
        customAIModelId: characterData?._id,
        groupId,
        device: "website",
        messageContent,
        imgPromptDefaults
    };

    ws.send(JSON.stringify(messageData));   
}