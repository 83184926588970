import * as Yup from 'yup';

export const AddDealSchema = Yup.object({
  brandDealName: Yup.string().label('Deal Name').required(),
  brandDealDescription: Yup.string().label('Deal Description').required(),
  budgetPerPost: Yup.number().moreThan(0).label('Budget Per Post').required(),
  revenuePercentShare: Yup.number().moreThan(0).label('Revnue Percent Share').required(),
  delieveryDate: Yup.date().label('Delievery Date').required(),
  isAdultContent: Yup.boolean().required(),
  category: Yup.string().label('Deal Category').required()
});
