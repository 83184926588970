export const languageLookup = {
  en: "English",
  ko: "Korean",
  ph: "Filipino",
  ch: "Mandarin",
  ur: "Urdu",
  jp: "Japanese",
  ru: "Russian",
  es: "Spain",
  vn: "Vietnamese"
};