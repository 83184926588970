import { useSelector } from "react-redux";
import PageTitle from "../../../Component/Comman/PageTitle";
import CouponsList from "../CouponsList/CouponsList";
import { useNavigate } from "react-router";

const CouponsScreen = () => {
    const navigate = useNavigate();
    const merchant = useSelector((state) => {
        return state?.merchant?.currentUserMerchant;
    });

    const coupons = useSelector((state) => {
        return state?.merchant?.currentUserMerchantCoupons;
    });

    return (
        <div className="container-xxl px-4">
            <PageTitle pagetitle="My Coupons" goBackLink="/my-store" />
            <div>
                <CouponsList
                    navigateToAddCoupon={() => {
                        navigate("/my-store/add-coupon", {
                            state: { merchantUUID: merchant._id, isAddingMerchant: false }
                        });
                    }}
                    navigateUpdateCouponScreen={(updatingCoupon) => {
                        navigate("/my-store/edit-coupon", {
                            state: {
                                merchantUUID: merchant._id,
                                isAddingMerchant: false,
                                updatingCoupon
                            }
                        });
                    }}
                    coupons={coupons !== null ? coupons : []}
                />
            </div>
        </div>
    );
};

export default CouponsScreen;
