import React, { useCallback, useEffect, useState }  from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { adminAPIInstance } from "../../../apis/AdminAPI";

function PairTable() {
    const [tokenPairs, setTokenPairs] = useState([]);

    const getTokenPairs = useCallback(async () => {
        try {
            const response = await adminAPIInstance.getTokenPairs();
            setTokenPairs(response.data);
        } catch (error) {
            console.error(error?.message ?? "Error fetching tokens");
            alert("Error fetching tokens");
        }
    }, []);

    useEffect(() => {
        getTokenPairs();
        return () => setTokenPairs([]);
    }, [getTokenPairs]);


    const columns = [
        {
            name: "Pair Name",
            selector: (row) => `${row.token1.symbol}/${row.token2.symbol}`
        },
        {
            name: "Blockchain & Addresses",
            selector: (row) => (
                <div className="d-flex flex-column">
                    <span>
                        {`${row.token1?.tokenInfo[0]?.network ?? "N/A"} - ${row.token1?.tokenInfo[0]?.address ?? "N/A"}`}
                    </span>
                    <span>
                        {`${row.token2?.tokenInfo[0]?.network ?? "N/A"} - ${row.token2?.tokenInfo[0]?.address ?? "N/A"}`}
                    </span>
                </div>
            )
        },
        {
            name: "Volume",
            selector: (row) => 0
        },
        {
            name: "Total Trades",
            selector: (row) => 0
        },
        {
            name: "",
            selector: (row) => (
                <div className="d-flex flex-row">
                    <Button variant="light me-2">Update</Button>
                    <Button variant="danger">Delete</Button>
                </div>
            )
        }
    ];
    return (
        <DataTable title="" columns={columns} data={tokenPairs} selectableRows={false} highlightOnHover={true} pagination={true} />
    );
}

export default PairTable;
