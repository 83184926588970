import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ch from './languages/ch.json';
import en from './languages/en.json';
import es from './languages/es.json';
import jp from './languages/jp.json';
import ko from './languages/ko.json';
import ph from './languages/ph.json';
import ru from './languages/ru.json';
import ur from './languages/ur.json';
import vn from './languages/vn.json';

i18n.use(initReactI18next).use(LanguageDetector).init({
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  resources: {
    en: en,
    ch: ch,
    ph: ph,
    ur: ur,
    ko: ko,
    vn: vn,
    jp: jp,
    ru: ru,
    es: es

  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  detection: {
    order: ['htmlTag', 'cookie', 'localStorage', 'path', 'subdomain'],
    caches: ['cookie'],
  },
});

export default i18n;
