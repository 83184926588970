import axios from "axios";
import { config } from "../utils/config";
import { readCookie } from "../utils/cookie";
import { createFormData } from "../utils/createFormData";
import { httpTokenInterceptor } from "./common";

class ClientCharAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/clientchar"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    getAllClientsChatList() {
        return this.axios.get("/get_all_clients_chats", {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getClientCharacterChatHistory(clientUserID, characterId, page = 1) {
        return this.axios.get(`/get_client_user_chat_history_with_reactions/${characterId}/${clientUserID}?page=${page}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    sendImageMessageAsCharacter(data) {
        return this.axios.post(`/send_image_as_char`, data, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

}

export const ClientCharAPIInstance = new ClientCharAPI();

export default ClientCharAPI;
