import { useNavigate } from "react-router";
import { getStoreDisplayableImage, getUserImageUrl } from "../../utils/images";
import { useTranslation } from "react-i18next";
import Avatar from "../Comman/Avatar";
import { Button, Card, Modal } from "react-bootstrap";
import { IoLocationOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useState } from "react";
import Table from 'react-bootstrap/Table';

export default function MerchantHeader({ merchant, isPublic = true, followMerchant, messageMerchant }) {
    const navigate = useNavigate();
    const { t } = useTranslation("merchant_store");
    const loggedInUserID = useSelector((state) => state.AuthReducer.user.userId);
    const [isModal, setIsModal] = useState(false);
    const [followersModal, setfollowersModal] = useState(false);
    const isFollowing = !merchant?.followers?.includes(loggedInUserID);
    const followers = useSelector((state) => {
        return state?.merchant?.currentUserMerchant?.followers ?? [];
    });
    const showAlert = () => setIsModal(true);

    const handleFollowersCountClick = () => {
        if (loggedInUserID === merchant?.userId) {
            setfollowersModal(true);
        }
    };

    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex flex-column">
                    <div className="justify-content-between d-flex align-items-start">
                        <div className="d-flex flex-row gap-4 align-items-center">
                            <Avatar
                                avatarUrl={getStoreDisplayableImage(merchant)}
                                name={merchant?.storeName}
                                className="xxl"
                            />

                            <div>
                                <Card.Title className="mb-2">{merchant?.storeName}</Card.Title>
                                <Card.Text className="text-muted align-items-center text-break mt-2 mb-2">
                                    <IoLocationOutline size={18} className="me-1 mb-1" />
                                    {merchant?.businessAddress}
                                </Card.Text>
                                {isPublic && (
                                    <div className="d-flex flex-row gap-3 align-items-center pt-2">
                                        <Card.Text onClick={handleFollowersCountClick} className="text-primary fw-medium mb-0">
                                            {merchant?.followers?.length} Follower{merchant?.followers?.length > 1 ? "s" : ""}
                                        </Card.Text>
                                        {
                                            <Button
                                                variant={isFollowing ? "primary" : "light"}
                                                onClick={loggedInUserID === merchant?.userId ? showAlert : followMerchant}
                                                size="sm"
                                            >
                                                {isFollowing ? "Follow" : "Unfollow"}
                                            </Button>
                                        }
                                    </div>
                                )}
                            </div>
                        </div>

                        {!isPublic ? (
                            <Button
                                variant="outline"
                                onClick={() =>
                                    navigate("edit", {
                                        state: {
                                            merchantUUID: merchant?._id,
                                            isAddingMerchant: false,
                                            updatingCoupon: null
                                        }
                                    })
                                }
                                className="text-primary"
                            >
                                Edit
                            </Button>
                        ) : (
                            <Button
                                variant="primary"
                                onClick={loggedInUserID === merchant?.userId ? showAlert : messageMerchant}
                                size="sm"
                            >
                                Send Message
                            </Button>
                        )}
                    </div>
                    <div className="row">
                        <div className=" align-items-start d-flex justify-content-between flex-row flex-wrap">
                            <Card.Text className="text-muted  text-break d-flex mt-2">{merchant?.storeDescription}</Card.Text>

                            {!isPublic && (
                                <div className="d-flex gap-2">
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            navigate(`/my-store/public/${merchant?._id}`, {
                                                state: {
                                                    merchantID: merchant?._id,
                                                    from: "my-store"
                                                }
                                            })
                                        }
                                    >
                                        {t("View_As_Public")}
                                    </Button>
                                    <Button variant="primary"
                                    onClick={() => {alert('Coming Soon!');}}
                                    >{t("Upgrade")}</Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <FollowersModal isModal={followersModal} setIsModal={setfollowersModal} followers={followers}/>
            <Modal
                show={isModal}
                onHide={() => {
                    setIsModal(false);
                }}
            >
                <Modal.Header>
                    <h5 className="modal-title" id="staticBackdropLiveLabel">
                        Warning
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setIsModal(false);
                        }}
                    ></button>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        The action you're trying to do doesn't work while using{" "}
                        <span className="fw-medium">View As Public</span>
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            setIsModal(false);
                        }}
                    >
                        OK
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


const FollowersModal = ({ isModal, setIsModal, followers = [] }) => {
    return (
        <Modal
            show={isModal}
            onHide={() => {
                setIsModal(false);
            }}
        >
            <Modal.Header>
                <h5 className="modal-title" id="staticBackdropLiveLabel">
                    Followers
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setIsModal(false);
                    }}
                ></button>
            </Modal.Header>

            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Username</th>
                            <th>Profile</th>
                        </tr>
                    </thead>
                    {followers?.length > 0 ? (
                        <tbody>
                            {followers.map((follower, i) => (
                                <tr key={follower?._id ?? `follower${i}`}>
                                    <td>{i+1}</td>
                                    <td>{follower?.username ?? 'No username'}</td>
                                    <td>
                                        <img src={getUserImageUrl(follower?.avatar_url)} alt="follower-profile"/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <h5>You do not have any followers</h5>
                    )}
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                        setIsModal(false);
                    }}
                >
                    OK
                </button>
            </Modal.Footer>
        </Modal>
    );
};