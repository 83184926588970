import React from 'react'
import { Button } from 'react-bootstrap'

export default function ReportAdultModalBody({onSubmit}) {
  return (
    <div className='d-flex flex-column gap-3'>
        <span>Do you want to report this character as Adult?</span>
        <Button variant='primary' onClick={() => onSubmit(true)}>Yes</Button>
        <Button variant='secondary' onClick={() => onSubmit(false)}>No</Button>
    </div>
  )
}
