import React from "react";
import { NO_IMAGE, getMerchantImageUrl } from "../../utils/images";
import Avatar from "../Comman/Avatar";

export default function DealCard({ id, onClick, title, creatorName, itemUrl, onSubtitleClick, coupon }) {
    
    const handleImageError = (e) => {
        const target = e.target;
        target.src = NO_IMAGE;
    }

    return (
        <div className="d-flex flex-column p-2 gap-2 deal-card">
            <div className="deal-image" onClick={() => onClick(id)}>
                <div className="deal-img-overlay"></div>
                <img src={itemUrl} alt="" onError={handleImageError}/>
                <div className="d-flex flex-column gap-1 deal-info">
                    <span className="color-white deal-price">
                        {coupon?.priceInDollars} {coupon?.currency ?? "USD"}
                    </span>
                    <span className="color-white">{title}</span>
                </div>
            </div>
            <div onClick={onSubtitleClick} className="d-flex flex-row gap-2 align-items-center">
                <Avatar
                    className="deal-store-img"
                    name="s"
                    avatarUrl={getMerchantImageUrl(coupon?.merchantUUID?.storePhotos?.[0])}
                />
                <strong>
                    <span>{creatorName}</span>
                </strong>
            </div>
        </div>
    );
}
