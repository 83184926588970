import React, { useCallback, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { adminAPIInstance } from "../../../apis/AdminAPI";
import { tokenTypes } from "../../../utils/config";

export const PointToPoint = () => {
    const [pointTokens, setPointTokens] = useState([]);
    const [pointToken1, setPointToken1] = useState("");
    const [pointToken2, setPointToken2] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);

    const fetchAllMerchantPoints = useCallback(async () => {
        const result = await adminAPIInstance.getAllPointTokens();
        setPointTokens(result?.data?.pointTokens ?? []);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (pointToken1 === "" || pointToken2 === "") {
            alert("Please input token 1 or token 2");
            return;
        }

        if (pointToken1 === pointToken2) {
            alert("Same token is not allowed");
            return;
        }

        const body = {
            token1: {
                type: tokenTypes.POINT,
                pointTokenId: pointToken1
            },
            token2: {
                type: tokenTypes.POINT,
                pointTokenId: pointToken2
            }
        };

        try {
            setSubmitLoading(true);
            await adminAPIInstance.addTokenPair({ body });

            setSubmitLoading(false);
            setPointToken1("");
            setPointToken2("");
            alert("Successfully added pair");
        } catch (error) {
            console.error(error);
            alert("Error adding pair");
        }
    };

    useEffect(() => {
        fetchAllMerchantPoints();
    }, [fetchAllMerchantPoints]);

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Label htmlFor="token2Input">Merchant Point 1</Form.Label>
                <div className="d-flex flex-row mb-4">
                    <Form.Select
                        aria-label="Token1 select chain"
                        className="me-2"
                        style={{ flex: 1 }}
                        value={pointToken1}
                        onChange={(e) => setPointToken1(e.target.value)}
                    >
                        <option value="">--Select point token--</option>
                        {pointTokens.map((token) => (
                            <option key={`token1-${token._id}`} value={token._id}>
                                {token.pointRewardName}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <Form.Label htmlFor="token2Input">Merchant Point 2</Form.Label>
                <div className="d-flex flex-row mb-4">
                    <Form.Select
                        aria-label="Token2 select chain"
                        className="me-2"
                        style={{ flex: 1 }}
                        value={pointToken2}
                        onChange={(e) => setPointToken2(e.target.value)}
                    >
                        <option value="">--Select point token--</option>
                        {pointTokens.map((token) => (
                            <option key={`token2-${token._id}`} value={token._id}>
                                {token.pointRewardName}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <Button type="submit" variant="primary" disabled={submitLoading}>
                    Submit
                </Button>
            </Form>
        </div>
    );
};
