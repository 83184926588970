import React from "react";
import { Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
import {
    OrderbookDatafirst,
    OrderbookDatasecond,
    OrderbookLongData,
    OrderbookSortData
} from "../Data/ExchangeData/OrderbookData";

function OrderBook({ bids, asks, marketPrice, currentSide, pair }) {
    const bidsSliced = bids.slice(0, 10);
    const asksSliced = asks.slice(0, 10);

    return (
        <div className="card" id="orderbook-container">
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold ">{pair.toUpperCase()} Order Book</h6>
            </div>
            <div className="card-body">
                <Tab.Container defaultActiveKey="first">
                    <Tab.Content className="tab-content">
                        <Tab.Pane className="tab-pane fade" id="Both" eventKey="first">
                            <div id="priceTabledown_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                <div className="row">
                                    <div
                                        className="col-sm-12 col-md-6 mb-2 "
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        <div id="myDataTable_filter" className="dataTables_filter">
                                            <label className="d-flex align-items-center">
                                                Search:
                                                <div className="mx-1">
                                                    <input type="search" className="form-control" />
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <DataTable
                                            title={OrderbookDatasecond.title}
                                            columns={OrderbookDatasecond.columns}
                                            data={asksSliced}
                                            defaultSortField="title"
                                            selectableRows={false}
                                            highlightOnHover={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-5">
                                        <div
                                            className="dataTables_info"
                                            id="priceTabledown_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing 1 to 10 of 10 entries
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-7">
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="priceTabledown_paginate"
                                        >
                                            <ul className="pagination">
                                                <li
                                                    className="paginate_button page-item previous disabled"
                                                    id="priceTabledown_previous"
                                                >
                                                    <a href="#!" className="page-link">
                                                        Previous
                                                    </a>
                                                </li>
                                                <li className="paginate_button page-item active">
                                                    <a href="#!" className="page-link">
                                                        1
                                                    </a>
                                                </li>
                                                <li
                                                    className="paginate_button page-item next disabled"
                                                    id="priceTabledown_next"
                                                >
                                                    <a href="#!" className="page-link">
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 ms-3">
                                <h5
                                    className={
                                        currentSide === "bid"
                                            ? "color-price-up"
                                            : currentSide === "ask"
                                            ? "color-price-down"
                                            : null
                                    }
                                >
                                    {parseFloat(marketPrice).toFixed(6)}
                                </h5>
                            </div>

                            <div id="priceTableup_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                <div className="row">
                                    <div
                                        className="col-sm-12 col-md-6 mb-2 "
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        <div id="myDataTable_filter" className="dataTables_filter">
                                            <label className="d-flex align-items-center">
                                                Search:
                                                <div className="mx-1">
                                                    <input type="search" className="form-control" />
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <DataTable
                                            title={OrderbookDatafirst.title}
                                            columns={OrderbookDatafirst.columns}
                                            data={bidsSliced}
                                            defaultSortField="title"
                                            selectableRows={false}
                                            highlightOnHover={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-5">
                                        <div
                                            className="dataTables_info"
                                            id="priceTableup_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing 1 to 10 of 10 entries
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-7">
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="priceTableup_paginate"
                                        >
                                            <ul className="pagination">
                                                <li
                                                    className="paginate_button page-item previous disabled"
                                                    id="priceTableup_previous"
                                                >
                                                    <a href="#!" className="page-link">
                                                        Previous
                                                    </a>
                                                </li>
                                                <li className="paginate_button page-item active">
                                                    <a href="#!" className="page-link">
                                                        1
                                                    </a>
                                                </li>
                                                <li
                                                    className="paginate_button page-item next disabled"
                                                    id="priceTableup_next"
                                                >
                                                    <a href="#!" className="page-link">
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>

                        <Tab.Pane className="tab-pane fade" id="Long" eventKey="second">
                            <div id="priceTableuponly_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                <div className="row">
                                    <div
                                        className="col-sm-12 col-md-6 mb-2 "
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        <div id="myDataTable_filter" className="dataTables_filter">
                                            <label className="d-flex align-items-center">
                                                Search:
                                                <div className="mx-1">
                                                    <input type="search" className="form-control" />
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <DataTable
                                            title={OrderbookLongData.title}
                                            columns={OrderbookLongData.columns}
                                            data={OrderbookLongData.rows}
                                            defaultSortField="title"
                                            selectableRows={false}
                                            highlightOnHover={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-5">
                                        <div
                                            className="dataTables_info"
                                            id="priceTableuponly_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing 1 to 10 of 10 entries
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-7">
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="priceTableuponly_paginate"
                                        >
                                            <ul className="pagination">
                                                <li
                                                    className="paginate_button page-item previous disabled"
                                                    id="priceTableuponly_previous"
                                                >
                                                    <a href="#!" className="page-link">
                                                        Previous
                                                    </a>
                                                </li>
                                                <li className="paginate_button page-item active">
                                                    <a href="#!" className="page-link">
                                                        1
                                                    </a>
                                                </li>
                                                <li
                                                    className="paginate_button page-item next disabled"
                                                    id="priceTableuponly_next"
                                                >
                                                    <a href="#!" className="page-link">
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane className="tab-pane fade" id="Short" eventKey="third">
                            <div id="priceTabledownonly_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                <div className="row">
                                    <div
                                        className="col-sm-12 col-md-6 mb-2 "
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        <div id="myDataTable_filter" className="dataTables_filter">
                                            <label className="d-flex align-items-center">
                                                Search:
                                                <div className="mx-1">
                                                    <input type="search" className="form-control" />
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <DataTable
                                            title={OrderbookSortData.title}
                                            columns={OrderbookSortData.columns}
                                            data={OrderbookSortData.rows}
                                            defaultSortField="title"
                                            selectableRows={false}
                                            highlightOnHover={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-5">
                                        <div
                                            className="dataTables_info"
                                            id="priceTabledownonly_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing 1 to 10 of 10 entries
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-7">
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="priceTabledownonly_paginate"
                                        >
                                            <ul className="pagination">
                                                <li
                                                    className="paginate_button page-item previous disabled"
                                                    id="priceTabledownonly_previous"
                                                >
                                                    <a href="#!" className="page-link">
                                                        Previous
                                                    </a>
                                                </li>
                                                <li className="paginate_button page-item active">
                                                    <a href="#!" className="page-link">
                                                        1
                                                    </a>
                                                </li>
                                                <li
                                                    className="paginate_button page-item next disabled"
                                                    id="priceTabledownonly_next"
                                                >
                                                    <a href="#!" className="page-link">
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
}

export default OrderBook;
