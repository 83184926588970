import CommentImg from "../../../assets/icons/comment.png";
import UpVoteImg from "../../../assets/icons/upvote.png";
import DownVoteImg from "../../../assets/icons/downvote.png";
import { AIApiInstance } from "../../../apis/AIAPI";
import { selectCurrentUser } from "../../../Redux/selectors/auth";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export const messageReactionTypes = {
    COMMENT: "COMMENT",
    UPVOTE: "UPVOTE",
    DOWNVOTE: "DOWNVOTE"
};

const messageReactionInfo = {
    [messageReactionTypes.COMMENT]: {
        imageSrc: CommentImg
    },
    [messageReactionTypes.UPVOTE]: {
        imageSrc: UpVoteImg
    },
    [messageReactionTypes.DOWNVOTE]: {
        imageSrc: DownVoteImg
    }
};

export const MessageReaction = ({
    type = messageReactionTypes.COMMENT,
    count = 0,
    handleClick = () => {},
    voted = null
}) => {
    const _handleClick = () => {
        if (!getDisabledStatus()) {
            handleClick(type);
        }
    };

    const getStyle = () => {
        if (type !== messageReactionTypes.COMMENT) {
            if (type === messageReactionTypes.UPVOTE && voted === true) {
                return "opacity-50";
            }
            if (type === messageReactionTypes.DOWNVOTE && voted === false) {
                return "opacity-50";
            }
            return "";
        }
        return "";
    };

    const getDisabledStatus = () => {
        if (type !== messageReactionTypes.COMMENT) {
            if (type === messageReactionTypes.UPVOTE && voted === true) {
                return true;
            }
            if (type === messageReactionTypes.DOWNVOTE && voted === false) {
                return true;
            }
            return false;
        }
        return false;
    };

    return (
        <div className={`message-reaction-container ${getStyle()}`} onClick={_handleClick}>
            <div className="message-reaction-icon">
                <img src={messageReactionInfo[type].imageSrc} alt={type} />
            </div>
            <span>{count}</span>
        </div>
    );
};

export const MessageReactions = ({ message, handleSelectedMessage = () => {}, i = null }) => {
    const user = useSelector(selectCurrentUser);
    const [comments, setComments] = useState(() => {
        return message?.comments ?? [];
    });
    const [upVotes, setupVotes] = useState(() => {
        return message?.upvotes ?? [];
    });
    const [downVotes, setdownVotes] = useState(() => {
        return message?.downvotes ?? [];
    });
    const [voted, setVoted] = useState(null);

    useEffect(() => {
        const isVoted = upVotes.find((vt) => {
            return vt?.createdBy === user?.userId;
        });
        if (isVoted) {
            setVoted(true);
        }
    }, [upVotes, user?.userId]);

    useEffect(() => {
        const isVoted = downVotes.find((vt) => {
            return vt?.createdBy === user?.userId;
        });
        if (isVoted) {
            setVoted(false);
        }
    }, [downVotes, user?.userId]);

    const addUpVote = () => {
        setdownVotes((prev) => {
            const newDownVotes = prev.filter((vt) => {
                return vt?.createdBy !== user?.userId;
            });
            return newDownVotes;
        });
        setupVotes((prev) => {
            const newUpVotes = [...prev];
            newUpVotes.push({ messageId: message?._id, voteType: true, createdBy: user?.userId });
            return newUpVotes;
        });
    };

    const addDownVote = () => {
        setdownVotes((prev) => {
            const newDownVotes = [...prev];
            newDownVotes.push({ messageId: message?._id, voteType: true, createdBy: user?.userId });
            return newDownVotes;
        });
        setupVotes((prev) => {
            const newUpVotes = prev.filter((vt) => {
                return vt?.createdBy !== user?.userId;
            });
            return newUpVotes;
        });
    };

    const updateVotes = (voteType) => {
        voteType = voteType === messageReactionTypes.UPVOTE ? true : false;
        AIApiInstance.voteMessage(message?.customAIModelId, voteType, message?._id)
            .then((_) => {
                voteType ? addUpVote() : addDownVote();
            })
            .catch((e) => {
                alert("Something went wrong.");
            });
    };

    const handleReactionClick = (type) => {
        switch (type) {
            case messageReactionTypes.COMMENT:
                handleSelectedMessage(i);
                break;
            case messageReactionTypes.UPVOTE:
                updateVotes(type);
                break;
            case messageReactionTypes.DOWNVOTE:
                updateVotes(type);
                break;
            default:
                break;
        }
    };

    return (
        <div className="d-flex flex-row gap-3">
            <MessageReaction
                type={messageReactionTypes.COMMENT}
                count={comments?.length}
                handleClick={handleReactionClick}
                voted={voted}
            />
            <MessageReaction
                type={messageReactionTypes.UPVOTE}
                count={upVotes?.length}
                handleClick={handleReactionClick}
                voted={voted}
            />
            <MessageReaction
                type={messageReactionTypes.DOWNVOTE}
                count={downVotes?.length}
                handleClick={handleReactionClick}
                voted={voted}
            />
        </div>
    );
};
