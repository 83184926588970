import { generateRandomId } from "../../../../utils/id";
import { socketConstants } from "../../../../apis/SocketApis";

export const groupcChatSocketHelper = (eventData, 
    selectedType, 
    setMessages, 
    setCharReplying, 
    user, 
    setCharData, 
    setSpicyAiSelected,
    chatId, currentGroupChat, setInProgressImage, setImgGenButtonDisabled) => {

    const { CLIENT, SERVER } = socketConstants;
    // console.log("characterSocketRecieverHelper ?.type: ", eventData?.type);
    switch (eventData?.type) {
        case SERVER.GROUP_CHAT.MESSAGE:
            console.log("SERVER.GROUP_CHAT.NEW_MESSAGE: ", eventData?.groupMessage);
            // alert("eventData?.groupMessage"+JSON.stringify(eventData?.groupMessage));
                let senderInfo;
                if(eventData?.groupMessage?.sentByUser){
                   senderInfo = currentGroupChat?.userMembers?.find(u => {
                        return u?._id === eventData?.groupMessage?.userId
                    }) 
                } else {
                    setCharReplying(false);
                    senderInfo = currentGroupChat?.aiMembers?.find(u => {
                        return u?._id === eventData?.groupMessage?.characterId
                    }) 
                }
                setMessages((prev) => {
                    console.log(currentGroupChat)
                    let temp = [...prev];
                    temp.push({
                        ...eventData?.groupMessage,
                        ...(eventData?.groupMessage?.sentByUser ? { usersInfo: senderInfo } : { charactersInfo: senderInfo })
                    });
                    return [...temp];
                });
                case SERVER.IMAGE_GEN.MINER_SEND_IMAGE_PROGRESS_TO_CLIENT:
                    //from image generator replier
                    console.log("MINER_SEND_IMAGE_PROGRESS_TO_CLIENT user.userId: ", user?.userId);
                    //imageQueueID, result
                        const userIdForImage =eventData?.imageQueueID?.current[0]?.sender_userId;
                    if (selectedType !== "client" && userIdForImage==user.userId) {
                        //setImgGenButtonDisabled(true);
                        const percentage = eventData?.result?.progress;
                        const baseImage =  eventData?.result?.current_image;
                        const imageQueueID = eventData?.imageQueueID?.current[0]?.sender_userId;
                        console.log("imageQueueID: ", imageQueueID);
                        console.log("eventData?.result?.imageQueueID?.sender_userId: ", eventData?.imageQueueID?.current[0]?.sender_userId);
                        setInProgressImage({"percent":percentage, "base64":baseImage });
                        //console.log("MINER_SEND_IMAGE_PROGRESS_TO_CLIENT eventData: ", eventData.result.current_image);
                    }
                break;

                case SERVER.IMAGE_GEN.FINISHED_IMAGE_SENT_BACK_TO_USER: {
                    
                    const userIdForImage = eventData?.AiChat?.userId;
                    
                    if (eventData?.AiChat?.groupId === currentGroupChat?._id && userIdForImage==user.userId) {
                        setImgGenButtonDisabled(false);
                        setMessages(prev => [...prev, eventData?.AiChat]);
                    }
                }
                break;
            break;
        
    }//end switch
}