import React, { useState, useEffect } from 'react';
import Home from '../../../assets/icons/menu/home.png'; 
import Chat from '../../../assets/icons/menu/chat.png'; 
import Wallet from '../../../assets/icons/menu/wallet.png';  
import Profile from '../../../assets/icons/menu/profile.png';
import {NavLink } from "react-router-dom";
import "./bottomNav.css";

const BottomNavbar = () => {
    const [isVisible, setIsVisible] = useState(true); 

    const handleResize = () => {
        console.log('resizing');
        if (window.innerWidth > 766) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    }

    useEffect(() => {
        //set when page render
        handleResize();

        const mediaQuery = window.matchMedia("(max-width: 766px)");
        mediaQuery.addEventListener('change', handleResize);

        return () => mediaQuery.removeEventListener('change', handleResize);
    }, []);


    return (
        <div
        className="or-bottom-nav-group btn-group" role="group" aria-label="radio toggle button group"

        style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#000',
            textAlign: 'center',
            padding: '10px',
            display: isVisible ? 'flex' : 'none',
            justifyContent: 'space-around',
            zIndex: 5,
            minWidth: '320px',
        }}>
            <NavLink
                to={'/'}
                className={(navData) => (navData.isActive ? "bottom-nav-icon-active btn" : 'btn')}
                >
                <i class="fa-solid fa-house"></i>
            </NavLink>

            <NavLink
                to={'/chat'}
                className={(navData) => (navData.isActive ? "bottom-nav-icon-active btn" : 'btn')}
                >
                <i class="fa-solid fa-message"></i>            </NavLink>

            <NavLink
                to={'/coupon-wallet'}
                className={(navData) => (navData.isActive ? "bottom-nav-icon-active btn" : 'btn')}
                >
                <i class="fa-solid fa-wallet"></i>
            </NavLink>

            <NavLink
                to={'/my-profile'}
                className={(navData) => (navData.isActive ? "bottom-nav-icon-active btn" : 'btn')}
                >
                <i class="fa-solid fa-user"></i>
            </NavLink>

        </div>
    );
};

export default BottomNavbar;