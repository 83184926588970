import { useEffect, useState } from "react";
import Avatar from '../Comman/Avatar'
import { useNavigate } from 'react-router';
import { getAiCharacterImageUrl, getAiCharacterImageUrlV2, getSchedueledImageUrl, getUserImageUrl } from '../../utils/images';
import HiddenImage from '../../assets/images/hide-image.png';
import { formatDate } from '../../utils/formatter/dateFormatter';
import { MessageReactions } from '../../Screen/AICharacterScreen/components/MessageReaction';
import { Button, Modal } from 'react-bootstrap';
import { theme } from '../../utils/config';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import { AIApiInstance } from "../../apis/AIAPI";

export default function ({conversations = [], groupChatConversations = [], autoTranslate = false}) {
    const navigate = useNavigate();

    const handleAvatarClick = (character_id) => {
        navigate(`/character/${character_id}`);
    }

    const handleViewConversation = (character_id, user_id) => {
        navigate(`/character/${character_id}?userId=${user_id}`);
    }

    const getGroupMessageUserInfo = (message, conversation) => {
        if(message?.sentByUser){
            return conversation?.userMembers?.find(u => u?._id === message?.userId)
        }
    };

  return (
      <div className="d-flex flex-column gap-3 my-2 px-2">
         {groupChatConversations.map((conversation, index) => (
                    <div
                        className="d-flex flex-column gap-3 p-4 position-relative conversation-border"
                        key={`conversation-${index}`}
                    >
                  <div
                      onClick={() => {
                        handleAvatarClick(conversation?.aiMembers?.[0]?._id);
                    }}
                      className="d-flex flex-row align-items-center gap-3"
                  >
                      <Avatar
                          avatarUrl={getAiCharacterImageUrlV2(conversation?.aiMembers?.[0])}
                          name={conversation?.aiMembers?.[0]?.characterName}
                          className="br-50"
                      />
                      <span>{conversation?.aiMembers?.[0]?.characterName}</span>
                  </div>
                  {conversation?.groupMessages?.map((message, mindex) => (
                      <PublicChatItem
                          characterName={conversation?.aiMembers?.[0]?.characterName}
                          message={{...message, timeStamp: message?.createdAt}}
                          sendReceivePosition={true}
                          info={{ user: getGroupMessageUserInfo(message, conversation) }}
                          characterAvatar={getAiCharacterImageUrlV2(conversation?.aiMembers?.[0])}
                          key={`mindex-${mindex}`}
                          autoTranslate={autoTranslate}
                      />
                  ))}
                  <Button className="p-3"
                      onClick={() => handleViewConversation(conversation?.aiMembers?.[0]?._id, conversation?.user_info?._id)}
                      style={{
                            background: 'linear-gradient(175deg, rgba(250,33,33,1) 0%, rgba(249,139,38,1) 100%)',
                            color: "#FFFFFF",
                            margin: "0.4em",
                            borderRadius: '8px'
                      }}
                  >
                      View full conversation
                  </Button>
              </div>
          ))}
          {conversations.map((conversation, index) => (
                    <div
                        className="d-flex flex-column gap-3 p-4 position-relative conversation-border"
                        key={`conversation-${index}`}
                    >
                  <div
                      onClick={() => handleAvatarClick(conversation?.character_info?._id)}
                      className="d-flex flex-row align-items-center gap-3"
                  >
                      <Avatar
                          avatarUrl={getAiCharacterImageUrlV2(conversation?.character_info)}
                          name={conversation?.character_info?.characterName}
                          className="br-50"
                      />
                      <span>{conversation?.character_info?.characterName}</span>
                  </div>
                  {conversation?.topAiChats?.slice().reverse().map((message, mindex) => (
                      <PublicChatItem
                          characterName={conversation?.character_info?.characterName}
                          message={message}
                          sendReceivePosition={true}
                          info={{ user: conversation?.user_info }}
                          characterAvatar={getAiCharacterImageUrlV2(conversation?.character_info)}
                          key={`mindex-${mindex}`}
                          autoTranslate={autoTranslate}
                      />
                  ))}
                  <Button className="p-3"
                      onClick={() => handleViewConversation(conversation?.character_info?._id, conversation?.user_info?._id)}
                      style={{
                            background: 'linear-gradient(175deg, rgba(250,33,33,1) 0%, rgba(249,139,38,1) 100%)',
                            color: "#FFFFFF",
                            margin: "0.4em",
                            borderRadius: '8px'
                      }}
                  >
                      View full conversation
                  </Button>
              </div>
          ))}
      </div>
  );
}

const PublicChatItem = ({
    info,
    characterName = "",
    characterAvatar = "",
    message = null,
    handleClick = () => {},
    i = null,
    containerClass = "w-100",
    sendReceivePosition = false,
    handleSelectedMessage = () => {},
    autoTranslate = false
}) => {
    const navigate = useNavigate();
    const [imageModal, setImageModal] = useState(false);
    const user = useSelector(selectCurrentUser);
    const [translation, setTranslation] = useState(null);
    const _message = message ;
    const type = _message?.messageContent?.encrypted_text ? 'character' : 'group';
    
    useEffect(() => {
        const shouldTraslate = autoTranslate && user?.userLanguageCode !== "en" && !_message?.translatedMessages?.[user?.userLanguageCode];
        console.log('hook called', shouldTraslate, autoTranslate, user?.userLanguageCode, _message?.translatedMessages?.[user?.userLanguageCode]);
        if (shouldTraslate) {
            console.log(_message)
            AIApiInstance.chat_message_translate(
                _message?.messageContent?.encrypted_text ?? _message?.message,
                _message?._id,
                type
            )
                .then((res) => {
                    setTranslation(res?.data?.data?.translation);
                })
                .catch(console.error);
        } else if(autoTranslate && user?.userLanguageCode !== "en") {
            setTranslation(_message?.translatedMessages?.[user?.userLanguageCode]);
        }
    }, [autoTranslate, user?.userLanguageCode]);

    const toggleImageModal = () => {
        setImageModal(!imageModal);
    };

    const getContainerStyle = () => {
        if (sendReceivePosition) {
            return !_message?.sentByUser ? "justify-content-start" : "justify-content-end";
        }
        return "";
    };

    const getMessageStyle = () => {
        if (sendReceivePosition) {
            return !_message?.sentByUser ? "received-message-container" : "sent-message-container";
        }
        return "";
    };

    const getTextStyle = () => {
        return sendReceivePosition ? 'text-white' : '';
    };

    const getMessageImage = () => {
        return _message?.imageUrl !== "hidden" ? getSchedueledImageUrl(_message?.imageUrl) : HiddenImage;
    };

    const handleImageClick = () => {
        toggleImageModal()
        if (_message?.imageUrl === "hidden") {
            const confirm = window.confirm("Please Subscribe to see the Image.");
            if (confirm) {
                navigate("/premium-price");
            }
        }
    };

    return (
        <>
                <div key={`public-chat-bubble${i}`} className={`d-flex ${getContainerStyle()} ${containerClass} public-chat-bubble`}>
                    <div
                        className={`card p-3 public-chat-item gap-2 w-100 ${getMessageStyle()}`}
                        onClick={() => {
                            handleClick(i);
                        }}
                    >
                        <div className="d-flex flex-row gap-2 align-items-center">
                            <Avatar
                                avatarUrl={_message?.sentByUser ? getUserImageUrl(info?.user?.avatar_url) : characterAvatar}
                                name={info?.user?.username}
                                className="br-50 public-chat-avatar"
                            />
                            <span className={getTextStyle()}>
                                {_message?.sentByUser ? info?.user?.username : characterName}
                            </span>
                            <span className={`public-message-date px-4 ${getTextStyle()}`}>
                                {formatDate(_message?.timeStamp)}
                            </span>
                        </div>
                        {sendReceivePosition && _message?.imageUrl && (
                            <div onClick={handleImageClick} style={{ width: "20%", height: "100%", cursor: "pointer" }}>
                                <img src={getMessageImage()} style={{ width: "100%", height: "100%" }} alt="" />
                            </div>
                        )}
                        <p className={`my-0 ${getTextStyle()}`}>{translation ?? _message?.messageContent?.encrypted_text ?? _message?.message}</p>
                        <MessageReactions key={`reactions${i}`} message={_message} handleSelectedMessage={handleSelectedMessage} i={i} />
                    </div>
                </div>
                <Modal show={imageModal} onHide={toggleImageModal} size="xl" centered>
                    <Modal.Body style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img
                            style={{ maxWidth: "100%", maxHeight: "100vh" }}
                            src={getSchedueledImageUrl(message?.imageUrl)}
                            alt={message?.imageUrl}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" className="orange-gradient-btn" onClick={toggleImageModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
        </>
    );
};