export function formatUnixTimestamp(timestamp) {
  const currentDate = new Date();
  const targetDate = new Date(timestamp * 1000);
  const diffSeconds = Math.floor((currentDate - targetDate) / 1000);

  if (diffSeconds < 60) {
    return 'just now';
  } else if (diffSeconds < 120) {
    return '1 minute ago';
  } else if (diffSeconds < 3600) {
    const minutes = Math.floor(diffSeconds / 60);
    return `${minutes} minutes ago`;
  } else if (diffSeconds < 7200) {
    return '1 hour ago';
  } else if (diffSeconds < 86400) {
    const hours = Math.floor(diffSeconds / 3600);
    return `${hours} hours ago`;
  } else if (diffSeconds < 172800) {
    return 'yesterday';
  } else {
    const days = Math.floor(diffSeconds / 86400);
    return `${days} days ago`;
  }
}