import { MerchantApiInstance } from '../../apis/MerchantsAPI';
import { PointRewardApiInstance } from '../../apis/PointRewardApi';
// import { setAlertDialog, setDefaultLoading } from '../Reducers/Mainreducer';
import {
  setCoupons,
  setCurrentUserMerchant,
  setCurrentUserMerchantCoupons,
  setPurchasedCoupons,
  setAllMyPoints,
  setCurrentUserCouponsHistory,
  setCurrentUserMerchantTotalCouponSales,
  setLoadingPurchasedCoupons,
  setLoadingCoupons,
} from '../Reducers/StoreReducer';

export const getCurrentUserMerchant = (token) => async (dispatch) => {
  try {
    // dispatch(setDefaultLoading(true));/

    const merchantResponse = await MerchantApiInstance.getCurrentUserMerchant(
      token
    );
    // dispatch(setDefaultLoading(false));

    if (merchantResponse.data?.data?.merchant) {
      dispatch(
        setCurrentUserMerchant(merchantResponse.data.data.merchant)
      );
    }
  } catch (error) {
    // dispatch(setDefaultLoading(false));
    console.error(error?.message ?? 'Error fetching user merchant');
  }
};

export const getCurrentUserMerchantCoupons = (merchantUUID, token) => async (
  dispatch
) => {
  try {
    const merchantResponse = await MerchantApiInstance.getCouponsByMerchant(
      merchantUUID,
      token
    );
    if (merchantResponse.data?.data?.coupons) {
      dispatch(
        setCurrentUserMerchantCoupons(merchantResponse.data.data.coupons)
      );
    }
  } catch (error) {
    console.error(error?.message ?? 'Error fetching coupons');
  }
};

export const getAllCoupons = (token) => async (dispatch) => {
  try {
    dispatch(setLoadingCoupons(true));

    const couponResponse = await MerchantApiInstance.getAllCoupons(token);

    dispatch(setLoadingCoupons(false));

    if (couponResponse.data?.data?.coupons) {
      dispatch(setCoupons(couponResponse.data.data.coupons));
    }
  } catch (error) {
    console.error(error?.message ?? 'Error fetching coupons');
    dispatch(setLoadingCoupons(false));
  }
};

export const getAllPurchasedCoupons = (token) => async (dispatch) => {
  try {
    dispatch(setLoadingPurchasedCoupons(true));

    const couponResponse = await PointRewardApiInstance.getPurchasedCoupons(
      token
    );

    dispatch(setLoadingPurchasedCoupons(false));

    if (couponResponse.data?.data?.purchasedCoupons) {
      dispatch(
        setPurchasedCoupons(couponResponse.data.data.purchasedCoupons)
      );
    }
  } catch (error) {
    dispatch(setLoadingPurchasedCoupons(false));
    console.error(error?.message ?? 'Error fetching coupons');
  }
};

export const getAllMyPoints = (token) => async (dispatch) => {
  try {
    const myPointsResponse = await PointRewardApiInstance.getAllMyPoints(
      token
    );
    if (myPointsResponse.data?.data?.pointWallets) {
      dispatch(setAllMyPoints(myPointsResponse.data.data.pointWallets));
    }
  } catch (error) {
    console.error(error?.message ?? 'Error fetching coupons');
  }
};

export const getMerchantCouponHistory = (merchantId, token) => async (
  dispatch
) => {
  try {
    const response = await PointRewardApiInstance.getMerchantCouponHistory(
      merchantId,
      token
    );

    if (response.data?.data?.couponsHistory) {
      const { couponsHistory } = response.data.data;
      dispatch(setCurrentUserCouponsHistory(couponsHistory));
    }

    if (response.data?.data?.totalSales) {
      const { totalSales } = response.data.data;
      dispatch(setCurrentUserMerchantTotalCouponSales(totalSales));
    }
  } catch (error) {
    console.error(error?.message ?? 'Error fetching coupons history');
  }
};

export const checkDailyCheckinReward = (token) => {  
  
  PointRewardApiInstance.redeemDailyCheckinReward(token).then(res => {
    if(res.status === 201){
      if(res.data?.data?.numberOfPoints) {
        alert(`Daily reward: You have been rewarded with ${res.data?.data?.numberOfPoints ?? 5} StoryChat points`);
      }
    }
  })
  .catch(() => {});
};
