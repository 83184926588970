import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from "react-router";
import { selectCurrentUser } from '../../../Redux/selectors/auth';
import { AIApiInstance } from "../../../apis/AIAPI";
import { YoutubeDialog } from "./YoutubeDialog";

export function YoutubeVideos({characterName = ""}) {
  const { state } = useLocation();
  const { id } = useParams();
  const [showDialog, setShowDialog] = useState(false);
  const user = useSelector(selectCurrentUser);
  const [isEditing, setIsEditing] = useState(false);
  const [editingVideo, setEditingVideo] = useState(null);
  const [character, setCharacter] = useState(() => {
    return state?.character ?? null;
  });
  const [videos, setVideos] =  useState([]);
  const dispatch = useDispatch();


  useEffect(() => {
    if (!character) {
        AIApiInstance.getCharacterInfo(id)
            .then((res) => {
                setCharacter(res.data.data.character);
            })
            .catch(console.error);
    }
  }, [id, character]);

  useEffect(() => {
    if (character) {
    AIApiInstance.getAllMyAiYoutubeLinks(id)
    .then((res) => {
        console.log('API Response:', res);
        const filteredVideos = (res.data.data.aiYoutubeLinks ?? []).filter(
        (link) => link.characterUUID === id
        );
        setVideos(filteredVideos);
    })
    .catch(console.error);
}
}, [character, id]);

  const openEditDialog = (video) => {
    setEditingVideo(video);
    setIsEditing(true);
    setShowDialog(true);
  };

  const openAddDialog = () => {
    setEditingVideo(null);
    setIsEditing(false);
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const handleAddYoutube = (data) => {
    setVideos(prev => [...prev,data])
    closeDialog();
  };

  const handleDelete = async (video) => {
    try {
      await AIApiInstance.deleteYoutubeLink(video._id);
      // Update the state by removing the deleted video
      const updatedVideos = videos.filter((v) => v._id !== video._id);
      setVideos(updatedVideos);

    } catch (error) {
      console.error(error);
      alert(video.id);
    }
  };

  return (
    <div className="youtube-tab-bar">
      <Button className="add-youtube-button" onClick={openAddDialog}>Add YouTube Video</Button>

      <YoutubeDialog
        isOpen={showDialog}
        onRequestClose={closeDialog}
        onAddYoutube={handleAddYoutube}
        editingVideo={editingVideo}
        isEditing={isEditing}
      />

      <div>
        {videos.map((video) => (
          <div key={video.id}>
            {video.youtubeLink && (
              <iframe
                width="100%"
                height="400px"
                padding="20"
                src={`https://www.youtube.com/embed/${video.youtubeLink
                  .split("v=")[1]
                  .split("&")[0]}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
            <div style={{ display: "flex", alignItems: "center", margin: "5px 10px 20px 10px" }}>
              <h3 style={{ flex: 1, marginRight: "10px" }}>{video.youtubeTitle}</h3>
              {user && user.userId === video.createdBy && (
                <div>
                  <Button variant="primary" onClick={() => openEditDialog(video)}>
                    Edit
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ marginLeft: "5px" }}
                    onClick={() => handleDelete(video)}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
