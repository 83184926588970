import React, { useState } from "react";
import { Container, Col, Row, Table, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/formatter/dateFormatter";
import { Modal } from "../../Component/Modal/Modal";

const PayoutNotesModalBody = ({ note }) => {
    return (
        <div className="payoutNotesModalBody-container">
            <h6>Notes from admin</h6>
            <Form.Control value={note} as="textarea" placeholder="Leave a comment here" disabled={true}/>
        </div>
    );
};

export function PayoutsTable({ payouts = []}) {
    const { t } = useTranslation("profile_screen");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedNote, setSelectedNote] = useState("");

    const handleShowNotes = (note) => {
        setSelectedNote(note);
        setIsOpen(true);
    };

    return (
        <Container style={{ paddingLeft: "0em" }}>
            <Modal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} body={<PayoutNotesModalBody note={selectedNote} />} />
            <Row className="mt-5">
                <Col md={6}>
                    <h4>{t("Payouts")}</h4>
                    {payouts.length > 0 ? (
                        <Table striped={true} bordered={true} hover={true}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t("Amount $")}</th>
                                    <th>{t("Withdrawn Status")}</th>
                                    <th>{t("Notes")}</th>
                                    <th>{t("Date/time")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payouts.map((payout, i) => (
                                    <tr key={payout?._id ?? i}>
                                        <td>{i + 1}</td>
                                        <td>{payout?.amount}</td>
                                        <td>{payout?.status}</td>
                                        <td>
                                            <div className="notes-row">
                                                <span>{payout?.note ? payout?.note?.substring(0, 5) : "-"}</span>
                                                {payout?.note && (
                                                    <span
                                                        className="show-modal"
                                                        onClick={() => handleShowNotes(payout?.note)}
                                                    >
                                                        Show
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                        <td>{formatDate(payout?.createdAt)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div>No earnings available</div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
