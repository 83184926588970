import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

export function CreateApiModal({ onAction, apiKey, handleUpdateSettingsClick = (_) => {} }) {
    const [keyName, setKeyName] = useState();

    const handleChange = (e) => {
        const value = e.target.value;
        setKeyName(value);
    };

    const handleClick = () => {
        onAction(keyName);
    };

    return (
        <div className="createKeyContainer">
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder="Enter API key name..."
                    aria-label="Enter API key name..."
                    aria-describedby="basic-addon1"
                    value={keyName}
                    onChange={handleChange}
                    id="api-key-name-input"
                />
            </InputGroup>
            <Button onClick={handleClick}>{apiKey ? 'Update Key name': 'Add Key name'}</Button>
            {apiKey && <Button onClick={() => handleUpdateSettingsClick(apiKey)}>Update Settings</Button>}
        </div>
    );
}
