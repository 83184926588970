/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import useWebSocket from "react-use-websocket";
import { Form } from "react-bootstrap";
import Chart from "../../Component/Exchange/Chart";
import OrderBook from "../../Component/Exchange/OrderBook";
import Spot from "../../Component/Exchange/Spot";
import SpotTradStatus from "../../Component/Exchange/SpotTradStatus";
import { readCookie } from "../../utils/cookie";
import EVENTS from "../../utils/socketEvents";
import { CandleSticksData } from "../../Component/Data/ExchangeData/OrderbookData";
import { orderAPIInstance } from "../../apis/OrderAPI";
import { adminAPIInstance } from "../../apis/AdminAPI";
import { useSearchParams } from "react-router-dom";
import PageTitle from "../../Component/Comman/PageTitle";
import useOrders from "../../Hooks/Orders/useOrders";
import { config } from "../../utils/config";

const initialState = {
    bids: [],
    asks: [],
    trades: [],
    marketPrice: 0,
    subscribed: false,
    streamReady: false,
    currentSide: null,
    priceForBid: 0,
    amountForBid: 0,
    priceForAsk: 0,
    amountForAsk: 0,
    addOrderLoading: false,
    token1Balance: 0,
    token2Balance: 0,
    loading: false,
    tokenPairs: []
};

function Exchange() {
    // prettier-ignore
    const { sendMessage, lastMessage } = useWebSocket(
        //todo: change this to production url compatible config[production, development]
        `${config[config.environment].wsUrl}?token=${readCookie("token")}&device=mobile`
    );

    const pendingOrders = useOrders({ status: "pending", me: "true" });
    const orderHistory = useOrders({ status: "fulfilled", me: "true" });

    const [event, updateEvent] = useReducer((prev, next) => {
        const newEvent = { ...prev, ...next };
        return newEvent;
    }, initialState);

    const [searchParams, setSearchParams] = useSearchParams();
    const pair = (searchParams.get("pair") ?? "").replace("%2F", "/");
    const [token1Symbol, token2Symbol] = pair.split("/");
    const orderType = searchParams.get("order") ?? "limit";

    const spotOrder = useMemo(() => {
        return {
            priceForBid: event.priceForBid,
            priceForAsk: event.priceForAsk,
            amountForBid: event.amountForBid,
            amountForAsk: event.amountForAsk,
            token1Balance: event.token1Balance,
            token2Balance: event.token2Balance,
            orderType
        };
    }, [
        event.priceForBid,
        event.priceForAsk,
        event.amountForBid,
        event.amountForAsk,
        event.token1Balance,
        event.token2Balance,
        orderType
    ]);

    const getTokenPairs = useCallback(async () => {
        try {
            updateEvent({ loading: true });
            const response = await adminAPIInstance.getTokenPairs();
            updateEvent({ tokenPairs: response.data });
            updateEvent({ loading: false });
        } catch (error) {
            console.error(error);
            updateEvent({ loading: false });
            alert("Error fetching tokens");
        }
    }, []);

    const getBalancesByTokenPair = useCallback(async () => {
        try {
            if (!pair) return;

            const response = await adminAPIInstance.getBalancesByTokenPair(pair);

            updateEvent({
                token1Balance: response.data?.data?.token1Balance ?? 0,
                token2Balance: response.data?.data?.token2Balance ?? 0
            });
        } catch (error) {
            console.error(error);
        }
    }, [pair]);

    const getCandleStick = (orders) => {
        if (orders.length <= 0) {
            return [0, 0, 0, 0];
        }

        const open = orders[orders.length - 1].price;
        const high = Math.max(...orders.map((o) => o.price));
        const low = Math.min(...orders.map((o) => o.price));
        const close = orders[0].price;

        return [open, high, low, close];
    };

    const parseTradesToSeriesData = (trades) => {
        if (trades.length <= 0) {
            return [];
        }

        const ms = 60000; // 1 minute
        const groupedData = {};

        trades.forEach((item) => {
            const date = new Date(item.timestamp * 1000);
            const group = Math.floor(date.getTime() / ms) * ms;
            const groupKey = group.toString();

            if (!groupedData[groupKey]) {
                groupedData[groupKey] = [];
            }

            groupedData[groupKey].push(item);
        });

        const seriesData = [];

        Object.keys(groupedData).forEach((key) => {
            const data = {
                x: new Date(parseInt(key)),
                y: getCandleStick(groupedData[key])
            };

            seriesData.push(data);
        });

        return [{ data: seriesData }];
    };

    const addOrder = useCallback(
        async (price, amount, side) => {
            const priceFloat = parseFloat(parseFloat(price).toFixed(6));
            const amountFloat = parseFloat(parseFloat(amount).toFixed(6));

            const data = {
                price: priceFloat,
                amount: amountFloat,
                side,
                pairKey: pair
            };

            try {
                updateEvent({ addOrderLoading: true });
                await orderAPIInstance.makeLimitOrder(data);
                updateEvent({ addOrderLoading: false });
                alert("Successfully added order");
            } catch (error) {
                console.error(error);
                alert("Add order failed");
            }
        },
        [pair]
    );

    useEffect(() => {
        if (!lastMessage) return;

        let dataParsed = null;

        try {
            dataParsed = JSON.parse(lastMessage.data);
        } catch (error) {
            console.error(error);
            return;
        }

        if (dataParsed?.type === EVENTS.SERVER.ORDERBOOK.SUBSCRIBE && dataParsed?.message === "ok") {
            updateEvent({ subscribed: true });
        }

        if (dataParsed?.type === EVENTS.SERVER.CHANGESTREAM.SUBSCRIBE && dataParsed?.message === "ok") {
            updateEvent({ streamReady: true });
        }

        if (dataParsed?.type === EVENTS.SERVER.ORDERBOOK.GET && dataParsed?.bids && dataParsed?.asks) {
            const { bids, asks, trades, currentMarketPrice, currentSuccessTradeSide } = dataParsed;

            updateEvent({
                bids,
                asks: asks.reverse(),
                trades,
                marketPrice: currentMarketPrice,
                currentSide: currentSuccessTradeSide
            });
        }
    }, [lastMessage]);

    useEffect(() => {
        sendMessage(JSON.stringify({ type: EVENTS.CLIENT.ORDERBOOK.SUBSCRIBE }));
        sendMessage(JSON.stringify({ type: EVENTS.CLIENT.CHANGESTREAM.SUBSCRIBE }));
    }, [sendMessage]);

    useEffect(() => {
        if (event.subscribed) {
            sendMessage(JSON.stringify({ type: EVENTS.CLIENT.ORDERBOOK.GET, pairKey: pair }));
        }
    }, [event.subscribed, pair, sendMessage]);

    useEffect(() => {
        const price = parseFloat(searchParams.get("price"));
        const amount = parseFloat(searchParams.get("amount"));
        const side = searchParams.get("side");

        if (!(price && amount && side)) {
            return;
        }

        if (side === "ask") {
            updateEvent({ priceForAsk: price, amountForAsk: amount });
        } else if (side === "bid") {
            updateEvent({ priceForBid: price, amountForBid: amount });
        }
    }, []);

    useEffect(() => {
        getTokenPairs();
        return () => updateEvent({ tokenPairs: [] });
    }, [getTokenPairs]);

    useEffect(() => {
        const { tokenPairs } = event;

        if (tokenPairs) {
            const defaultValue =
                tokenPairs.length > 0
                    ? tokenPairs.find((tokenPair) => tokenPair.pairKeyString === "OP/BTC") ?? tokenPairs[0]
                    : null;

            if (!pair && defaultValue) {
                setSearchParams({ pair: defaultValue.pairKeyString }, { replace: true });
            }
        }
    }, [event.tokenPairs]);

    useEffect(() => {
        getBalancesByTokenPair();
        return () => {
            updateEvent({
                token1Balance: 0,
                token2Balance: 0
            });
        };
    }, [getBalancesByTokenPair]);

    return (
        <div className="px-lg-4 px-md-4">
            <PageTitle pagetitle="Exchange" />
            <div className="container-xxl">
                {event.loading ? (
                    <div className="d-flex" style={{ height: "100vh", width: "100%" }}>
                        <span className="m-auto">
                            <i className="fas fa-circle-notch fa-spin text-warning" style={{ fontSize: "2rem" }}></i>
                        </span>
                    </div>
                ) : (
                    <>
                        <form className="mb-4">
                            <Form.Select
                                value={pair}
                                aria-label="Select trading pair"
                                className="w-50"
                                onChange={(e) => setSearchParams({ pair: e.target.value })}
                            >
                                {event.tokenPairs.map((tokenPair) => (
                                    <option key={tokenPair._id}>{tokenPair.pairKeyString}</option>
                                ))}
                            </Form.Select>
                        </form>

                        <div className="row g-3 mb-3">
                            <div className="col-md-12">
                                <Chart
                                    pair={pair}
                                    series={parseTradesToSeriesData(event.trades)}
                                    options={CandleSticksData.options}
                                />
                            </div>
                        </div>
                        <div className="row g-3 mb-3">
                            <div className="col-xxl-5">
                                <OrderBook
                                    bids={event.bids}
                                    asks={event.asks}
                                    marketPrice={event.marketPrice}
                                    currentSide={event.currentSide}
                                    pair={pair}
                                />
                            </div>
                            <div className="col-xxl-7">
                                <Spot
                                    addOrderLoading={event.addOrderLoading}
                                    addOrder={addOrder}
                                    updateEvent={updateEvent}
                                    token1Symbol={token1Symbol ?? "TOKEN"}
                                    token2Symbol={token2Symbol ?? "TOKEN"}
                                    spotOrder={spotOrder}
                                />
                                <SpotTradStatus pendingOrders={pendingOrders} orderHistory={orderHistory} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Exchange;
