import axios from "axios";
import { readCookie } from "../utils/cookie";
import { config } from "../utils/config";
import { httpTokenInterceptor } from "./common";

class ChatApi {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + '/chats'
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    getAllChatList() {
        return this.axios.get("/", {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`,
            }
        });
    }

    getMessagesByRecipientId(recipientId, page) {
        return this.axios.get(`/${recipientId}?page=${page}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`,
                "Content-Type": "multipart/form-data"
            }
        });
    }

    deleteMessageById(messageId) {
        return this.axios.delete(`/message/${messageId}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`,
                "Content-Type": "multipart/form-data"
            }
        });
    }
}

export const ChatApiInstance = new ChatApi();

export default ChatApi;
