export function removeChatPrefix(message, username) {
  if (!message || typeof message !== 'string') {
    return;
  }
  return message
    .replace(/You:/gi, "")
    .replace(/<START>/gi, "")
    .replace(/<BOT>:/gi, "")
    .replace(/<USER>/gi, username || "friend");
}


export const removeYouPrefix = (message) => {
  return message.replace(/You:/gi, "");
};