import { Button, Tab, Nav } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import MerchantHeader from "../../Component/Merchant/MerchantHeader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserMerchantCoupons } from "../../Redux/Actions/MerchantActions";
import { readCookie } from "../../utils/cookie";
import { MerchantApiInstance } from "../../apis/MerchantsAPI";
import CouponCard from "../CouponScreen/CouponCard/CouponCard";

const PublicStoreScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const  merchantID = location.state?.merchantID;
    const  from = location.state?.from;

    const token = readCookie("token");
    const loggedInUserID = useSelector((state) => state?.AuthReducer?.user?.userId);
    const [merchant, setMerchant] = useState(null);
    //const [coupons, setCoupons] = useState([]);
    const coupons = useSelector((state) => {
        return state?.merchant?.currentUserMerchantCoupons;
    });

    const getMerchantCoupons = useCallback(async () => {
        if (id) {
            dispatch(getCurrentUserMerchantCoupons(id, token));
        }
    }, [dispatch, id, token]);

    const getMerchant = async () => {
        try {
            const res = await MerchantApiInstance.getMerchantById(id, token);
            if (res.data.data.merchant) {
                setMerchant(res.data.data.merchant);
                //setCoupons(res.data.data.merchant.currentUserMerchantCoupons);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const followMerchant = async () => {
        try {
            if (!merchant?.followers?.includes(loggedInUserID)) {
                await MerchantApiInstance.followMerchant(merchant._id);
            } else {
                await MerchantApiInstance.unfollowMerchant(merchant._id);
            }

            await getMerchant();
        } catch (error) {
            console.error(error);
        }
    };

    const messageMerchant = () => {
        navigate(`/chat?type=user&id=${merchant.userId}`)
    }

    useEffect(() => {
        getMerchantCoupons();
        getMerchant();
    }, []);

    const handleOtherCouponsImageError = (i) => {
        /* setCoupons((prev) => {
            const newCoupons = [...prev];
            newCoupons[i] = { ...newCoupons[i], couponPhotos: [NO_IMAGE] };
            return newCoupons;
        }); */
    };

    return (
        <div>
            {from === "my-store" && <div className="mb-5">
                <div
                    className="bg-primary position-absolute top-0 start-0 end-0"
                    style={{ opacity: 0.3, height: "50px", zIndex: 10 }}
                />
                <div className="d-flex justify-content-end p-2 position-absolute top-0 end-0">
                    <Button variant="primary" style={{ zIndex: 20 }} onClick={() => navigate("/my-store")}>
                        Exit View As Public
                    </Button>
                </div>
            </div>}
            <div className="container-xxl p-4 ">
                {merchant ? (
                    <div className="gap-3 flex-column d-flex ">
                        <MerchantHeader merchant={merchant} followMerchant={followMerchant} messageMerchant={messageMerchant} />
                        <div className="row g-3 mt-3">
                            <div className="col-md-12">
                                <Tab.Container defaultActiveKey="coupons-selling">
                                    <div className="card-header pb-3 d-flex justify-content-between bg-transparent align-items-center flex-wrap">
                                        <Nav
                                            className="nav nav-pills rounded d-inline-flex mt-2 mt-md-0 rounded-0"
                                            role="tablist"
                                        >
                                            <Nav.Item className="nav-item">
                                                <Nav.Link
                                                    eventKey="coupons-selling"
                                                    className="nav-link"
                                                    data-bs-toggle="tab"
                                                    role="tab"
                                                    aria-selected="false"
                                                >
                                                    Coupons Selling
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="nav-item">
                                                <Nav.Link
                                                    eventKey="points-selling"
                                                    className="nav-link "
                                                    data-bs-toggle="tab"
                                                    role="tab"
                                                    aria-selected="true"
                                                >
                                                    Points Selling
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <div className="card-body">
                                        <Tab.Content className="tab-content">
                                            <Tab.Pane
                                                className="tab-pane fade"
                                                id="coupons-selling"
                                                eventKey="coupons-selling"
                                            >

                                                <div className="other-coupons">
                                                    <h5>Other Coupons</h5>
                                                    <div className="coupons-list">
                                                        {coupons.map((coupon, i) => 
                                                            
                                                            {if (coupon._id !==id) return (
                                                                <CouponCard
                                                                key={coupon._id}
                                                                coupon={coupon}
                                                                index={i}
                                                                merchantInfo={merchant}
                                                                handleOtherCouponsImageError={handleOtherCouponsImageError}
                                                            />)}
                                                        )}
                                                    </div>
                                                </div>
                                                
                                            </Tab.Pane>
                                            <Tab.Pane
                                                className="tab-pane fade  show"
                                                id="points-selling"
                                                eventKey="points-selling"
                                            >
                                                <div className="card card-body">
                                                    <h6>Points Selling</h6>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex flex-grow-1" style={{ height: "80vh", width: "100%" }}>
                        <span className="m-auto">
                            <i className="fas fa-circle-notch fa-spin text-warning" style={{ fontSize: "2rem" }}></i>
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PublicStoreScreen;
