import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import {IChat, IMessage} from '../../types/model/chatModel';

// interface IChatReducer {
//   chats: Array<IChat>;
//   activeChatId: string;
//   socketConnected: SocketConnectionStatus;
// }

// interface ILoadChatsPayload {
//   data: Array<Omit<IChat, 'messages' | 'messagesLoaded'>>;
// }

export const UpdateMessageType = {
    translate: "translate",
    original: "original",
    delete: "delete",
    id: "id"
};

// interface IUpdateMessagePayload {
//   id: string;
//   newText: string;
//   recipient_id: string;
//   type: UpdateMessageType;
// }

// interface IReceivedMessagePayload {
//   _id?: string;
//   current_userId: string;
//   recipient_id: string;
//   message: string;
//   timeStamp: number;
//   sent: boolean;
// }

// interface ISetMessagesPayload {
//   messages: Array<IMessage>;
//   recipient_id: string;
// }

// TODO: Refactor, copied from chainclip

export const SocketConnectionStatus = {
    CONNECTED: "online",
    DISCONNECTED: "offline",
    CONNECTING: "connecting..."
};

const initialState = {
    chats: [], // user chats
    activeChatId: "",
    socketConnected: SocketConnectionStatus.DISCONNECTED
};

const ChatReducer = createSlice({ // for user to user chat
    name: "chatReducer",
    initialState,
    reducers: {
        setAllChats: (state, action) => {
            const _chats = [];

            action.payload.data.forEach((chatObj) => {
                _chats.push({ ...chatObj, messages: [], messagesLoaded: false });
            });

            state.chats = _chats;
        },
        modifyChatPinnedMessage: (state, action) => {
            let _chats = [];

            _chats = [...state.chats];

            const foundIndex = _chats.findIndex((chatObj) => {
                if (chatObj.recipient_id === action.payload.recipient_id) {
                    return true;
                }
                return false;
            });

            if (foundIndex !== -1) {
                _chats[foundIndex].messageContent = action.payload.message;
                _chats[foundIndex].timeStamp = action.payload.timeStamp;
                state.chats = _chats;
            }
        },
        setMessagesOfChat: (state, action) => {
            let _chats = [];

            _chats = [...state.chats];

            const foundIndex = _chats.findIndex((chatObj) => {
                if (chatObj.recipient_id === action.payload.recipient_id) {
                    return true;
                }
                return false;
            });

            if (foundIndex !== -1) {
                _chats[foundIndex].messages = action.payload.messages;
                _chats[foundIndex].messagesLoaded = true;
                state.chats = _chats;
            }
        },
        addMessageToChat: (state, action) => {
            let _chats = [];

            _chats = [...state.chats];

            const foundIndex = _chats.findIndex((chatObj) => {
                if (chatObj.recipient_id === action.payload.recipient_id) {
                    return true;
                }
                return false;
            });

            if (foundIndex !== -1) {
                let _messages = [..._chats[foundIndex].messages];

                _messages.unshift({
                    _id: action.payload._id,
                    sender_id: action.payload.sent ? action.payload.current_userId : action.payload.recipient_id,
                    receiver_id: !action.payload.sent ? action.payload.current_userId : action.payload.recipient_id,
                    chatId: _chats[foundIndex].chatId,
                    messageContent: action.payload.message,
                    isRead: false,
                    timeStamp: action.payload.timeStamp,
                    originalMessage: ""
                });

                _chats[foundIndex].messages = _messages;
                state.chats = _chats;
            }
        },
        resetChatData: (state) => {
            state.chats = [];
            state.activeChatId = "";
        },
        updateMessage: (state, action) => {
            const _chats = [...state.chats];

            const foundIndex = _chats.findIndex((chatObj) => {
                if (chatObj.recipient_id === action.payload.recipient_id) {
                    return true;
                }
                return false;
            });

            if (foundIndex !== -1) {
                const messagefoundIndex = _chats[foundIndex].messages.findIndex((messageObj) => {
                    return messageObj._id === action.payload.id;
                });

                if (messagefoundIndex !== -1) {
                    if (action.payload.type === UpdateMessageType.translate) {
                        _chats[foundIndex].messages[messagefoundIndex].originalMessage =
                            _chats[foundIndex].messages[messagefoundIndex].messageContent;
                        _chats[foundIndex].messages[messagefoundIndex].messageContent = action.payload.newText;
                    } else if (action.payload.type === UpdateMessageType.original) {
                        _chats[foundIndex].messages[messagefoundIndex].messageContent =
                            _chats[foundIndex].messages[messagefoundIndex].originalMessage;
                        _chats[foundIndex].messages[messagefoundIndex].originalMessage = "";
                    } else if (action.payload.type === UpdateMessageType.delete) {
                        _chats[foundIndex].messages[messagefoundIndex].messageContent = "You deleted this message";
                        _chats[foundIndex].messages[messagefoundIndex].originalMessage = "";
                    } else if (action.payload.type === UpdateMessageType.id) {
                        _chats[foundIndex].messages[messagefoundIndex]._id = action.payload.newText;
                    }

                    state.chats = [..._chats];
                }
            }
        },
        changeSocketConnectionStatus: (state, action) => {
            state.socketConnected = action.payload;
        }
    }
});

export const {
    setAllChats,
    addMessageToChat,
    modifyChatPinnedMessage,
    setMessagesOfChat,
    resetChatData,
    updateMessage,
    changeSocketConnectionStatus
} = ChatReducer.actions;

export default ChatReducer;
