import { useState } from "react";
import PageTitle from "../../../Component/Comman/PageTitle";
import StoreForm from "./StoreForm";
import { CustomToast, ToastStatus } from "../../../Component/Comman/CustomToast";
import { useDispatch } from "react-redux";
import { getCurrentUserMerchant } from "../../../Redux/Actions/MerchantActions";
import { MerchantApiInstance } from "../../../apis/MerchantsAPI";
import { useNavigate } from "react-router";

const EditStoreScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [toast, setToast] = useState({
        show: false,
        message: "",
        status: ""
    });

    const handleSubmit = async (merchantValues, token, merchantUploadImages) => {
        try {
            console.log("merchantUploadImages: ", merchantUploadImages);
            
            const merchantResponse = await MerchantApiInstance.updateMerchant(merchantValues, token, merchantUploadImages);

            dispatch(getCurrentUserMerchant(token));
            setToast({
                show: true,
                message: "Store updated successfully!",
                status: ToastStatus.SUCCESS
            });

            setTimeout(() => {
                navigate("/my-store");
            }, 2000);
        } catch (error) {
            console.error(error?.message);

            setToast({
                show: true,
                message: "An error has occurred",
                status: ToastStatus.ERROR
            });
        }
    };

    return (
        <div className="container-xxl px-4">
            <PageTitle pagetitle="Edit Store" goBackLink="/my-store" />
            <StoreForm onSubmit={handleSubmit} />
            
            <CustomToast
                status={toast.status}
                show={toast.show}
                message={toast.message}
                onClose={() => setToast((prev) => ({ ...prev, show: false }))}
            />
        </div>
    );
};

export default EditStoreScreen;
