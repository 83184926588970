import A2 from "../../../assets/images/coin/SOL.png";
import A1 from "../../../assets/images/coin/ETH.png";
import A3 from "../../../assets/images/coin/DGD.png";
import A4 from "../../../assets/images/coin/ADA.png";
import A5 from "../../../assets/images/coin/BNB.png";
import A6 from "../../../assets/images/coin/DOGE.png";
import A7 from "../../../assets/images/coin/ADX.png";

import B1 from "../../../assets/images/coin/ETH.png";
import B2 from "../../../assets/images/coin/SOL.png";
import B3 from "../../../assets/images/coin/ETH.png";
import B4 from "../../../assets/images/coin/SOL.png";

import C1 from "../../../assets/images/coin/ETH.png";
import C2 from "../../../assets/images/coin/SOL.png";
import C3 from "../../../assets/images/coin/ETH.png";
import C4 from "../../../assets/images/coin/SOL.png";

import D1 from "../../../assets/images/coin/ICN.png";
import D2 from "../../../assets/images/coin/EDG.png";
import D3 from "../../../assets/images/coin/ADA.png";
import D4 from "../../../assets/images/coin/ARK.png";
import D5 from "../../../assets/images/coin/FUN.png";
import D6 from "../../../assets/images/coin/BNB.png";
import D7 from "../../../assets/images/coin/ETH.png";
import { orderAPIInstance } from "../../../apis/OrderAPI";

export const OrderbookDatafirst = {
    columns: [
        {
            //name: "PRICE(USDT)",
            selector: (row) => row.price,
            //sortable: true,
            cell: (row) => <span className="color-price-up">{row.price.toFixed(6)}</span>
        },
        {
            //name: "AMOUNT(BTC)",
            selector: (row) => row.amount.toFixed(6),
            sortable: true
        },
        {
            //name: "TOTAL",
            selector: (row) => row.total.toFixed(6)
            //sortable: true
        }
    ],
    rows: [
        {
            usdt: "44279.20",
            btc: "0.17214",
            total: "76,053.29043"
        },
        {
            usdt: "43979.43",
            btc: "0.03855",
            total: "10,978.37750"
        },
        {
            usdt: "43978.90",
            btc: "0.00062",
            total: "1,151.15971"
        },
        {
            usdt: "43978.43",
            btc: "0.11228",
            total: "6,938.88312"
        },
        {
            usdt: "43928.23",
            btc: "0.08881",
            total: "1,677.76807"
        },
        {
            usdt: "43925.73",
            btc: "0.08752",
            total: "5,022.51319"
        },
        {
            usdt: "42978.90",
            btc: "0.00062",
            total: "1,151.15971"
        },
        {
            usdt: "42978.43",
            btc: "0.11228",
            total: "6,938.88312"
        },
        {
            usdt: "42928.23",
            btc: "0.08881",
            total: "1,677.76807"
        },
        {
            usdt: "42925.73",
            btc: "0.08752",
            total: "5,022.51319"
        }
    ]
};

export const OrderbookDatasecond = {
    columns: [
        {
            name: "PRICE(USDT)",
            selector: (row) => row.price,
            //sortable: true,
            cell: (row) => <span className="color-price-down">{row.price.toFixed(6)}</span>
        },
        {
            name: "AMOUNT(BTC)",
            selector: (row) => row.amount.toFixed(6)
            //sortable: true
        },
        {
            name: "TOTAL",
            selector: (row) => row.total.toFixed(6)
            //sortable: true
        }
    ],
    rows: [
        {
            usdt: "44279.20",
            btc: "0.17214",
            total: "76,053.29043"
        },
        {
            usdt: "43979.43",
            btc: "0.03855",
            total: "10,978.37750"
        },
        {
            usdt: "43978.90",
            btc: "0.00062",
            total: "1,151.15971"
        },
        {
            usdt: "43978.43",
            btc: "0.11228",
            total: "6,938.88312"
        },
        {
            usdt: "43928.23",
            btc: "0.08881",
            total: "1,677.76807"
        },
        {
            usdt: "43925.73",
            btc: "0.08752",
            total: "5,022.51319"
        },
        {
            usdt: "42978.90",
            btc: "0.00062",
            total: "1,151.15971"
        },
        {
            usdt: "42978.43",
            btc: "0.11228",
            total: "6,938.88312"
        },
        {
            usdt: "42928.23",
            btc: "0.08881",
            total: "1,677.76807"
        },
        {
            usdt: "42925.73",
            btc: "0.08752",
            total: "5,022.51319"
        }
    ]
};

export const OrderbookSortData = {
    columns: [
        {
            name: "PRICE(USDT)",
            selector: (row) => row.usdt,
            sortable: true,
            cell: (row) => <span className="color-price-up">{row.usdt}</span>
        },
        {
            name: "AMOUNT(BTC)",
            selector: (row) => row.btc,
            sortable: true
        },
        {
            name: "TOTAL",
            selector: (row) => row.total,
            sortable: true
        }
    ],
    rows: [
        {
            usdt: "44279.20",
            btc: "0.17214",
            total: "76,053.29043"
        },
        {
            usdt: "43979.43",
            btc: "0.03855",
            total: "10,978.37750"
        },
        {
            usdt: "43978.90",
            btc: "0.00062",
            total: "1,151.15971"
        },
        {
            usdt: "43978.43",
            btc: "0.11228",
            total: "6,938.88312"
        },
        {
            usdt: "43928.23",
            btc: "0.08881",
            total: "1,677.76807"
        },
        {
            usdt: "43925.73",
            btc: "0.08752",
            total: "5,022.51319"
        },
        {
            usdt: "42978.90",
            btc: "0.00062",
            total: "1,151.15971"
        },
        {
            usdt: "42978.43",
            btc: "0.11228",
            total: "6,938.88312"
        },
        {
            usdt: "42928.23",
            btc: "0.08881",
            total: "1,677.76807"
        },
        {
            usdt: "42925.73",
            btc: "0.08752",
            total: "5,022.51319"
        }
    ]
};

export const OrderbookLongData = {
    columns: [
        {
            name: "PRICE(USDT)",
            selector: (row) => row.usdt,
            sortable: true,
            cell: (row) => <span className="color-price-up">{row.usdt}</span>
        },
        {
            name: "AMOUNT(BTC)",
            selector: (row) => row.btc,
            sortable: true
        },
        {
            name: "TOTAL",
            selector: (row) => row.total,
            sortable: true
        }
    ],
    rows: [
        {
            usdt: "44279.20",
            btc: "0.17214",
            total: "76,053.29043"
        },
        {
            usdt: "43979.43",
            btc: "0.03855",
            total: "10,978.37750"
        },
        {
            usdt: "43978.90",
            btc: "0.00062",
            total: "1,151.15971"
        },
        {
            usdt: "43978.43",
            btc: "0.11228",
            total: "6,938.88312"
        },
        {
            usdt: "43928.23",
            btc: "0.08881",
            total: "1,677.76807"
        },
        {
            usdt: "43925.73",
            btc: "0.08752",
            total: "5,022.51319"
        },
        {
            usdt: "42978.90",
            btc: "0.00062",
            total: "1,151.15971"
        },
        {
            usdt: "42978.43",
            btc: "0.11228",
            total: "6,938.88312"
        },
        {
            usdt: "42928.23",
            btc: "0.08881",
            total: "1,677.76807"
        },
        {
            usdt: "42925.73",
            btc: "0.08752",
            total: "5,022.51319"
        }
    ]
};

export const OpenOrderData = {
    columns: [
        {
            name: "DATE",
            selector: (row) => row.date,
            sortable: true,
            maxWidth: "50px",
            cell: (row) =>
                new Date(row.timestamp * 1000).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                }),
            minWidth: "150px"
        },
        {
            name: "PAIR",
            selector: (row) => row.name,
            cell: (row) => row.pairKey,
            sortable: true,
            minWidth: "150px"
        },
        {
            name: "TYPE",
            selector: (row) => row.type,
            sortable: true
        },
        {
            name: "SIDE",
            selector: (row) => row.side,
            sortable: true
        },
        {
            name: "PRICE",
            selector: (row) => row.price,
            sortable: true
        },
        {
            name: "AMOUNT",
            selector: (row) => row.amount,
            sortable: true
        },
        {
            name: "ACTION",
            selector: () => {},
            sortable: true,
            cell: (row) => (
                <div className="btn-group" role="group">
                    {/* <button type="button" className="btn btn-outline-secondary">
                        <i className="icofont-edit text-success"></i>
                    </button> */}
                    <button
                        onClick={() => {
                            try {
                                orderAPIInstance.cancelOrder(row._id);
                                window.location.reload();
                            } catch (error) {
                                console.error(error);
                                alert("Error cancelling order!");
                            }
                        }}
                        type="button"
                        className="btn btn-outline-secondary deleterow"
                    >
                        <i className="icofont-ui-delete text-danger"></i>
                    </button>
                </div>
            )
        }
    ],
    rows: [
        {
            date: "09-18 17:32:15",
            name: "ETH/USDT",
            image: A1,
            type: "Limit",
            side: "Buy",
            price: "2774.00",
            amount: "0.000378"
        },
        {
            date: "09-20 18:38:15",
            name: "SOL/USDT",
            image: A2,
            type: "Limit",
            side: "Buy",
            price: "125.00",
            amount: "0.005378"
        },
        {
            date: "09-18 17:32:15",
            name: "DGD/USDT",
            image: A3,
            type: "Limit",
            side: "Sell",
            price: "35.00",
            amount: "0.000005"
        },
        {
            date: "09-21 13:32:15",
            name: "ADA/USDT",
            image: A4,
            type: "Limit",
            side: "Sell",
            price: "3.500",
            amount: "0.000001"
        },
        {
            date: "09-21 13:32:15",
            name: "BNB/USDT",
            image: A5,
            type: "Limit",
            side: "Sell",
            price: "415.00	",
            amount: "0.000041"
        },
        {
            date: "09-20 13:32:15",
            name: "DOGE/USDT",
            image: A6,
            type: "Limit",
            side: "Sell",
            price: "0.2040",
            amount: "0.203900"
        },
        {
            date: "09-22 16:32:15",
            name: "ADX/USDT",
            image: A7,
            type: "Limit",
            side: "Buy",
            price: "2.134",
            amount: "2.13"
        }
    ]
};

export const OrderHistoryData = {
    columns: [
        {
            name: "DATE",
            selector: (row) => row.date,
            sortable: true,
            maxWidth: "50px",
            minWidth: "150px",
            cell: (row) =>
                new Date(row.timestamp * 1000).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                })
        },
        {
            name: "PAIR",
            selector: (row) => row.name,
            cell: (row) => row.pairKey,
            sortable: true,
            minWidth: "150px"
        },
        {
            name: "TYPE",
            selector: (row) => row.type,
            sortable: true
        },
        {
            name: "SIDE",
            selector: (row) => row.side,
            sortable: true
        },
        // {
        //     name: "AVERAGE",
        //     selector: (row) => row.average,
        //     sortable: true
        // },
        {
            name: "PRICE",
            selector: (row) => row.price,
            sortable: true
        },
        // {
        //     name: "EXECUTED",
        //     selector: (row) => row.executed,
        //     sortable: true
        // },
        {
            name: "AMOUNT",
            selector: (row) => row.amount,
            sortable: true
        },
        {
            name: "TOTAL",
            selector: (row) => row.total,
            sortable: true
        }
    ],
    rows: [
        {
            date: "09-18 17:32:15",
            name: "ETH/USDT",
            image: B1,
            type: "Market",
            side: "Sell",
            average: "3,487.50",
            price: "Market",
            executed: "0.0110",
            amount: "0.0110",
            total: "38.36"
        },
        {
            date: "09-18 17:31:11",
            name: "SOL/USDT",
            image: B2,
            type: "Market",
            side: "Sell",
            average: "160.33",
            price: "Market",
            executed: "0.75",
            amount: "0.75",
            total: "120.25"
        },
        {
            date: "09-18 08:52:04",
            name: "ETH/USDT",
            image: B3,
            type: "Market",
            side: "Buy",
            average: "3,439.20",
            price: "Market",
            executed: "0.0111",
            amount: "0.0111",
            total: "38.18"
        },
        {
            date: "09-17 08:34:14",
            name: "SOL/USDT",
            image: B4,
            type: "Market",
            side: "Buy",
            average: "147.04",
            price: "Market",
            executed: "0.76",
            amount: "0.71",
            total: "111.75"
        }
    ]
};

export const TradeHistoryData = {
    columns: [
        {
            name: "DATE",
            selector: (row) => row.timestamp,
            sortable: true,
            maxWidth: "50px",
            minWidth: "150px"
            // cell: (row) => <a href="#!" className="fw-bold text-secondary">{row.id}</a>
        },
        {
            name: "PAIR",
            selector: (row) => row.pairKey?.toUpperCase(),
            sortable: true,
            minWidth: "150px"
        },
        {
            name: "SIDE",
            selector: (row) => (row.side === "bid" ? "BUY" : "SELL"),
            sortable: true
        },
        {
            name: "PRICE",
            selector: (row) => row.price.toFixed(6),
            sortable: true
        },
        {
            name: "TOTAL",
            selector: (row) => row.total.toFixed(6),
            sortable: true
        }
    ],
    rows: [
        {
            date: "09-18 17:32:15",
            name: "ETH/USDT",
            image: C1,
            side: "Sell",
            price: "3,487.50",
            executed: "0.0110",
            fee: "0.03836250 USDT",
            total: "38.36250000 USDT"
        },
        {
            date: "09-18 17:31:11",
            name: "SOL/USDT",
            image: C2,
            side: "Sell",
            price: "160.33",
            executed: "0.75",
            fee: "0.12024750 USDT",
            total: "120.24750000 USDT"
        },
        {
            date: "09-18 08:52:04",
            name: "ETH/USDT",
            image: C3,
            side: "Buy",
            price: "3,439.20",
            executed: "0.0111",
            fee: "0.00001110 ETH",
            total: "38.17512000 USDT"
        },
        {
            date: "09-17 08:34:14",
            name: "SOL/USDT",
            image: C4,
            side: "Buy",
            price: "147.04",
            executed: "0.76",
            fee: "0.00076000 SOL",
            total: "111.75040000 USDT"
        }
    ]
};

export const FunData = {
    columns: [
        {
            name: "COIN",
            selector: (row) => row.name,
            cell: (row) => (
                <>
                    {" "}
                    <img src={row.image} alt="" className="img-fluid avatar mx-1" />
                    {row.name}
                </>
            ),
            sortable: true
        },
        {
            name: "TOTAL BALANCE",
            selector: (row) => row.total,
            sortable: true
        },
        {
            name: "AVAILABLE BALANCE",
            selector: (row) => row.availablebalance,
            sortable: true
        },
        {
            name: "IN ORDER",
            selector: (row) => row.order,
            sortable: true
        },
        {
            name: "BTC VALUE",
            selector: (row) => row.btcvalue,
            sortable: true
        }
    ],
    rows: [
        {
            name: "1INCH",
            image: D1,
            total: "10.00000000",
            availablebalance: "10.00000000	",
            order: "08.00000000",
            btcvalue: "0.00000080 BTC"
        },
        {
            name: "EDG",
            image: D2,
            total: "11.00000000",
            availablebalance: "11.00000000",
            order: "10.00000010",
            btcvalue: "0.000000010 BTC"
        },
        {
            name: "ADA",
            image: D3,
            total: "112.00000000",
            availablebalance: "112.00000000",
            order: "098.00000098",
            btcvalue: "0.0000000009 BTC"
        },
        {
            name: "ARK",
            image: D4,
            total: "113.00000000",
            availablebalance: "113.00000000",
            order: "	097.00000097",
            btcvalue: "0.0000000009 BTC"
        },
        {
            name: "Fun",
            image: D5,
            total: "218.00000000",
            availablebalance: "218.00000000",
            order: "095.00000095",
            btcvalue: "0.0000000008 BTC"
        },
        {
            name: "BNB",
            image: D6,
            total: "0.00431435",
            availablebalance: "0.00431435",
            order: "0.00000000",
            btcvalue: "0.00003445 BTC"
        },
        {
            name: "ETH",
            image: D7,
            total: "0.05431435",
            availablebalance: "0.05431435",
            order: "0.00000000",
            btcvalue: "0.00543445 BTC"
        }
    ]
};

export const CandleSticksData = {
    series: [
        {
            data: [
                {
                    x: new Date(1538778600000),
                    y: [6629.81, 6650.5, 6623.04, 6633.33]
                },
                {
                    x: new Date(1538780400000),
                    y: [6632.01, 6643.59, 6620, 6630.11]
                },
                {
                    x: new Date(1538782200000),
                    y: [6630.71, 6648.95, 6623.34, 6635.65]
                },
                {
                    x: new Date(1538784000000),
                    y: [6635.65, 6651, 6629.67, 6638.24]
                },
                {
                    x: new Date(1538785800000),
                    y: [6638.24, 6640, 6620, 6624.47]
                },
                {
                    x: new Date(1538787600000),
                    y: [6624.53, 6636.03, 6621.68, 6624.31]
                },
                {
                    x: new Date(1538789400000),
                    y: [6624.61, 6632.2, 6617, 6626.02]
                },
                {
                    x: new Date(1538791200000),
                    y: [6627, 6627.62, 6584.22, 6603.02]
                },
                {
                    x: new Date(1538793000000),
                    y: [6605, 6608.03, 6598.95, 6604.01]
                },
                {
                    x: new Date(1538794800000),
                    y: [6604.5, 6614.4, 6602.26, 6608.02]
                },
                {
                    x: new Date(1538796600000),
                    y: [6608.02, 6610.68, 6601.99, 6608.91]
                },
                {
                    x: new Date(1538798400000),
                    y: [6608.91, 6618.99, 6608.01, 6612]
                },
                {
                    x: new Date(1538800200000),
                    y: [6612, 6615.13, 6605.09, 6612]
                },
                {
                    x: new Date(1538802000000),
                    y: [6612, 6624.12, 6608.43, 6622.95]
                },
                {
                    x: new Date(1538803800000),
                    y: [6623.91, 6623.91, 6615, 6615.67]
                },
                {
                    x: new Date(1538805600000),
                    y: [6618.69, 6618.74, 6610, 6610.4]
                },
                {
                    x: new Date(1538807400000),
                    y: [6611, 6622.78, 6610.4, 6614.9]
                },
                {
                    x: new Date(1538809200000),
                    y: [6614.9, 6626.2, 6613.33, 6623.45]
                },
                {
                    x: new Date(1538811000000),
                    y: [6623.48, 6627, 6618.38, 6620.35]
                },
                {
                    x: new Date(1538812800000),
                    y: [6619.43, 6620.35, 6610.05, 6615.53]
                },
                {
                    x: new Date(1538814600000),
                    y: [6615.53, 6617.93, 6610, 6615.19]
                },
                {
                    x: new Date(1538816400000),
                    y: [6615.19, 6621.6, 6608.2, 6620]
                },
                {
                    x: new Date(1538818200000),
                    y: [6619.54, 6625.17, 6614.15, 6620]
                },
                {
                    x: new Date(1538820000000),
                    y: [6620.33, 6634.15, 6617.24, 6624.61]
                },
                {
                    x: new Date(1538821800000),
                    y: [6625.95, 6626, 6611.66, 6617.58]
                },
                {
                    x: new Date(1538823600000),
                    y: [6619, 6625.97, 6595.27, 6598.86]
                },
                {
                    x: new Date(1538825400000),
                    y: [6598.86, 6598.88, 6570, 6587.16]
                },
                {
                    x: new Date(1538827200000),
                    y: [6588.86, 6600, 6580, 6593.4]
                },
                {
                    x: new Date(1538829000000),
                    y: [6593.99, 6598.89, 6585, 6587.81]
                },
                {
                    x: new Date(1538830800000),
                    y: [6587.81, 6592.73, 6567.14, 6578]
                },
                {
                    x: new Date(1538832600000),
                    y: [6578.35, 6581.72, 6567.39, 6579]
                },
                {
                    x: new Date(1538834400000),
                    y: [6579.38, 6580.92, 6566.77, 6575.96]
                },
                {
                    x: new Date(1538836200000),
                    y: [6575.96, 6589, 6571.77, 6588.92]
                },
                {
                    x: new Date(1538838000000),
                    y: [6588.92, 6594, 6577.55, 6589.22]
                },
                {
                    x: new Date(1538839800000),
                    y: [6589.3, 6598.89, 6589.1, 6596.08]
                },
                {
                    x: new Date(1538841600000),
                    y: [6597.5, 6600, 6588.39, 6596.25]
                },
                {
                    x: new Date(1538843400000),
                    y: [6598.03, 6600, 6588.73, 6595.97]
                },
                {
                    x: new Date(1538845200000),
                    y: [6595.97, 6602.01, 6588.17, 6602]
                },
                {
                    x: new Date(1538847000000),
                    y: [6602, 6607, 6596.51, 6599.95]
                },
                {
                    x: new Date(1538848800000),
                    y: [6600.63, 6601.21, 6590.39, 6591.02]
                },
                {
                    x: new Date(1538850600000),
                    y: [6591.02, 6603.08, 6591, 6591]
                },
                {
                    x: new Date(1538852400000),
                    y: [6591, 6601.32, 6585, 6592]
                },
                {
                    x: new Date(1538854200000),
                    y: [6593.13, 6596.01, 6590, 6593.34]
                },
                {
                    x: new Date(1538856000000),
                    y: [6593.34, 6604.76, 6582.63, 6593.86]
                },
                {
                    x: new Date(1538857800000),
                    y: [6593.86, 6604.28, 6586.57, 6600.01]
                },
                {
                    x: new Date(1538859600000),
                    y: [6601.81, 6603.21, 6592.78, 6596.25]
                },
                {
                    x: new Date(1538861400000),
                    y: [6596.25, 6604.2, 6590, 6602.99]
                },
                {
                    x: new Date(1538863200000),
                    y: [6602.99, 6606, 6584.99, 6587.81]
                },
                {
                    x: new Date(1538865000000),
                    y: [6587.81, 6595, 6583.27, 6591.96]
                },
                {
                    x: new Date(1538866800000),
                    y: [6591.97, 6596.07, 6585, 6588.39]
                },
                {
                    x: new Date(1538868600000),
                    y: [6587.6, 6598.21, 6587.6, 6594.27]
                },
                {
                    x: new Date(1538870400000),
                    y: [6596.44, 6601, 6590, 6596.55]
                },
                {
                    x: new Date(1538872200000),
                    y: [6598.91, 6605, 6596.61, 6600.02]
                },
                {
                    x: new Date(1538874000000),
                    y: [6600.55, 6605, 6589.14, 6593.01]
                },
                {
                    x: new Date(1538875800000),
                    y: [6593.15, 6605, 6592, 6603.06]
                },
                {
                    x: new Date(1538877600000),
                    y: [6603.07, 6604.5, 6599.09, 6603.89]
                },
                {
                    x: new Date(1538879400000),
                    y: [6604.44, 6604.44, 6600, 6603.5]
                },
                {
                    x: new Date(1538881200000),
                    y: [6603.5, 6603.99, 6597.5, 6603.86]
                },
                {
                    x: new Date(1538883000000),
                    y: [6603.85, 6605, 6600, 6604.07]
                },
                {
                    x: new Date(1538884800000),
                    y: [6604.98, 6606, 6604.07, 6606]
                }
            ]
        }
    ],
    options: {
        chart: {
            type: "candlestick",
            height: 350
        },
        title: {
            text: "",
            align: "left"
        },
        xaxis: {
            type: "datetime"
        },
        yaxis: {
            tooltip: {
                enabled: true
            }
        }
    }
};
