
import {getBrowserCurrentUrl} from './getCurrentUrl';

export const isRedbitUrl = () => {
    const currentUrl = getBrowserCurrentUrl();
    if(currentUrl.includes("redchat")) {
        return true;
    } else {
        return false;
    }
}