export const StatusBadge = ({ status = "offline" }) =>{ 
    const colors = {
        online: 'success',
        offline: 'default'
    }
    return (
    <span>
        <span className={`badge border border-white rounded-circle p-1 pill badge-status-${colors[status]}`}>
            <span className="visually-hidden">user status</span>
        </span>
    </span>
)};
