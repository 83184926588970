import axios from "axios";
import { readCookie } from "../utils/cookie";
import { config } from "../utils/config";
import { httpTokenInterceptor } from "./common";

class UserTierAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/user_tiers"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    subscribeChatTierStripe(tier, priceID) {
        return this.axios.post(
            "/chat_tier/subscribe/",
            {
                tier,
                priceID
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getSubscriptionStatus() {
        return this.axios.get("/subscription_status", {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    cancelSubscription() {
        return this.axios.post("/cancel_subscription/", {}, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    gemsCheckout(gemsCount) {
        return this.axios.post("/gems/checkout", {gemsCount}, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    gemsCheckoutCapture(gemsCount, orderID) {
        return this.axios.post("/gems/checkout/capture", {gemsCount, orderID}, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    orangePointsCheckout(orangePointsCount) {
        return this.axios.post("/orange_points/checkout", {orangePointsCount}, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    orangePointsCheckoutCapture(orangePointsCount, orderID) {
        return this.axios.post("/orange_points/checkout/capture", {orangePointsCount, orderID}, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }
}

export const userTierAPIInstance = new UserTierAPI();

export default UserTierAPI;
