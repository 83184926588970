import React from "react";
import { Container, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/formatter/dateFormatter";
import { getCouponImageUrl } from "../../utils/images";
import Avatar from "../../Component/Comman/Avatar";

export function CouponSalesTable({ earnings = []}) {
    const { t } = useTranslation("profile_screen");

    return (
        <Container style={{ paddingLeft: "0em" }}>
            <Row className="mt-5">
                <Col md={6}>
                    <h4>{t("Coupon Sales")}</h4>
                    {earnings.length > 0 ? (
                        <Table striped={true} bordered={true} hover={true}>
                            <thead>
                                <tr>
                                    <th>{t("No.")}</th>
                                    <th>{t("Coupon Name")}</th>
                                    <th>{t("Coupon Image")}</th>
                                    <th>{t("Total Sales")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {earnings.map((earning, i) => (
                                    <tr key={earning?._id ?? i}>
                                        <td>{i + 1}</td>
                                        <td>{earning?.coupon_info?.couponName}</td>
                                        <td className="d-flex flex-row justify-content-center">
                                            <Avatar name={earning?.coupon_info?.couponName} avatarUrl={getCouponImageUrl(earning?.coupon_info?.couponPhotos?.[0])}/>
                                        </td>
                                        <td>{earning?.totalQuantity}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div>No Sales Available</div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
