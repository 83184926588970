import { Form, Modal, Spinner } from "react-bootstrap";
import React, { useState, useEffect, useCallback } from "react";
import { authAPIInstance } from "../../apis/AuthAPI";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmButton } from "../Comman/Buttons";
import { ErrorMsg } from "../Comman/Typography";
import { AxiosError } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import OTPInput from "react-otp-input";

function Enable2FAModal({ show, onHide }) {
    const dispatch = useDispatch();

    const [authQRImg, setAuthQRImg] = useState(null);
    const [loading, setLoading] = useState(false);

    const currentUser = useSelector((state) => state?.AuthReducer?.user);
    const schema = Yup.object({
        otp: Yup.string().label("OTP").required()
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting, errors },
        reset
    } = useForm({
        resolver: yupResolver(schema)
    });

    const [error, setError] = useState(null); // for axios error

    const enableTwoFactor = useCallback(async () => {
        setLoading(true);

        try {
            const response = await authAPIInstance.enableTwoFactor();

            if (!response?.data?.auth_qr_code) {
                throw new Error("Auth image not found!");
            }

            setAuthQRImg(response?.data?.auth_qr_code);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error?.message ?? "Error fetching two factor aut QR");
        }
    }, []);

    const onSubmit = async (data) => {
        setError(null);
        try {
            await authAPIInstance.completeEnableTwoFactor(data.otp);

            dispatch({
                type: "UPDATE",
                payload: {
                    user: {
                        key: "twoFactorEnabled",
                        value: true
                    }
                }
            });

            setAuthQRImg(null);

            onHide();
        } catch (error) {
            if (error instanceof AxiosError) {
                setError(error.response.data?.message);
            } else {
                setError("Error enabling two factor authentication");
            }

            console.error(error?.message ?? "Error enabling two factor auth");
        }
    };

    useEffect(() => {
        if (!currentUser?.twoFactorEnabled) {
            enableTwoFactor();
        }
    }, [currentUser?.twoFactorEnabled, enableTwoFactor]);

    useEffect(() => {
        setError(null);
        reset();
    }, [onHide]);

    return (
        <Modal className="modal" id="Enable2FA" style={{ display: "block" }} show={show} onHide={onHide} backdrop="static">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-content">
                    <Modal.Header className="modal-header" closeButton>
                        <h5 className="modal-title">Enable Two-factor Authentication</h5>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="mb-3">
                            <div className="">
                                Scan the QR code with your TOTP app (e.g Google Authenticator, Authy, Duo Mobile) and enter
                                the code below to activate two-factor authentication for you account.{" "}
                            </div>

                            <div className="card p-2 justify-content-center align-items-center d-flex my-3">
                                {loading ? (
                                    <Spinner animation="border" variant="primary" className="my-5" />
                                ) : (
                                    authQRImg && <img src={authQRImg} height={200} width={200} />
                                )}
                            </div>
                        </div>
                        <div className="mb-3 d-flex flex-column align-items-center ">
                            <label className="form-label">Enter Code</label>

                            <div className="mx-5 mb-1">
                                <Controller
                                    name={"otp"}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <OTPInput
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            value={field.value}
                                            numInputs={6}
                                            renderSeparator={<span className="me-2" />}
                                            renderInput={(props) => <Form.Control {...props} className="w-100" />}
                                        />
                                    )}
                                />
                            </div>
                            {errors.otp ? <ErrorMsg msg={errors.otp?.message} /> : error ? <ErrorMsg msg={error} /> : <></>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={onHide}>
                            Cancel
                        </button>
                        <ConfirmButton isSubmitting={isSubmitting} confirmText={"Activate"} />
                    </Modal.Footer>
                </div>
            </form>
        </Modal>
    );
}

export default Enable2FAModal;
