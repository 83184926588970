import axios from "axios";
import { config } from "../utils/config";
import store from "../store";
import { readCookie } from "../utils/cookie";
import { httpTokenInterceptor } from "./common";

class PointRewardApi {
    constructor() {
        this.axios = axios.create({
            baseURL: `${config[config["environment"]].baseApiUrl}/points`
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    scanQRcodeCheckin(checkinBody, token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.post(
                    "/checkin_earn_points",
                    {
                        ...checkinBody
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error adding merchant");
                reject(error);
            }
        });
    }

    buyCoupon(buyCouponBody, token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.post(
                    "/buy_coupon",
                    {
                        ...buyCouponBody
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error buying coupon");
                reject(error);
            }
        });
    }

    buyCouponBtc(buyCouponBody, token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.post(
                    "/buy_coupon_btc",
                    {
                        ...buyCouponBody
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error buying coupon");
                reject(error);
            }
        });
    }

    getPointTokenByMerchantId(merchantId, token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.get(`/get_point_token_by_merchant_uuid/${merchantId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error fetching point token");
                reject(error);
            }
        });
    }

    getPurchasedCoupons(token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.get("/get_purchased_coupons", {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error fetching purchased coupons");
                reject(error);
            }
        });
    }

    scanAndRedeemCoupon(redeemCouponBody, token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.post(
                    "/redeem_coupon",
                    {
                        ...redeemCouponBody
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error redeeming coupon");
                reject(error);
            }
        });
    }

    async sendPoints(sendPointsBody) {
        const response = await this.axios.post(
            "/send_points",
            {
                ...sendPointsBody
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie('token')}`
                }
            }
        );

        return response;
    }

    async getP2PSentPoints({limit = 10, skip = 0}) {
        const response = await this.axios.get(`/get_p2p_sent_points?limit=${limit}&skip=${skip}`, {
            headers: {
                Authorization: `Bearer ${readCookie('token')}`
            }
        });

        return response;
    }

    getStorePoints(pointTokenID, token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.get(`/get_store_points/${pointTokenID}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error fetching points");
                reject(error);
            }
        });
    }

    getAllMyPoints(token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.get("/get_all_my_points/", {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error fetching points");
                reject(error);
            }
        });
    }

    getMerchantCouponHistory(merchantId, token, page = 1, limit = 3) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.get(
                    `/get_store_owners_coupon_history/${merchantId}?limit=${limit}&page=${page}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error fetching coupons history");
                reject(error);
            }
        });
    }

    sendNotificationsToMerchantFollowers(token, storeQRCode) {
        return this.axios.post(
            "/send_recommendation_to_merchant_followers",
            { storeQRCode },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    checkInStoreWithPhone(token, phoneNumber) {
        return this.axios.post(
            "/checkin_phone",
            { phoneNumber },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    checkoutPointsPurchase(token, merchantId, points) {
        return this.axios.get(`/checkout_points/v2/${merchantId}?points=${points}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    capturePointsPurchase(token, merchantId, points, orderID) {
        return this.axios.post(
            "/checkout_points/v2/capture",
            { merchantId, orderID, points },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    checkoutCouponsPurchase(token, couponUUID) {
        return this.axios.post(
            "/coupon_checkout",
            { couponUUID },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    captureCouponsPurchase(token, couponUUID, orderID) {
        return this.axios.post(
            "/coupon_checkout/capture",
            { couponUUID, orderID },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    getRedeemedDailyLoginRewards(token) {
        return this.axios.get("/get_redeemed_daily_login_rewards", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    redeemDailyCheckinReward(token) {
        return this.axios.post(
            "/redeem_daily_checkin_reward",
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    redeemAdvertReward(token, numberOfPoints, selectedAdCharacterId) {
        return this.axios.post(
            "/redeem_advert_reward",
            {
                numberOfPoints,
                characterId: selectedAdCharacterId
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    getCreatedCharacterEarnings(token) {
        return this.axios.get("/created_character_earnings", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    updateCouponRedeemStatus(couponReceivedTxId, status) {
        return this.axios.patch(
            "/coupon_redeem_status",
            {
                couponReceivedTxId,
                status
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }
}

const PointRewardApiInstance = new PointRewardApi();

export { PointRewardApiInstance, PointRewardApi };
