/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { MerchantApiInstance } from "../../apis/MerchantsAPI";
import { readCookie } from "../../utils/cookie";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import "./index.css";
import { NO_IMAGE, getCouponImageUrl, getMerchantImageUrl, getStoreDisplayableImage } from "../../utils/images";
import { Modal } from "../../Component/Modal/Modal";
import BuyCoupon from "./BuyCoupon/BuyCoupon";
import { PointRewardApiInstance } from "../../apis/PointRewardApi";
import CouponCard from "./CouponCard/CouponCard";
import { createCookie } from "../../utils/cookie";
import DealCard from "../../Component/Explore/DealCard";


const customStyles = {
    
    content: {

        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "90vh",
        width: window.innerWidth <= 760 ? "80%": "30vw",
    }
};

export default function CouponScreen() {
    const { id } = useParams();
    const { state } = useLocation();
    const [preview] = useState(() => id === "preview");
    const token = readCookie("token");
    const [data, setData] = useState(() => (id === "preview" ? state : null));
    const [coupons, setCoupons] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [pointToken, setPointToken] = useState(null);
    const [pointWallet, setPointWallet] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!preview) {
            MerchantApiInstance.getCouponById(id)
                .then((res) => {
                    getPointTokenAndWallet(res.data.data.coupon?.merchantInfo?._id);
                    setData({
                        ...res.data.data.coupon,
                        merchantInfo: {
                            ...res.data.data.coupon.merchantInfo,
                            storePhotos: [res.data.data.coupon?.merchantInfo?.storePhotos?.[0]]
                        },
                        couponPhotos: [getCouponImageUrl(res.data.data.coupon?.couponPhotos?.[0])]
                    });
                })
                .catch(console.error);
        }
    }, [id, token, preview]);

    useEffect(() => {
        if (data?.merchantInfo && data?.merchantInfo?._id) {
            MerchantApiInstance.getCouponsByMerchant(data?.merchantInfo?._id, token)
                .then((res) => {
                    setCoupons(
                        res.data.data.coupons.map((coupon) => ({
                            ...coupon,
                            couponPhotos: [coupon?.couponPhotos?.[0]],
                            merchantInfo: data.merchantInfo
                        }))
                    );
                })
                .catch(console.error);
        }
    }, [data?.merchantInfo, id, token]);

    const getPointTokenAndWallet = useCallback(
        async (merchant_id) => {
            if (!token || !merchant_id) {
                return;
            }
            const pointTokenResponse = await PointRewardApiInstance.getPointTokenByMerchantId(merchant_id, token);
            if (pointTokenResponse?.data?.data?.pointToken) {
                const { pointToken: _pointToken } = pointTokenResponse.data.data;
                setPointToken(_pointToken);
                const pointWalletResponse = await PointRewardApiInstance.getStorePoints(_pointToken._id, token);
                if (pointWalletResponse?.data?.data?.pointWallet) {
                    const { pointWallet: _pointWallet } = pointWalletResponse.data.data;
                    setPointWallet(_pointWallet);
                }
            }
        },
        [token]
    );

    const handleStoreImageError = () => {
        setData((prev) => {
            return {
                ...prev,
                merchantInfo: {
                    ...prev.merchantInfo,
                    storePhotos: [NO_IMAGE]
                }
            };
        });
    };

    const handleCouponImageError = () => {
        setData((prev) => {
            return { ...prev, couponPhotos: [NO_IMAGE] };
        });
    };

    const handleOtherCouponsImageError = (i) => {
        setCoupons((prev) => {
            const newCoupons = [...prev];
            newCoupons[i] = { ...newCoupons[i], couponPhotos: [NO_IMAGE] };
            return newCoupons;
        });
    };

    const handleBuyClick = () => {
        if (!token) {
            createCookie("redirect", `/coupon/${id}`);
            navigate('/sign-in');
        }
        if (!preview) {
            setModalIsOpen(true);
        }
    };

    const handleMessageClick = () => {
        if (!token) {
            createCookie("redirect", `/coupon/${id}`);
            navigate('/sign-in');
        }
        if (!preview) {
            navigate(`/chat?type=user&id=${data?.merchantInfo?._id}`)
        }
    };

    const handleStoreClick = () => {
        navigate(`/store/${data?.merchantInfo?._id}`);
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCouponClick = (_id) => {
        navigate(`/coupon/${_id}`);
    }

    return (
        <>
            <Modal
                styles={customStyles}
                modalIsOpen={modalIsOpen}
                setIsOpen={setModalIsOpen}
                body={<BuyCoupon pointToken={pointToken} pointWallet={pointWallet} coupon={data} />}
            />
            {!data && (
                <div className="spinner-container">
                    <Spinner />
                </div>
            )}
            {data && (
                <div className="coupon-page-container">
                    {preview && (<div onClick={handleBackClick}><a href="#"><i className="fa fa-chevron-left me-2 " /> go back </a></div>)}
                    <div className="store-info-container" onClick={handleStoreClick}>
                        <div className="store-img-container">
                            <img onError={handleStoreImageError} src={getStoreDisplayableImage(data?.merchantInfo)} alt="store" />
                        </div>
                        <span>
                            <strong>{data?.merchantInfo?.storeName}</strong>
                        </span>
                    </div>
                    <div className="coupon-info-container">
                        <div className="coupon-img-container">
                            <img onError={handleCouponImageError} src={data?.couponPhotos?.[0]} alt="coupon" />
                        </div>
                        <div className="coupon-right-container">
                            <span className="coupon-name">{data?.couponName} <span className="coupon-price">price: {data?.priceInDollars ?? 0} {data?.currency}</span></span>
                            <p className="coupon-desc">{data?.couponDescription}</p>
                            <span className="coupon-points">
                                Your points for this store:
                                <span className="coupon-points-value"> {pointWallet?.totalAmount ?? 0}</span>
                            </span>
                            <Button className="buy-btn" onClick={handleBuyClick}>
                                Buy now
                            </Button>
                            <Button className="message-btn" onClick={handleMessageClick}>
                                Message Seller
                            </Button>
                        </div>
                    </div>
                    {coupons.length > 0 && !preview && (
                        <div className="other-coupons">
                            <h5>Other Coupons</h5>
                            <div className="coupons-list">
                                {coupons.map((coupon, i) => {
                                    if (coupon._id !== id)
                                        return (
                                            <DealCard
                                                id={coupon?._id}
                                                onClick={handleCouponClick}
                                                title={coupon?.couponName}
                                                creatorName={coupon?.merchantInfo?.storeName}
                                                itemUrl={getCouponImageUrl(coupon?.couponPhotos?.[0])}
                                                onSubtitleClick={() => {
                                                    navigate(`/store/${coupon?.merchantInfo?._id}`);
                                                }}
                                                coupon={{ ...coupon, merchantUUID: coupon?.merchantInfo }}
                                            />
                                        );
                                    return <></>;
                                })}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
