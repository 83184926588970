import React from "react";
import { Container, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function ReferralsTable({ earnings = []}) {
    const { t } = useTranslation("profile_screen");

    return (
        <Container style={{ paddingLeft: "0em" }}>
            <Row className="mt-5">
                <Col md={6}>
                    <h4>{t("Referral Earnings")}</h4>
                    {earnings.length > 0 ? (
                        <Table striped={true} bordered={true} hover={true}>
                            <thead>
                                <tr>
                                    <th>{t("username")}</th>
                                    <th>{t("Earnings $")}</th>
                                    <th>{t("Earning by")}</th>
                                    <th>{t("Withdrawn Status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {earnings.map((earning, i) => (
                                    <tr key={earning?._id ?? i}>
                                        <td>{earning?.username}</td>
                                        <td>{earning?.currency ?? '$'} {earning?.totalEarnings}</td>
                                        <td>{earning?.action}</td>
                                        <td>{earning?.payout_id ? "Withdrawn already" : "Withdraw available"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div>No earnings available</div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
