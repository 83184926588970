import React from "react";
import Avatar from "../../../Component/Comman/Avatar";
import { Button } from "react-bootstrap";

export default function SubscribeCharacterModal({
    avatar = "avatar",
    name = "name",
    description = "description",
    duration = "30 days",
    price = "price",
    currency = "$",
    onSubmit = () => {}
}) {
    return (
        <div className="d-flex flex-column gap-2 align-items-center">
            <h4>
                Subscribe to {name} for {duration}
            </h4>
            <div className="d-flex flex-row gap-2 align-items-center">
                <Avatar avatarUrl={avatar} className="ai-character-modal-avatar"/>
                <span>{name}</span>
            </div>
            <p>{description}</p>
            <Button onClick={onSubmit}>Pay {price} {currency} </Button>
        </div>
    );
}
