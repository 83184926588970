import React, { useEffect, useState } from "react";
import Avatar from "../../Component/Comman/Avatar";
import { authAPIInstance } from "../../apis/AuthAPI";
import { getStoreDisplayableImage } from "../../utils/images";
import { useNavigate } from "react-router";
import './favorites.css';

export default function Favorites() {
    const [merchants, setMerchants] = useState([]);

    useEffect(() => {
        authAPIInstance
            .getFollowing()
            .then((res) => {
              console.log(res);
                let followingStores = res?.data?.data?.following?.followingStores;
                if (Array.isArray(followingStores)) {
                    followingStores = followingStores.map((store) => {
                        return {
                            name: store?.storeName,
                            avatar: getStoreDisplayableImage(store),
                            link: `/store/${store?._id}`
                        };
                    });
                    setMerchants(followingStores);
                }
            })
            .catch(console.error);
    }, []);

    return (
        <div className="d-flex flex-column gap-2 p-2">
            <div className="d-flex flex-column gap-1">
                <h5>Favorite Merchants</h5>
                {merchants.length > 0 ? (
                    <div className="d-flex flex-row gap-5 p-2">
                        {merchants?.map((mt) => (
                            <FollowingItem name={mt?.name} avatar={mt?.avatar} link={mt?.link} />
                        ))}
                    </div>
                ) : (
                    <div className="d-flex justify-content-center">
                     You are not following any merchants
                    </div>
                )}
            </div>
        </div>
    );
}

function FollowingItem({ name = "", avatar = "", link = "" }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(link);
    };

    return (
        <div className="d-flex flex-row gap-2 align-items-center cursor-pointer following-container" onClick={handleClick}>
            <Avatar className="following-avatar" avatarUrl={avatar} name={name} />
            <span>{name}</span>
        </div>
    );
}
