import React, { useCallback, useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { developerAPIInstance } from "../../../apis/DeveloperAPI";
import { formatDate } from "../../../utils/formatter/dateFormatter";

export function ApiKeyStatsModal({ apiKey }) {
  const [apiCalls, setApiCalls] = useState([]);

  const fetchApiCalls = useCallback(() => {
      developerAPIInstance
          .getApiKeyStats(apiKey)
          .then((res) => {
              setApiCalls(res.data.data.api_calls);
          })
          .catch(console.error);
  }, [apiKey]);

  useEffect(() => {
      fetchApiCalls();
  }, [fetchApiCalls]);

  return (
      <div className="createKeyContainer">
          <Table striped bordered hover>
              <thead>
                  <tr>
                      <th>#</th>
                      <th>resource</th>
                      <th>httpMethod</th>
                      <th>Date</th>
                  </tr>
              </thead>
              <tbody>
                  {apiCalls.map((call, i) => (
                      <tr key={call?._id}>
                          <td>{i + 1}</td>
                          <td>{call?.resourcePath}</td>
                          <td>{call?.httpMethod}</td>
                          <td>{formatDate(call?.timeStamp)}</td>
                      </tr>
                  ))}
              </tbody>
          </Table>
      </div>
  );
}