import { Toast, ToastContainer } from "react-bootstrap";

export const ToastStatus = {
    SUCCESS: "success",
    ERROR: "danger",
    WARNING: "warning",
    INFO: "info"
};

export const CustomToast = ({ status, message, show, onClose }) => {
    return (
        <ToastContainer className="p-3" position={"bottom-end"} style={{ zIndex: 10 }}>
            <Toast show={show} onClose={onClose} bg={status} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">{status === ToastStatus.ERROR ? 'ERROR' : status?.toUpperCase()}</strong>
                </Toast.Header>
                <Toast.Body className="text-white">{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};
