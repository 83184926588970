import ReactGA from "react-ga4";

export const eventActions = {
  form_submit_event: "form_submit_event",
  form_change_event: "form_change_event",
  form_focus_event: "form_focus_event",
  chat_client_event: "chat_client_event",
  chat_user_event: "chat_user_event"
};

export const eventCategories = {
  USER_ACTION: 'user_action'
};

export const sendPageEvent = (path) => {
    ReactGA.send({ hitType: "pageview", page: path });
};

export const sendFormActionEvent = (category = eventCategories.USER_ACTION, action = eventActions.form_event,
  label = "", value = "") => {
    ReactGA.event({ category, action , label, value });
};
