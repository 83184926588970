import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Button, Container, Row, Col, FormGroup, FormLabel, Spinner, Form as RForm } from "react-bootstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import { authAPIInstance } from "../../apis/AuthAPI";
import { readCookie } from "../../utils/cookie";
import { Modal } from "../../Component/Modal/Modal";

const validationSchema = Yup.object().shape({
    bankName: Yup.string().required("Bank Name is required"),
    bankAccountNumber: Yup.number().required("Bank Account Number is required"),
    bankRoutingNumber: Yup.number().required("Bank Routing Number is required"),
    paypalEmail: Yup.string().email("Email is not valid"),
});

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: "50vh",
        width: "50vh",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#57389C"
    },
};


const PasswordModalBody = ({ handlePasswordChange, password, closeModal }) => {

    return (
        <div>
            <span>Please enter account password to complete this action.</span>
            <RForm.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <RForm.Label>Account password</RForm.Label>
                <RForm.Control value={password} onChange={handlePasswordChange} type="password" />
                <Button onClick={closeModal}>Submit</Button>
            </RForm.Group>
        </div>
    );
};

const BankForm = () => {

    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [password, setPassword] = useState("");
    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const token = readCookie('token');

    const initialValues = {
        bankName: user?.bankName ?? "",
        bankAccountNumber: user?.bankAccountNumber ?? "",
        bankRoutingNumber: user?.bankRoutingNumber ?? "",
        paypalEmail: user?.paypalEmail ?? ""
    };

    const handleSubmit = (values) => {
        if(password === "") {
            setIsOpen(true);
            return;
        }
        setLoading(true);
        authAPIInstance.updateBankInfo(token, {...values, password}).then(_ => {
            setLoading(false);
            Object.keys(values).forEach(key => {
                dispatch({
                    type: "UPDATE",
                    payload: {
                        user: {
                            key,
                            value: values[key]
                        }
                    }
                });
            })
            setPassword("");
            alert("Bank information updated.");
        }).catch(e  => {
            setLoading(false);
            setPassword("");
            alert("Something went wrong.");
            console.error(e)
        });
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
    };

    const closeModal = () => {
        setIsOpen(false);
    }

    return (
        <Container style={{ paddingLeft: "2em" }}>
            <Modal styles={modalStyles} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} body={<PasswordModalBody password={password} handlePasswordChange={handlePasswordChange} closeModal={closeModal}/>}/>
            <Row className="mt-5">
                <Col md={6}>
                    <h2>Bank Information</h2>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                        {({ handleSubmit, errors, touched }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup className="mb-3">
                                    <FormLabel>Bank Name</FormLabel>
                                    <Field type="text" name="bankName" className="form-control" />
                                    <ErrorMessage name="bankName" component="div" className="text-danger">
                                        {touched.bankName && errors.bankName && errors.bankName}
                                    </ErrorMessage>
                                </FormGroup>

                                <FormGroup className="mb-3">
                                    <FormLabel>Bank Account Number</FormLabel>
                                    <Field type="text" name="bankAccountNumber" className="form-control" />
                                    <ErrorMessage name="bankAccountNumber" component="div" className="text-danger">
                                        {touched.bankAccountNumber && errors.bankAccountNumber && errors.bankAccountNumber}
                                    </ErrorMessage>
                                </FormGroup>

                                <FormGroup className="mb-3">
                                    <FormLabel>Bank Routing Number</FormLabel>
                                    <Field type="text" name="bankRoutingNumber" className="form-control" />
                                    <ErrorMessage name="bankRoutingNumber" component="div" className="text-danger">
                                        {touched.bankRoutingNumber && errors.bankRoutingNumber && errors.bankRoutingNumber}
                                    </ErrorMessage>
                                </FormGroup>

                                <FormGroup className="mb-3">
                                    <FormLabel>Paypal Email</FormLabel>
                                    <Field type="text" name="paypalEmail" className="form-control" />
                                    <ErrorMessage name="paypalEmail" component="div" className="text-danger">
                                        {touched.paypalEmail && errors.paypalEmail && errors.paypalEmail}
                                    </ErrorMessage>
                                </FormGroup>

                                <Button disabled={loading} variant="primary" type="submit">
                                    Submit
                                    {loading && <Spinner size="sm" style={{ marginLeft: "0.5em" }} />}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default BankForm;
