import React, { useState } from "react";
import { useSelector } from "react-redux";
import Enable2FAModal from "./Enable2FAModal";
import Disable2FAModal from "./Disable2FAModal"
import EmailVerification from "./EmailVerification";
import PhoneVerification from "./PhoneVerification";

function Authentication() {
    const currentUser = useSelector((state) => state?.AuthReducer?.user);

    const [enableModal, setEnableModal] = useState(false);
    const [disableModal, setDisableModal] = useState(false);

    const [emailVerificationModal, setemailVerificationModal] = useState(false);
    const [emailVerification, setEmailVerification] = useState({
        code: "",
        loading: false,
        code_sent: false
    });

    const [phoneVerificationModal, setPhoneVerificationModal] = useState(false);
    
    return (
        <div className="col-xl-12">
            <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent align-items-center ">
                    <h6 className="mb-0 fw-bold">Authentication</h6>
                </div>
                <div className="card-body">
                    <div className="border-bottom py-2 mb-2">
                        <div className="row justify-content-between">
                            <div className="col-lg-4 col-xl-4">
                                <div className="d-flex">
                                    <i className="icofont-key fs-5 text-primary"></i>
                                    <div className="d-flex flex-column px-2">
                                        <span className="fw-bold">2FA (Recommended)</span>
                                        <span className="text-muted small">
                                            Protect your account with two-factor authentication.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4">
                                <div className="d-flex align-items-center">
                                    {currentUser?.twoFactorEnabled ? (
                                        <>
                                            <i className="icofont-check-circled fs-5 text-success"></i>
                                            <span className="px-2">Set</span>
                                        </>
                                    ) : (
                                        <>
                                            <i className="icofont-close-circled fs-5 text-danger"></i>
                                            <span className="px-2">Unset</span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4">
                                {currentUser?.twoFactorEnabled ? (
                                    <button
                                        type="button"
                                        className="btn flex-fill btn-light-danger py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end"
                                        onClick={() => {
                                            setDisableModal(true);
                                        }}
                                    >
                                        Disable
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn flex-fill btn-light-warning py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end"
                                        onClick={() => {
                                            setEnableModal(true);
                                        }}
                                    >
                                        Enable
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom py-2 mb-2">
                        {/* <div className="row justify-content-between">
                            <div className="col-lg-4 col-xl-4">
                                <div className="d-flex">
                                    <i className="icofont-smart-phone fs-5 text-primary"></i>
                                    <div className="d-flex flex-column px-2">
                                        <span className="fw-bold">Phone Number Verification</span>
                                        <span className="text-muted small">Protect your account and transactions.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4">
                                <div className="d-flex align-items-center">
                                    {currentUser?.phone?.isVerified && (
                                        <i className="icofont-check-circled fs-5 text-success"></i>
                                    )}
                                    <span className="px-2">{currentUser?.phone?.phoneNumber}</span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4">
                                {!currentUser?.phone?.isVerified && (
                                    <button
                                        type="button"
                                        className="btn flex-fill btn-light-success py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end mx-2"
                                        onClick={() => {
                                            setPhoneVerificationModal(true);
                                        }}
                                    >
                                        Verify
                                    </button>
                                )}
                            </div>
                        </div> */}
                    </div>
                    <div className="py-2">
                        <div className="row justify-content-between">
                            <div className="col-lg-4 col-xl-4">
                                <div className="d-flex">
                                    <i className="icofont-email fs-5 text-primary"></i>
                                    <div className="d-flex flex-column px-2">
                                        <span className="fw-bold">Email Address Verification</span>
                                        <span className="text-muted small">Protect your account and transactions.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4">
                                <div className="d-flex align-items-center">
                                    {currentUser?.emailVerified && (
                                        <i className="icofont-check-circled fs-5 text-success"></i>
                                    )}
                                    <span className="px-2">{currentUser?.email}</span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xl-4">
                                {!currentUser?.emailVerified && (
                                    <button
                                        type="button"
                                        className="btn flex-fill btn-light-success py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end mx-2"
                                        onClick={() => {
                                            setemailVerificationModal(true);
                                        }}
                                    >
                                        Verify
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Enable2FAModal show={enableModal} onHide={() => setEnableModal(false)} />
            <Disable2FAModal show={disableModal} onHide={() => setDisableModal(false)} />

            <EmailVerification
                emailVerificationModal={emailVerificationModal}
                setemailVerificationModal={setemailVerificationModal}
                emailVerification={emailVerification}
                setEmailVerification={setEmailVerification}
            />
            <PhoneVerification
                phoneVerificationModal={phoneVerificationModal}
                setPhoneVerificationModal={setPhoneVerificationModal}
                currentUser={currentUser}
            />
        </div>
    );
}

export default Authentication;
