import React, { useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import ImageUpload from '../../assets/images/image_upload.jpg';
import { getUserIpInfo } from "../../utils/getUserCountry";
import { getSchedueledImageUrl } from "../../utils/images";
import { timeZones } from "../../utils/timezones";
import "./index.css";

export default function SchedueledMessages({
    schedueledMessages = [],
    removeSchedueledMessage = (_) => {},
    addMessage = () => {},
    updateMessage = (_, __, ___) => {},
    submitMessages = () => {},
    goBack = () => {},
    update = false,
}) {
    return (
        <div className="d-flex flex-column container p-4 gap-2 create-character-body">
            <h4>Scheduled Messages</h4>
            <div className="d-flex flex-column gap-2 justify-content-between schedueledMessages-container">
                <Button onClick={addMessage} className="scheduled-message-btn py-4">
                    <i class="fa-solid fa-plus me-4" style={{color: "#ffffff"}}></i>
                    Add Message
                </Button>
                <div className="d-flex flex-column gap-2">
                    {schedueledMessages.map((msg, i) => (
                        <SchedueledMessage
                            key={`schedueledMessage${i}`}
                            schedueledMessage={msg}
                            removeSchedueledMessage={removeSchedueledMessage}
                            updateMessage={updateMessage}
                            index={i}
                            update={update}
                        />
                    ))}
                </div>
                <div className="d-flex flex-row justify-content-end gap-3">
                    <Button onClick={submitMessages} className="orange-gradient-btn">Save</Button>
                    <Button onClick={goBack}>Cancel</Button>
                </div>
            </div>
        </div>
    );
}

function SchedueledMessage({
    schedueledMessage = {},
    removeSchedueledMessage = (_) => {},
    updateMessage = (_, __, ___) => {},
    index,
    update = false,
    autoSelectTimzone = true
}) {
    const fileInputRef = useRef();

    useEffect(() => {
        if (autoSelectTimzone && !schedueledMessage?._id) {
            getUserIpInfo()
                .then(({ timezone }) => {
                    const foundTimeZone = timeZones.find((tz) => tz === timezone);
                    if (foundTimeZone) {
                        updateMessage(index, "timezone", foundTimeZone);
                    }
                })
                .catch(console.error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoSelectTimzone, index, schedueledMessage?._id]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        updateMessage(index, "file", file);
    }

    const handleUploadClick = () => {
        fileInputRef?.current?.click();
    }

    const getImageUrl = () => {
        if (!update) {
            return schedueledMessage?.file
                ? URL.createObjectURL(schedueledMessage?.file)
                : ImageUpload;
        }
        if (schedueledMessage?.file) {
            return URL.createObjectURL(schedueledMessage?.file);
        }
        if (schedueledMessage?.messageImageUrl && schedueledMessage?.messageImageUrl !== "") {
            return getSchedueledImageUrl(schedueledMessage?.messageImageUrl);
        }
        return ImageUpload;
    };

    const handleImageDelete = () => {
        if (update && schedueledMessage?.messageImageUrl) {
            updateMessage(index, "messageImageUrl", '');
            return;
        }
        updateMessage(index, "file", null);
    };

    const showDeleteButton = () => {
        if (schedueledMessage?.messageImageUrl || schedueledMessage?.file) {
            return true;
        }
        return false;
    };

    return (
        <div className="create-character-form">
            <Form className="d-flex flex-column gap-2 p-4">
                <input ref={fileInputRef} type="file" onChange={handleFileChange} style={{ display: "none" }} />
                <div style={{ width: "150px", cursor: "pointer", position: "relative" }}>
                    <img src={getImageUrl()} alt="" style={{ width: "100%", height: "100%" }} onClick={handleUploadClick}/>
                    {showDeleteButton() && (
                        <i
                            style={{ cursor: "pointer", position: "absolute", right: "10%", top: "5%" }}
                            class="fa-solid fa-trash"
                            onClick={handleImageDelete}
                        ></i>
                    )}
                </div>
                <div>
                    <Form.Label>Message Text</Form.Label>
                    <Form.Control
                        type="text"
                        value={schedueledMessage.messageText}
                        onChange={(e) => {
                            updateMessage(index, "messageText", e.target.value);
                        }}
                    />
                </div>

                <div>
                    <Form.Label>Message Timezone</Form.Label>
                    <Form.Select
                        type="text"
                        value={schedueledMessage.timezone}
                        onChange={(e) => {
                            updateMessage(index, "timezone", e.target.value);
                        }}
                    >
                        {timeZones.map((zone, i) => (
                            <option key={`zone${i}`} label={zone}>
                                {zone}
                            </option>
                        ))}
                    </Form.Select>
                </div>

                <div>
                    <Form.Label>Message Time</Form.Label>
                    <Form.Control
                        type="time"
                        value={schedueledMessage.time}
                        onChange={(e) => {
                            updateMessage(index, "time", e.target.value);
                        }}
                    >
                    </Form.Control>
                </div>
                <div>
                    <Form.Label>Message Text</Form.Label>
                    <Form.Select
                        type="text"
                        value={schedueledMessage.frequency}
                        onChange={(e) => {
                            updateMessage(index, "frequency", e.target.value);
                        }}
                    >
                        <option label="Daily">Daily</option>
                        {/* <option label="Weekly">weekly</option>
                        <option label="Monthly">monthly</option> */}
                    </Form.Select>
                </div>
                {schedueledMessage?.isSent ? (
                    <span> Cannot delete already sent message</span>
                ) : (
                    <Button onClick={() => removeSchedueledMessage(index)} className="orange-gradient-btn py-3">Delete Message</Button>
                  )}
            </Form>
        </div>
    );
}
