const initialState = {
    user: null,
    token: null,
    authenticated: false,
    authLoading: true
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case "AUTHENTICATE":
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
                authenticated: true,
                authLoading: false
            };

        case "SET_AUTH_LOADING":
            return {
                ...state,
                authLoading: action.payload
            };

        case "LOGOUT":
            return {
                ...state,
                user: null,
                token: null,
                authenticated: false
            };
        case "UPDATE":
            return {
                ...state,
                user: {
                    ...state.user,
                    [action.payload.user.key]: action.payload.user.value
                }
            };
        case "UPDATE_PROFILE":
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export default AuthReducer;
