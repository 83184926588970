import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { readCookie } from "../../utils/cookie";

function AuthRedirect(props) {
    const redirect = readCookie('redirect');

    if (props.AuthReducer.authLoading) {
        return (
            <div className="d-flex" style={{ height: "100vh", width: "100%" }}>
                <span className="m-auto">
                    <i className="fas fa-circle-notch fa-spin text-warning" style={{ fontSize: "2rem" }}></i>
                </span>
            </div>
        );
    }

    if (props.AuthReducer.authenticated && props.AuthReducer.user) {
        if (redirect) {
            return <Navigate to={redirect} replace={true} />;
        }
        return <Navigate to="/" replace={true} />;
    }
    return props.children;
}

const mapStateToProps = ({ AuthReducer }) => ({
    AuthReducer
});

export default connect(mapStateToProps, {})(AuthRedirect);
