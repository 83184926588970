import { useState } from "react";
import PageTitle from "../../../Component/Comman/PageTitle";
import { CustomToast, ToastStatus } from "../../../Component/Comman/CustomToast";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllCoupons,
    getCurrentUserMerchant,
    getCurrentUserMerchantCoupons
} from "../../../Redux/Actions/MerchantActions";
import { MerchantApiInstance } from "../../../apis/MerchantsAPI";
import { useLocation, useNavigate } from "react-router";
import CouponForm from "./CouponForm";
import { readCookie } from "../../../utils/cookie";

const EditCouponScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const merchant = useSelector((state) => {
        return state?.merchant?.currentUserMerchant;
    });
    const location = useLocation();
    const couponID = location?.state?.updatingCoupon.id;
    const token = readCookie("token");
    const [isSubmitting, setSubmitting] = useState(false);
    const [isDeleting, setDeleting] = useState(false);

    const [toast, setToast] = useState({
        show: false,
        message: "",
        status: ""
    });

    const handleSubmit = async ({ couponBody, token, couponUploadImages }) => {
        try {
            setSubmitting(true)
            if (!merchant._id) {
                throw new Error("Merchant id not found");
            }

            let couponResponse = null;

            let newImages = couponUploadImages
                .filter((image) => {
                    return image?.file !== undefined;
                })
                .map((image) => {
                    return image.file;
                });
            couponResponse = await MerchantApiInstance.updateCoupon(couponID, couponBody, merchant._id, token, newImages);

            if (!couponResponse) {
                throw new Error("Error adding coupon");
            }

            dispatch(getCurrentUserMerchant(token));
            dispatch(getCurrentUserMerchantCoupons(merchant._id, token));
            dispatch(getAllCoupons(token));
            setSubmitting(false)

            setToast({
                show: true,
                message: "Successfully updated coupon!",
                status: ToastStatus.SUCCESS
            });

            setTimeout(() => {
                navigate("/my-store");
            }, 2000);
        } catch (error) {
            console.error(error?.message ?? "Error updating coupon");
            setSubmitting(false)

            setToast({
                show: true,
                message: "An error has occurred!",
                status: ToastStatus.ERROR
            });
        }
    };

    const handleDeleteCoupon = async () => {
        try {
            setDeleting(true)

            await MerchantApiInstance.deleteCoupon(couponID, token);
            dispatch(getCurrentUserMerchantCoupons(merchant.id, token));
            setDeleting(false)

            setToast({
                show: true,
                message: "Successfully deleted coupon!",
                status: ToastStatus.SUCCESS
            });

            setTimeout(() => {
                navigate("/my-store");
            }, 2000);
        } catch (error) {
            setDeleting(false)

            console.error(error?.message ?? "Error deleting coupon");

            setToast({
                show: true,
                message: "Could not delete coupon!",
                status: ToastStatus.ERROR
            });
        }
    };

    return (
        <div className="container-xxl px-4">
            <PageTitle pagetitle="Edit Coupon" goBackLink="/my-store" />
            <CouponForm onSubmit={handleSubmit} handleDeleteCoupon={handleDeleteCoupon} isSubmitting={isSubmitting} isDeleting={isDeleting}/>

            <CustomToast
                status={toast.status}
                show={toast.show}
                message={toast.message}
                onClose={() => setToast((prev) => ({ ...prev, show: false }))}
            />
        </div>
    );
};

export default EditCouponScreen;
