import React, { useState } from "react";
import './apiPricing.css';
import { Modal } from "../../Component/Modal/Modal";
import StripeCheckout from "../../Component/StripeCheckout/StripeCheckout";
import { developerAPIInstance } from "../../apis/DeveloperAPI";
import { useNavigate } from "react-router";

const packages = [
    {
        title: "FREE",
        name: "free",
        price: "Free",
        points: [
            'free 100 api calls per day',
            'with ads (every 10 calls)'
        ]
    },
    {
        title: "SILVER",
        name: "silver",
        price: 50,
        points: [
            'ads off (turn on ads profit share)',
            '10k calls per month'
        ]
    },
    {
        title: "GOLD",
        name: "gold",
        price: 100,
        points: [
            'Everything on silver',
            '1 Million calls per month',
            'developer support'
        ]
    },
    {
        title: "ENTERPRISE",
        name: "enterprise",
        price: "Contact",
        points: [
            'Dedicated integration Engineer',
            '24/7 support',
            'Custom alerts and debugging',
            'Security audit'
        ]
    }
];

export default function ApiPricing() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const [package_name, setPackage_name] = useState("");
    const navigate = useNavigate();

    const fetchCheckoutClientSecret = async (package_name) => {
        try {
            const { data } = await developerAPIInstance.upgradePlan(package_name);
            setClientSecret(data.data.secret);
        } catch (error) {
            alert('Upgrade not available');
            setIsOpen(false);
        }
    };

    const handleCheckout = (name) => {
        if (name === "free" || name === "enterprise") {
            return;
        }
        setPackage_name(name);
        fetchCheckoutClientSecret(name);
        setIsOpen(true);
    };

    const onPaymentSucessCallback = async (paymentIntent) => {
        try {
            await developerAPIInstance.upgradePlanCapture(package_name, paymentIntent);
            alert('Plan upgraded.');
            setIsOpen(false);
            navigate("/developer-portal");
        } catch (error) {
            console.error(error);
            alert('Something went wrong.');
            setIsOpen(false);
        }
    };

    return (
        <div className="pricing-main-container">
            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                title="Checkout"
                body={<StripeCheckout clientSecret={clientSecret} onPaymentSucessCallback={onPaymentSucessCallback}/>}
            />
            <span>Start for free or paid service for different Tiers.</span>
            <div className="packages-container">
                <div className="packages-cards-container">
                    {packages.map((pkg, i) => (
                        <div className="package-card" key={`dev_package${i}`}>
                            <div className="package-first-point">
                                <h5 className="package-heading">{pkg.title}</h5>
                                <span className="package-pricing">
                                    {typeof pkg.price === "string" ? pkg.price : `${pkg.price}$ /month`}
                                </span>
                                <button
                                    type="button"
                                    hidden={pkg.name === "free"}
                                    className="btn btn-primary start-btn"
                                    onClick={() => handleCheckout(pkg.name)}
                                >
                                    Start Now
                                </button>
                            </div>
                            {pkg.points.map((point, i) => (
                                <div key={`pointText${i}`} className="package-point-containers" style={{ paddingBottom: "3.7em" }}>
                                    {point}
                                </div>
                            ))}
                            <div className="package-point-containers card-grey-bg empty">
                                <button
                                    type="button"
                                    className="btn btn-primary start-btn"
                                    onClick={() => handleCheckout(pkg.name)}
                                >
                                    Start Now
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
