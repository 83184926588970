import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import TotalEarnings from "./components/TotalEarnings";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import { readCookie } from "../../utils/cookie";
import { useNavigate } from "react-router";
// import { ReferralsTable } from "./ReferralsTable";
import { authAPIInstance } from "../../apis/AuthAPI";
// import { PayoutsTable } from "./PayoutsTable";
import { MerchantApiInstance } from "../../apis/MerchantsAPI";
import Referral from "./components/Referral";

export default function Earnings() {

  const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const token = readCookie("token");
    const [earnings, setEarnings] = useState([]);
    const [payouts, setPayouts] = useState([]);
    const [couponSaleEarnings, setCouponSaleEarnings] = useState([]);
    const [couponSales, setCouponSales] = useState([]);
    const [characterEarnings, setCharacterEarnings] = useState([]);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [withdrawEarningsAvailable, setWithdrawEarningsAvailable] = useState(0);

    useEffect(() => {
        authAPIInstance
            .getReferralEarnings(token)
            .then((res) => {
                const { data } = res.data;
                if (data.earnings) {
                    let _earnings = [];
                    let _totalEarnings = 0;
                    let _withdrawEarningsAvailable = 0;
                    data.earnings.forEach((earning) => {
                        let current_user_earnings = [];
                        earning.earnings.forEach((_earning) => {
                            _totalEarnings += _earning?.amountEarned;
                            if(!_earning?.payout_id) {
                                _withdrawEarningsAvailable += _earning?.amountEarned;
                            }
                            current_user_earnings.push({
                                username: earning?.referred_user_info?.username,
                                avatar: earning?.referred_user_info?.avatar_url,
                                totalEarnings: _earning?.amountEarned,
                                currency: _earning?.currency,
                                payout_id: _earning?.payout_id,
                                action: _earning?.action,
                                timeStamp: _earning?.timeStamp,
                                withdrawn: false
                            });
                        });
                        if (current_user_earnings.length > 0) {
                            _earnings = _earnings.concat(current_user_earnings);
                        } else {
                            _earnings.push({
                                username: earning?.referred_user_info?.username,
                                avatar: earning?.referred_user_info?.avatar_url,
                                totalEarnings: 0,
                                payout_id: null,
                                action: '-',
                                withdrawn: true,
                                timeStamp: '',
                            });
                        }
                    });
                    setWithdrawEarningsAvailable(_withdrawEarningsAvailable.toFixed(2));
                    setTotalEarnings(_totalEarnings.toFixed(2));
                    setEarnings(_earnings);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, [token]);

    useEffect(() => {
        authAPIInstance.getPayouts(token)
        .then(res => {
            setPayouts(res?.data?.data?.payouts ?? []);
        })
        .catch(console.error);
    }, [token]);

    useEffect(() => {
        authAPIInstance.getCharacterEarnings(token)
        .then(res => {
            setCharacterEarnings(res?.data?.data?.earnings ?? []);
        })
        .catch(console.error);
    }, [token]);

    useEffect(() => {
        MerchantApiInstance.getCouponEarnings(token)
        .then(res => {
            setCouponSaleEarnings(res?.data?.data?.coupon_sales ?? []);
        })
        .catch(console.error);
    }, [token]);

    useEffect(() => {
        MerchantApiInstance.getCouponSales(token)
        .then(res => {
            setCouponSales(res?.data?.data?.coupon_sales ?? []);
        })
        .catch(console.error);
    }, [token]);

    const handleEditClick = () => {
        navigate("/bank-info");
    };

    const handleWithdraw = () => {
        authAPIInstance.payoutReferralEarnings(token)
        .then(_ => {
            setWithdrawEarningsAvailable(0);
            alert("Payout request successful. Amount will be credited in your account within 14 days.");
        }).catch(e => {
            console.error(e);
            if(e?.response?.status === 409){
                alert("Please add your account details");
            } else alert("Something went wrong.");
        })
    };

    const handleCouponStatusUpdate = (txId, currentStatus) => {
        setCouponSaleEarnings((prev) => {
            const previousCouponsTx = [...prev];
            const foundIndex = prev.findIndex((tx) => {
                return tx._id === txId;
            });
            if (foundIndex === -1) {
                return prev;
            }
            previousCouponsTx[foundIndex] = { ...previousCouponsTx[foundIndex], isCouponRedeem: !currentStatus };
            return [...previousCouponsTx];
        });
    };
  
    return (
        <div className="d-flex flex-column gap-3 mt-4">
            <div className="d-flex flex-row gap-2 earning-headings">
                <span>
                    <i class="fa-solid fa-dollar-sign"></i>
                </span>
                Total Earnings
            </div>
            <TotalEarnings handleWithdraw={handleWithdraw} totalEarnings={totalEarnings} withdrawEarningsAvailable={withdrawEarningsAvailable}/>
            <div className="d-flex flex-row gap-2 earning-headings">
                <span>
                  <i class="fa-solid fa-building-columns"></i>
                </span>
                Bank Details
            </div>
            <div className="bank-account-container">
                <div className="top-container">
                    <span>
                        <strong>My Bank Account</strong>
                    </span>
                    <span className="acc-edit-btn" onClick={handleEditClick}>
                        Edit
                    </span>
                </div>
                <div className="top-container">
                    <span className="acc-name-no">{user?.bankName ? user?.bankName : "Bank name not added"}</span>
                    <span>{user?.bankAccountNumber ? user?.bankAccountNumber : "Account number not added"}</span>
                </div>
            </div>
            <div className="d-flex flex-row gap-2 earning-headings">
                <span>
                <i class="fa-solid fa-people-group"></i>
                </span>
                Referral Earnings
            </div>
            <div className="d-flex flex-row gap-5 flex-wrap">
                {
                  earnings.map((e) => (
                    <Referral earning={e}/>
                  ))
                }
            </div>
        </div>
    );
}
