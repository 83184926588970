import axios from "axios";
import { readCookie } from "../utils/cookie";
import { config } from "../utils/config";
import { httpTokenInterceptor } from "./common";

class TransactionAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/transactions"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    async withdraw({ body }) {
        try {
            const response = await this.axios.post(
                "/withdraw",
                {
                    ...body
                },
                {
                    headers: {
                        Authorization: `Bearer ${readCookie("token")}`
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async completeEthWithdraw({ body }) {
        try {
            const response = await this.axios.patch(
                "/withdraw",
                {
                    ...body
                },
                {
                    headers: {
                        Authorization: `Bearer ${readCookie("token")}`
                    }
                }
            );
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getWithdraws() {
        try {
            const response = await this.axios.get("/withdraw", {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getDeposits() {
        try {
            const response = await this.axios.get("/deposit", {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async btcWithdraw({ body }) {
        try {
            const response = await this.axios.post(
                "/btc/withdraw",
                { ...body },
                {
                    headers: {
                        Authorization: `Bearer ${readCookie("token")}`
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async completebtcWithdraw({ body }) {
        try {
            const response = await this.axios.patch(
                "/btc/withdraw",
                { ...body },
                {
                    headers: {
                        Authorization: `Bearer ${readCookie("token")}`
                    }
                }
            );
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getBtcWithdraws() {
        try {
            const response = await this.axios.get("/btc/withdraws", {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getBtcDeposits() {
        try {
            const response = await this.axios.get("/btc/deposits", {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getUserTransactions(userId) {
        try {
            const response = await this.axios.get(`/${userId}`, {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async cancelBtcWithdrawTransaction({ body }) {
        try {
            const response = await this.axios.patch(
                "/btc/status/cancel",
                { ...body },
                {
                    headers: {
                        Authorization: `Bearer ${readCookie("token")}`
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async cancelEthWithdrawTransaction({ body }) {
        try {
            const response = await this.axios.patch(
                "/status/cancel",
                { ...body },
                {
                    headers: {
                        Authorization: `Bearer ${readCookie("token")}`
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

export const transactionAPIInstance = new TransactionAPI();

export default TransactionAPI;
