import { authAPIInstance } from "../../apis/AuthAPI";
import { createCookie, eraseCookie, readCookie } from "../../utils/cookie";

export const register = (password, username, email, fingerPrint, inviteCode, errorCb) => async (dispatch) => {
    try {
        const res = await authAPIInstance.register(password, username, email, fingerPrint, inviteCode);
        createCookie("token", res?.data?.token);
        dispatch({
            type: "AUTHENTICATE",
            payload: {
                user: res?.data?.user,
                token: res?.data?.token
            }
        });
    } catch (error) {
        errorCb(error);
        dispatch({
            type: "ERROR",
            payload: "There is an error registering user"
        });
    }
};

export const getUserProfile = () => async (dispatch) => {
    try {
        const res = await authAPIInstance.getCurrentUserProfile();
        dispatch({
            type: "AUTHENTICATE",
            payload: {
                user: res?.data?.user,
                token: readCookie("token")
            }
        });
    } catch (error) {
        console.error(error?.message);
    }
};

export const login = (password, email, fp, errorCb) => async (dispatch) => {
    try {
        const res = await authAPIInstance.login(password, email, fp);

        return res
    } catch (error) {
        console.log(error)
        errorCb(error);
        dispatch({
            type: "ERROR",
            payload: "There is an error logging in"
        });
    }
};

export const logout = () => async (dispatch) => {
    try {
        await authAPIInstance.logout();
        dispatch({
            type: "LOGOUT"
        });
        eraseCookie("token");
    } catch (error) {
        console.error(error?.message);
        dispatch({
            type: "ERROR",
            payload: "There is an error logging in"
        });
    }
};

export const setAuthLoading = (payload) => async (dispatch) => {
    dispatch({
        type: "SET_AUTH_LOADING",
        payload
    });
};
