import React, { useEffect, useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import Avatar from "../../../Component/Comman/Avatar.js";
import { ChatItem } from "../../../Component/App/ChatBox/ChatItem.jsx";
import { AIApiInstance } from "../../../apis/AIAPI.js";
import { useNavigate, useParams } from "react-router-dom";
import { getAiCharacterImageUrlV2 } from "../../../utils/images.js";
import './index.css';

function NotLoginChatBox() {
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const bottomRef = useRef(null);
    const navigate = useNavigate();
    const [character, setcharacter] = useState();
    const {charId} = useParams();

    useEffect(() => {
        AIApiInstance.getCharacterInfo(charId).then(res => {
            setcharacter(res?.data?.data?.character)
        }).catch(console.error)
    }, [charId]);

    const getCharacterAvatar = () => {
        return getAiCharacterImageUrlV2(character);
    }

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const handleNavSignupUser = () => {
        navigate("/sign-up");
    };

    const handleSendMessage = () => {
        setMessages((prev) => {
            let temp = [...prev];
            const messageId = `${Date.now()}-${Math.random()}`
            temp.push({
                sentByUser: true,
                _id: messageId,
                timeStamp: Math.floor(new Date().getTime() / 1000),
                messageContent: message,
                originalMessage: ""
            });
            return [...temp];
        });
        let previous_messages = messages.slice(-10);
        previous_messages = previous_messages.map((msg) => {
            return {
                ...msg,
                messageContent: {
                    encrypted_text: msg?.messageContent
                }
            };
        });
        AIApiInstance.trySendCharacterMessage(character?._id, message, )
        .then(res => {
            setMessages((prev) => {
                let temp = [...prev];
                const messageId = `${Date.now()}-${Math.random()}`
                temp.push({
                    sentByUser: false,
                    _id: messageId,
                    timeStamp: Math.floor(new Date().getTime() / 1000),
                    messageContent: res.data?.data?.message?.original,
                    originalMessage: ""
                });
                return [...temp];
            });
        })
        .catch(console.error)
        setMessage("");
    };

    const handleSpicyModeChange = (e) => {
        alert('Register or login to try spicy mode.');
    }

    const handleBackButtonClick = () => {
        navigate(-1);
    }

    return (
        <>
            <div className="chat-header d-flex justify-content-between align-items-center border-bottom px-4 pt-4 pt-lg-3 pb-4 pb-lg-3 mt-2">
                <div className="d-flex align-items-center gap-3">
                    <i class="fa-solid fa-circle-arrow-left" style={{fontSize: '20px'}} onClick={handleBackButtonClick}></i>
                    <Avatar avatarUrl={getCharacterAvatar()} name={character?.name} />
                    <div>
                        <h6 className="mb-0">{character?.characterName}</h6>
                    </div>
                </div>
                <div>
                    <Alert variant="warning" className="" onClick={handleNavSignupUser}>
                        Register to save chat & Earn Rewards!
                    </Alert>
                </div>
            </div>
            {Array.isArray(messages) && (
                <ul
                    className="no-login-messages-container chat-history list-unstyled mb-0 flex-grow-1 px-5 px-lg-4 py-4 py-lg-3"
                >
                    {messages.map((message) => (
                        <ChatItem avatar={getCharacterAvatar()} message={message} chatName={character?.characterName} />
                    ))}
                    {messages.length === 0 && (
                        <div
                            className="d-flex flex-column align-items-center justify-content-center h-100"
                        >
                            <h3>No messages</h3>
                        </div>
                    )}
                    <div ref={bottomRef}></div>
                </ul>
            )}

            <div className="position-relative bottom-0 border-top d-flex px-1 px-lg-4 py-4 py-lg-3 align-items-center">
                <textarea
                    className="form-control"
                    style={{ marginBottom: "10px" }}
                    placeholder="Enter text here..."
                    value={message}
                    onChange={(e) => setMessage(e.currentTarget.value)}
                ></textarea>
                <div>
                    <Button className="ms-2 d-flex align-items-center py-2" onClick={handleSendMessage} variant="primary">
                        <i className="fa fa-paper-plane"></i>
                        <p className="mb-0 ms-2">Send</p>
                    </Button>
                </div>
            </div>
        </>
    );
}
export default NotLoginChatBox;
