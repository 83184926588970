import React, { useEffect, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Avatar from "../../Comman/Avatar";
import { authAPIInstance } from "../../../apis/AuthAPI";
import { AIApiInstance } from "../../../apis/AIAPI";
import { getUserImageUrl, getAiCharacterImageUrlV2 } from "../../../utils/images";

export default function GroupCreateModal({closeModal = () => {}, handleGroupSave = () => {}, currentGroupChat = null}) {
    console.log(currentGroupChat)
    const [name, setname] = useState(currentGroupChat?.name ?? "");
    const [users, setUsers] = useState([]);
    const [characters, setcharacters] = useState([]);

    const selectedUsers = useMemo(() => {
        return users.filter((u) => u?.selected);
    }, [users]);

    const selectedChars = useMemo(() => {
        return characters.filter((u) => u?.selected);
    }, [characters]);

    useEffect(() => {
            authAPIInstance
            .getAllUserPublicProfile()
            .then((res) => {
                let _users = res?.data?.data?.users;
                if(currentGroupChat){
                    _users = _users.map(u => {
                        if(currentGroupChat?.userMembers?.find(um => um?._id === u?._id)){
                            return {...u, selected: true};
                        } else {
                            return u;
                        }
                    });
                }
                setUsers(_users);
            })
            .catch(console.error);

        AIApiInstance.getAllPublicCharacters({getCreatorData: true,
            search: "",
            adultContent: false,
            pageNumber: 1})
            .then((res) => {
                let _chars = res?.data?.data?.aiCharacters;
                if(currentGroupChat){
                    _chars = _chars.map(u => {
                        if(currentGroupChat?.aiMembers?.find(um => um?._id === u?._id)){
                            return {...u, selected: true};
                        } else {
                            return u;
                        }
                    });
                }
                setcharacters(_chars);
            })
            .catch(console.error);
    }, [currentGroupChat]);

    const handleNameChange = (e) => {
        setname(e.target.value);
    };

    const handleMemberAdd = (type, member) => {
        if (type === "user") {
            setUsers((prev) => {
                const newUsers = [...prev];
                const index = newUsers.findIndex((u) => u._id === member._id);
                if (newUsers[index]?.selected) {
                    newUsers[index] = { ...newUsers[index], selected: false };
                } else {
                    newUsers[index] = { ...newUsers[index], selected: true };
                }
                return [...newUsers];
            });
        } else {
            setcharacters((prev) => {
                const newUsers = [...prev];
                const index = newUsers.findIndex((u) => u._id === member._id);
                if (newUsers[index]?.selected) {
                    newUsers[index] = { ...newUsers[index], selected: false };
                } else {
                    newUsers[index] = { ...newUsers[index], selected: true };
                }
                return [...newUsers];
            });
        }
    };

    const handleSave = () => {
        if(currentGroupChat){
            handleGroupSave(name, selectedUsers, selectedChars);
        } else {
            handleGroupSave(name, selectedUsers.map(u => u?._id), selectedChars.map(u => u?._id));
        }
        closeModal();
    }

    return (
        <div className="d-flex flex-column gap-2">
            <Form.Control
                value={name}
                onChange={handleNameChange}
                type="text"
                placeholder="Please enter a name for group."
            />

            <div className="mt-2">
                <Tabs defaultActiveKey="users" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="users" title={`Users (${selectedUsers.length})`}>
                        <div className="d-flex flex-column gap-2" style={{ overflowY: "scroll", maxHeight: "20vh" }}>
                            {users.map((u) => (
                                <div className="d-flex flex-row justify-content-between">
                                    <div className="d-flex flex-row gap-2 align-items-center">
                                        <Avatar avatarUrl={getUserImageUrl(u?.avatar_url)} name={u?.username} />
                                        <span>{u?.username}</span>
                                    </div>
                                    <Button onClick={() => handleMemberAdd("user", u)} className="mx-2">
                                    {u?.selected ? "Remove" : 'Add'}
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </Tab>
                    <Tab eventKey="characters" title={`AI characters (${selectedChars.length})`}>
                        <div className="d-flex flex-column gap-2" style={{ overflowY: "scroll", maxHeight: "20vh" }}>
                            {characters.map((u) => (
                                <div className="d-flex flex-row justify-content-between">
                                    <div className="d-flex flex-row gap-2 align-items-center">
                                        <Avatar avatarUrl={getAiCharacterImageUrlV2(u)} name={u?.characterName} />
                                        <span>{u?.characterName}</span>
                                    </div>
                                    <Button onClick={() => handleMemberAdd("char", u)} className="mx-2">
                                        {u?.selected ? "Remove" : 'Add'}
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <div className="d-flex justify-content-center mt-2">
                <Button onClick={handleSave}>Save</Button>
            </div>
        </div>
    );
}
