import React, { useCallback, useEffect, useReducer } from "react";
import useWebSocket from "react-use-websocket";
import BalanceDetails from "../../Component/WalletPage/BalanceDetails";
import Deposit from "../../Component/WalletPage/Deposit";
import Transaction from "../../Component/WalletPage/Transaction";
import Transfer from "../../Component/WalletPage/Transfer";
import WithdrawCrypto from "../../Component/WalletPage/WithdrawCrypto";
import DataTable from "react-data-table-component";
import { transactionAPIInstance } from "../../apis/TransactionAPI";
import { readCookie, removeCookie } from "../../utils/cookie";
import EVENTS from "../../utils/socketEvents";
import { useNavigate } from "react-router";
import { config } from "../../utils/config";

function formatEthersWithFixedDecimals(value) {
    if (!value) {
        return 0;
    }
    return parseFloat(value).toFixed(8);
}

const PortfolioColumns = [
    {
        name: "TOKEN",
        selector: (row) => row?.name,
        sortable: true
    },
    {
        name: "AMOUNT",
        // eslint-disable-next-line no-undef
        selector: (row) => formatEthersWithFixedDecimals(row?.amount),
        sortable: true
    }
];

const initialState = {
    tokenName: "",
    tokenAddress: "",
    recipient: "",
    network: "",
    amount: 0,
    loading: false,
    deposits: [],
    withdraws: [],
    balances: [],
    modalIsOpen: false,
    transactionId: "",
    streamReady: false,
    cancelLoading: false
};

function WalletPage({ user }) {
    const { sendMessage, lastMessage } = useWebSocket(`${config[config.environment].wsUrl}?token=${readCookie("token")}&device=mobile`);
    const navigate = useNavigate();

    // Use `useReducer` instead to minimize the stacking of `useState(s)`
    const [event, updateEvent] = useReducer((prev, next) => {
        const newEvent = { ...prev, ...next };
        return newEvent;
    }, initialState);

    const handleWithdraw = async () => {
        const { tokenAddress, recipient, amount, network } = event;
        if (tokenAddress === "") {
            alert("Please select a token to withdraw");
            return;
        }

        if (tokenAddress === "BTC") {
            withdrawBtc(amount, recipient);
            return;
        }

        const body = {
            token_address: tokenAddress,
            recipient_address: recipient,
            amount: String(amount),
            tokenType: "erc20",
            network: network
        };

        updateEvent({ loading: true });
        try {
            const res = await transactionAPIInstance.withdraw({ body });
            updateEvent({ loading: false });

            if (res?.data?.transaction_id) {
                updateEvent({
                    recipient: "",
                    amount: 0,
                    transactionId: res?.data?.transaction_id
                });
                alert("Transaction created. Please complete three factor authentication to process the withdraw");
                updateEvent({ modalIsOpen: true });
            }
        } catch (e) {
            updateEvent({ loading: false });
            alert(`Withdraw failed ${e?.response?.data?.message ?? e?.response?.data?.error ?? ""}`);
        }
    };

    const checkBalances = useCallback(async () => {
        if (!lastMessage) {
            return;
        }

        const dataParsed = JSON.parse(lastMessage.data);
        const { type, name, amount } = dataParsed;

        if (type === EVENTS.SERVER.CHANGESTREAM.SUBSCRIBE) {
            updateEvent({ streamReady: true });
        }

        if (type === EVENTS.SERVER.CHANGESTREAM.BALANCE.UPDATE && event.streamReady) {
            const newBalances = [...event.balances];
            const idx = newBalances.findIndex((balance) => balance.name === name);

            if (idx !== -1) {
                newBalances[idx] = {
                    name,
                    amount
                };
            } else {
                newBalances.push({ name, amount });
            }

            updateEvent({ balances: newBalances });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage]);

    const withdrawBtc = (amount, recipient) => {
        if (user?.btcWallet?.balance < amount) {
            alert("Balance not enough.");
            return;
        }
        updateEvent({ loading: true });
        const body = {
            amount,
            recipient_address: recipient
        };
        transactionAPIInstance
            .btcWithdraw({ body })
            .then(async (res) => {
                console.log(res.data.transaction_id);
                updateEvent({
                    loading: false,
                    tokenName: "",
                    recipient: "",
                    amount: 0,
                    transactionId: res.data.transaction_id
                });
                alert("Please complete three factor authentication to withdraw.");
                updateEvent({ modalIsOpen: true });
            })
            .catch((e) => {
                updateEvent({ loading: false });
                console.error(e);
                alert("Withdraw failed");
            });
    };

    const handleCancelWithdraw = async (transaction_id, chain) => {
        try {
            const body = {
                transaction_id
            };

            updateEvent({ cancelLoading: true });

            if (!chain) {
                throw new Error("Chain undefined");
            }

            if (chain === "BTC") {
                await transactionAPIInstance.cancelBtcWithdrawTransaction({ body });
            }

            if (chain === "ETH") {
                await transactionAPIInstance.cancelEthWithdrawTransaction({ body });
            }
            getTransactions();
            updateEvent({ cancelLoading: false });
            alert("Sucessfully cancelled");
        } catch (error) {
            console.error(error);
            updateEvent({ cancelLoading: false });
            alert("Cancel withdraw eth failed");
        }
    };

    const getTransactions = useCallback(async () => {
        try {
            const transactions = await transactionAPIInstance.getUserTransactions(user.userId);
            const {
                data: {
                    user_transactions: { deposits, withdraws }
                }
            } = transactions;            
            updateEvent({ 
                deposits: deposits.sort((a, b) => b.createdAt - a.createdAt),
                withdraws: withdraws.sort((a, b) => b.createdAt - a.createdAt),
            });
        } catch (error) {
            console.error(error);
            if (error?.response?.status === 401) {
                removeCookie("token");
                navigate("/sign-in");
            }
        }
    }, [navigate, user.userId]);

    const getInitialBalancesFromUserObj = useCallback(() => {
        const ethBalances = user?.ethWallet?.balances.map(({ tokenInfo, amount, tokenType }) => {
            return {
                name: tokenType === "native" ? "ETH" : tokenInfo[0]?.name,
                amount
            };
        });

        const btcBalance = {
            name: "BTC",
            amount: user?.btcWallet?.balance
        };

        const newBalances = [...ethBalances, btcBalance];
        updateEvent({ balances: newBalances });
    }, [user?.btcWallet?.balance, user?.ethWallet?.balances]);

    useEffect(() => {
        getTransactions();
        getInitialBalancesFromUserObj();
    }, [getInitialBalancesFromUserObj, getTransactions]);

    useEffect(() => {
        sendMessage(JSON.stringify({ type: EVENTS.CLIENT.CHANGESTREAM.SUBSCRIBE }));
    }, [sendMessage]);

    useEffect(() => {
        checkBalances();
    }, [checkBalances]);

    return (
        <div className="container-xxl px-lg-4 px-md-4">
            <div className="row g-3 mb-3 row-deck">
                <BalanceDetails />
                <WithdrawCrypto
                    tokens={user?.ethWallet?.balances || []}
                    handleWithdraw={handleWithdraw}
                    event={event}
                    updateEvent={updateEvent}
                />
            </div>
            <div className="row g-3 mb-3 row-deck">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header py-3 d-flex justify-content-between">
                            <h6 className="mb-0 fw-bold">Portfolio</h6>
                        </div>
                        <div className="w-100">
                            <DataTable
                                title=""
                                columns={PortfolioColumns}
                                data={event.balances}
                                defaultSortField="title"
                                selectableRows={false}
                                highlightOnHover={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-3 mb-3 row-deck">
                <Deposit user={user} />
                <Transfer />
            </div>
            <div className="row">
                <Transaction
                    withdraws={event.withdraws}
                    deposits={event.deposits}
                    updateEvent={updateEvent}
                    cancelWithdraw={handleCancelWithdraw}
                    cancelLoading={event.cancelLoading}
                />
            </div>
        </div>
    );
}
export default WalletPage;
