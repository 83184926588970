import axios from "axios";
import { config } from "../utils/config";
import { readCookie } from "../utils/cookie";
import { createFormData } from "../utils/createFormData";
import { httpTokenInterceptor } from "./common";

class VoiceAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/voice"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    getMessageAudio(messageId, isGroupChat) {
        console.log(messageId, isGroupChat);
        return this.axios.post(
            `/text_to_speech/`,
            {
                messageId,
                isGroupChat
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

}

export const VoiceAPIInstance = new VoiceAPI();

export default VoiceAPI;
