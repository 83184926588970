import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { adminAPIInstance } from '../../../apis/AdminAPI';

const UserSessionColumns = [
    {
        name: "ipAddress",
        selector: (row) => row?.ipAddress,
        compact:true,
        width:"5%"
    },
    {
        name: "id",
        selector: (row) => row?._id,
        sortable: true,
        compact:true,
        width:"13%"
    },
    {
        name: "sessionStart",
        selector: (row) => new Date(row?.createdAt * 1000).toUTCString(),
        compact:true,
        width:"13%"
    },
    {
        name: "sessionExpiry",
        selector: (row) => new Date((row?.createdAt + row?.tokenExpiryInSeconds) * 1000).toUTCString(),
        compact:true,
        width:"13%"
    },
    {
        name: "fingerPrint",
        selector: (row) => row?.fingerPrint ?? "",
        compact:true,
        width:"16%"
    },
    {
        name: "userDevice",
        selector: (row) => row?.userAgent ?? "",
    },
];


export default function UserSessions({ userId }) {
    console.log(userId);
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        if (userId) {
            adminAPIInstance.getUserSessions(userId).then(res => {
                setSessions(res.data.sessions)
            }).catch(e => {
                console.error(e);
            })
        }
    }, [userId])

    return (
        <div>
            <div className="w-100">
                <DataTable
                    title="Sessions"
                    columns={UserSessionColumns}
                    data={sessions}
                    defaultSortField="id"
                    selectableRows={false}
                    highlightOnHover={true}
                    pagination={true}
                    responsive={true}
                />
            </div>
        </div>
    )
}
