import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { authAPIInstance } from "../../apis/AuthAPI";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { ConfirmButton } from "../Comman/Buttons";

function Disable2FAModal({ show, onHide }) {
    const dispatch = useDispatch();
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const {
        handleSubmit,
        formState: { isSubmitting }
    } = useForm();

    const onSubmit = async () => {
        if (!password) {
            return;
        }
        authAPIInstance
            .disableTwoFactorAuth(password)
            .then((_) => {
                alert("Two Factor Authentication disabled.");
                dispatch({
                    type: "UPDATE",
                    payload: {
                        user: {
                            key: "twoFactorEnabled",
                            value: false
                        }
                    }
                });
                onHide();
            })
            .catch((e) => {
                alert(e?.response?.data?.message ?? "Something went wrong.");
                console.error(e);
            });
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
    };

    return (
        <Modal className="modal" id="Disable2FA" style={{ display: "block" }} show={show} onHide={onHide}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-content">
                    <Modal.Header className="modal-header" closeButton>
                        <h5 className="modal-title">Disable 2FA</h5>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="mb-3">
                            <div className="mb-3">Please enter your password to disable Two Factor Authentication?</div>
                            <div className="mb-3">
                                <input type="password" value={password} onChange={handlePasswordChange} placeholder="Password..." className="form-control" />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={onHide}>
                            Close
                        </button>
                        <ConfirmButton isSubmitting={isSubmitting} confirmText={"Confirm"} />
                    </Modal.Footer>
                </div>
            </form>
        </Modal>
    );
}

export default Disable2FAModal;
