// Code to create a group chat
import React, { useEffect, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Avatar from "../../Component/Comman/Avatar";
import { authAPIInstance } from "../../apis/AuthAPI";
import { AIApiInstance } from "../../apis/AIAPI";
import { getUserImageUrl, getAiCharacterImageUrlV2 } from "../../utils/images";
import { Link, useNavigate } from "react-router-dom";

function CreateGroupChat() { 
    const [name, setname] = useState("");
    const [users, setUsers] = useState([]);
    const [characters, setcharacters] = useState([]);
    const navigate = useNavigate();

    const selectedUsers = useMemo(() => {
        return users.filter((u) => u?.selected);
    }, [users]);

    const selectedChars = useMemo(() => {
        return characters.filter((u) => u?.selected);
    }, [characters]);

    useEffect(() => {
            authAPIInstance
            .getAllUserPublicProfile()
            .then((res) => {
                let _users = res?.data?.data?.users;
                
                setUsers(_users);
            })
            .catch(console.error);

        AIApiInstance.getAllPublicCharacters({getCreatorData: true,
            search: "",
            adultContent: false,
            pageNumber: 1})
            .then((res) => {
                let _chars = res?.data?.data?.aiCharacters;
                
                setcharacters(_chars);
            })
            .catch(console.error);
    }, []);

    const handleNameChange = (e) => {
        setname(e.target.value);
    };

    const handleMemberAdd = (type, member) => {
        if (type === "user") {
            setUsers((prev) => {
                const newUsers = [...prev];
                const index = newUsers.findIndex((u) => u._id === member._id);
                if (newUsers[index]?.selected) {
                    newUsers[index] = { ...newUsers[index], selected: false };
                } else {
                    newUsers[index] = { ...newUsers[index], selected: true };
                }
                return [...newUsers];
            });
        } else {
            setcharacters((prev) => {
                const newUsers = [...prev];
                const index = newUsers.findIndex((u) => u._id === member._id);
                if (newUsers[index]?.selected) {
                    newUsers[index] = { ...newUsers[index], selected: false };
                } else {
                    newUsers[index] = { ...newUsers[index], selected: true };
                }
                return [...newUsers];
            });
        }
    };

    const handleSave = () => {
            handleGroupSave(name, selectedUsers.map(u => u?._id), selectedChars.map(u => u?._id));
    }

    const handleGroupSave = (name, selectedUsers, selectedChars) => {
        if(!name) {
            alert("Please enter a name for group.");
            return;
        }

        AIApiInstance.createGroupChat(name, selectedUsers, selectedChars).then(res => {
            alert("Group chat created.");
            console.log(res.data?.data?.group);
            //nav chat?type=ai&isGroup=true&id=65d196103cda7b320380a71d#!
            navigate('/chat?type=ai&isGroup=true&id='+res.data?.data?.group?._id);
        }).catch(console.error);
    }

    const getParticipantContainerBgColor = (index) => {
        if(index % 2 !== 0){
            return 'rgba(255, 255, 255, .1)';
        }
        return '';
    }

    return (
        <div className="d-flex flex-column gap-2">
            <Link to={"/chat"}>
                <div className="d-flex align-items-center justify-content-between px-5 py-4" style={{background: "rgba(0,0,0,0.5)"}}>
                    <div className="d-flex align-items-center">
                        <i className="fa fa-chevron-left me-3" />
                        <p className="mb-0 ">Back</p>
                    </div>
                    <h6>Create Group</h6>
                </div>
            </Link>
            <Form.Control
                value={name}
                onChange={handleNameChange}
                type="text"
                placeholder="Please enter a name for group."
                className="px-4"
            />

            <div className="mt-2 px-4">
                <Tabs defaultActiveKey="users" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="users" title={`Users (${selectedUsers.length})`}>
                        <div className="d-flex flex-column gap-2" style={{ overflowY: "scroll", maxHeight: "20vh" }}>
                            {users.map((u, i) => (
                                <div className="d-flex flex-row justify-content-between" style={{backgroundColor: getParticipantContainerBgColor(i)}}>
                                    <div className="d-flex flex-row gap-2 align-items-center">
                                        <Avatar avatarUrl={getUserImageUrl(u?.avatar_url)} name={u?.username} />
                                        <span>{u?.username}</span>
                                    </div>
                                    <Button onClick={() => handleMemberAdd("user", u)} className="mx-2">
                                    {u?.selected ? "Remove" : 'Add'}
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </Tab>
                    <Tab eventKey="characters" title={`AI characters (${selectedChars.length})`}>
                        <div className="d-flex flex-column gap-2" style={{ overflowY: "scroll", maxHeight: "20vh" }}>
                            {characters.map((u, i) => (
                                <div className="d-flex flex-row justify-content-between" style={{backgroundColor: getParticipantContainerBgColor(i)}}>
                                    <div className="d-flex flex-row gap-2 align-items-center">
                                        <Avatar avatarUrl={getAiCharacterImageUrlV2(u)} name={u?.characterName} />
                                        <span>{u?.characterName}</span>
                                    </div>
                                    <Button onClick={() => handleMemberAdd("char", u)} className="mx-2">
                                        {u?.selected ? "Remove" : 'Add'}
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <div className="d-flex justify-content-center mt-2">
                <Button 
                className="btn btn-light-danger mt-2 d-flex me-2"
                onClick={() => navigate('/chat')}>Cancel</Button>
                <Button 
                className="mt-2 d-flex me-2"
                onClick={handleSave}>Save</Button>
                
            </div>
        </div>);
}

export default CreateGroupChat;
