import React from "react";
import { useNavigate } from "react-router";

export default function CategoryBar({ categoryItems = [], category = null, setCategory = () => {} }) {
    //
    const navigate = useNavigate();

    const getTabMenuItemStyle = (type) => {
        let style = { borderRadius: "12px", height: "100%", cursor: "pointer", fontWeight: "500" };
        if (category === type) {
            return { ...style, background: 'linear-gradient(105deg, rgba(250,33,33,1) 0%, rgba(249,139,38,1) 100%)' , color: "white" };
        }
        return { ...style, color: "#B7C1D3" };
    };

    const handleCategoryClick = (type) => {
        setCategory(type);
    };

    const handleCreateCharacterClick = () => {
        navigate("/create-character");
    };

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div
                style={{
                    backgroundColor: "rgba(0,0,0,0.5)",
                    borderRadius: "12px",
                    display: "inline-block",
                }}
                className="my-3 p-2 category-bar"
            >
                <div className="d-flex flex-row">
                    {categoryItems.map((item) => (
                        <div
                            key={item.type}
                            onClick={() => handleCategoryClick(item.type)}
                            style={getTabMenuItemStyle(item.type)}
                            className="p-3"
                        >
                            {item.title}
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className="my-3 px-2">
                <Button
                    onClick={handleCreateCharacterClick}
                    style={{
                        border: `1px solid ${theme.color}`,
                        backgroundColor: "transparent",
                        color: theme.color,
                        margin: "0.4em",
                    }}
                >
                    Create AI Character
                </Button>
            </div> */}
        </div>
    );
}
