import React, { useState } from "react";
import {
    CardElement,
    useStripe,
    useElements,
    Elements,
} from "@stripe/react-stripe-js";
import { Button, Spinner } from "react-bootstrap";
import { stripePromise } from "../StripeCheckout/StripeCheckout";

function StripePaymentForm({ onSucess = () => {} }) {
    return <StripeProvider child={<PaymentForm onSucess={onSucess}/>} />;
}

function PaymentForm({ onSucess = () => {} }) {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setLoading(true);
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
          });
        if (result.error) {
            setLoading(false);
            setError(result.error.message);
        } else {
            setLoading(false);
            onSucess(result.paymentMethod.id);
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <h5>Add Payment Method</h5>
            <CardElement />
            {error && <div className="error">{error}</div>}
            <Button disabled={loading} type="submit">
                Submit
                {loading && <Spinner size="sm" className="mx-1"/>}
                </Button>
        </form>
    );
}

function StripeProvider({ child }) {
    return (
        <Elements stripe={stripePromise} options={{
            theme: 'stripe'
          }}>
            {child}
        </Elements>
    );
}

export default StripePaymentForm;
