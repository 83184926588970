import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { ErrorMsg } from "../../Component/Comman/Typography";
import * as Yup from 'yup';
import { ChatSocketInstance as SocketInstance } from "../../apis/SocketApis/ChatSocket";
import { socketConstants } from "../../apis/SocketApis";

const settingsSchema = Yup.object().shape({
  n: Yup.number().required(),
  prompt: Yup.string().required(),
  firstMessage: Yup.string().required(),
  use_story: Yup.string().required(),
  use_memory: Yup.string().required(),
  use_authors_note: Yup.string().required(),
  use_world_info: Yup.string().required(),
  max_context_length: Yup.number().required(),
  max_length: Yup.number().required(),
  rep_pen: Yup.number().required(),
  rep_pen_range: Yup.number().required(),
  rep_pen_slope: Yup.number().required(),
  temperature: Yup.number().required(),
  tfs: Yup.number().required(),
  top_a: Yup.number().required(),
  top_k: Yup.number().required(),
  top_p: Yup.number().required(),
  typical: Yup.number().required(),
  sampler_order: Yup.array().of(Yup.number()).required(),
  singleline: Yup.string().required(),
  quiet: Yup.string().required(),
  stop_sequence: Yup.string().required(),
});

const initialValues = {
  n: 1,
  prompt: '',
  firstMessage: '',
  use_story: 'false',
  use_memory: 'true',
  use_authors_note: 'false',
  use_world_info: 'true',
  max_context_length: 1024,
  max_length: 100,
  rep_pen: 1.19,
  rep_pen_range: 1024,
  rep_pen_slope: 0.9,
  temperature: 0.54,
  tfs: 0.95,
  top_a: 0,
  top_k: 0,
  top_p: 0.9,
  typical: 1,
  sampler_order: [6, 0, 1, 2, 3, 4, 5],
  singleline: 'true',
  quiet: 'true',
  stop_sequence: 'You:',
};

function convertBooleanToString(obj) {
  const convertedObj = {};

  for (const prop in obj) {
    if (typeof obj[prop] === 'boolean') {
      convertedObj[prop] = obj[prop].toString();
    } else {
      convertedObj[prop] = obj[prop];
    }
  }

  return convertedObj;
}


const AiSettingsScreen = () => {
    const location = useLocation();
    const { aiCharacterId } = location.state || {};
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState(initialValues);

    useEffect(() => {
      SocketInstance.connect(() => {
        SocketInstance.subscribeToMessage(data => {
          if (
            data?.type === socketConstants.SERVER.CUSTOM_AI.GET_AI_SETTINGS &&
            data?.characterId === aiCharacterId
          ) {
            if (data?.settings !== 'null') {
              setSettings(
                convertBooleanToString({
                  ...data?.settings,
                  stop_sequence: data?.settings?.stop_sequence?.[0],
                }),
              );
            }
            setLoading(false);
          }
          if (
            data?.type === socketConstants.SERVER.CUSTOM_AI.UPDATE_AI_SETTINGS
          ) {
            alert("Settings updated.");
            setLoading(false);
          }
          if (data?.type === socketConstants.CONNECTED) {
              SocketInstance.sendMessage({
                  type: socketConstants.CLIENT.CUSTOM_AI.GET_AI_SETTINGS,
                  characterId: aiCharacterId
              });
          }
        });
      });
    }, [aiCharacterId]);
  
    const handleSettingsSubmit = (values) => {
      try {
        SocketInstance.connect(() => {
          SocketInstance.sendMessage({
            type: socketConstants.CLIENT.CUSTOM_AI.UPDATE_AI_SETTINGS,
            characterId: aiCharacterId,
            settings: values,
          });
          setLoading(true);
        });
      } catch (error) {
        console.error(error);
      }
    };

    return (
        <Formik
            initialValues={loading ? initialValues : settings}
            validationSchema={settingsSchema}
            onSubmit={handleSettingsSubmit}
            enableReinitialize={true}
        >
            {({ handleChange, handleSubmit, values, errors, setFieldValue, touched }) => (
                <div className="row gy-3">
                    <div className="col-12 col-md-6">
                        <div className="card card-body gap-3">
                            <Form.Group>
                                <Form.Label className="required">n</Form.Label>
                                <Form.Control
                                    value={values?.n?.toString() ?? ""}
                                    onChange={handleChange("n")}
                                    type="text"
                                    placeholder={"Enter n"}
                                />
                                {touched.n && errors.n && <ErrorMsg msg={errors.n} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">prompt</Form.Label>
                                <Form.Control
                                    value={values?.prompt?.toString() ?? ""}
                                    onChange={handleChange("prompt")}
                                    type="text"
                                    placeholder={"Enter prompt"}
                                />
                                {touched.prompt && errors.prompt && <ErrorMsg msg={errors.prompt} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">First Message</Form.Label>
                                <Form.Control
                                    value={values?.firstMessage?.toString() ?? ""}
                                    onChange={handleChange("firstMessage")}
                                    type="text"
                                    placeholder={"Enter firstMessage"}
                                />
                                {touched.firstMessage && errors.firstMessage && <ErrorMsg msg={errors.firstMessage} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">Use story</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={handleChange("use_story")}
                                    defaultValue={values.use_story}
                                >
                                    <option value={"true"}>true</option>
                                    <option value={"false"}>false</option>
                                </Form.Select>
                                {touched.use_story && errors.use_story && (
                                    <span color="red.500" mt="1" fontSize="xs">
                                        {errors.use_story}
                                    </span>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">Use memory</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={handleChange("use_memory")}
                                    defaultValue={values.use_memory}
                                >
                                    <option value={"true"}>true</option>
                                    <option value={"false"}>false</option>
                                </Form.Select>
                                {touched.use_memory && errors.use_memory && (
                                    <span color="red.500" mt="1" fontSize="xs">
                                        {errors.use_memory}
                                    </span>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">Use authors note</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={handleChange("use_authors_note")}
                                    defaultValue={values.use_authors_note}
                                >
                                    <option value={"true"}>true</option>
                                    <option value={"false"}>false</option>
                                </Form.Select>
                                {touched.use_authors_note && errors.use_authors_note && (
                                    <span color="red.500" mt="1" fontSize="xs">
                                        {errors.use_authors_note}
                                    </span>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">Use world info</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={handleChange("use_world_info")}
                                    defaultValue={values.use_world_info}
                                >
                                    <option value={"true"}>true</option>
                                    <option value={"false"}>false</option>
                                </Form.Select>
                                {touched.use_world_info && errors.use_world_info && (
                                    <span color="red.500" mt="1" fontSize="xs">
                                        {errors.use_world_info}
                                    </span>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">Max content length</Form.Label>
                                <Form.Control
                                    value={values?.max_context_length?.toString() ?? ""}
                                    onChange={handleChange("max_context_length")}
                                    type="text"
                                    placeholder={"Enter max_context_length"}
                                />
                                {touched.max_context_length && errors.max_context_length && (
                                    <ErrorMsg msg={errors.max_context_length} />
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">Max length</Form.Label>
                                <Form.Control
                                    value={values?.max_length?.toString() ?? ""}
                                    onChange={handleChange("max_length")}
                                    type="text"
                                    placeholder={"Enter max_length"}
                                />
                                {touched.max_length && errors.max_length && <ErrorMsg msg={errors.max_length} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">rep_pen</Form.Label>
                                <Form.Control
                                    value={values?.rep_pen?.toString() ?? ""}
                                    onChange={handleChange("rep_pen")}
                                    type="text"
                                    placeholder={"Enter rep_pen"}
                                />
                                {touched.rep_pen && errors.rep_pen && <ErrorMsg msg={errors.rep_pen} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">rep_pen_range</Form.Label>
                                <Form.Control
                                    value={values?.rep_pen_range?.toString() ?? ""}
                                    onChange={handleChange("rep_pen_range")}
                                    type="text"
                                    placeholder={"Enter rep_pen_range"}
                                />
                                {touched.rep_pen_range && errors.rep_pen_range && <ErrorMsg msg={errors.rep_pen_range} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">rep_pen_slope</Form.Label>
                                <Form.Control
                                    value={values?.rep_pen_slope?.toString() ?? ""}
                                    onChange={handleChange("rep_pen_slope")}
                                    type="text"
                                    placeholder={"Enter rep_pen_slope"}
                                />
                                {touched.rep_pen_slope && errors.rep_pen_slope && <ErrorMsg msg={errors.rep_pen_slope} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">temperature</Form.Label>
                                <Form.Control
                                    value={values?.temperature?.toString() ?? ""}
                                    onChange={handleChange("temperature")}
                                    type="text"
                                    placeholder={"Enter temperature"}
                                />
                                {touched.temperature && errors.temperature && <ErrorMsg msg={errors.temperature} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">tfs</Form.Label>
                                <Form.Control
                                    value={values?.tfs?.toString() ?? ""}
                                    onChange={handleChange("tfs")}
                                    type="text"
                                    placeholder={"Enter tfs"}
                                />
                                {touched.tfs && errors.tfs && <ErrorMsg msg={errors.tfs} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">top_a</Form.Label>
                                <Form.Control
                                    value={values?.top_a?.toString() ?? ""}
                                    onChange={handleChange("top_a")}
                                    type="text"
                                    placeholder={"Enter top_a"}
                                />
                                {touched.top_a && errors.top_a && <ErrorMsg msg={errors.top_a} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">top_k</Form.Label>
                                <Form.Control
                                    value={values?.top_k?.toString() ?? ""}
                                    onChange={handleChange("top_k")}
                                    type="text"
                                    placeholder={"Enter top_k"}
                                />
                                {touched.top_k && errors.top_k && <ErrorMsg msg={errors.top_k} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">top_p</Form.Label>
                                <Form.Control
                                    value={values?.top_p?.toString() ?? ""}
                                    onChange={handleChange("top_p")}
                                    type="text"
                                    placeholder={"Enter top_p"}
                                />
                                {touched.top_p && errors.top_p && <ErrorMsg msg={errors.top_p} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">typical</Form.Label>
                                <Form.Control
                                    value={values?.typical?.toString() ?? ""}
                                    onChange={handleChange("typical")}
                                    type="text"
                                    placeholder={"Enter typical"}
                                />
                                {touched.typical && errors.typical && <ErrorMsg msg={errors.typical} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">Choose singleline</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={handleChange("singleline")}
                                    defaultValue={values.singleline}
                                >
                                    <option value={"true"}>true</option>
                                    <option value={"false"}>false</option>
                                </Form.Select>
                                {touched.singleline && errors.singleline && (
                                    <span color="red.500" mt="1" fontSize="xs">
                                        {errors.singleline}
                                    </span>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">Choose quiet</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={handleChange("quiet")}
                                    defaultValue={values.quiet}
                                >
                                    <option value={"true"}>true</option>
                                    <option value={"false"}>false</option>
                                </Form.Select>
                                {touched.quiet && errors.quiet && (
                                    <span color="red.500" mt="1" fontSize="xs">
                                        {errors.quiet}
                                    </span>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">stop_sequence</Form.Label>
                                <Form.Control
                                    value={values?.stop_sequence?.toString() ?? ""}
                                    onChange={handleChange("stop_sequence")}
                                    type="text"
                                    placeholder={"Enter stop_sequence"}
                                />
                                {touched.stop_sequence && errors.stop_sequence && <ErrorMsg msg={errors.stop_sequence} />}
                            </Form.Group>

                            <Button w="full" onClick={handleSubmit} disabled={loading}>
                                Update Settings
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default AiSettingsScreen;
