import React from "react";
import Avatar from "../../Comman/Avatar";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { StatusBadge } from "../../Comman/StatusBadge";

dayjs.extend(relativeTime);

function ChatTab({ selectedId, id, onClick, name, message, timestamp, avatarUrl, status, selectedUserId = "", userId = "", isGroup = false }) {

    let isSelected = selectedId === id;

    if(selectedUserId && userId){
        isSelected = isSelected && selectedUserId === userId
    }

    return (
        <li className={`px-md-4 py-3 ${isSelected ? "list-group-item-selected" : "list-group-item"}`}>
            <a onClick={onClick} href="#!" className="character-flex d-flex align-items-center">
                <div className="gradient-border">
                    <Avatar name={name} avatarUrl={avatarUrl} className="br-50"/>
                </div>
                <div className="flex-fill ms-3 text-truncate">
                    <h6 className="character-flex justify-content-between mb-0 d-flex">
                        <div>
                        <span className="me-1 text-muted" >{name}</span>
                        {status && <StatusBadge status={status}/>}
                        </div>
                        {timestamp && <small className="msg-time">{dayjs( timestamp * 1000 ).fromNow()} </small>}
                    </h6>
                    {message && <span className="text-muted">{message}</span>}
                </div>
            </a>
        </li>
    );
}

export default ChatTab;
