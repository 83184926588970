import React from "react";
import { Tab, Nav } from "react-bootstrap";

function Deposit({ user }) {
    return (
        <div className="col-xl-6 col-xxl-7">
            <div className="card">
                <Tab.Container defaultActiveKey="first">
                    <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom align-items-center flex-wrap">
                        <h6 className="mb-0 fw-bold">Deposit</h6>

                        <Nav className="nav nav-tabs tab-body-header rounded d-inline-flex mt-2 mt-md-0" role="tablist">
                            <Nav.Item className="nav-item">
                                <Nav.Link
                                    className="nav-link "
                                    eventKey="first"
                                    data-bs-toggle="tab"
                                    href="#eth-deposit"
                                    role="tab"
                                >
                                    Ethereum
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="nav-item">
                                <Nav.Link
                                    className="nav-link"
                                    eventKey="second"
                                    data-bs-toggle="tab"
                                    href="#btc-deposit"
                                    role="tab"
                                >
                                    BTC
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="card-body">
                        <Tab.Content className="tab-content">
                            <Tab.Pane className="tab-pane" eventKey="first" id="eth-deposit">
                                <div>
                                    <div className="mb-3">
                                        <label className="form-label d-block">
                                            Wallet Address: <span>{user.ethWallet.public_key}</span>
                                        </label>
                                        <div className="d-flex flex-wrap align-items-center">
                                            <img
                                                src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${user.ethWallet.public_key}&choe=UTF-8`}
                                                alt="Download App"
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane className="tab-pane" eventKey="second" id="btc-deposit">
                                <div>
                                    <div className="mb-3">
                                        <label className="form-label d-block">
                                            BTC Wallet Address: <span>{user.btcWallet.public_key}</span>
                                        </label>
                                        <div className="d-flex flex-wrap align-items-center">
                                            <img
                                                src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${user.btcWallet.public_key}&choe=UTF-8`}
                                                alt="Download App"
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </div>
    );
}

export default Deposit;
