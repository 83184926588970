import React from "react";
import { Button } from "react-bootstrap";

export default function TotalEarnings({ totalEarnings = 0, withdrawEarningsAvailable = 0, handleWithdraw = () => {} }) {
    return (
        <div className="totalEarnings-container">
            <div className="totalEarnings-container-row">
                <span>Total Earnings</span>
                <strong><span>${totalEarnings}</span></strong>
            </div>
            <div className="totalEarnings-container-row">
                <span>Withdraw available</span>
                <span>
                    <strong>${withdrawEarningsAvailable}</strong>
                </span>
            </div>
            <Button onClick={handleWithdraw} disabled={withdrawEarningsAvailable <= 0} className="withdraw-btn">
                Withdraw
            </Button>
        </div>
    );
}
