import axios from 'axios';
import {config} from '../utils/config';
import { createFormDataV2 } from '../utils/createFormData';
import { readCookie } from '../utils/cookie';
import { httpTokenInterceptor } from "./common";

class MerchantApi {
    constructor() {
        this.axios = axios.create({
            baseURL: `${config[config["environment"]].baseApiUrl}/merchants`
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    addMerchant(merchantBody, token, photos) {
        return new Promise(async (resolve, reject) => {
            try {
                const formData = createFormDataV2(merchantBody, photos);
                const response = await this.axios.post("/add_merchant", formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data"
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error adding merchant");
                reject(error);
            }
        });
    }

    addCoupon(couponBody, merchantUUID, token, photos) {
        return new Promise(async (resolve, reject) => {
            try {
                const body = {
                    merchantUUID,
                    ...couponBody
                };

                const formData = createFormDataV2(body, photos);
                const response = await this.axios.post("/add_coupon", formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data"
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error adding merchant");
                reject(error);
            }
        });
    }

    updateCoupon(couponId, couponBody, merchantUUID, token, photos) {
        return new Promise(async (resolve, reject) => {
            try {
                const body = {
                    merchantUUID,
                    ...couponBody
                };

                const formData = createFormDataV2(body, photos);
                const response = await this.axios.put(`/update_coupon/${couponId}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data"
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error updating coupon");
                reject(error);
            }
        });
    }

    deleteCoupon(couponId, token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.delete(`/delete_coupon/${couponId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error deleting coupon");
                reject(error);
            }
        });
    }

    getCurrentUserMerchant(token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.get("/my_store", {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error fetching merchant");
                reject(error);
            }
        });
    }

    getMerchantById(merchantId, token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.get(`/get_merchant_by_id/${merchantId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error fetching merchant");
                reject(error);
            }
        });
    }

    getMerchantByUserId(userId) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.get(`/get_merchant_by_user_id/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${readCookie("token")}`
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error fetching merchant");
                reject(error);
            }
        });
    }

    getCouponsByMerchant(merchantUUID, token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.get(`/get_merchant_coupons/${merchantUUID}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error fetching coupons");
                reject(error);
            }
        });
    }

    updateMerchant(merchantBody, token, photos) {
        return new Promise(async (resolve, reject) => {
            try {
                // const body = {
                //   merchantUUID: merchantId,
                //   ...merchantBody,
                // };

                const formData = createFormDataV2(merchantBody, photos);
                const response = await this.axios.put("/update_merchant", formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data"
                    }
                });
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error adding merchant");
                reject(error);
            }
        });
    }

    updateMerchantLocation(merchant_id, longitude, latitude, address, token) {
        return this.axios.patch(
            "/merchant/location",
            {
                merchant_id,
                longitude,
                latitude,
                address
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    getAllCoupons = async (page = 1, limit = 10, search = "", category = "") => {
        let url = `/get_all_coupons?limit=${limit}&page=${page}&search=${search}`;

        if (category && category !== "") {
            url = `/get_all_coupons?limit=${limit}&page=${page}&category=${category}`;
        }

        const response = await this.axios.get(url, {
            headers: {}
        });
        return response;
    };

    getMyStoreQRcode(token, update = false) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.axios.post(
                    "/get_store_checkin_qr",
                    {
                        update
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                resolve(response);
            } catch (error) {
                console.error(error?.message ?? "Error getting store qr");
                reject(error);
            }
        });
    }

    getCouponsNearBy(token, longitude, latitude) {
        return this.axios.get(`/coupons/nearby?longitude=${longitude}&latitude=${latitude}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    followMerchant(merchantId) {
        return this.axios.post(
            `/follow/${merchantId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    unfollowMerchant(merchantId) {
        return this.axios.post(
            `/unfollow/${merchantId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    reportCoupon(token, body) {
        return this.axios.post(
            "report_coupon",
            {
                ...body
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    deleteCouponImage(token, couponId, imageName) {
        return this.axios.delete(`/coupon/${couponId}/image/${imageName}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    deleteStoreImage(token, imageName) {
        return this.axios.delete(`/image/${imageName}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    getAllCouponCategories(token) {
        return this.axios.get("/categories", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    getCouponById(id) {
        return this.axios.get(`/coupon/${id}`);
    }

    getCouponEarnings(token) {
        return this.axios.get("/coupon_sale_earnings", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    getCouponSales(token) {
      return this.axios.get("/coupon_sales", {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
  }
}

export const MerchantApiInstance = new MerchantApi();

export default MerchantApi;
