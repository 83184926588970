import { useSelector } from "react-redux";
import ChatTab from "./ChatTab";
import { Spinner } from "react-bootstrap";
import EmptyPage from "./EmptyChatTab";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {getUserImageUrl } from "../../../utils/images";

dayjs.extend(relativeTime);

export default function UserChatTab({ setSelectedId, selectedId, selectedType, loading, chatsDrawerOpen, toggleDrawer}) {
    const chats = useSelector((state) => state.chat.chats);

    const handleSelectChat = (chat) => {
        if(chatsDrawerOpen){
            toggleDrawer();
        }
        setSelectedId(chat, "user");
    }

    return (
        <div className="d-flex flex-column flex-grow-1">
            {loading ? (
                <div className="justify-content-center align-items-center d-flex my-4">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : chats.length === 0 ? (
                <EmptyPage />
            ) : (
                <>
                    <ul
                        className="list-unstyled list-group list-group-custom list-group-flush px-2 gap-2 pt-2"
                        style={{ maxHeight: "85vh" }}
                    >
                        {chats.length > 0 &&
                            chats.map((chat, i) => {
                                return (
                                    <ChatTab
                                        key={i + chat.recipient_id}
                                        id={chat.recipient_id}
                                        name={chat.chatName}
                                        avatarUrl={
                                            getUserImageUrl(chat.recipient_avatar)
                                        }
                                        timestamp={chat.timeStamp}
                                        message={chat.messageContent}
                                        onClick={() => handleSelectChat(chat)}
                                        selectedId={selectedId}
                                    />
                                );
                            })}
                    </ul>
                </>
            )}
        </div>
    );
}
