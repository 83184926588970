import React, { useEffect, useRef, useState } from "react";
import ReactStars from "react-stars";
import { Modal } from "../../../Component/Modal/Modal";
import { Button, Form } from "react-bootstrap";
import { AIApiInstance } from "../../../apis/AIAPI";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../Redux/selectors/auth";
import { getUserImageUrl } from "../../../utils/images";
import { formatUnixTimestamp } from "../../../utils/time";

const CreateReviewModal = ({handleSaveReview, edit = false, old_review = {}, handleUpdate = () => {}}) => {
    const [rating, setrating] = useState(edit ? old_review?.rating : 0);
    const [review, setreview] = useState(edit ? old_review?.ratingDescription : "");

    const handleReviewChange = (e) => {
        const value = e.target.value;
        setreview(value);
    };

    const _handleSaveReview = () => {
        if(!edit){
            handleSaveReview(rating, review);
        } else {
            handleUpdate(rating, review, old_review._id);
        }
    }

    return (
        <div className="d-flex flex-column justify-content-center align-items-center gap-2">
            <ReactStars count={5} value={rating} onChange={setrating} size={24} color2={"#ffd700"} />
            <Form.Control value={review} onChange={handleReviewChange} as="textarea" placeholder="Post a review here..." />
            <Button onClick={_handleSaveReview} variant="primary">Save</Button>
        </div>
    );
};

export default function Reviews({characterId = ""}) {
    const [reviews, setReviews] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [disablePost, setdisablePost] = useState(false);
    const [editing, setediting] = useState(false);
    const myReview = useRef(null);
    const user = useSelector(selectCurrentUser);

    useEffect(() => {
        const found = reviews.find(r => {
            return user?.userId === r?.userId
        })
        if(found){
            setdisablePost(true);
        }
    }, [reviews]);

    useEffect(() => {
        AIApiInstance.getCharacterReviews(characterId)
        .then(({data}) => {
            setReviews(data?.data?.ratings);
        })
        .catch(console.error)
    }, []);

    const handleSaveReview = (rating, review) => {
        AIApiInstance.saveReviewCharacter(rating, review, characterId)
        .then(({data}) => {
            setModalIsOpen(false);
            setReviews(prev => {
                const temp = [...prev];
                temp.unshift(
                    {
                        ...data?.data?.rating,
                        userInfo: {
                            username: user?.username,
                            avatar_url: user?.avatar_url,
                            _id: user?.userId
                        }
                    } ?? {}
                );
                return temp;
            })
        })
        .catch(console.error)
    }

    const handleUpdateReview = (rating, review, reviewId) => {
        AIApiInstance.updateReviewCharacter(rating, review, reviewId)
        .then(() => {
            setModalIsOpen(false);
            setReviews(prev => {
                const temp = [...prev];
                const index =  temp.findIndex(r => r._id === reviewId)
                if(index !== -1){
                    temp[index] = {...temp[index], rating, ratingDescription: review};
                }
                return temp;
            });
            setediting(false);
            myReview.current = null;
        })
        .catch(console.error)
    }

    const handlePostReviewClick = () => {
        setModalIsOpen(true);
    }

    const handleEditReviewClick = () => {
        const _myReview = reviews.find((r) => {
            return r?.userInfo?._id === user?.userId;
        });
        if (_myReview) {
            setediting(true);
            setModalIsOpen(true);
            myReview.current = _myReview;
        }
    }

    return (
        <div className="d-flex flex-column justify-content-center p-4">
           {!disablePost && <Button onClick={handlePostReviewClick}>Post a review</Button>}
           {disablePost && <Button onClick={handleEditReviewClick}>Edit review</Button>}
            <Modal modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen} 
            body={<CreateReviewModal handleUpdate={handleUpdateReview} edit={editing} old_review={myReview.current} handleSaveReview={handleSaveReview}/>} />
            <div className="d-flex flex-column gap-3 px-1 py-3">
                {reviews.map((v) => (
                    <Review rating={v} />
                ))}
            </div>
        </div>
    );
}

const Review = ({ rating }) => {

    const getUserAvatar = () => {
       return getUserImageUrl(rating?.userInfo?.avatar_url)
    };

    return (
        <div className="d-flex flex-row card p-3 align-items-center justify-content-between rounded-3 flex-wrap">
            <div className="d-flex flex-row gap-2 align-baseline">
                <div style={{ width: "40px", height: "40px" }}>
                    <img
                        style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "50%" }}
                        src={getUserAvatar()}
                    />
                </div>
                <div className="d-flex flex-column gap-1">
                    <span className="font-weight-bold">{rating?.userInfo?.username}</span>
                    <p style={{ maxWidth: "500px", opacity:0.6, marginBottom:0 }}>
                        {rating?.ratingDescription ?? ''}
                    </p>
                    <span style={{fontSize:"0.6em", opacity:0.6 }}>{formatUnixTimestamp(rating?.createdAt)}</span>
                </div>
            </div>
            <ReactStars edit={false} count={5} value={rating?.rating ?? 0} size={24} color2={"#ffd700"} />
        </div>
    );
};
