export const socketConstants = {
  CONNECTION: 'connection',
  MESSAGE: 'message',
  CLOSE: 'close',
  CONNECTED: 'connected',
  ERROR: 'error',
  DEVICE: {
    REPLIER: 'replier',
    MOBILE: 'mobile',
    WEB:'web'
  },
  CLIENT: {
    ORDERBOOK: {
      SUBSCRIBE: 'orderbook.client.subscribe',
      ADD: 'orderbook.client.add',
      REMOVE: 'orderbook.client.remove',
      GET: 'orderbook.client.get',
    },
    CHANGESTREAM: {
      SUBSCRIBE: 'changestream.client.subscribe',
    },
    CHAT: {
      MESSAGE: 'CHAT.MESSAGE',
      TYPING: 'CHAT.TYPING',
      STOP_TYPING: 'CHAT.STOP_TYPING',
      SERVER_MESSAGE_ACKNOWLEDGEMENT: 'CHAT.SERVER_MESSAGE_ACKNOWLEDGEMENT',
    },
    CUSTOM_AI: {
      SUBSCRIBE: 'custom_ai.client.subscribe',
      MESSAGE: 'custom_ai.client.message',
      RESPONSE_FROM_REPLIER: 'custom_ai.client.response_from_replier',
      LOAD_MESSAGES: 'custom_ai.client.load_messages',
      SEND_REPLIER_RESPONSE_TO_CLIENT:
        'custom_ai.server.send_replier_response_to_client',
      SERVER_MESSAGE_ACKNOWLEDGEMENT:
        'CUSTOM_AI.SERVER_MESSAGE_ACKNOWLEDGEMENT',
      GET_AI_SETTINGS: 'custom_ai.client.get_ai_settings',
      UPDATE_AI_SETTINGS: 'custom_ai.client.update_ai_settings',
      REGENERATE_RESPONSE: 'custom_ai.client.regenerate_response',
      SELECT_SPICY_AI_MODEL: 'custom_ai.client.select_spicy_ai_model',
      GET_ALL_AVAILABLE_REPLIERS: 'custom_ai.client.get_all_available_repliers',
      UPDATE_CLIENT_AI_MODEL: 'custom_ai.client.update_client_ai_model',
    },
    GROUP_CHAT: {
      MESSAGE: 'client.group_chat.user_message',
    },
   IMAGE_GEN: {
    MESSAGE: 'client.image_gen.message',
    GROUP_MESSAGE: 'group.image_gen.message',
    RESPONSE: 'client.image_gen.response',
    ERROR: 'client.image_gen.error',
   }
  },
  SERVER: {
    ORDERBOOK: {
      SUBSCRIBE: 'orderbook.server.subscribe',
      GET: 'orderbook.server.get',
    },
    CHANGESTREAM: {
      SUBSCRIBE: 'changestream.server.subscribe',
      BALANCE: {
        UPDATE: 'changestream.server.balance.update',
      },
    },
    CUSTOM_AI: {
      SUBSCRIBE: 'custom_ai.server.subscribe',
      MESSAGE: 'custom_ai.server.message',
      PROCESS_PROMPT: 'custom_ai.server.process_prompt',
      SEND_REPLIER_RESPONSE_TO_CLIENT:
        'custom_ai.server.send_replier_response_to_client',
      SENT_PROMPT_TO_CLIENT: 'custom_ai.server.send_prompt_to_client',
      ERROR: 'custom_ai.server.error',
      LOAD_MESSAGES: 'custom_ai.server.load_messages',
      SERVER_MESSAGE_ACKNOWLEDGEMENT:
        'CUSTOM_AI.SERVER_MESSAGE_ACKNOWLEDGEMENT',
      GET_AI_SETTINGS: 'custom_ai.server.get_ai_settings',
      UPDATE_AI_SETTINGS: 'custom_ai.server.update_ai_settings',
      REGENERATE_RESPONSE: 'custom_ai.server.regenerate_response',
      GET_ALL_AVAILABLE_REPLIERS: 'custom_ai.server.get_all_available_repliers',
      STATUS: {
        REPLYING: 'custom_ai.server.status.replying',
        READY: 'custom_ai.server.status.ready'
      }
    },
    GROUP_CHAT: {
      MESSAGE: 'server.group_chat.user_message',
      ERROR: 'server.group_chat.error'
   },
   IMAGE_GEN: {
    MESSAGE: 'server.image_gen.message',
    ERROR: 'server.image_gen.error',
    FINISHED_IMAGE_SENT_BACK_TO_USER: 'server.image_gen.finished_image_sent_back_to_user',
    MINER_SEND_IMAGE_PROGRESS_TO_CLIENT: 'server.image_gen.miner_send_image_progress_to_client',
   }
  },
};
