import React from "react";
import { Button } from "react-bootstrap";

export default function PublishChatBody({
    respondAskPublicModal = () => {},
    toggleAskPublicModal = () => {},
    isChatPublic = true
}) {
    return (
        <div className="px-2 d-flex flex-column gap-3">
            <strong>
                <span>{`Are you sure you want to make this Chat ${!isChatPublic ? "Public" : "Private"}?`}</span>
            </strong>
            <div className="d-flex flex-row justify-content-center gap-2">
                <Button onClick={() => respondAskPublicModal(true)} className="text-white" variant="danger">
                    OK
                </Button>
                <Button onClick={() => toggleAskPublicModal(false)} variant="light">
                    Cancel
                </Button>
            </div>
        </div>
    );
}
