import CryptoJS from "crypto-js";
import { config } from "../utils/config";

export async function encrypt(message) {
    const res = await fetch(`${config[config.environment].baseApiUrl}/client_keys`);

    if (!res.ok) {
        throw new Error("Error fetching keys");
    }

    const resJSON = await res.json();
    const key = CryptoJS.enc.Hex.parse(resJSON.client_key);
    const iv = CryptoJS.enc.Hex.parse(resJSON.client_iv);

    const ciphertext = CryptoJS.AES.encrypt(message, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return ciphertext.ciphertext.toString(CryptoJS.enc.Base64);
}

export async function decrypt(cipherText) {
    const res = await fetch(`${config[config.environment].baseApiUrl}/client_keys`);

    if (!res.ok) {
        throw new Error("Error fetching keys");
    }

    const resJSON = await res.json();
    const key = CryptoJS.enc.Hex.parse(resJSON.client_key);
    const iv = CryptoJS.enc.Hex.parse(resJSON.client_iv);

    const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted;
}
