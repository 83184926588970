import jwtDecode from "jwt-decode";
import { removeCookie } from "../utils/cookie";

export const httpTokenInterceptor = (config) => {
    let token = config.headers?.Authorization ?? '';
    token = token.split("Bearer ")[1];
    if (token) {
        const { exp } = jwtDecode(token);
        const currentTimeStamp = Math.floor(new Date().getTime() / 1000);
        const difference = exp - currentTimeStamp;
        if(difference < 3600){
            removeCookie("token");
            window.location.href = "/sign-in";
        }
    }
    return config;
};
