import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MerchantApiInstance } from "../../../apis/MerchantsAPI";
import { AddStoreSchema } from "../schema/AddStoreSchema";
//   import CouponsList from './components/CouponsList';
import {
    getAllCoupons,
    getCurrentUserMerchant,
    getCurrentUserMerchantCoupons
} from "../../../Redux/Actions/MerchantActions";
import { selectCurrentUser } from "../../../Redux/selectors/auth";
import { theme } from "../../../utils/config";
import { getMerchantImageUrl } from "../../../utils/images";
//import Carousel, {Pagination} from 'react-native-snap-carousel';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router";
import { readCookie } from "../../../utils/cookie";
import CouponsList from "../CouponsList/CouponsList";
import "../index.css";
import PageTitle from "../../../Component/Comman/PageTitle";
import { FormProvider } from "react-hook-form";
import { BiSolidImageAdd } from "react-icons/bi";
import { IoTrash } from "react-icons/io5";
import { ErrorMsg } from "../../../Component/Comman/Typography";
import StoreForm from "./StoreForm";
import { CustomToast, ToastStatus } from "../../../Component/Comman/CustomToast";
import AddCouponScreen from "../CouponsScreen/CouponForm";
import { Spinner } from "react-bootstrap";
import CouponForm from "../CouponsScreen/CouponForm";

// `CreateStoreScreen` is same as with `MyStoreAndCoupons`
const CreateStoreScreen = () => {
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState(1);
    const [createStoreData, setCreateStoreData] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    const [toast, setToast] = useState({
        show: false,
        message: "",
        status: ""
    });

    const onNext = () => setCurrentStep((prev) => prev + 1);

    const onSubmitForm = async (createCouponData) => {
        try {
            setSubmitting(true);

            const merchantResponse = await MerchantApiInstance.addMerchant(
                createStoreData.merchantValues,
                createStoreData.token,
                createStoreData.newImages
            );

            const merchantUUID = merchantResponse.data.data.merchant._id;

            if (merchantUUID && createCouponData) {
                let newImages = createCouponData.couponUploadImages
                    .filter((image) => {
                        return image?.file !== undefined;
                    })
                    .map((image) => {
                        return image.file;
                    });

                const couponResponse = await MerchantApiInstance.addCoupon(
                    createCouponData.couponBody,
                    merchantUUID,
                    createCouponData.token,
                    newImages
                );

                if (!couponResponse) {
                    throw new Error("Error adding coupon");
                }

                dispatch(getCurrentUserMerchant(createCouponData.token));
                dispatch(getCurrentUserMerchantCoupons(merchantUUID, createCouponData.token));
                dispatch(getAllCoupons(createCouponData.token));

                setSubmitting(false);

                setToast({
                    show: true,
                    message: "Store created successfully!",
                    status: ToastStatus.SUCCESS
                });

                setTimeout(() => navigate("/my-store"), 2000);
            } else {
                throw new Error("No merchant UUID or createCouponData");
            }
        } catch (e) {
            console.log(e);
            setSubmitting(false);

            setToast({
                show: true,
                message: "An error has occurred",
                status: ToastStatus.ERROR
            });
        }
    };

    const steps = [
        {
            title: "Create Store",
            component: (
                <StoreForm
                    onSubmit={(merchantValues, token, newImages) => {
                        setCreateStoreData({ merchantValues, token, newImages });
                        onNext();
                    }}
                />
            )
        },
        {
            title: "Add Your First Coupon",
            component: (
                <CouponForm
                    onSubmit={onSubmitForm}
                    buttonContent={(onSubmit) => (
                        <div className="d-flex justify-content-end ">
                            <div>
                                <Button
                                    data-step-action="prev"
                                    style={{ width: "100px" }}
                                    variant="light"
                                    className={`${currentStep > 1 ? "" : "d-none"} me-2`}
                                    onClick={() => setCurrentStep((prev) => prev - 1)}
                                >
                                    Previous
                                </Button>
                                {currentStep === steps.length && (
                                    <Button
                                        disabled={submitting}
                                        variant="primary"
                                        data-step-action="submit"
                                        style={{ width: "100px" }}
                                        onClick={onSubmit}
                                    >
                                        {submitting && (
                                            <Spinner animation="border" variant="light" size="sm" className="me-2" />
                                        )}
                                        Submit
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                />
            )
        }
    ];

    return (
        <div className="container-xxl px-4">
            <PageTitle pagetitle="Create Store" goBackLink="/my-store" />
            <div className="card mb-4">
                <div className="card-body">
                    <div className="wizard-main" id="w-horizontal">
                        <div className="step-app">
                            <ul className="step-steps p-0">
                                {steps.map((step, index) => {
                                    const stepNumber = index + 1;
                                    return (
                                        <li
                                            key={"header" + stepNumber}
                                            data-step-target={`step${stepNumber}`}
                                            className={`${currentStep === stepNumber ? "active" : ""}`}
                                        >
                                            <span>{stepNumber}</span>
                                            {step.title}
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="step-content">
                                {steps.map((step, index) => {
                                    const stepNumber = index + 1;
                                    return (
                                        <div
                                            key={"content" + stepNumber}
                                            className={`step-tab-panel ${currentStep === stepNumber ? "" : "d-none"}`}
                                            data-step={`step${stepNumber}`}
                                        >
                                            {step.component}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomToast
                status={toast.status}
                show={toast.show}
                message={toast.message}
                onClose={() => setToast((prev) => ({ ...prev, show: false }))}
            />
        </div>
    );
};

export default CreateStoreScreen;
