import { socketConstants } from './index';
import { config } from "../../utils/config";
import { readCookie } from "../../utils/cookie";
//todo: clean up and replace with NewSocketService.js in characterChatBox
//currently used for Ai Settings page only for prompt variables
class ChatSocket {
  url
  ws
  connected

  constructor() {
    this.url = `${config[config.environment].wsUrl}`;
    this.ws = null;
    this.connected = false;
  }

  connect(cb) {
    try {
      if (this.ws && this.connected) {
        cb(null);
        return;
      }

      // @dev append token here so we have an updated token every time we
      // connect to sockets and we are only invoking the constructor once
      this.ws = new WebSocket(
        `${this.url}?token=${readCookie("token")}&device=${
          socketConstants.DEVICE.MOBILE
        }`,
      );

      this.ws.onopen = () => {
          if (this.ws.readyState === 1) {
              console.log("Connection opened");
              this.connected = true;
              cb(null);
          }
      };

      this.ws.onerror = e => {
        console.log('error on socket connection');
        console.log(e);
      };

      this.ws.onclose = e => {
        console.log('Connection closed');
        console.log(e.code, e.reason);
        this.connected = false;
      };
    } catch (error) {
      console.error(error);
      cb(error);
    }
  }

  sendMessage(data) {
    if (this.ws && this.connected) {
      this.ws.send(JSON.stringify(data));
      return true;
    } else {
      console.log('Socket not connected');
      return false;
    }
  }

  subscribeToMessage(cb) {
    if (this.ws && this.connected) {
      this.ws.onmessage = e => {
        cb(JSON.parse(e.data));
      };
      return true;
    } else {
      return false;
    }
  }

  closeConnection() {
    if (this.ws && this.connected) {
      this.ws.close();
      this.connected = false;
    }
  }
}

export const ChatSocketInstance = new ChatSocket();
