import React from 'react'
import { Container, Row, Col, Form, Button} from 'react-bootstrap';

function CreateServiceForm() {
  return (
      <Container className=".create-service-modal-container">
          <Row>
              <h6>Make offer to Merchant</h6>
              <Col md={6}>
                  <Form.Group className="mb-3">
                      <div className="border rounded dotted-border text-center p-5">
                          {/* <input type="file" /> */}
                          <p>Click here to upload photo</p>
                      </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                      <Form.Label className='wt-500'>Service Name</Form.Label>
                      <Form.Control type="text" placeholder="Service Name" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                      <Form.Label className='wt-500'>Service Description</Form.Label>
                      <Form.Control as="textarea" rows={3} placeholder="Service Description" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                      <Form.Label className='wt-500'>Category Type</Form.Label>
                      <Form.Control as="select">
                          <option>Youtube Post</option>
                          <option>Youtube Post</option>
                      </Form.Control>
                  </Form.Group>
                  <div className="d-flex">
                      <Button variant="primary secondary-btn">Preview</Button>
                      <Button variant="success mx-3 action-btn">Add Service</Button>
                  </div>
              </Col>

              <Col md={6}>
                  <Form.Group className="mb-3">
                      <Form.Label className='wt-500'>Price of Service</Form.Label>
                      <Form.Control type="text" placeholder="Price of Service" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                      <Form.Label className='wt-500'>Quantity</Form.Label>
                      <Form.Control type="number" placeholder="Quantity" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                      <Form.Label className='wt-500'>Delivery Days</Form.Label>
                      <Form.Control type="number" placeholder="Delivery Days" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                      <Form.Check
                          type="switch"
                          id="custom-switch"
                          label='NSFW Content'
                      />
                  </Form.Group>
              </Col>
          </Row>
      </Container>
  );
}

export default CreateServiceForm;

