import { btcNetworks, blockchains } from "../../../utils/config";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Tab, Tabs, Form } from "react-bootstrap";
import { adminAPIInstance } from "../../../apis/AdminAPI";
import { tokenTypes } from "../../../utils/config";

export const CryptoToPoint = () => {
    const cryptoNetworkOptions = btcNetworks; // To include ETH to supported options in the future
    const blockchainOptions = [blockchains[1]]; // To include ETH to supported options in the future

    const [pointTokens, setPointTokens] = useState([]);

    const token1InputRef = useRef(null);

    const [token1Chain, setToken1Chain] = useState("");
    const [token1Network, setToken1Network] = useState("");

    const [token2Chain, setToken2Chain] = useState("");

    const [submitLoading, setSubmitLoading] = useState(false);

    const fetchAllMerchantPoints = async () => {
        const result = await adminAPIInstance.getAllPointTokens();
        setPointTokens(result?.data?.pointTokens ?? []);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const token1Data = JSON.parse(token1Chain);
            const token2Data = JSON.parse(token2Chain);

            if (!token1Network) {
                alert("Please select network");
                return false;
            }

            const body = {
                token1: {
                    name: token1Data.completeChainName,
                    symbol: token1Data.value,
                    type: tokenTypes[token1Data.value]
                },
                token2: {
                    type: "point",
                    pointTokenId: token2Data._id
                }
            };

            setSubmitLoading(true);
            await adminAPIInstance.addTokenPair({ body }); // TODO: Check if token pair already exists ??
            setSubmitLoading(false);

            alert("Successfully added pair");
        } catch (error) {
            console.error(error?.message);
            alert("Error adding pair");
            return false;
        }
    };

    useEffect(() => {
        fetchAllMerchantPoints();
    }, []);

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Label htmlFor="cryptoInput">Crypto Token</Form.Label>
                <div className="d-flex flex-row mb-4">
                    <Form.Select
                        aria-label="Token1 select chain"
                        className="me-2"
                        style={{ flex: 1 }}
                        value={token1Chain}
                        onChange={(e) => {
                            if(e.target.value === '') {
                                setToken1Network(''); 
                            }

                            const parsed = e.target.value !== '' ? JSON.parse(e.target.value) : ''
        
                            if (parsed?.value === "BTC") {
                                token1InputRef.current.value = "Bitcoin";
                                token1InputRef.current.disabled = true;
                            } else {
                                token1InputRef.current.value = "";
                                
                                token1InputRef.current.disabled = false;
                            }
                            setToken1Chain(e.target.value);
                        }}
                    >
                        <option value="">--Select token--</option>
                        {blockchainOptions.map((chain) => (
                            <option key={`token1-${chain.value}`} value={JSON.stringify(chain)}>
                                {chain.value}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select
                        aria-label="Token1 select network"
                        className="me-2"
                        style={{ flex: 1 }}
                        disabled={token1Chain === ""}
                        value={token1Network}
                        onChange={(e) => setToken1Network(e.target.value)}
                    >
                        <option value="">--Select network--</option>
                        {cryptoNetworkOptions.map((network) => (
                            <option key={`token1-network-${network.value}`} value={network.value}>
                                {network.label}
                            </option>
                        ))}
                    </Form.Select>
                    <div style={{ flex: 3 }}>
                        <Form.Control
                            ref={token1InputRef}
                            type="text"
                            id="token1Input"
                            aria-describedby="token1InputHelpBlock"
                            placeholder="Input token 1 or leave black if native token"
                        />
                    </div>
                </div>
                <Form.Label htmlFor="token2Input">Merchant Point</Form.Label>
                <div className="d-flex flex-row mb-4">
                    <Form.Select
                        aria-label="Token2 select chain"
                        className="me-2"
                        style={{ flex: 1 }}
                        value={token2Chain}
                        onChange={(e) => {
                         
                            setToken2Chain(e.target.value);
                        }}
                    >
                        <option value="">--Select point token--</option>
                        {pointTokens.map((token) => (
                            <option key={`token2-${token._id}`} value={JSON.stringify(token)}>
                                {token.pointRewardName}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <Button type="submit" variant="primary" disabled={submitLoading}>
                    Submit
                </Button>
            </Form>
        </div>
    );
};
