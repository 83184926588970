import React, { useReducer } from "react";
import { Nav, Tab } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const initialState = {
    askLimitRange: 0,
    bidLimitRange: 0,
    askMarketRange: 0,
    bidMarketRange: 0
};

function Spot({ addOrder, updateEvent, addOrderLoading, token1Symbol, token2Symbol, spotOrder }) {
    // prettier-ignore
    const { 
        priceForBid, 
        priceForAsk, 
        amountForBid, 
        amountForAsk, 
        token1Balance, 
        token2Balance, 
        orderType 
    } = spotOrder;

    const [spotEvent, updateSpotEvent] = useReducer((prev, next) => {
        const newEvent = { ...prev, ...next };
        return newEvent;
    }, initialState);

    return (
        <div className="card mb-3">
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold ">Spot</h6>
            </div>
            <div className="card-body">
                <Tab.Container defaultActiveKey={orderType}>
                    <Nav className="nav nav-tabs tab-body-header rounded d-inline-flex" role="tablist">
                        <Nav.Item className="nav-item">
                            <Nav.Link className="nav-link " eventKey="limit" data-bs-toggle="tab" href="" role="tab">
                                Limit
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item">
                            <Nav.Link className="nav-link" eventKey="market" data-bs-toggle="tab" href="" role="tab">
                                Market
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content className="tab-content">
                        <Tab.Pane className="tab-pane fade " id="Limit" eventKey="limit">
                            <div className="row g-3">
                                <div className="col-lg-6">
                                    <div className="d-flex align-items-center justify-content-between my-3">
                                        <span className="small text-muted">Avbl</span>
                                        <span className="">
                                            {`${token1Balance ? parseFloat(token1Balance).toFixed(6) : 0} ${token1Symbol}`}
                                        </span>
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Price</span>
                                        <input
                                            value={priceForBid}
                                            type="number"
                                            className="form-control"
                                            onChange={(e) => updateEvent({ priceForBid: e.target.value })}
                                        />
                                        <span className="input-group-text">{token2Symbol}</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Amount</span>
                                        <input
                                            value={amountForBid}
                                            type="number"
                                            className="form-control"
                                            onChange={(e) => updateEvent({ amountForBid: e.target.value })}
                                        />
                                        <span className="input-group-text">{token1Symbol}</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="mb-2 d-flex justify-content-between align-items-center w-100">
                                            <span className="text-muted">0%</span>
                                            <span className="text-muted px-2">25%</span>
                                            <span className="text-muted px-1">50%</span>
                                            <span className="text-muted px-1">75%</span>
                                            <span className="text-muted">100%</span>
                                        </div>
                                        <Form.Range
                                            value={spotEvent.bidLimitRange}
                                            onChange={(e) => {
                                                updateSpotEvent({ bidLimitRange: e.target.valueAsNumber });
                                                updateEvent({
                                                    amountForBid: token1Balance * (e.target.valueAsNumber / 100)
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Total</span>
                                        <input
                                            value={priceForBid * amountForBid}
                                            type="text"
                                            className="form-control"
                                            readOnly
                                        />
                                        <span className="input-group-text">{token2Symbol}</span>
                                    </div>
                                    <button
                                        disabled={addOrderLoading}
                                        onClick={() => {
                                            addOrder(priceForBid, amountForBid, "bid");
                                        }}
                                        type="submit"
                                        className="btn flex-fill btn-light-success py-2 fs-5 text-uppercase px-5 w-100"
                                    >
                                        BUY {token1Symbol}
                                    </button>
                                </div>

                                <div className="col-lg-6">
                                    <div className="d-flex align-items-center justify-content-between my-3">
                                        <span className="small text-muted">Avbl</span>
                                        <span className="">
                                            {`${token2Balance ? parseFloat(token2Balance).toFixed(6) : 0} ${token2Symbol}`}
                                        </span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Price</span>
                                        <input
                                            value={priceForAsk}
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => updateEvent({ priceForAsk: e.target.value })}
                                        />
                                        <span className="input-group-text">{token2Symbol}</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Amount</span>
                                        <input
                                            value={amountForAsk}
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => updateEvent({ amountForAsk: e.target.value })}
                                        />
                                        <span className="input-group-text">{token1Symbol}</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="mb-2 d-flex justify-content-between align-items-center w-100">
                                            <span className="text-muted">0%</span>
                                            <span className="text-muted px-2">25%</span>
                                            <span className="text-muted px-1">50%</span>
                                            <span className="text-muted px-1">75%</span>
                                            <span className="text-muted">100%</span>
                                        </div>
                                        <Form.Range
                                            value={spotEvent.askLimitRange}
                                            onChange={(e) => {
                                                updateSpotEvent({ askLimitRange: e.target.valueAsNumber });
                                                updateEvent({
                                                    amountForAsk: token2Balance * (e.target.valueAsNumber / 100)
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Total</span>
                                        <input
                                            value={priceForAsk * amountForAsk}
                                            type="text"
                                            className="form-control"
                                            readOnly
                                        />
                                        <span className="input-group-text">{token2Symbol}</span>
                                    </div>
                                    <button
                                        disabled={addOrderLoading}
                                        onClick={() => {
                                            addOrder(priceForAsk, amountForAsk, "ask");
                                        }}
                                        type="submit"
                                        className="btn flex-fill btn-light-danger py-2 fs-5 text-uppercase px-5 w-100"
                                    >
                                        SELL {token1Symbol}
                                    </button>
                                </div>
                            </div>
                        </Tab.Pane>

                        <Tab.Pane className="tab-pane fade" id="Market" eventKey="market">
                            <div className="row g-3">
                                <div className="col-lg-6">
                                    <div className="d-flex align-items-center justify-content-between my-3">
                                        <span className="small text-muted">Avbl</span>
                                        <span className="">
                                            {`${token1Balance ? parseFloat(token1Balance).toFixed(6) : 0} ${token1Symbol}`}
                                        </span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Price</span>
                                        <input
                                            value={priceForBid}
                                            type="number"
                                            className="form-control"
                                            onChange={(e) => updateEvent({ priceForBid: e.target.value })}
                                        />
                                        <span className="input-group-text">{token2Symbol}</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <button type="button" className="btn btn-outline-secondary">
                                            Amount
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <span className="visually-hidden">Toggle Dropdown</span>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" href="#!">
                                                    Amount
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#!">
                                                    Total
                                                </a>
                                            </li>
                                        </ul>
                                        <input
                                            value={amountForBid}
                                            type="number"
                                            className="form-control"
                                            onChange={(e) => updateEvent({ amountForBid: e.target.value })}
                                        />
                                        <span className="input-group-text">{token1Symbol}</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="mb-2 d-flex justify-content-between align-items-center w-100">
                                            <span className="text-muted">0%</span>
                                            <span className="text-muted px-2">25%</span>
                                            <span className="text-muted px-1">50%</span>
                                            <span className="text-muted px-1">75%</span>
                                            <span className="text-muted">100%</span>
                                        </div>
                                        <Form.Range
                                            value={spotEvent.bidMarketRange}
                                            onChange={(e) => {
                                                updateSpotEvent({ bidMarketRange: e.target.valueAsNumber });
                                                updateEvent({
                                                    amountForBid: token1Balance * (e.target.valueAsNumber / 100)
                                                });
                                            }}
                                        />
                                    </div>
                                    <button
                                        onClick={() => {
                                            addOrder(priceForBid, amountForBid, "bid");
                                        }}
                                        type="submit"
                                        className="btn flex-fill btn-light-success py-2 fs-5 text-uppercase px-5 w-100"
                                    >
                                        BUY {token1Symbol}
                                    </button>
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex align-items-center justify-content-between my-3">
                                        <span className="small text-muted">Avbl</span>
                                        <span className="">
                                            {`${token2Balance ? parseFloat(token2Balance).toFixed(6) : 0} ${token2Symbol}`}
                                        </span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Price</span>
                                        <input
                                            value={priceForAsk}
                                            type="number"
                                            className="form-control"
                                            onChange={(e) => updateEvent({ priceForAsk: e.target.value })}
                                        />
                                        <span className="input-group-text">{token2Symbol}</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <button type="button" className="btn btn-outline-secondary">
                                            Amount
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <span className="visually-hidden">Toggle Dropdown</span>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" href="#!">
                                                    Amount
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#!">
                                                    Total
                                                </a>
                                            </li>
                                        </ul>
                                        <input
                                            value={amountForAsk}
                                            type="number"
                                            className="form-control"
                                            onChange={(e) => updateEvent({ amountForAsk: e.target.value })}
                                        />
                                        <span className="input-group-text">{token1Symbol}</span>
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="mb-2 d-flex justify-content-between align-items-center w-100">
                                            <span className="text-muted">0%</span>
                                            <span className="text-muted px-2">25%</span>
                                            <span className="text-muted px-1">50%</span>
                                            <span className="text-muted px-1">75%</span>
                                            <span className="text-muted">100%</span>
                                        </div>
                                        <Form.Range
                                            value={spotEvent.askMarketRange}
                                            onChange={(e) => {
                                                updateSpotEvent({ askMarketRange: e.target.valueAsNumber });
                                                updateEvent({
                                                    amountForAsk: token2Balance * (e.target.valueAsNumber / 100)
                                                });
                                            }}
                                        />
                                    </div>
                                    <button
                                        onClick={() => {
                                            addOrder(priceForAsk, amountForAsk, "ask");
                                        }}
                                        type="submit"
                                        className="btn flex-fill btn-light-danger py-2 fs-5 text-uppercase px-5 w-100"
                                    >
                                        SELL {token1Symbol}
                                    </button>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
}
export default Spot;
