import React, { useCallback, useEffect, useRef, useState } from "react";
import { Tab } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import PageTitle from "../../Component/Comman/PageTitle";
import CategoryBar from "../../Component/Explore/CategoryBar";
import TopCharacters from "../../Component/Explore/TopCharacters";
import TopConversations from "../../Component/Explore/TopConversations";
import TopCreators from "../../Component/Explore/TopCreator";
import { AIApiInstance } from "../../apis/AIAPI";
import primaryBody from "../../assets/images/primary-body.svg";
import { isRedbitUrl } from "../../utils/checkRedbitUrl";
import { getBrowserCurrentUrl } from "../../utils/getCurrentUrl";
import './index.css';
import { authAPIInstance } from "../../apis/AuthAPI";
import Avatar from '../../Component/Comman/Avatar';
import { getUserImageUrl } from '../../utils/images';
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../Redux/selectors/auth";

export const CATEGORY = {
    GROUPS: "GROUPS",
    CHARACTERS: "CHARACTERS",
    CONVERSATIONS: "CONVERSATIONS",
};

export default function Explore() {

    const [search, setSearch] = useState("");
    const [category, setCategory] = useState(CATEGORY.CONVERSATIONS);
    const [characters, setCharacters] = useState([]);
    const [conversations, setConversations] = useState([]);
    const [groupChatConversations, setGroupChatConversations] = useState([]);
    const [nsfw, setNsfw] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumberCharacter, setPageNumberCharacter] = useState(1);
    const [pageNumberConversation, setPageNumberConversation] = useState(1);
    const currentRedbitUrl = isRedbitUrl();
    const [isEndOfPage, setIsEndOfPage] = useState(false);
    const lastCharacterRef = useRef();
    const lastCharacterObserver = useRef();
    const lastConversationRef = useRef();
    const lastConversationObserver = useRef();
    const scrollableTopCreator = useRef();
    const [creators, setCreators] = useState([]);
    const {autoTranslateMessages = false} = useSelector(selectCurrentUser) ?? {autoTranslateMessages: false}
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const categoryItems = [
        {
            title: "Top Conversations",
            type: CATEGORY.CONVERSATIONS,
            tabContent: <TopConversations autoTranslate={autoTranslateMessages} conversations={conversations}/>
        },
        {
            title: "Top Characters",
            type: CATEGORY.CHARACTERS,
            tabContent: <TopCharacters characters={characters} />
        },
        {
            title: "Top Groups",
            type: CATEGORY.GROUPS,
            tabContent: <TopConversations groupChatConversations={groupChatConversations}/>
        },
    ];

    const handleObserver = useCallback(
        // for pagination when scroll to bottom of the page
        (entries) => {
            const target = entries[0];
    
            if (target.isIntersecting && target.intersectionRatio > 0) {
                const lastConversationIndex = conversations.length - 1;
                if (lastConversationIndex >= 0 && target.target === lastConversationRef.current) {
                    setPageNumberConversation((prevPageNumberConversation) => prevPageNumberConversation + 1);
                }
            }

            if (target.isIntersecting && target.intersectionRatio > 0) {
                const lastCharacterIndex = characters.length - 1;
                if (lastCharacterIndex >= 0 && target.target === lastCharacterRef.current) {
                    setPageNumberCharacter((prevPageNumberCharacter) => prevPageNumberCharacter + 1);
                }
            }
        },
        [characters, conversations]
    );

    useEffect(() => {
        //pagination observer handleObserver
        const lastCharacter = lastCharacterRef.current;
        const lastConversation = lastConversationRef.current;
        if (category === CATEGORY.CONVERSATIONS && lastConversation) {
            lastConversationObserver.current = new IntersectionObserver(handleObserver, {
                root: null,
                rootMargin: "0px",
                threshold: 1,
            });
            if (lastConversation) {
                lastConversationObserver.current.observe(lastConversation);
            }
        }

        if (category === CATEGORY.CHARACTERS && lastCharacter) {
            lastCharacterObserver.current = new IntersectionObserver(handleObserver, {
                root: null,
                rootMargin: "0px",
                threshold: 1,
            });
            if (lastCharacter) {
                lastCharacterObserver.current.observe(lastCharacter);
            }
        }
    
        return () => {
            if (lastConversationObserver.current) {
                lastConversationObserver.current.disconnect();
            }
            
            if (lastCharacterObserver.current) {
                lastCharacterObserver.current.disconnect();
            }
        };
    }, [category, handleObserver, lastCharacterRef, lastConversationRef]);

    useEffect(() => {
        fetchData();
    }, [nsfw]);

    useEffect(() => {
        if (!isEndOfPage) {
            fetchData(pageNumberCharacter);
        } 
    }, [pageNumberCharacter]);

    const fetchData = async (page) => {
        setLoading(true);
        // if (isEndOfPage) { // this prevents calls if user reaches eop but is toggling nsfw filter
        //     setLoading(false);
        //     return;
        // }
    
        try {
            const result = await AIApiInstance.getAllPublicCharacters({
                getCreatorData: true,
                search: "",
                adultContent: nsfw,
                pageNumber: pageNumberCharacter
            });

            const newCharacters = result.data.data.aiCharacters;
            // Check if there are new characters
            if (page) {
                setCharacters((prevCharacters) => [...prevCharacters, ...newCharacters]);
            } else {
                setCharacters((_) => [...newCharacters]);
            }
            if(newCharacters.length <= 2 && page){
                setIsEndOfPage(true);
            }
            
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        fetchConversations();
    }, [nsfw]);

    useEffect(() => {
        if (!isEndOfPage) {
            fetchConversations(pageNumberConversation);
        }
    }, [pageNumberConversation]);
    
    const fetchConversations = async (page) => {
        setLoading(true);
        // if (isEndOfPage) { // this prevents calls if user reaches eop but is toggling nsfw filter
        //     setLoading(false);
        //     return;
        // }

        try {
           AIApiInstance.getTopGroupChats(nsfw)
            .then(res => {
                setGroupChatConversations(res.data.data.groups);
            }).catch(console.error);

            const result = await AIApiInstance.getTopConversations({ adultContent: nsfw, pageNumber: pageNumberConversation });
            const newConversation = result.data?.data?.topCharacterChats ?? [];
            console.log('page')
            if (page) {
                if (newConversation.length === 0) {
                    setIsEndOfPage(true);
                } else {
                    setConversations((prevConversations) => {
                        const uniqueNewConversations = newConversation.filter((conversation) => {
                            return !prevConversations.some((prevConversation) => prevConversation.id === conversation.id);
                        });
                        return [...prevConversations, ...uniqueNewConversations];
                    });
                }
            } else {
                setConversations(newConversation);
            }
    
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleAvatarClick = (creatorUsername) => {
        navigate(`/@${creatorUsername}`);
    };

    const cleanCharsIfOrangePoint = (characters) => {
        //if not redbit then filter out redbit characters
        if(!currentRedbitUrl) {
            return characters.filter((character) => !character.isRedbit);
        } else {
            return characters;
        }
    };

    // const handleSearchChange = (e) => {
    //     const value = e.target.value;
    //     if (!loading.current) {
    //         loading.current =  true;
    //         if (category === CATEGORY.CHARACTERS) {
    //             fetchAllPublicCharacters(value);
    //         } else {
    //             fetchAllCoupons(1, 10, value);
    //         }
    //     }
    //     setSearch(value);
    // };

    const handleCategoryChange = (e) => {
        const value = e.target.value;
        setCategory(value);
    };

    const handleNSFWChange = (e) => {
        const value = e.target.checked;
        setNsfw(value);
    };

    const handleAutoTranslateChange = (e) => {
        const value = e.target.checked;
        authAPIInstance.setAutoTranslateMessages(value)
            .then(_ => {
                dispatch({
                    type: 'UPDATE',
                    payload: {
                        user: {
                            key: 'autoTranslateMessages',
                            value: value
                        }
                    }
                });
            })
            .catch(console.error)
    };

    const showNSFWToggle = () => {
        return getBrowserCurrentUrl().includes("redchat") || getBrowserCurrentUrl().includes("localhost") ;
    };

    const getTopCreators = () => {
        authAPIInstance
            .getCreatorsPublicProfile()
            .then((res) => {
                const creatorsArray = Object.values(res?.data?.data?.users);
    
                const creatorsWithTotalCharacters = creatorsArray.map(creator => {
                    const totalCharacters = characters.filter(character => character.createdBy.username === creator.username).length;
                    return { ...creator, totalCharacters };
                });
    
                creatorsWithTotalCharacters.sort((a, b) => b.totalCharacters - a.totalCharacters);
    
                const sortedCreators = creatorsWithTotalCharacters.map(({ totalCharacters, ...rest }) => rest);
    
                setCreators(sortedCreators);
            })
            .catch((e) => {
                console.error(e);
        });
    };
    

    useEffect(() => {
        getTopCreators();
    }, [characters]);


    const scroll = (scrollOffset) => {
        if (scrollableTopCreator.current) {
            scrollableTopCreator.current.scrollLeft += scrollOffset;
        }
    };

    const getTabContent = () => {
        //Neede for Pagination: For getting last element of the list , it asks for parent of element
        //pagination observer handleObserver 
        const selectedCategory = categoryItems.find((c) => c.type === category);
        return (
            <div>
                {selectedCategory && selectedCategory.tabContent}
                {selectedCategory.type === CATEGORY.CONVERSATIONS && (
                    <div>
                        {conversations.map((conversation, index) => (
                            <div key={index}>
                                {/**very last element for handling pagination when hit bottom */}
                                {index === conversations.length - 1 && (
                                    <div ref={lastConversationRef}></div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                {selectedCategory.type === CATEGORY.CHARACTERS && (
                    <div>
                        {characters.map((character, index) => (
                            <div key={index}>
                                {index === characters.length - 1 && (
                                    <div ref={lastCharacterRef}></div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };
    
    return (
        <div className="px-lg-6  px-md-4" style={{ backgroundImage: `url(${primaryBody})`, backgroundSize: 'cover', backgroundAttachment: 'fixed'}}>
            <div className="py-2 px-0 d-flex flex-row justify-content-between align-items-center">
                <h4>Recommended</h4>
                <div className="d-flex flex-row scroll-arrow-container gap-2 px-3">
                    <i className="fa-solid fa-arrow-left py-2 pe-2" onClick={() => scroll(-1000)}></i>
                    <div className="vr m-0"></div>
                    <i className="fa-solid fa-arrow-right py-2 ps-2" onClick={() => scroll(1000)}></i>
                </div>
            </div>
            

            <div className="d-flex flex-row gap-4 overflow-auto py-2 top-creator-container" style={{overflowX: "scroll"}} ref={scrollableTopCreator}>
                {creators.map((creator, index) => (
                    <div className="d-flex flex-column gap-3 p-0 position-relative conversation-border align-items-center"
                        key={`conversation-${index}`} style={{ minWidth: "180px", flex: "0 0 auto" }}>
                        <div className="p-4 d-flex flex-column align-items-center gap-3" onClick={() => handleAvatarClick(creator.username)}>
                            <Avatar
                                avatarUrl={getUserImageUrl(creator?.avatar_url)}
                                name={"test"}
                                className="br-50 xl"
                            />
                            <span>{creator.username}</span>
                        </div>
                        <div className="col-12">
                            <hr className="m-0"></hr>
                            <p className="pointer character-card-details pt-3 px-2">@{creator.username}</p>
                        </div>
                    </div>
                ))}
            </div>
            {/* <div className="d-flex flex-row justify-content-between">
                <div>
                    <button onClick={handleCreateCharacterClick} style={{ marginLeft: "0.5em" }} className="btn btn-primary btn-sm mb-3">
                        +Create AI Character
                    </button>
                </div>
            </div> */}
            {/* <div className="search-container">
                <Form.Control
                    id="search-input"
                    value={search}
                    onChange={handleSearchChange}
                    type="text"
                    placeholder="Seach Coupons, AI characters..."
                />
                <Form.Select
                    id="category-input"
                    value={category}
                    onChange={handleCategoryChange}
                    aria-label="Default select example"
                >
                    <option disabled={true} value={"null"}>
                        Select category
                    </option>
                    <option value={CATEGORY.COUPON}>Coupons</option>
                    <option value={CATEGORY.CHARACTERS}>Characters</option>
                </Form.Select>
            </div> */}
            <div className="w-100">
                <div className="row g-3 mb-3">
                    <div className="col-md-12">
                        <Tab.Container>
                            <div className="d-flex justify-content-between flex-wrap px-2">
                                <CategoryBar categoryItems={categoryItems} category={category} setCategory={setCategory} />
                                
                                {showNSFWToggle() && (
                                    <div className="d-flex align-items-center">
                                        <Form.Switch checked={nsfw} onChange={handleNSFWChange} />
                                        <span className="mx-2">NSFW</span>
                                    </div>
                                )}
                                <div className="d-flex align-items-center">
                                    <Form.Switch checked={autoTranslateMessages} onChange={handleAutoTranslateChange} />
                                    <span className="mx-2">Auto translate</span>
                                </div>
                            </div>
                            <div className="card-body">{getTabContent()}</div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
    );
}