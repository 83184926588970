import React, { useState } from "react";
import { PublicChats } from "./PubliChats";
import { YoutubeVideos } from "./YoutubeVideos";
import CharacterImages from "./CharacterImages";
import Reviews from "./Reviews";
import { PublicGroupChats } from "./PublicGroupChats";

const tabsEnum = {
    publicChats: "Public Chats",
    groupChats: "Goup Chats",
    videos: "videos",
    characterImages: "characterImages",
    reviews: "reviews"
};

const tabs = [
    { text: "Public Chats", category: tabsEnum.publicChats },
    { text: "Goup Chats", category: tabsEnum.groupChats },
    { text: "Character Images", category: tabsEnum.characterImages },
    { text: "Videos", category: tabsEnum.videos },
    { text: "Reviews", category: tabsEnum.reviews },
];

export function ProfileTabs({chats = [], groupChats = [], characterName = '', videos=[], images = [], characterId = "", selectedChatUserId = "", character = {}}) {
    const [selectedtab, setselectedtab] = useState(tabsEnum.publicChats);
    const [showVideoFile, setShowVideoFile] = useState(false);

    const selectTab = (category) => {
        setselectedtab(category);
    };

    const openVideoFile = () => {
        setShowVideoFile(true);
    };

    const tabsContent = {
        [tabsEnum.publicChats]: (
            <PublicChats character={character} key={"publicChats"} chats={chats} characterName={characterName} containerStyle={{ width: "100%" }} selectedChatUserId={selectedChatUserId} />
        ),
        [tabsEnum.groupChats]: (
            <PublicGroupChats character={character} key={"publicChats"} chats={groupChats} characterName={characterName} containerStyle={{ width: "100%" }} selectedChatUserId={selectedChatUserId} />
        ),
        [tabsEnum.videos]: (
            <YoutubeVideos key={"videos"} videos={videos} characterName={characterName} containerStyle={{ width: "100%" }} />
        ),
        [tabsEnum.characterImages]: <CharacterImages images={images}/>,
        [tabsEnum.reviews]: <Reviews characterId={characterId}/>,
    };

    return (
        <div className="character-profile-right-container">
            <div className="ai-profiles-tab-container">
                <div className="profiles-tab-container-tabs">
                    {tabs.map((tab, i) => (
                        <div
                            key={tab?.text}
                            onClick={() => selectTab(tab.category)}
                            className={`profiles-tab ai-profiles-tab ${
                                tab.category === selectedtab ? "profiles-selected-tab" : ""
                            }`}
                        >
                            {tab.text}
                        </div>
                    ))}
                </div>
                <div className="profiles-tab-content">{tabsContent[selectedtab]}</div>
            </div>
        </div>
    );
}