import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

const paymentElementOptions = {
    layout: "tabs"
};

export default function CheckoutForm({ clientSecret, onPaymentSucessCallback }) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        setIsDisabled(true);
        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            redirect: "if_required"
        });

        if (error) {
            if (error?.type === "card_error" || error?.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        setIsDisabled(false);
        } else {
            stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
                console.log(paymentIntent);
                switch (paymentIntent.status) {
                    case "succeeded": {
                        onPaymentSucessCallback(paymentIntent.id);
                        setMessage("Payment succeeded!");
                        break;
                    }
                    case "processing":
                        setMessage("Your payment is processing.");
                        break;
                    case "requires_payment_method":
                        setMessage("Your payment was not successful, please try again.");
                        break;
                    default:
                        setMessage("Something went wrong.");
                        break;
                }
            });
        }
        setIsLoading(false);
    };

    return (
        <form id="payment-form">
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <Button className="mt-2" disabled={isDisabled || !stripe || !elements} id="submit" onClick={handleSubmit}>
                Pay now{(isDisabled || isLoading) && <Spinner className="mx-2" animation="border" size="sm" />}
            </Button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}
