import { Form, Modal } from "react-bootstrap";
import React, { useState } from "react";
import { AptosAPIInstance } from "../../apis/AptosAPI";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import OTPInput from "react-otp-input";
import { ErrorMsg } from "../../Component/Comman/Typography";
import { AxiosError } from "axios";

function WithdrawModal2FA({ show, onHide, tempToken, user, email, receiverAddress, withdrawAmount }) {
    const dispatch = useDispatch();
    const schema = Yup.object({
        otp: Yup.string().label("OTP").required()
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting, errors }
    } = useForm({
        resolver: yupResolver(schema)
    });

    const [error, setError] = useState(null);

    async function onSubmit(data) {
        try {
            setError(null);
            await AptosAPIInstance.withdrawAptos(
                data.otp,
                tempToken,
                user,
                receiverAddress,
                withdrawAmount
            );
            alert("Withdrawal Success");
            onHide();
        } catch (error) {
            if (error instanceof AxiosError) {
                setError(error.response.data.message);
            } else {
                console.error(error?.message ?? "Error enabling two factor auth");
                setError("Error signing in with two factor authentication");
            }
        }
    }

    return (
        <Modal className="modal" id="2FA" style={{ display: "block", backgroundColor: "rgba(4, 4, 35, 0.5)" }} show={show} onHide={onHide} backdrop="static" centered>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-content otp-card-modal">
                    <Modal.Header className="modal-header" closeButton>
                    </Modal.Header>
                    <Modal.Body className="modal-body px-5 py-4">
                        <div className="mb-3 d-flex flex-column align-items-center justify-content-center">
                            <h4 className="modal-title mb-3">Two-factor Authentication</h4>
                            <div className="justify-content-center">
                                Enter the authentication code generated by your TOTP app (e.g Google Authenticator, Authy,
                                Duo Mobile).
                            </div>
                        </div>
                        <div className="my-4 d-flex flex-column align-items-center otp-card-body px-4 py-2">
                            <div className="mb-1 gap-2 py-4">
                                <Controller
                                    name={"otp"}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <OTPInput
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            value={field.value}
                                            numInputs={6}
                                            renderSeparator={<span className="me-2" />}
                                            renderInput={(props) => <Form.Control {...props} className="w-100" />}
                                        />
                                    )}
                                />
                            </div>
                            {errors.otp ? <ErrorMsg msg={errors.otp?.message} /> : error ? <ErrorMsg msg={error} /> : <></>}
                            <div className="mb-4 col-12 text-center">
                                <button type="submit" className="btn orange-gradient-btn  py-2 fs-7 w-100 mt-2" isSubmitting={isSubmitting}>Verify</button>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mb-2">
                            <div title="#" className="orange-red-gradient-text text-decoration-none">Resend code</div>
                        </div>
                    </Modal.Body>
                </div>
            </form>
        </Modal>
    );
}

export default WithdrawModal2FA;
