import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./Redux/Reducers/AuthReducer";
import Mainreducer from "./Redux/Reducers/Mainreducer";
import ChatReducer from "./Redux/Reducers/ChatReducer";
import MerchantReducer from "./Redux/Reducers/StoreReducer";

const store = configureStore({
    reducer: {
        AuthReducer,
        Mainreducer,
        chat: ChatReducer.reducer,
        merchant: MerchantReducer.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export default store;
