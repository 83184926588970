import React from "react";
import Button from "react-bootstrap/Button";

export default function AiSetting({ handleSettingsUpdateClick}) {
    return (
        <div className="api-keys-container">
            <div className="top-container">
                <h6>AI Settings</h6>
            </div>
            <p>Tweak AI params to get desired output messages</p>
            <div className="api-keys-table">
                <div className="header">
                    <span>
                        <Button onClick={handleSettingsUpdateClick} id="upgrade-plan-btn">{`Upgrade Settings`}</Button>
                    </span>
                </div>
            </div>
        </div>
    );
}
