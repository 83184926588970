import axios from "axios";
import { readCookie } from "../utils/cookie";
import { config } from "../utils/config";
import { httpTokenInterceptor } from "./common";

class AdminAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    async getAllUsers() {
        try {
            const response = await this.axios.get("/users/", {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getUserSessions(userId) {
        try {
            const response = await this.axios.get(`/users/${userId}/sessions`, {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getUserTransactions(userId) {
        try {
            const response = await this.axios.get(`/transactions/admin/${userId}`, {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async updateUserBanStatus(userId, status) {
        try {
            const response = await this.axios.patch(
                `/users/`,
                { status, userId },
                {
                    headers: {
                        Authorization: `Bearer ${readCookie("token")}`
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async addTokenPair({ body }) {
        try {
            const response = await this.axios.post(`/tokens/pairs`, body, {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getTokenPairs() {
        try {
            const response = await this.axios.get(`/tokens/pairs`, {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getAllPointTokens() {
        try {
            const response = await this.axios.get(`/tokens/points`, {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getBalancesByTokenPair(pair) {
        return this.axios.get(`/tokens/get_balances_by_token_pair/${pair.replace("/", "%2F")}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getAllPayouts() {
        return this.axios.get(`/users/admin/payouts`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    updatePayout({ body }) {
        return this.axios.patch(`/users/admin/payouts`, body, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }
}

export const adminAPIInstance = new AdminAPI();

export default AdminAPI;
