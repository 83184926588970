import React from "react";
import { Link } from "react-router-dom";

const BackLink = ({ goBackLink }) => (
    <Link to={process.env.PUBLIC_URL + goBackLink}>
        <div className="d-flex align-items-center mb-4 text-link">
            <i className="fa fa-chevron-left me-2 " />
            <p className="mb-0">Back</p>
        </div>
    </Link>
);

function PageTitle(props) {
    const { pagetitle, hasDivider = true, goBackLink = null } = props;
    return (
        <div>
            <div className={hasDivider ? "body-header border-bottom d-flex py-3 mb-3 " : ""}>
                <div>
                    {goBackLink && <BackLink goBackLink={goBackLink} />}
                    <div className="row align-items-center g-2">
                        <div className="col">
                            <h1 style={{ marginLeft: "0.5em" }} className="h4 mt-1">{pagetitle}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PageTitle;
