import { Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllCoupons,
    getCurrentUserMerchantCoupons
} from "../../../Redux/Actions/MerchantActions";
import { MerchantApiInstance } from "../../../apis/MerchantsAPI";
import { AddCouponSchema } from "../schema/AddCouponSchema";
// import '../../i18n';
// import {useTranslation} from 'react-i18next';
import { theme } from "../../../utils/config";
// import {CryptoApiInstance} from '../../apis/CryptoApi';
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import { readCookie } from "../../../utils/cookie";
import { NO_IMAGE, getCouponImageUrl, getMerchantImageUrl } from "../../../utils/images";
import "./index.css";
import { ErrorMsg } from "../../../Component/Comman/Typography";
import { IoTrash } from "react-icons/io5";
import { BiSolidImageAdd } from "react-icons/bi";
import { setUnsavedCoupon } from "../../../Redux/Reducers/StoreReducer";

const coupon_categories = [
    { name: "coaching", text: "Coaching" },
    { name: "restaurants", text: "Restaurants" },
    { name: "hotels", text: "Hotels" },
    { name: "plastic_surgery", text: "Plastic Surgery" },
    { name: "clinics", text: "Clinics" },
    { name: "pet_care", text: "Pet Care" },
    { name: "hair_salons", text: "Hair Salons" },
    { name: "shops", text: "Shops" },
    { name: "fitness", text: "Fitness" }
];

const coupon_currencies = [
    { name: "KRW", text: "Korean Won" },
    { name: "PKR", text: "Pakistani Rupees" },
    { name: "USD", text: "United States Dollar" },
    { name: "PHP", text: "Phillipine Pesos" },
    { name: "VND", text: "Vietnamese Dong" },
];

const initialValues = {
    couponName: "",
    couponDescription: "",
    priceInPoints: "",
    priceInDollars: "",
    priceInBTC: "",
    quantity: "1",
    isAdultContent: false,
    category: coupon_categories[0].name,
    currency: "USD"
};


const CouponForm = ({ buttonContent, onSubmit, handleDeleteCoupon, isSubmitting = false, isDeleting = false }) => {
    const location = useLocation();
    const { merchantUUID, isAddingMerchant, updatingCoupon } = location.state || {};
    const formikCtx = useRef();
    const dispatch = useDispatch();
    const currentUserMerchant =  useSelector(state => state.merchant.currentUserMerchant);
    const unsavedCoupon =  useSelector(state => state.merchant.unsavedCoupon);
    const couponImageInput = useRef(null);
    const token = readCookie("token");
    const navigate = useNavigate();
    const { t } = useTranslation("add_coupon");
    const [allowPriceInDollars, setAllowPriceInDollars] = useState(updatingCoupon?.priceInDollars ? true : false);

    const [allowPriceInBTC, setAllowPriceInBTC] = useState(updatingCoupon?.priceInBTC ? true : false);

    const [toggleAdultContent, setToggleAdultContent] = useState(updatingCoupon?.isAdultContent ?? false);

    const [couponUploadImages, setCouponUploadImages] = useState(() => {
        if (updatingCoupon?.couponPhoto) {
            return [{ uri: getCouponImageUrl(updatingCoupon?.couponPhoto) }];
        } else if (updatingCoupon?.couponPhotos) {
            return updatingCoupon.couponPhotos.map((photo) => ({
                uri: getCouponImageUrl(photo)
            }));
        } else {
            if (unsavedCoupon) {
                if(unsavedCoupon?.couponUploadImages){
                    let couponPhotos = unsavedCoupon?.couponUploadImages;
                    couponPhotos = couponPhotos?.map(photo => {
                        if(typeof photo === "string"){
                            if(photo?.startsWith('http')) {
                                return photo;
                            }
                        }
                        return { ...photo };
                    })
                    return couponPhotos;
                }

            }
            return [];
        }
    });

    const [btcPriceInUsd, setBtcPriceInUsd] = useState(0);

    const handleSubmitCoupon = async (values) => {
        const couponBody = {
            ...values,
            priceInPoints: values.priceInPoints || 0,
            priceInDollars: values.priceInDollars || 0,
            priceInBTC: values.priceInBTC || 0,
            quantity: values.quantity || 1,
            isAdultContent: toggleAdultContent,
            category: values.category
        };

      await onSubmit({ couponBody, token, couponUploadImages });
    };

    const handleImagePicker = () => {
        couponImageInput?.current?.click();
    };

    const getInitialValues = () => {
        if (updatingCoupon) {
            return {
                ...initialValues,
                couponName: updatingCoupon?.couponName,
                couponDescription: updatingCoupon?.couponDescription,
                priceInPoints: updatingCoupon?.priceInPoints,
                priceInDollars: updatingCoupon?.priceInDollars,
                priceInBTC: updatingCoupon?.priceInBTC,
                quantity: updatingCoupon?.quantity,
                isAdultContent: updatingCoupon?.isAdultContent,
                category: updatingCoupon?.category ?? coupon_categories[0].name,
                ...(updatingCoupon?.currency ? { currency: updatingCoupon?.currency } : {})
            };
        }
        if (unsavedCoupon) {
            const {couponUploadImages, ...rest } = unsavedCoupon;
            return { ...rest };
        }
        return initialValues;
    };

    const getBitcoinPrice = useCallback(async () => {
        // const price = await CryptoApiInstance.getBitcoinPriceInUSD();
        setBtcPriceInUsd(0);
    }, []);

    useEffect(() => {
        getBitcoinPrice();
    }, [getBitcoinPrice]);

    // carousel

    const removeImage = (uri) => {
        if (updatingCoupon) {
            MerchantApiInstance.deleteCouponImage(token, updatingCoupon._id, uri?.split("/")?.pop());
        }
        dispatch(getCurrentUserMerchantCoupons(merchantUUID, token));
        dispatch(getAllCoupons(token));
        setCouponUploadImages((prev) => {
            const newImages = prev.filter((image) => {
                return image.uri !== uri;
            });
            return [...newImages];
        });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCouponUploadImages((prev) => {
                    const newImage = { file: file, uri: reader.result, new: true };
                    return [...prev, newImage];
                });
            };
            reader.readAsDataURL(file);
        }
    };
    
    const handlePreview = () => {
        if (!updatingCoupon) {
            dispatch(setUnsavedCoupon({ coupon: { ...formikCtx.current.values, couponUploadImages } }));
        }
        let couponPhotos = [];
        couponPhotos = couponUploadImages.map((img) => (img.file ? img.uri : getCouponImageUrl(img)));
        if(couponPhotos.length === 0) {
            couponPhotos = [NO_IMAGE];
        }
        let merchantInfo = {...currentUserMerchant};
        if (!merchantInfo?.storePhotos?.[0]) {
            merchantInfo = { ...merchantInfo, storePhotos: [NO_IMAGE] };
        } else {
            merchantInfo = { ...merchantInfo, storePhotos: [getMerchantImageUrl(merchantInfo.storePhotos[0])] };
        }
        const state = {
            ...formikCtx.current.values,
            couponPhotos,
            merchantInfo,
        };
  
        navigate("/coupon/preview", { state });
    };

    const handleQuantityChange = (e, setFieldValue) => {
        let newQuantity = parseInt(e.target.value, 10);
        if (isNaN(newQuantity) || newQuantity < 1) {
            newQuantity = 1;
        }
        else if (newQuantity >= 10000) {
            newQuantity = 9999;
        }
        setFieldValue("quantity", newQuantity);
    };

    return (
        <Formik
            innerRef={formikCtx}
            initialValues={getInitialValues()}
            validationSchema={AddCouponSchema}
            onSubmit={handleSubmitCoupon}
            enableReinitialize={true}
        >
            {({ handleChange, handleSubmit, values, errors, setFieldValue, touched }) => (
                <div className="row gy-3">
                    <div className="col-12 col-md-6">
                        <div className="card card-body gap-3">
                            <div className="d-flex justify-content-center mt-2">
                                <Carousel showThumbs={false} className="store_image_container rounded w-100">
                                    {[...couponUploadImages, { type: "upload" }]?.map((image, index) =>
                                        image?.type !== "upload" ? (
                                            <div className="" key={"img" + index}>
                                                <div>
                                                    <Button
                                                        variant="dark"
                                                        className="position-absolute top-0 start-0 ms-4 my-2 rounded px-2 py-1"
                                                        onClick={() => removeImage(image.uri)}
                                                    >
                                                        <IoTrash size={16} color="white" />
                                                    </Button>
                                                    <div className="couponCarouselImage">
                                                        <img
                                                            src={image.uri}
                                                            alt="carousel_img"
                                                            className="object-fit-cover rounded"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div onClick={handleImagePicker} className="w-100" key={"img-none" + index}>
                                                <div className="image_upload_container rounded w-100">
                                                    <span color={theme.color}>
                                                        <BiSolidImageAdd size={28} className="me-2" />
                                                        Upload Image
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </Carousel>
                                <input
                                    id="storeImageInput"
                                    type="file"
                                    ref={couponImageInput}
                                    onChange={handleImageUpload}
                                    accept="image/*"
                                />
                            </div>

                            <Form.Group>
                                <Form.Label className="required">{t("Coupon_Name")}</Form.Label>
                                <Form.Control
                                    value={values.couponName}
                                    onChange={handleChange("couponName")}
                                    type="text"
                                    placeholder={t("Enter_Coupon_Name")}
                                />
                                {touched.couponName && errors.couponName && <ErrorMsg msg={errors.couponName} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">{t("Coupon_Description")}</Form.Label>
                                <Form.Control
                                    value={values.couponDescription}
                                    onChange={handleChange("couponDescription")}
                                    type="text"
                                    placeholder="E.g. 200"
                                />
                                {touched.couponDescription && errors.couponDescription && (
                                    <ErrorMsg msg={errors.couponDescription} />
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">{t("Coupon_Category")}</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={handleChange("category")}
                                    defaultValue={values.category}
                                >
                                    {coupon_categories.map((category, i) => (
                                        <option value={category.name} key={i}>
                                            {t(category.text.replace(/\s+/g, "_"))}
                                        </option>
                                    ))}
                                </Form.Select>
                                {touched.category && errors.category && (
                                    <span color="red.500" mt="1" fontSize="xs">
                                        {errors.category}
                                    </span>
                                )}
                            </Form.Group>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 ">
                        <div className="card card-body gap-3 h-100">
                            {/* <Form.Group>
                                <Form.Label className="required">{t("Point_Price")}</Form.Label>
                                <Form.Control
                                    value={values.priceInPoints?.toString()}
                                    onChange={handleChange("priceInPoints")}
                                    type="number"
                                    placeholder="E.g. 200"
                                />
                                {touched.priceInPoints && errors.priceInPoints && <ErrorMsg msg={errors.priceInPoints} />}
                            </Form.Group> */}

                            <Form.Group>
                                <Form.Label className="required">{t("Currency")}</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={handleChange("currency")}
                                    defaultValue={values.currency}
                                >
                                    {coupon_currencies.map((currency, i) => (
                                        <option value={currency.name} key={i}>
                                            {t(currency.text)}
                                        </option>
                                    ))}
                                </Form.Select>
                                {touched.currency && errors.currency && (
                                    <span color="red.500" mt="1" fontSize="xs">
                                        {errors.currency}
                                    </span>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">In {values.currency}</Form.Label>
                                <Form.Control
                                    value={values.priceInDollars?.toString()}
                                    onChange={handleChange("priceInDollars")}
                                    type="number"
                                    placeholder="E.g. 200"
                                />
                                {touched.priceInDollars && errors.priceInDollars && <ErrorMsg msg={errors.priceInDollars} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{t("Quantity")}</Form.Label>
                                <Form.Control
                                    value={values.quantity?.toString()}
                                    onChange={(e) => handleQuantityChange(e, setFieldValue)}
                                    min={1}
                                    type="number"
                                    placeholder="E.g. 10"
                                />
                                {touched.quantity && errors.quantity && <ErrorMsg msg={errors.quantity} />}
                            </Form.Group>

                            {/* <Form.Group>
                                <Form.Label>{t("Price_per_Coupon_in_BTC")}</Form.Label>
                                <Form.Control
                                    value={values.priceInBTC}
                                    onChange={handleChange("priceInBTC")}
                                    type="number"
                                    placeholder="E.g. 200"
                                    disabled={!allowPriceInDollars}
                                />
                                {btcPriceInUsd !== 0 && (
                                    <span color="gray.400" mt="1" fontSize="xs">
                                        {`$${parseFloat(String(btcPriceInUsd * values.priceInBTC)).toFixed(5)}`}
                                    </span>
                                )}
                                {touched.priceInBTC && errors.priceInBTC && <ErrorMsg msg={errors.priceInBTC} />}
                            </Form.Group> */}

                            {/* <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={t("Sell_Coupon_in_Bitcoin")}
                                onChange={() => {
                                    setFieldValue("priceInBTC", "");
                                    setAllowPriceInDollars(!allowPriceInDollars);
                                }}
                            /> */}

                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={t("Is_NSFW_Content")}
                                checked={toggleAdultContent}
                                onChange={() => {
                                    setToggleAdultContent(!toggleAdultContent);
                                }}
                            />
                        </div>
                    </div>

                    {buttonContent == null ? (
                        <div className="d-flex justify-content-end mb-2 gap-2">
                            {handleDeleteCoupon && (
                                <Button
                                    w="full"
                                    onClick={handleDeleteCoupon}
                                    style={{ width: "100px" }}
                                    variant="danger"
                                    className="text-white"
                                    disabled={isDeleting || isSubmitting}
                                >
                                    {isDeleting && <Spinner size="sm" className="me-2" animation="border" variant="white" />}
                                    {t("Delete")}
                                </Button>
                            )}
                            {!updatingCoupon && (
                                <Button w="full" onClick={handlePreview} style={{ width: "100px" }} className="text-white">
                                    {t("Preview")}
                                </Button>
                            )}
                            <Button
                                bg={theme.color}
                                w="full"
                                onClick={handleSubmit}
                                style={{ width: "100px" }}
                                disabled={isDeleting || isSubmitting}
                            >
                                {isSubmitting && <Spinner size="sm" className="me-2" animation="border" variant="white" />}
                                {updatingCoupon ? t("Update") : t("Add")}
                            </Button>
                        </div>
                    ) : (
                        buttonContent(handleSubmit)
                    )}
                </div>
            )}
        </Formik>
    );
};

export default CouponForm;
