import useWebSocket, { ReadyState } from 'react-use-websocket';
import { socketConstants } from "./index";
import { config } from "../../utils/config";
import { readCookie } from "../../utils/cookie";

export const NewSocketService = (fromWhere) => {
        const url = `${config[config.environment].wsUrl}`;
        let builtUrl = `${url}?token=${readCookie("token")}&device=${socketConstants.DEVICE.MOBILE}`;
        //config to change websocket url to prod or dev
        const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
            builtUrl,
            {
            onOpen: () => console.log('NewSocketService : opened'),
            shouldReconnect: () => true,
            reconnectAttempts: 500,
            reconnectInterval: 5000,
            onMessage: (event) => {
                const data = JSON.parse(event.data);
                console.log("NewSocketService, data: ", data);
            }
            }
        );
        //console.log("NewSocketService,readyState : "+ fromWhere + ":" , readyState);

    return { sendMessage, lastMessage, readyState, getWebSocket };
}