import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { adminAPIInstance } from '../../../apis/AdminAPI';

const UserDepositColumns = [
    {
        name: "chain",
        selector: (row) => row?.chain,
        compact: true,
        width: "3%"
    },
    {
        name: "tokenType",
        selector: (row) => row?.tokenType,
        compact: true,
        width: "6%"
    },
    {
        name: "token_address",
        selector: (row) => row?.token_address,
    },
    {
        name: "network",
        selector: (row) => row?.network,
        compact: true,
        width: "5%"
    },
    {
        name: "txHash",
        selector: (row) => row?.txHash ?? "",
        compact: true,
        width: "20%",
        style: { padding: "0em 1em" }
    },
    {
        name: "createdAt",
        selector: (row) => new Date(row?.createdAt * 1000).toUTCString(),
        compact: true,
        width: "16%"
    },
    {
        name: "amount",
        selector: (row) => parseFloat(row?.amount ?? 0).toFixed(6),
    },
];


const UserWthdrawColumns = [
    {
        name: "chain",
        selector: (row) => row?.chain,
        compact: true,
        width: "2%"
    },
    {
        name: "tokenType",
        selector: (row) => row?.tokenType,
        compact: true,
        width: "6%"
    },
    {
        name: "token_address",
        selector: (row) => row?.token_address,
        compact: true,
        width: "10%",
        style: { padding: "0em 1em" }
    },
    {
        name: "network",
        selector: (row) => row?.network,
        compact: true,
        width: "4%"
    },
    {
        name: "txHash",
        selector: (row) => row?.txHash ?? "",
        compact: true,
        width: "10%",
        style: { padding: "0em 1em" }
    },
    {
        name: "createdAt",
        selector: (row) => new Date(row?.createdAt * 1000).toUTCString(),
        compact: true,
        width: "13%"
    },
    {
        name: "amount",
        selector: (row) => parseFloat(row?.amount ?? 0).toFixed(6),
        compact: true,
        width: "4%"
    },
    {
        name: "recipient",
        selector: (row) => row?.recipient ?? "",
        compact: true,
        width: "10%",
        style: { padding: "0em 1em" }
    },
    {
        name: "status",
        selector: (row) => row?.status ?? "",
    },
];

export default function UserTransactions({ userId }) {
    console.log(userId);
    const [tab, setTab] = useState("deposits");
    const [transactions, setTransactions] = useState({ deposits: [], withdraws: [] });

    const onDepositsSelect = () => {
        setTab("deposits");
    }

    const onWithdrawsSelect = () => {
        setTab("withdraws");
    }


    useEffect(() => {
        if (userId) {
            adminAPIInstance.getUserTransactions(userId).then(res => {
                setTransactions({ deposits: res.data.user_transactions.deposits, withdraws: res.data.user_transactions.withdraws });
            }).catch(e => {
                console.error(e);
            })
        }
    }, [userId]);

    return (
        <div>
            <div className='info-selector'>
                <div className={tab === "deposits" ? "selected" : ""} onClick={onDepositsSelect}>Deposits</div>
                <div className={tab === "withdraws" ? "selected" : ""} onClick={onWithdrawsSelect}>Withdraws</div>
            </div>
            <div className="w-100">
                <DataTable
                    title={tab}
                    columns={tab === "deposits" ? UserDepositColumns : UserWthdrawColumns}
                    data={tab === "deposits" ? transactions.deposits : transactions.withdraws}
                    defaultSortField="id"
                    selectableRows={false}
                    highlightOnHover={true}
                    pagination
                />
            </div>
        </div>
    )
}
