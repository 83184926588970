import React from "react";
import CharacterChatBox from "../../Component/App/ChatBox/CharacterChatBox";
import ClientChatBox from "../../Component/App/ChatBox/ClientChatBox";
import GroupChatBox from "../../Component/App/ChatBox/GroupChatBox";
import UserChatBox from "../../Component/App/ChatBox/UserChatBox";

export default function ChatBoxSwitcher({
    selectedType,
    isGroup,
    user,
    selectedId,
    toggleDrawer,
    status,
    selectedUserId,
    currentGroupChat,
    setCurrentGroupChat,
    activeChat,
    loading
}) {
    return (
        <>
            {selectedType === "ai" && !isGroup ? (
                <CharacterChatBox
                    user={user}
                    chatId={selectedId}
                    toggleDrawer={toggleDrawer}
                    status={status}
                    selectedType={selectedType}
                    selectedUserId={selectedUserId}
                />
            ) : selectedType === "client" && !isGroup ? (
                <ClientChatBox
                    user={user}
                    chatId={selectedId}
                    toggleDrawer={toggleDrawer}
                    status={status}
                    selectedType={selectedType}
                    selectedUserId={selectedUserId}
                />
            ) : selectedType == "ai" && isGroup ? (
                <GroupChatBox
                    user={user}
                    chatId={selectedId}
                    toggleDrawer={toggleDrawer}
                    status={status}
                    selectedType={selectedType}
                    selectedUserId={selectedUserId}
                    currentGroupChat={currentGroupChat}
                    setCurrentGroupChat={setCurrentGroupChat}
                />
            ) : (
                activeChat && (
                    <UserChatBox
                        user={user}
                        chatId={selectedId}
                        toggleDrawer={toggleDrawer}
                        activeChat={activeChat}
                        loading={loading}
                    />
                )
            )}
        </>
    );
}
