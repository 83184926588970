import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { theme } from "../../../utils/config";
import { getCouponImageUrl, getMerchantImageUrl } from "../../../utils/images";
import "./coupons_list.css";
import { Button, Carousel } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router";

const CouponsList = ({ coupons, navigateToAddCoupon, navigateUpdateCouponScreen }) => {
    const merchant = useSelector((state) => state.merchant.currentUserMerchant);
    const navigate = useNavigate();

    const { t } = useTranslation("store_and_coupon");

    return (
        <div className="card card-body ">
            <div className="d-flex justify-content-between mb-3">
                <div className="flex-row d-flex align-items-center gap-2 ">
                    <h6>
                        Coupons <span>({coupons?.length || 0})</span>
                    </h6>
                    {/* <Button
                        variant="outline"
                        onClick={() => {
                          navigate("/my-store/my-coupons")
                        }}
                        className="d-flex align-items-center text-primary"
                    >
                        See all // TODO: Create separate page to show all in a vertical view + search bar in the future
                    </Button> */}
                </div>

                {navigateToAddCoupon && (
                    <div className="addCouponContainer">
                        <Button variant="primary" onClick={navigateToAddCoupon} className="d-flex align-items-center">
                            <MdAdd size={18} className="me-2" />
                            {t("Add_Coupon_to_Sell")}
                        </Button>
                    </div>
                )}
            </div>

            <div>
                <div className="" style={{ overflowX: "auto", overflowY: "hidden" }}>
                    <div className="d-flex flex-row gap-3 align-items-center">
                        {coupons?.map((coupon) => (
                            <div key={coupon.id} className="rounded pointer clickable-card border" style={{ width: "220px" }}>
                                <Carousel slide indicators={false}>
                                    {coupon.couponPhotos?.map((image, index) => (
                                        <Carousel.Item className="" key={"img" + index}>
                                            <div
                                                className="coupon_image_container"
                                                onClick={() => navigateUpdateCouponScreen(coupon)}
                                            >
                                                <img src={getCouponImageUrl(image)} alt={coupon.couponName} />
                                            </div>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                                <div className="p-2" onClick={() => navigateUpdateCouponScreen(coupon)}>
                                    <p className="mb-0 fw-medium">{coupon.couponName}</p>
                                    <small className="mb-0 fw-medium text-muted">Quantity: {coupon.quantity}</small>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CouponsList;
