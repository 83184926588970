export function formatDate(date) {
	const dateOptions = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
    hour: "numeric",
    minute: "numeric",

	}

	return new Date(date * 1000)
		.toLocaleDateString('en-US', dateOptions)
		.toString()
}