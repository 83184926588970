import React from 'react'
import { NO_IMAGE, getAiCharacterImageUrl, getAiCharacterImageUrlV2 } from '../../../../utils/images';
import Avatar from '../../../../Component/Comman/Avatar';
import { useNavigate } from 'react-router';
import CharacterCardV2 from '../../../../Component/Explore/CharacterCard';

export default function CreatedCharacters({characters = []}) {

    const navigate = useNavigate();

    const handleCharacterClick = (ch) => {
        navigate(`/character/${ch._id}`);
    };

    return (
        <div className="d-flex flex-row flex-wrap gap-4 p-2">
            {characters.map((ch) => (
                <CharacterCardV2
                key={ch?._id}
                onClick={() => handleCharacterClick(ch)}
                id={ch?._id}
                title={ch?.characterName}
                creatorName={ch?.createdBy?.username}
                description={ch?.description}
                itemUrl={getAiCharacterImageUrlV2(ch)}
                />
            ))}
        </div>
    );
}

export function CharacterCard({ characterInfo = {}, containerStyle = {}}) {

    const navigate = useNavigate();

    const handleCharacterClick = () => {
        navigate(`/character/${characterInfo._id}`);
    };

    return (
        <div className="character-card-container"
        key={`character_+${characterInfo?._id}`}
         style={containerStyle} onClick={handleCharacterClick}>
            <div className="character-card-cover">
                <img src={NO_IMAGE} alt="creator-cover" />
                <div className="character-card-avatar">
                    <Avatar
                        name={characterInfo?.characterName}
                        avatarUrl={getAiCharacterImageUrl(characterInfo?.avatarUrl)}
                    />
                </div>
            </div>
            <div className="character-card-info">
                <h4>{characterInfo?.characterName}</h4>
                <span>{characterInfo?.price ? `${characterInfo?.price} ${characterInfo?.currency}` : "Free"}</span>
                <p>{characterInfo?.description}</p>
            </div>
        </div>
    );
  }