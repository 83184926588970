import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { adminAPIInstance } from "../../apis/AdminAPI";
import { Modal } from "../../Component/Modal/Modal";
import "./admin.css";
import UserInfo from "./UserInfo/UserInfo";
import { Tab, Nav } from "react-bootstrap";
import { PayoutsTable } from "./Payouts/Payouts";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import { useNavigate } from "react-router";

const UserColumns = (changeBanStatus) => [
    {
        name: "username",
        selector: (row) => row?.username
    },
    {
        name: "email",
        selector: (row) => row?.email
    },
    {
        name: "ipAddress",
        selector: (row) => row?.ipAddress
    },
    {
        name: "fingerPrint",
        selector: (row) => row?.fingerPrint
    },
    {
        name: "Action",
        cell: (row) => {
            return (
                <button
                    className="btn flex-fill btn-light-warning fs-5 text-uppercase px-5"
                    onClick={() => {
                        changeBanStatus(row._id, row?.banStatus ?? false);
                    }}
                >
                    {row?.banStatus ? "UnBan" : "Ban"}
                </button>
            );
        }
    }
];

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "60%"
    }
};

export default function Admin() {
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();

    useEffect(() => {
        if(user?.role !== "ADMIN"){
            navigate('/sign-in');
        }
        adminAPIInstance
            .getAllUsers()
            .then((data) => {
                setUsers(data.data.users);
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    const onRowSelected = (user) => {
        setSelectedUserId(user._id);
        setModalOpen(true);
    };

    const changeBanStatus = (userId, status) => {
        adminAPIInstance
            .updateUserBanStatus(userId, !status)
            .then((data) => {
                alert("Updated.");
            })
            .catch((e) => {
                console.error(e);
                alert("Could not update.");
            });
    };

    return (
        <div className="px-lg-4 px-md-4">
            <div className="container-xxl">
                <div className="row g-3 mb-3">
                    <div className="col-md-12">
                        <Tab.Container defaultActiveKey="users">
                            <div className="card-header pb-3 d-flex justify-content-between bg-transparent align-items-center flex-wrap">
                                <Nav className="nav nav-pills rounded d-inline-flex mt-2 mt-md-0 rounded-0" role="tablist">
                                    <Nav.Item className="nav-item">
                                        <Nav.Link
                                            eventKey="users"
                                            className="nav-link "
                                            data-bs-toggle="tab"
                                            role="tab"
                                            aria-selected="true"
                                        >
                                            Users
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link
                                            eventKey="payouts"
                                            className="nav-link"
                                            data-bs-toggle="tab"
                                            role="tab"
                                            aria-selected="false"
                                        >
                                            Payouts
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="card-body">
                                <Tab.Content className="tab-content">
                                    <Tab.Pane className="tab-pane fade  show" id="users" eventKey="users">
                                        <div className="px-lg-4 px-md-4">
                                            <h4>Registered Users</h4>
                                            <div className="w-100">
                                                <DataTable
                                                    title=""
                                                    columns={UserColumns(changeBanStatus)}
                                                    data={users}
                                                    defaultSortField="title"
                                                    selectableRows={false}
                                                    highlightOnHover={true}
                                                    onRowClicked={onRowSelected}
                                                    pagination={true}
                                                />
                                            </div>
                                            <Modal
                                                styles={customStyles}
                                                modalIsOpen={modalOpen}
                                                setIsOpen={setModalOpen}
                                                body={<UserInfo userId={selectedUserId} />}
                                            />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane className="tab-pane fade" id="payouts" eventKey="payouts">
                                        <PayoutsTable/>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
    );
}
