import React from "react";

export default function ExpressionsFormattedMessage({ message = "" }) {
    const lines = message.split("\n").filter(line => line.trim() !== "");

    return (
        <>
            {lines.map((line, index) => {
                const parts = line.split(/(\*[^*]*\*)/).filter(part => part.trim() !== "");

                return (
                    <div key={index}>
                        {parts.map((part, partIndex) => {
                        if (part.startsWith("*") && part.endsWith("*")) {
                            const highlightedText = part;
                            return (
                            <span style={{ color: "yellow" }} key={partIndex}>
                                {partIndex !== 0 && <br />}
                                {highlightedText}
                                {partIndex !== parts.length-1 && <br />}
                                <br />
                            </span>
                            );
                        } else {
                            return (
                            <span style={{ color: "white" }} key={partIndex}>
                                {part}
                                <br />
                            </span>
                            );
                        }
                        })}
                        {index !== lines.length - 1 && <br />}
                    </div>
                );
            })}
        </>
  );
}
