import React, { useState } from "react";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import validator from "validator";
import { Modal } from '../../Component/Modal/Modal';
import { authAPIInstance } from '../../apis/AuthAPI';
import AuthRight from "./AuthRight";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "45vh",
        background: "radial-gradient(circle, rgba(14, 14, 14, 1) 60%, rgba(26, 26, 26, 1) 100%)",
        borderColor: "transparent",
        borderRadius: "12px",
        height: "40vh",
        overflowY: "auto"
    },
    overlay: {
        backgroundColor: "rgba(4, 4, 35, .5)",
        backdropFilter: "blur(3px)"
    }
};

function ForgotPassword() {

    const [email, setEmail] = useState("");
    const {t} = useTranslation('forget_password_page');
    const navigate = useNavigate();
    const [otpModal, setOtpModal] = useState(false);

    const toggleOtpModal = () => {
        setOtpModal(!otpModal);
    };
    const onEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
    }

    const onSubmit = async () => {
        try {
            if (!validator.isEmail(email)) {
                alert("Email is not valid.");
                return;
            }
            await authAPIInstance.requestResetPassword(email);
            alert("A link has been sent to your email to reset password.");
            navigate("/reset_password");
        } catch (e) {
            console.error(e);
            alert(e?.response?.data?.message ?? e?.response?.data?.error ?? "Request failed");
        }
    }

    return (
        <>
            <div className="body d-flex p-0 p-xl-2 justify-content-center align-items-center">
                <div className="d-flex align-items-center flex-column -3">
                    <h1>{t("forgotPassword")}</h1>
                    <span className="text-muted w-75 p-3 mx-auto text-center">
                        {t("resetMessage")}
                    </span>
                </div>
                <div className="container sign-up-container mt-5">
                    <div className="row g-3">
                        <div className="d-flex justify-content-center align-items-center"> 
                            <div className="d-flex flex-column auth-form-container">
                                <Tab.Container defaultActiveKey="first">
                                    <Nav className="nav nav nav-pills mt-4" role="tablist">
                                        <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey="first" href="#!">{t("email")}</Nav.Link></Nav.Item>
                                        {/* <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey="second" href="#!">{t("mobile")}</Nav.Link></Nav.Item> */}
                                    </Nav>
                                    <Tab.Content className="tab-content mt-4 mb-3">
                                        <Tab.Pane className="tab-pane fade" id="Email" eventKey="first">
                                            <div className="card">
                                                <div className="card-body sign-up-card-body px-0 py-2">
                                                        <div className="mb-3 email-input-container position-relative">
                                                            <label className="form-label fs-6">{t("email")}</label>
                                                            <input value={email} onChange={onEmailChange} type="email" className="form-control" placeholder="Enter your email address"/>
                                                        </div>
                                                        <button onClick={onSubmit} className="btn orange-gradient-btn  py-2 fs-7 w-100 mt-2">{t("submit")}</button>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        {/* <Tab.Pane className="tab-pane fade" id="Mobile" eventKey="second">
                                            <div className="card">
                                                <div className="card-body sign-up-card-body px-0 py-2">
                                                    <form>
                                                        <label className="form-label fs-6">{t("mobile")}</label>
                                                        <Dropdown className="input-group mb-3">
                                                            <Dropdown.Toggle className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ zIndex: 0 }}>+91 India</Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu">
                                                                <li><Dropdown.Item className="dropdown-item">+376 Andora</Dropdown.Item></li>
                                                                <li><Dropdown.Item className="dropdown-item">+61 Australia</Dropdown.Item></li>
                                                                <li><Dropdown.Item className="dropdown-item">+55 Brazil</Dropdown.Item></li>
                                                            </Dropdown.Menu>
                                                            <input type="text" className="form-control" />
                                                        </Dropdown>
                                                        
                                                        <button
                                                            onClick={toggleOtpModal}
                                                            className="btn orange-gradient-btn  py-2 fs-7 w-100 mt-2"
                                                        >
                                                            {t("next")}
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </Tab.Pane> */}
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                        <AuthRight />
                        <Modal
                        setIsOpen={setOtpModal}
                        modalIsOpen={otpModal}
                        showClose={false}
                        styles={customStyles}
                        body={
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                <h4>Enter OTP</h4>
                                <span className="text-muted">We sent a verification code to your email or phone. <br />Enter the code from the field below.</span>
                                <div className="card mt-4 mb-3" style={{ maxWidth: '30rem' }} >
                                    <div className="card-body otp-card-body p-4">
                                        <form className="row g-4">
                                            <div className="col">
                                                <div className="mb-2">
                                                    <input type="text" className="form-control form-control-lg text-center"/>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mb-2">
                                                    <input type="text" className="form-control form-control-lg text-center"/>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mb-2">
                                                    <input type="text" className="form-control form-control-lg text-center"/>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mb-2">
                                                    <input type="text" className="form-control form-control-lg text-center"/>
                                                </div>
                                            </div>
                                            <div className="col-12 text-center mt-4">
                                                <button type="submit" className="btn orange-gradient-btn  py-2 fs-7 w-100 mt-2">Verify</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <Link to={process.env.PUBLIC_URL+"#!"} title="#" className="orange-red-gradient-text text-decoration-none">Resend code</Link>
                            </div>
                        }>
                    </Modal>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column gap-3 mt-3">
                    <Link
                        to={process.env.PUBLIC_URL + "/sign-in"}
                        title="#"
                        className="orange-red-gradient-text text-decoration-none"
                    >
                        {t("logIn")}
                    </Link>
                    <Link
                        to={process.env.PUBLIC_URL + "/sign-up"}
                        title="#"
                        className="orange-red-gradient-text text-decoration-none"
                    >
                        {t("registerNow")}
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword