import React, { useCallback, useEffect } from "react";
import { Button, Card, Col, Container, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCurrentUserMerchant, getCurrentUserMerchantCoupons } from "../../Redux/Actions/MerchantActions";
import { readCookie } from "../../utils/cookie";
import { getStoreDisplayableImage } from "../../utils/images";
import PageTitle from "../../Component/Comman/PageTitle";
import Avatar from "../../Component/Comman/Avatar";
import { IoLocationOutline } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import CouponsList from "./CouponsList/CouponsList";
import MerchantHeader from "../../Component/Merchant/MerchantHeader";

const StoreScreen = (props) => {
    const dispatch = useDispatch();
    const token = readCookie("token");
    const merchant = useSelector((state) => {
        return state?.merchant?.currentUserMerchant;
    });

    const coupons = useSelector((state) => {
        return state?.merchant?.currentUserMerchantCoupons;
    });

    const navigate = useNavigate();
    const { t } = useTranslation("merchant_store");

    const getMerchant = useCallback(async () => {
        dispatch(getCurrentUserMerchant(token));
    }, [dispatch, token]);

    const getMerchantCoupons = useCallback(async () => {
        if (merchant?._id) {
            dispatch(getCurrentUserMerchantCoupons(merchant._id, token));
        }
    }, [dispatch, merchant?._id, token]);

    useEffect(() => {
        getMerchant();
        getMerchantCoupons();
    }, [getMerchant, getMerchantCoupons]);

    const navigateTo = (screen) => {
        navigate(screen);
    };

    const storeActions = [
        {
            key: "send-points",
            label: t("Send_Points"),
            imageSrc: require("../../assets/icons/send-points-v2.png")
        },
        /* {
            key: "viewMyQrCodeForCheckin",
            label: t("View_QR_Checkin"),
            imageSrc: require("../../assets/icons/qr.png")
        }, */
        {
            key: "myStoreStatistics",
            label: t("Store_Statistics"),
            imageSrc: require("../../assets/icons/store_stats.png")
        },
        /* {
            key: "myStoreLocation",
            label: t("Store_Location"),
            imageSrc: require("../../assets/icons/store_location.png")
        }, */
        
        /* {
            key: "publicViewCheckin",
            label: t("Customer_Checkin_View"),
            imageSrc: require("../../assets/icons/qr.png")
        } */
    ];

    return (
        <div className="container-xxl px-4">
            {merchant ? (
                <div className="gap-3 flex-column d-flex">
                    <MerchantHeader merchant={merchant} isPublic={false}/>
                   
                    <CouponsList
                        navigateToAddCoupon={() => {
                            navigate("/my-store/add-coupon", {
                                state: { merchantUUID: merchant._id, isAddingMerchant: false }
                            });
                        }}
                        navigateUpdateCouponScreen={(updatingCoupon) => {
                            navigate("/my-store/edit-coupon", {
                                state: {
                                    merchantUUID: merchant._id,
                                    isAddingMerchant: false,
                                    updatingCoupon
                                }
                            });
                        }}
                        coupons={coupons !== null ? coupons : []}
                    />

                    <div className="row">
                        {storeActions.map((item) => (
                            <div className="col-6 col-md-4 col-lg-2 d-flex" key={item.key}>
                                <Card
                                    className="clickable-card mb-3 text-center justify-content-start d-flex align-items-center"
                                    onClick={() => {
                                        navigateTo(item.key);
                                    }}
                                    style={{ cursor: "pointer", width: "100%", background: "#FFF7E9" }}
                                >
                                    <Card.Header style={{ minHeight: 150, maxHeight: 180 }}>
                                        <Card.Img
                                            src={item.imageSrc}
                                            className="p-4"
                                            style={{ minHeight: 100, maxHeight: 150 }}
                                        />
                                    </Card.Header>
                                    <Card.Body style={{ backgroundColor: "white", width: "100%" }}>
                                        <Card.Text>{item.label}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="text-center">
                    <h3 className="mb-3">{t("Oops")}!</h3>
                    <p className="text-muted mb-4">{t("Empty_Store")}</p>
                    <Button variant="primary" className="mb-4" onClick={() => navigateTo("create")}>
                        {t("Start_Store")}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default StoreScreen;
