import React from "react";
import ReactStars from "react-rating-stars-component";
import { NO_IMAGE } from "../../../utils/images";

const mock_data = [
    { rating: 4.2, description: "mock comment from db" },
    { rating: 4, description: "mock comment from db" },
    { rating: 5, description: "mock comment from db" },
    { rating: 2.5, description: "mock comment from db" }
];

export default function Reviews({ reviews = mock_data }) {
    return (
        <div>
            {mock_data.map((review) => (
                <div className="review-container">
                    <div className="review-avatar-container">
                        <img src={NO_IMAGE} alt="review_user_profile"/>
                    </div>
                    <div>
                        <span>username</span>
                        <ReactStars value={review.rating} count={5} />
                        <p>{review.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}
