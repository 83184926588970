import { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Avatar from "../../../Component/Comman/Avatar";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import { getUserImageUrl } from "../../../utils/images";
import { MessageReactions } from "./MessageReaction";
import { AIApiInstance } from "../../../apis/AIAPI";

export const MessageComments = ({ message, allowComments = false }) => {
    const currentUser = useSelector((state) => state?.AuthReducer?.user);
    const [inputMessage, setInputMessage] = useState("");
    const [isCommentSubmitting, setCommentSubmitting] = useState(false);
    const [comments, setComments] = useState(message?.comments || []);

    const handlePostComment = () => {
        if (!inputMessage.trim()) {
            return;
        }
        try {
            setCommentSubmitting(true);
            AIApiInstance.createCommentAIMessage({
                characterId: message?.customAIModelId ?? "",
                messageId: message?._id,
                commentText: inputMessage
            });
            const newComment = {
                comment: {
                    createdAt: new Date().getTime(),
                    commentText: inputMessage
                },
                userInfo: {
                    avatar_url: currentUser?.avatar_url,
                    username: currentUser?.username
                }
            };
            setComments((prevState) => {
                return [...prevState, newComment];
            });
        } catch (error) {
        } finally {
            setInputMessage("");
            setCommentSubmitting(false);
        }
    };

    return (
        <div className="d-flex flex-column gap-3 public-message-comments">
            <span className="comment-message-time">{formatDate(message?.timeStamp)}</span>
            <p className="mb-0" style={{ wordBreak: "break-all" }}>
                {message?.messageContent?.encrypted_text ? message?.messageContent?.encrypted_text : message?.messageContent}
            </p>
            <MessageReactions message={message} />
            <div className="d-flex flex-column gap-2 public-message-comments-container">
                {comments.length > 0 ? (
                    comments.map((comment, i) => <Comment key={comment?._id ?? `comment-${i}`} comment={comment} />)
                ) : (
                    <div className="text-light py-2">No Comments</div>
                )}
                {allowComments && (
                    <div class="input-group mt-3">
                        <input
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            type="text"
                            className="form-control"
                            placeholder="Enter your comment here"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                        <Button
                            disabled={isCommentSubmitting}
                            onClick={handlePostComment}
                            className="input-group-append d-flex px-3 justify-content-center align-items-center"
                        >
                            <i className="fa fa-paper-plane"></i>
                            <p className="mb-0 ms-2">Send</p>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const Comment = ({ comment }) => {
    return (
        <div className="d-flex flex-column gap-2 comment px-3">
            <div className="d-flex flex-row gap-2 align-items-center">
                <Avatar
                    className="comment-avatar"
                    name={comment?.user?.username}
                    avatarUrl={getUserImageUrl(comment?.user?.username?.avatar_url)}
                />
                <span>{comment?.user?.username}</span>
                <span className="comment-time">{formatDate(comment?.comment?.createdAt)}</span>
            </div>
            <p className="mb-0">{comment?.comment?.commentText}</p>
        </div>
    );
};
