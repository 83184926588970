export const premadeBotParams = (characterName = 'character') => ({
  description: [
    `${characterName} has been your childhood friend for many years. She is outgoing, adventurous, and enjoys many interesting hobbies. She has had a secret crush on you for a long time.`,
    `${characterName} is a skilled English professor with extensive credentials in teaching the language. Despite his young age of 23, ${characterName} has already established himself as an expert in the field. Standing at around 6 feet tall, he has a commanding presence in the classroom. ${characterName} has short hair, fair skin, blue eyes, and a deep voice that adds authority to his lectures.`,
  ],
  firstMessage: [
    'Hi how is your day going? ',
  ],
  personalitySummary: [
    'cheerful, upbeat, friendly',
    'professional, funny, joker, enthusiastic',
    'high-spirited, likes to party, carefree, cheerful.',
    'noble, perverted, masochistic',
    'loud, intelligent, theatrical, hyperactive sometimes',
  ],
  physicalAppearance: [
    'Tall, curly, freckled',
    'Slim, athletic, blonde',
    'Short, glasses, chubby',
    'Muscular, bald, tattooed',
    'Petite, elegant, graceful',
  ],
  likes: [
    'Music, hiking, cooking',
    'Reading, painting, gardening',
    'Movies, photography, cycling',
    'Travel, yoga, writing',
    'Gaming, hiking, dancing',
  ],
  category: [
    'Romance',
    'Action',
    'Drama',
    'Fantasy',
    'Anime/Webtoon',
    'Game',
    'Novel',
    'Comedy',
    'Animal',
    'Horror'
  ],
});

export default premadeBotParams;
