import React, { useState } from 'react';
import "./userInfo.css";
import UserSessions from '../UserSessions/UserSessions';
import UserTransactions from '../UserTransactions/UserTransactions';

export default function UserInfo({ userId }) {
    console.log(userId);
    const [tab, setTab] = useState("sessions");

    const onSessionsSelect = () => {
        setTab("sessions");
    }

    const onTransactionsSelect = () => {
        setTab("transactions");
    }

    return (
        <div>
            <div className='info-selector'>
                <div className={tab === "sessions" ? "selected" : ""} onClick={onSessionsSelect}>Sessions</div>
                <div className={tab === "transactions" ? "selected" : ""} onClick={onTransactionsSelect}>Transactions</div>
            </div>
            {tab === "sessions" ? <UserSessions userId={userId}/> : <UserTransactions userId={userId}/>}
        </div>
    )
}
