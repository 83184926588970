import React from "react";
import { useNavigate } from "react-router";
import { NO_IMAGE, WHITE_IMAGE, getAiCharacterImageUrlV2 } from "../../utils/images";
import CharacterCardV2 from "./CharacterCard";
import './index.css';

export function CharacterCard() {
  return (
      <div className="character-card-container">
          <div className="character-card-cover">
              <img src={NO_IMAGE} alt="creator-cover" />
              <div className="character-card-avatar">
                  <img src={WHITE_IMAGE} alt="creator-avatar" />
              </div>
          </div>
          <div className="character-card-info">
              <h4>username</h4>
              <span>300k followers</span>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus enim nunc mus.</p>
              <div className="character-card-social-container">
                  <div className="character-card-social">
                      <img src={WHITE_IMAGE} alt="social" />
                  </div>
                  <div className="character-card-social">
                      <img src={WHITE_IMAGE} alt="social" />
                  </div>
                  <div className="character-card-social">
                      <img src={WHITE_IMAGE} alt="social" />
                  </div>
              </div>
          </div>
      </div>
  );
}

export default function TopCharacters({ characters = [] }) {
    const navigate = useNavigate();

    //when click send to http://localhost:3000/chat?type=ai&id=6483fb0c2cdb197074dabde3
    const handleAiCharacterClick = (_id) => {
        //navigate(`/chat?type=ai&id=${_id}`);
        navigate(`/character/${_id}`);
    };

    return (
        <div>
            <div className="d-flex flex-row flex-wrap gap-4 justify-content-around">
                {characters.length > 0 &&
                    characters.map((character, i) => {
                        return (
                            <CharacterCardV2
                                key={character._id}
                                onClick={handleAiCharacterClick}
                                id={character._id}
                                title={character.characterName}
                                creatorName={character?.createdBy?.username}
                                description={character.description}
                                itemUrl={getAiCharacterImageUrlV2(character)}
                                messagesCount={character?.messagesCount}
                            />
                        );
                    })}
            </div>
        </div>
    );
}
