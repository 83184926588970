import React, { memo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import { config } from "../../utils/config";

export const stripePromise = loadStripe(
    config[config.environment].stripePublicKey
);

function StripeCheckout({ clientSecret, onPaymentSucessCallback }) {
    const appearance = {
        theme: "stripe"
    };
    const options = {
        clientSecret,
        appearance
    };

    return (
        <div className="App">
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm clientSecret={clientSecret} onPaymentSucessCallback={onPaymentSucessCallback}/>
                </Elements>
            )}
        </div>
    );
}

export default memo(StripeCheckout)
