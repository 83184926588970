import React from "react";


function ComingSoon() {
    return (
        <div
        >
            <h1>Coming Soon</h1>
        </div>
    );
}

export default ComingSoon;
