import { addMessageToChat, modifyChatPinnedMessage, setMessagesOfChat } from "../../Redux/Reducers/ChatReducer";
import { socketConstants } from "./index";

export const handleIncomingMessage = (event , dispatch, setMessages, setStatus, userId, chatId, messages) => {
    //const dispatch = useDispatch();

    let _data = JSON.parse(event.data);
    console.log("_data.type: ", _data.type);
                //const _data = JSON.parse(event.data);
                if (_data.type === socketConstants.CLIENT.CHAT.TYPING) {
                    // message is received by recipient
                    if (_data.payload?.message_from === chatId) {
                        setStatus(_data.type);
                    }

                    if (_data?.payload.message_from !== userId) {
                        dispatch(
                            // if message if from others, update last message content
                            modifyChatPinnedMessage({
                                current_userId: userId,
                                recipient_id: chatId,
                                message: "typing...",
                                timeStamp: _data.payload.created_at,
                                sent: true,
                                originalMessage: ""
                            })
                        );
                    }
                }

                if (_data.type === socketConstants.CLIENT.CHAT.STOP_TYPING) {
                    // message is received by recipient
                    if (_data.payload.message_from === chatId) {
                        setStatus(_data.type);
                    }

                    if (_data.payload.message_from !== userId) {
                        dispatch(
                            // if message if from others, update last message content to be the latest chat
                            modifyChatPinnedMessage({
                                current_userId: userId,
                                recipient_id: chatId,
                                message: messages[messages.length - 1]?.messageContent,
                                timeStamp: messages[messages.length - 1]?.timeStamp,
                                sent: true,
                                originalMessage: ""
                            })
                        );
                    }
                }

                if (_data.type === socketConstants.CLIENT.CHAT.MESSAGE) {
                    // message is received by recipient
                    if (_data.payload.message_from === chatId) {
                        setStatus(_data.type);

                        setMessages((prev) => {
                            let newMessages = [...prev];
                            newMessages.unshift({
                                sender_id: _data.payload.message_from,
                                _id: _data.payload._id,
                                timeStamp: _data.payload.created_at,
                                messageContent: _data.payload.message_content,
                                originalMessage: ""
                            });
                            return [...newMessages];
                        });
                        if (_data.payload.message_from !== userId) {
                            dispatch(
                                // if message if from others, update last message content
                                modifyChatPinnedMessage({
                                    current_userId: userId,
                                    recipient_id: chatId,
                                    message: _data.payload.message_content,
                                    timeStamp: _data.payload.created_at,
                                    sent: true,
                                    originalMessage: ""
                                })
                            );
                        }
                    }
                }

                if (_data.type === socketConstants.CLIENT.CHAT.SERVER_MESSAGE_ACKNOWLEDGEMENT) {
                    setMessages((prev) => {
                        const newMessages = [...prev];
                        const foundMessageIndex = newMessages.findIndex((_message) => {
                            return _message?._id === _data?.payload?._id;
                        });
                        if (foundMessageIndex !== -1) {
                            newMessages[foundMessageIndex]._id = _data?.payload?.message_id;
                        }
                        return [...newMessages];
                    });
                }
}