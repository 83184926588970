import jwt_decode from "jwt-decode";

export const getTokenValidity = (token) => {
    try {
        const { exp } = jwt_decode(token);
        if (exp && typeof exp !== "number") {
            return false;
        }
        const expiryTimestamp = new Date(exp * 1000).getTime();
        const currentTimeStamp = new Date().getTime();
        let difference = expiryTimestamp - currentTimeStamp;
        difference = (difference / 1000) / 3600;

        if (difference >= 1) {
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
}