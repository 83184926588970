import React from "react";
import { Card } from "react-bootstrap";
import Avatar from "../Comman/Avatar";

export default function CharacterCardV2({
    onClick = () => {},
    id = "",
    title = "",
    creatorName = "",
    description = "",
    onSubtitleClick = () => {},
    itemUrl = "",
    messagesCount = ""
}) {
    return (
        <Card className="d-flex flex-column character-card-container my-0 mx-2" key={id} onClick={() => onClick(id)}>
            <div className="d-flex flex-column align-items-start pointer">
                <div className="d-flex flex-row p-3 align-items-center">
                    <div className="p-2 align-items-center">
                        <Avatar
                            name={title}
                            avatarUrl={itemUrl}
                            className="lg pointer character-card-avatar-lg"
                        />
                    </div>
                    <div className="p-2 align-items-center">
                        <h6 className="mb-0 clickable-list-text">{title}</h6>
                        <div>
                            <div className="d-flex">
                                <p className="pointer mb-0 mt-1 clickable-list-text character-card-desc-p" onClick={onSubtitleClick}>
                                    {description.length > 50 ? `${description.slice(0, 50)}...` : description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <hr className="m-0"></hr>
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center px-3 py-2">
                        <p className="pointer character-card-details m-0">@{creatorName}</p>
                        <div className="d-flex flex-row align-items-center character-card-details">
                            <i className="icofont-speech-comments px-2"></i>
                            <p className="pointer character-card-details m-0">{messagesCount}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}
