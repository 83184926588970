import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Spinner } from "react-bootstrap";
import { BiCheckCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addMessageToChat, modifyChatPinnedMessage, setMessagesOfChat } from "../../../Redux/Reducers/ChatReducer";
import { ChatApiInstance } from "../../../apis/ChatAPI";
import { socketConstants } from "../../../apis/SocketApis";
import { NewSocketService } from "../../../apis/SocketApis/NewSocketService";
import { socketCheckerInterval } from "../../../apis/SocketApis/SocketChecker";
import { handleIncomingMessage } from "../../../apis/SocketApis/UserMessageHelper";
import { readCookie } from "../../../utils/cookie";
import { eventActions, eventCategories, sendFormActionEvent } from '../../../utils/googleAnalytics';
import { getMerchantImageUrl, getUserImageUrl } from "../../../utils/images";
import Avatar from "../../Comman/Avatar";
import "./chatbox.css";

dayjs.extend(relativeTime);

function UserChatBox({ chatId, toggleDrawer, user, activeChat, parentLoading }) {
    const [messages, setMessages] = useState([]);
    const [avatar, setAvatar] = useState("");
    const [chatName, setChatName] = useState("");
    const [userUrlAddress, setUserUrlAddress] = useState("");
    const [message, setMessage] = useState("");
    const chats = useSelector((state) => state.chat.chats);
    let { sendMessage, lastMessage, readyState, getWebSocket } = NewSocketService("UserChatBox");
    
    let ws = getWebSocket();
    
    let newChat = true; // currently used when the chat is a new chat in chainclip. To be refactored later once the usecase arise.
    const dispatch = useDispatch();
    if(ws) {
        socketCheckerInterval(ws, dispatch, "UserChatBox");
    }
    const connectionStatus = useSelector((state) => state.chat.socketConnected);
    const bottomRef = useRef(null);
    const [loading, setLoading] = useState(parentLoading);
    const [status, setStatus] = useState("online");
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    // const isMerchant = searchParams.get("isMerchant") ?? false;
    const token = readCookie("token");
    function onChangeMessage(e) {
        setMessage(e.currentTarget.value);

        const data = {
            type: socketConstants.CLIENT.CHAT.TYPING,
            message_to: chatId
        };
        ws.send(JSON.stringify(data));
    }

    function handleOnBlur() {
        const data = {
            type: socketConstants.CLIENT.CHAT.STOP_TYPING,
            message_to: chatId
        };
        ws.send(JSON.stringify(data));
    }

    function handleFocus() {
        if (message !== "") {
            const data = {
                type: socketConstants.CLIENT.CHAT.TYPING,
                message_to: chatId
            };
            ws.send(JSON.stringify(data));
        }
    }
     
    useEffect(() => {
        if (chatId) {
            setLoading(true);
            ChatApiInstance.getMessagesByRecipientId(chatId, 1)
                .then(async (res) => {
                    const _messages = res?.data?.data?.messages.map((_message) => {
                        return { ..._message, originalMessage: "" };
                    });

                    setAvatar(activeChat.recipient_avatar);
                    setChatName(activeChat.chatName);
                    // setUserUrlAddress(activeChat.userUrlAddress);
                    setMessages([..._messages]);

                    dispatch(
                        setMessagesOfChat({
                            recipient_id: chatId,
                            messages: _messages
                        })
                    );
                    setLoading(false);
                })
                .catch((e) => {
                    setLoading(false);
                    console.error(e);
                });
        }
    }, [dispatch,ws, newChat, user?.userId, chatId]);

    //do not use UseEffect with getWebSocket(), must be outside for recconect when server down
    if(ws) {
        console.log("ws.onmessage event: ", ws.readyState);
        ws.onmessage = (event) => {
            handleIncomingMessage(event , dispatch, setMessages, setStatus, user?.userId, chatId, messages);
        };
    } 

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages, message]);

    const generateRandom = () => {
        return Math.floor(Math.random() * 900000) + 100000;
    };

    const generateRandomId = () => {
        return Math.ceil(new Date().getTime() / 1000)
            .toString()
            .concat(generateRandom().toString());
    };

    const handleAvatarClick = () => {
        if(activeChat.userUrlAddress){
            navigate(`/@${activeChat.userUrlAddress}`);
        }
        else{
            navigate(`/@${activeChat.chatName}`);
        }
    };

    const handleSendMessage = async () => {
        try {
            const newMessageId = generateRandomId();
            const data = {
                type: socketConstants.CLIENT.CHAT.MESSAGE,
                message_to: chatId,
                messageContent: message,
                messageId: newMessageId
            };
            const _message = message;
            if (ws && ws.readyState === 1) {
                ws.send(JSON.stringify(data));
            } else {
                alert("Your Internet is not connected");
                console.log("socket readyState: ", ws.readyState);
            }
            if (chatId !== user.userId) {
                setMessages((prev) => {
                    // shows the latest message in the sender side
                    let newMessages = [...prev];

                    newMessages.unshift({
                        sender_id: user?.userId,
                        _id: newMessageId,
                        timeStamp: Math.ceil(new Date().getTime() / 1000),
                        messageContent: _message,
                        originalMessage: ""
                    });

                    return [...newMessages];
                });

                dispatch(
                    addMessageToChat({
                        current_userId: user?.userId,
                        recipient_id: chatId,
                        message: _message,
                        timeStamp: Math.ceil(new Date().getTime() / 1000),
                        sent: true,
                        originalMessage: "",
                        _id: newMessageId
                    })
                );
            }

            dispatch(
                modifyChatPinnedMessage({
                    // updates the last message content in the sender side
                    current_userId: user?.userId,
                    recipient_id: chatId,
                    message: "You: " + _message,
                    timeStamp: Math.ceil(new Date().getTime() / 1000),
                    sent: true,
                    originalMessage: ""
                })
            );

            setMessage("");
        } catch (error) {
            console.error(error);
        }
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.chat_user_event, message);
    };

    return (
        <>
            {loading ? (
                <div className="d-flex w-100 flex-grow-1 align-items-center justify-content-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <>
                    <div className="chat-header d-flex justify-content-between align-items-center border-bottom pb-lg-3">
                        <div className="d-flex align-items-center px-4 pt-4 pt-lg-3 ">
                            <a className="py-2 d-block d-lg-none chatlist-toggle me-3" href="#!" onClick={toggleDrawer}>
                                <i className="fa fa-bars"></i>
                            </a>

                            <Avatar
                                onClick={handleAvatarClick}
                                name={chatName}
                                avatarUrl={avatar !== "" && (avatar.includes("http") ? avatar : getUserImageUrl(avatar))}
                            />
                            <div className="ms-3">
                                <h6 className="mb-0">{chatName}</h6>
                                <div className="d-flex align-items-center">
                                    <Badge bg="success" pill className="badge border border-white rounded-circle p-1 me-1">
                                        <span className="visually-hidden">user status</span>
                                    </Badge>
                                    <small className="text-muted">{connectionStatus}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="chat-history list-unstyled mb-0 flex-grow-1 px-5 px-lg-4 py-4 py-lg-3" style={{}}>
                        {messages.length > 0 ? (
                            messages
                                .sort((a, b) => a.timeStamp - b.timeStamp)
                                .map((d, i) => {
                                    return (
                                        <li
                                            key={"s" + i}
                                            className={
                                                d.sender_id !== user?.userId
                                                    ? "mb-3 d-flex flex-row align-items-end"
                                                    : "mb-3 d-flex flex-row-reverse align-items-end"
                                            }
                                        >
                                            {d.sender_id === user?.userId && (
                                                <div className="justify-content-end d-flex ms-1">
                                                    <BiCheckCircle color={"orange"} />
                                                </div>
                                            )}
                                            <div
                                                className={`max-width-70 ${
                                                    d.sender_id !== user?.userId ? "" : "text-right"
                                                }`}
                                            >
                                                <div className="user-info mb-1">
                                                    {d.sender_id !== user?.userId ? (
                                                        <Avatar
                                                            onClick={handleAvatarClick}
                                                            name={chatName}
                                                            avatarUrl={
                                                                avatar !== "" &&
                                                                (avatar.includes("http") ? avatar : getUserImageUrl(avatar) ?? getUserImageUrl(avatar))
                                                            }
                                                            className="lg rounded-circle me-1"
                                                        />
                                                    ) : null}
                                                    <span className="text-muted small">
                                                        {dayjs(d.timeStamp * 1000).fromNow()}
                                                    </span>
                                                </div>
                                                <div
                                                    className={`card border-0 p-3 ${
                                                        d.sender_id === user?.userId ? "chat-sent-by-user" : "chat-received-by-user"
                                                    }`}
                                                >
                                                    <div className="message">{d.messageContent}</div>
                                                </div>
                                            </div>
                                            {/* <Dropdown>
                                <Dropdown.Toggle
                                    as="a"
                                    href="#!"
                                    className="nav-link py-2 px-3 text-muted pulse"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="fa fa-ellipsis-v"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu border-0 shadow">
                                    <li>
                                        <Dropdown.Item href="#!">Edit</Dropdown.Item>
                                    </li>
                                    <li>
                                        <Dropdown.Item href="#!">Share</Dropdown.Item>
                                    </li>
                                    <li>
                                        <Dropdown.Item href="#!">Delete</Dropdown.Item>
                                    </li>
                                </Dropdown.Menu>
                            </Dropdown> */}
                                        </li>
                                    );
                                })
                        ) : (
                            <div className="d-flex justify-content-center ">
                                <p className="text-muted mb-0 rounded bg-white py-2 px-3">Send your first message!</p>
                            </div>
                        )}

                        {status === socketConstants.CLIENT.CHAT.TYPING && (
                            <li className={"mb-3 d-flex flex-row align-items-end"}>
                                <div className="max-width-70">
                                    <div className="user-info mb-1">
                                        <Avatar
                                            onClick={handleAvatarClick}
                                            name={chatName}
                                            avatarUrl={
                                                avatar !== "" && (avatar.includes("http") ? avatar : getMerchantImageUrl(avatar) ?? getUserImageUrl(avatar))
                                            }
                                            className="lg rounded-circle me-1"
                                        />
                                    </div>
                                    <div className="card border-0 p-3 mt-2">
                                        <div className="message">typing...</div>
                                    </div>
                                </div>
                            </li>
                        )}

                        <div ref={bottomRef} />
                    </ul>
                    <div className="chat-input-container">
                        <textarea
                            className="chat-form-control"
                            placeholder= {"Type your message here..." }
                            value={message}
                            onChange={(e) => onChangeMessage(e)}
                            onBlur={(e) => handleOnBlur()}
                            onFocus={handleFocus}
                        ></textarea>
                        <Button
                            disabled={message === ""}
                            className="gradient-chat-menu-icon-btn"
                            variant="transparent"
                            onClick={handleSendMessage}>
                            <i className="fa-solid fa-paper-plane gradient-chat-menu-icon"></i>
                        </Button>
                    </div>
                </>
            )}
        </>
    );
}
export default UserChatBox;
