import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import { AddDealSchema } from "../schema/AddDealSchema";
import { NO_IMAGE, getCouponImageUrl, getMerchantImageUrl } from "../../../utils/images";
import { ErrorMsg } from "../../../Component/Comman/Typography";
import { IoTrash } from "react-icons/io5";
import { BiSolidImageAdd } from "react-icons/bi";
import { readCookie } from "../../../utils/cookie";
import { theme } from "../../../utils/config";
import "../index.css";

const coupon_categories = [
    { name: "restaurants", text: "Restaurants" },
    { name: "hotels", text: "Hotels" },
    { name: "plastic_surgery", text: "Plastic Surgery" },
    { name: "clinics", text: "Clinics" },
    { name: "pet_care", text: "Pet Care" },
    { name: "hair_salons", text: "Hair Salons" },
    { name: "shops", text: "Shops" },
    { name: "fitness", text: "Fitness" }
];

const initialValues = {
    brandDealName: "",
    brandDealDescription: "",
    budgetPerPost: "",
    revenuePercentShare: "",
    delieveryDate: "",
    isAdultContent: false,
    category: coupon_categories[0].name
};

const DealForm = ({ buttonContent, onSubmit, handleDeleteCoupon, isSubmitting = false, isDeleting = false }) => {
    const location = useLocation();
    const { merchantUUID, isAddingMerchant, updatingCoupon } = location.state || {};
    const formikCtx = useRef();
    const dispatch = useDispatch();
    const currentUserMerchant =  useSelector(state => state.merchant.currentUserMerchant);
    const couponImageInput = useRef(null);
    const token = readCookie("token");
    const navigate = useNavigate();
    const { t } = useTranslation("add_coupon");

    const [couponUploadImages, setCouponUploadImages] = useState(() => {
        if (updatingCoupon?.couponPhoto) {
            return [{ uri: getCouponImageUrl(updatingCoupon?.couponPhoto) }];
        } else if (updatingCoupon?.couponPhotos) {
            return updatingCoupon.couponPhotos.map((photo) => ({
                uri: getCouponImageUrl(photo)
            }));
        } else {
            return [];
        }
    });


    const handleSubmitCoupon = async (values) => {
        // const couponBody = {
        //     ...values,
        //     priceInPoints: values.priceInPoints || 0,
        //     priceInDollars: values.priceInDollars || 0,
        //     priceInBTC: values.priceInBTC || 0,
        //     quantity: values.quantity || 1,
        //     isAdultContent: toggleAdultContent,
        //     category: values.category
        // };

    //   await onSubmit({ couponBody, token, couponUploadImages });
    };

    const handleImagePicker = () => {
        couponImageInput?.current?.click();
    };

    const getInitialValues = () => {
        // if (updatingCoupon) {
        //     return {
        //         ...initialValues,
        //         couponName: updatingCoupon?.couponName,
        //         couponDescription: updatingCoupon?.couponDescription,
        //         priceInPoints: updatingCoupon?.priceInPoints,
        //         priceInDollars: updatingCoupon?.priceInDollars,
        //         priceInBTC: updatingCoupon?.priceInBTC,
        //         quantity: updatingCoupon?.quantity,
        //         isAdultContent: updatingCoupon?.isAdultContent,
        //         category: updatingCoupon?.category ?? coupon_categories[0].name
        //     };
        // }
        // const unsaved_coupon = localStorage.getItem("unsaved_coupon");
        // if (unsaved_coupon) {
        //     return { ...JSON.parse(unsaved_coupon) };
        // }
        return initialValues;
    };


    // carousel

    const removeImage = (uri) => {
        setCouponUploadImages((prev) => {
            const newImages = prev.filter((image) => {
                return image.uri !== uri;
            });
            return [...newImages];
        });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCouponUploadImages((prev) => {
                    const newImage = { file: file, uri: reader.result, new: true };
                    return [...prev, newImage];
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePreview = () => {
        const state = {
            ...formikCtx.current.values,
        };
        navigate("/view-brand-deal", { state });
    };

    return (
        <Formik innerRef={formikCtx} initialValues={getInitialValues()} validationSchema={AddDealSchema} onSubmit={handleSubmitCoupon} enableReinitialize={true}>
            {({ handleChange, handleSubmit, values, errors, setFieldValue, touched }) => (
                <div className="row gy-3">
                    <div className="col-12 col-md-6">
                        <div className="card card-body gap-3">
                            <div className="d-flex justify-content-center mt-2">
                                <Carousel showThumbs={false} className="store_image_container rounded w-100">
                                    {[...couponUploadImages, { type: "upload" }]?.map((image, index) =>
                                        image?.type !== "upload" ? (
                                            <div className="" key={"img" + index}>
                                                <div>
                                                    <Button
                                                        variant="dark"
                                                        className="position-absolute top-0 start-0 ms-4 my-2 rounded px-2 py-1"
                                                        onClick={() => removeImage(image.uri)}
                                                    >
                                                        <IoTrash size={16} color="white" />
                                                    </Button>
                                                    <div className="couponCarouselImage">
                                                        <img
                                                            src={image.uri}
                                                            alt="carousel_img"
                                                            className="object-fit-cover rounded"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div onClick={handleImagePicker} className="w-100" key={"img-none" + index}>
                                                <div className="image_upload_container rounded w-100">
                                                    <span color={theme.color}>
                                                        <BiSolidImageAdd size={28} className="me-2" />
                                                        Upload Image
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </Carousel>
                                <input
                                    id="storeImageInput"
                                    type="file"
                                    ref={couponImageInput}
                                    onChange={handleImageUpload}
                                    accept="image/*"
                                />
                            </div>

                                               </div>
                    </div>

                    <div className="col-12 col-md-6 ">
                        <div className="card card-body gap-3 h-100">
                        <Form.Group>
                                <Form.Label className="required">{t("Deal Name")}</Form.Label>
                                <Form.Control
                                    value={values.brandDealName}
                                    onChange={handleChange("brandDealName")}
                                    type="text"
                                    placeholder={t("Enter Deal Name")}
                                />
                                {touched.brandDealName && errors.brandDealName && <ErrorMsg msg={errors.brandDealName} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">{t("Deal Description")}</Form.Label>
                                <Form.Control
                                    value={values.brandDealDescription}
                                    onChange={handleChange("brandDealDescription")}
                                    type="text"
                                    placeholder={t("Enter Deal Description")}
                                />
                                {touched.brandDealDescription && errors.brandDealDescription && (
                                    <ErrorMsg msg={errors.brandDealDescription} />
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">{t("Deal Category")}</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={handleChange("category")}
                                    defaultValue={values.category}
                                >
                                    {coupon_categories.map((category, i) => (
                                        <option value={category.name} key={i}>
                                            {t(category.text.replace(/\s+/g, "_"))}
                                        </option>
                                    ))}
                                </Form.Select>
                                {touched.category && errors.category && (
                                    <span color="red.500" mt="1" fontSize="xs">
                                        {errors.category}
                                    </span>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">{t("Budget Per Post")}</Form.Label>
                                <Form.Control
                                    value={values.budgetPerPost?.toString()}
                                    onChange={handleChange("budgetPerPost")}
                                    type="number"
                                    placeholder="E.g. 200"
                                />
                                {touched.budgetPerPost && errors.budgetPerPost && <ErrorMsg msg={errors.budgetPerPost} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{t("Revenue Percent Share")}</Form.Label>
                                <Form.Control
                                    value={values.revenuePercentShare?.toString()}
                                    onChange={handleChange("revenuePercentShare")}
                                    type="number"
                                    placeholder="E.g. 10"
                                />
                                {touched.revenuePercentShare && errors.revenuePercentShare && <ErrorMsg msg={errors.revenuePercentShare} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{t("Delievery Date")}</Form.Label>
                                <Form.Control
                                    value={values.delieveryDate}
                                    onChange={handleChange("delieveryDate")}
                                    type="date"
                                    placeholder="E.g. 10"
                                />
                                {touched.delieveryDate && errors.delieveryDate && <ErrorMsg msg={errors.delieveryDate} />}
                            </Form.Group>

                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={t("Is_NSFW_Content")}
                                checked={values.isAdultContent}
                                onChange={handleChange("isAdultContent")}
                            />

                        </div>
                    </div>

                    {buttonContent == null ? (
                        <div className="d-flex justify-content-end mb-2 gap-2">
                            {handleDeleteCoupon && (
                                <Button
                                    w="full"
                                    onClick={handleDeleteCoupon}
                                    style={{ width: "100px" }}
                                    variant="danger"
                                    className="text-white"
                                    disabled={isDeleting || isSubmitting}
                                >
                                    {isDeleting && <Spinner size="sm" className="me-2" animation="border" variant="white" />}
                                    {t("Delete")}
                                </Button>
                            )}
                            <Button className="secondary-btn" w="full" onClick={handlePreview} style={{ width: "100px" }}>
                                {t("Preview")}
                            </Button>
                            <Button
                                bg={theme.color}
                                w="full"
                                onClick={handleSubmit}
                                style={{ width: "100px" }}
                                disabled={isDeleting || isSubmitting}
                                className="action-btn"
                            >
                                {isSubmitting && <Spinner size="sm" className="me-2" animation="border" variant="white" />}
                                {updatingCoupon ? t("Update") : t("Add")}
                            </Button>
                        </div>
                    ) : (
                        buttonContent(handleSubmit)
                    )}
                </div>
            )}
        </Formik>
    );
};

export default DealForm;
