import React from "react";
import { useLocation } from "react-router";
import "./index.css";
import Avatar from "../../Component/Comman/Avatar";
import { getCouponImageUrl, getCouponQRImageUrl } from "../../utils/images";
import {QRCodeSVG} from 'qrcode.react';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

export default function CouponScan() {
    const navigate = useNavigate();
    const { state } = useLocation();
    console.log("state?.couponID: ",state?.couponID);

    const handleMerchantClick = () => {
        navigate(`/store/${state?.couponID?.merchantUUID}`, {
            state: {
                merchantID: state?.couponID?.merchantUUID,
                from: "explore"
            }
        });
    }

    return (
        <div className="coupon-scan-container">
            <div className="coupon-img-container">
            <img src={getCouponImageUrl(state?.couponID?.couponPhotos?.[0])} alt="coupon" />
                {/* <Avatar
                    className="coupon-img"
                    avatarUrl={getCouponImageUrl(state?.couponID?.couponPhotos?.[0])}
                    name={state?.couponID?.couponName}
                /> */}
            </div>
            <span><strong>{state?.couponID?.couponName}</strong></span>
            <div>
              <QRCodeSVG value={getCouponQRImageUrl(state?.couponID._id)}/>
            </div>
            <span className="merchant-scan-text"><Button onClick={handleMerchantClick}> View Store</Button> </span>
            <span className="merchant-scan-text">Description: {state?.couponID?.couponDescription}</span>

            <span className={`status-text ${state?.isCouponRedeem ? 'used-status-text' : 'unused-status-text'}`}>{state?.isCouponRedeem ? "Already used" : "Not used"}</span>
        </div>
    );
}
