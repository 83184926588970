import React, { useCallback, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import { adminAPIInstance } from "../../../apis/AdminAPI";
import PairTable from "./PairTable";
import { CryptoToPoint } from "./CryptoToPointTradingPair";
import { CryptoToCrypto } from "./CryptoToCryptoTradingPair";
import { PointToPoint } from "./PointToPointTradingPair";

function TradingPairs() {
  
    const tabs = [
        { title: "Show All Trading Pairs", eventKey: "show-all-trading-pairs", content: <PairTable /> },
        { title: "Add Crypto to Crypto", eventKey: "add-new-trading-pair", content: <CryptoToCrypto /> },
        { title: "Add Crypto to Point", eventKey: "add-c2c-trading-pair", content: <CryptoToPoint /> },
        { title: "Add Point to Point", eventKey: "add-p2p-trading-pair", content: <PointToPoint /> }
    ];
    return (
        <div className="px-lg-4 px-md-4">
            <Tab.Container defaultActiveKey="show-all-trading-pairs" className="mb-3 nav nav-pills rounded border border-0">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent align-items-center flex-wrap">
                    <Nav className="nav nav-pills rounded d-inline-flex mt-2 mt-md-0 rounded-0" role="tablist">
                        {tabs.map((tab) => (
                            <Nav.Item className="nav-item" key={tab.eventKey}>
                                <Nav.Link
                                    eventKey={tab.eventKey}
                                    href="#"
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    role="tab"
                                    aria-selected="false"
                                >
                                    {tab.title}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </div>
                <div className=" col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <Tab.Content className="tab-content">
                                {tabs.map((tab) => (
                                    <Tab.Pane key={tab.eventKey} eventKey={tab.eventKey} className={`fade show`}>
                                        {tab.content}
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </div>
                    </div>
                </div>
            </Tab.Container>
        </div>
    );
}

export default TradingPairs;
