import React from "react";
import ReactApexChart from "react-apexcharts";

function Chart({ pair, options, series }) {
    // let parsedPair = "";
    // if (pair.split("/").length > 1) {
    //     parsedPair = pair.split("/")[0] + pair.split("/")[1];
    // }

    return (
        <div className="card">
            <div className="card-body">
                <div className="tradingview-widget-container">
                    <div id="tradingview_e05b7">
                        <div
                            id="tradingview_d89db-wrapper"
                            style={{
                                position: "relative",
                                boxSizing: "content-box",
                                width: "100%",
                                height: "100%",
                                margin: " 0 auto !important",
                                padding: "0 !important"
                            }}
                        >
                            <div
                                style={{ width: "100%", height: "100%", background: "transparent", padding: "0 !important" }}
                            >
                                <ReactApexChart options={options} series={series} type="candlestick" height={350} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chart;
