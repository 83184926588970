import React, { useEffect } from "react";
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { getMerchantCouponHistory } from "../../Redux/Actions/MerchantActions";
import { readCookie } from "../../utils/cookie";
import "./index.css";

const StoreStatisticsHistoryScreen = () => {
    const baseUrl = process.env.PUBLIC_URL;
    const dispatch = useDispatch();
    const token = readCookie("token");

    const merchant = useSelector(state => state.merchant.currentUserMerchant);
    const totalSales = useSelector(state => state.merchant.currentUserMerchantTotalCouponSales);

    useEffect(() => {
        dispatch(getMerchantCouponHistory(merchant?._id, token));
    }, [dispatch, merchant?._id, token]);
    
    return (
        <Container fluid className="h-100">
            <Row className="justify-content-center align-items-center h-100">
                <Col xs={12} sm={8} md={7} lg={5} xl={3}>
                    <div className="rounded p-4" style={{ position: 'relative', borderRadius: '20px', border: '1px solid var(--bs-gray-500)' }}>
                        <div className="d-flex flex-column">
                            <div>
                                <Row>
                                    <Col className="d-flex">
                                        <span className="mb-2 fs-6 text-muted" >Total Earnings</span>
                                    </Col>
                                    <Col className="d-flex">
                                        <span className="mb-2 ms-auto" style={{ fontWeight: '500' }}>{totalSales}</span>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col className="d-flex">
                                        <span className="mb-2 fs-6 text-muted" >Fee($10)</span>
                                    </Col>
                                    <Col className="d-flex">
                                        <span className="mb-2 ms-auto" style={{ fontWeight: '500' }}>0</span>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col className="d-flex">
                                        <span className="mb-2 fs-6" style={{ fontWeight: '500' }}>Withdraw Available</span>
                                    </Col>
                                    <Col className="d-flex">
                                        <span className="mb-2 fw-bold ms-auto">{totalSales}</span>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3" style={{ position: 'relative'}}>
                        <div className="d-flex flex-column">
                            <div>
                                <Row>
                                    <Col className="d-flex">
                                        <span className="mb-2 fs-6 fw-bold">My Bank Account</span>
                                    </Col>
                                    <Col className="d-flex">
                                        <span className="mb-2 ms-auto" style={{ fontWeight: '500', color: 'var(--bs-orange)' }}>Edit</span>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <span className="mb-2 fs-6 text-muted">
                                        {merchant.bankName || "N/A"}: {merchant.bankAccountNumber || "N/A"}
                                    </span>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col className="d-flex">
                                        <span className="mb-2 fs-6 fw-bold">My History</span>
                                    </Col>
                                    <Col className="d-flex">
                                        <Link to={baseUrl + "/my-store/myStoreStatistics-history"} className="mb-2 ms-auto" style={{ fontWeight: '500', color: 'var(--bs-orange)' }}>
                                            View History
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <span className="mb-2 mb-2 fs-6 text-muted">Total Earning: {totalSales}</span>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="d-flex justify-content-center">
                            <Button className="rounded-sm w-100 text-white py-3 fw-bold" style={{backgroundColor: 'var(--bs-orange)'}}>
                                Withdraw to my Bank Account
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default StoreStatisticsHistoryScreen;
