import { useState, useRef, useEffect } from "react";
import PageTitle from "../../../Component/Comman/PageTitle";
import { CustomToast, ToastStatus } from "../../../Component/Comman/CustomToast";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CreatorProfileForm from "./CreatorProfileForm";
import { authAPIInstance } from "../../../apis/AuthAPI";
import { AIApiInstance } from "../../../apis/AIAPI";
import { AnalyticsAPIInstance } from "../../../apis/AnalyticsAPI";
import { UserCard } from "./ViewCreatorProfile";
import { selectCurrentUser } from "../../../Redux/selectors/auth";


const MakeCreatorProfile = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser)
    const [userInfo, setUserInfo] = useState({});
    const [followingStatus, setFollowingStatus] = useState(false);
    const [followers, setfollowers] = useState([]);
    const [favoriteCharacters, setfavoriteCharacters] = useState([]);
    const viewsLoaded =  useRef(false);

    const [toast, setToast] = useState({
        show: false,
        message: "",
        status: ""
    });
    const [isSubmitting, setSubmitting] = useState(false);

    const handleSubmit = async ({body, user_photo: user_photo_file}) => {
        try {
            setSubmitting(true)

            const formData = new FormData();

            Object.keys(body).forEach((key) => {
                if (key === "links") {
                    formData.append(key, JSON.stringify(body[key]));
                } else formData.append(key, body[key]);
            });

            if(user_photo_file){
                formData.append('file', user_photo_file);
            }

            const res = await authAPIInstance.updateUserProfile(formData);

            dispatch({
                type: "UPDATE_PROFILE",
                payload: {
                    username: body.username,
                    description: body.description,
                    socialLinks: body.links,
                    ...(user_photo_file ? { avatar_url: res?.data?.data?.avatar_url } : {}),
                    ...(!user_photo_file && body?.imageRemoved ? { avatar_url: '' } : {})
                }
            });

            setToast({
                show: true,
                message: "User Profile Updated.",
                status: ToastStatus.SUCCESS
            });
            setSubmitting(false)
            

        } catch (error) {
            console.error(error?.message ?? "Error saving profile");

            setSubmitting(false)

            setToast({
                show: true,
                message: "An error has occurred!",
                status: ToastStatus.ERROR
            });
        }
    };

    useEffect(() => {
        let userId = userInfo?._id;
        console.log('userId: ', userId);
        if (userId && userId !== typeof 'undefined') {
            authAPIInstance
                .getFollowers(userId)
                .then((res) => {
                    setfollowers(res.data?.data?.followers ?? []);
                })
                .catch((e) => {
                    console.error(e);
                    //setError(true);
                });
            authAPIInstance
                .getFollowingStatus(userId)
                .then((res) => {
                    setFollowingStatus(res.data?.data?.status ?? []);
                })
                .catch((e) => {
                    console.error(e);
                    //setError(true);
                });
        }
        if (userInfo?.username && !viewsLoaded.current) {
            AnalyticsAPIInstance.getUserProfileViews(userInfo?.username)
                .then((res) => {
                    viewsLoaded.current =  true;
                    setUserInfo((prev) => ({ ...prev, views: res?.data?.data?.views ?? 0 }));
                })
                .catch(console.error);
        }
    }, [userInfo]);

    useEffect(() => {
        setUserInfo({ ...user, _id: user?.userId, views: 0 });
            AIApiInstance.getFavoriteCharacters()
            .then(res => {
                setfavoriteCharacters(res?.data?.data?.characters ?? []);
            }).catch(console.error)
        console.log("userInfo ", userInfo)
    }, []);
    
    return (
        <div className="d-flex flex-row view-creator-profile w-100 gap-0">
            <div className="d-flex flex-column user-card-container">
                <div className="flex-grow-1 overflow-hidden">
                    <UserCard userInfo={userInfo} followingStatus={followingStatus} followersCount={followers?.length}/>
                </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center flex-fill mx-4">
                <div className="align-self-start">
                    <PageTitle pagetitle="Edit Profile" goBackLink="/my-profile" />
                </div>
                <CreatorProfileForm onSubmit={handleSubmit} isSubmitting={isSubmitting}/>
            </div>
            <CustomToast
                status={toast.status}
                show={toast.show}
                message={toast.message}
                onClose={() => setToast((prev) => ({ ...prev, show: false }))}
            />
        </div>
    );
    

};

export default MakeCreatorProfile;
