import React, { useEffect, useState } from "react";
import { Dropdown, Nav, Spinner, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getFingerPrint } from "../../utils/fingerPrint";
import AuthRight from "./AuthRight";
import Modal2FA from "./Modal2FA";
import { createCookie } from "../../utils/cookie";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { AuthActions } from "../../Redux/Actions";
import { config } from "../../utils/config";
import { eventActions, eventCategories, sendFormActionEvent } from '../../utils/googleAnalytics';

function Signin({ login }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [open2FAModal, setOpen2FAModal] = useState(false);
    const [tempToken, setTempToken] = useState(null);
    const [loading, setloading] = useState(false);

    const {t} = useTranslation('sign_in_page');

    const dispatch = useDispatch();

    const handeLoginError = (error) => {
        setloading(false);
        alert(error?.response?.data?.message ?? "Something went wrong.");
    };

    const handleLogin = async () => {

        try {
            sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_submit_event, 'login');
            setloading(true);
            const fp = await getFingerPrint();
            const res = await login(password, email, fp, handeLoginError);
            if (res?.message === "TWO_FACTOR_REQUIRED") {
                setloading(false);
                setTempToken(res?.data?.token);
                setOpen2FAModal(true);
                return;
            } else if (res?.data?.token) {
                createCookie("token", res?.data?.token);
                setloading(false);
                dispatch({
                    type: "AUTHENTICATE",
                    payload: {
                        user: res?.data?.user,
                        token: res?.data?.token
                    }
                });
            }
        } catch (error) {
            handeLoginError(error);
        }
    };

    useEffect(() => {
        const messageListener = (event) => {
            const origin = event.origin;
            const target = event.data?.target;
            if (origin === config[config.environment].host && target === "sign-in") {
                createCookie("token",  event.data?.token);
                dispatch(AuthActions.getUserProfile());
                setloading(false);
            }
        };
        window.addEventListener("message", messageListener);
        return () => {
            window.removeEventListener('message', messageListener)
        };
    }, [dispatch]);

    const handleGoogleLogin = () => {
        //todo config url prod and dev
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_submit_event, 'google_login');
        setloading(true);
        window.open(config[config.environment].host+"/api/v1/users/auth/web/google/login");
    }

    function hideModal () {
        setOpen2FAModal(false)
        setTempToken(null);
    }

    const handleEmailChange = (e) => {
        const value = e.target.value;
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_change_event, 'email', value);
        setEmail(value)
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_change_event, 'password', value);
        setPassword(value)
    };

    return (
        <>
            {open2FAModal && <Modal2FA show={open2FAModal} onHide={hideModal} tempToken={tempToken}  />}
            <div className="body d-flex p-0 p-xl-2 justify-content-center align-items-center"> 
                <div className="d-flex align-items-center flex-column -3">
                    <h1>{t("accountLogin")}</h1>
                    <span className="text-muted w-75 p-3 mx-auto text-center">
                        {t("welcomeBack")}
                    </span>
                </div>
                <div className="container sign-up-container mt-5">
                    <div className="row g-3">
                        <div className="d-flex justify-content-center align-items-center"> 
                            <div className="d-flex flex-column auth-form-container">
                                <Tab.Container defaultActiveKey="first">
                                    <Nav className="nav nav-pills mt-4" role="tablist" defaultActiveKey="first">
                                        <Nav.Item className="nav-item">
                                            <Nav.Link className="nav-link" eventKey="first">
                                                {t("email")}
                                            </Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item className="nav-item">
                                            <Nav.Link className="nav-link" eventKey="second">
                                                {t("mobile")}
                                            </Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                    <Tab.Content className="tab-content mt-4 mb-3">
                                        <Tab.Pane className="tab-pane fade " id="Email" eventKey="first">
                                            <div className="card">
                                                <div className="card-body sign-up-card-body px-0 py-2">
                                                    <div className="mb-3 email-input-container position-relative">
                                                        <label className="form-label fs-6">{t("email")}</label>
                                                        <div className="position-relative">
                                                            <input
                                                                value={email}
                                                                type="email"
                                                                className="form-control"
                                                                onChange={handleEmailChange}
                                                            />
                                                            <span className="position-absolute top-50 end-0 translate-middle-y">
                                                                <i class="fa-regular fa-envelope pe-3"></i>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3 email-input-container position-relative">
                                                        <label className="form-label fs-6">{t("password")}</label>
                                                        <div className="position-relative">
                                                            <input
                                                                value={password}
                                                                type="password"
                                                                className="form-control"
                                                                onChange={handlePasswordChange}
                                                            />
                                                            <span className="position-absolute top-50 end-0 translate-middle-y">
                                                                <i class="fa-regular fa-eye pe-3"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={handleLogin}
                                                        type="submit"
                                                        disabled={loading}
                                                        className="btn orange-gradient-btn  py-2 fs-7 w-100 mt-2"
                                                    >
                                                        {t("logIn")}
                                                        {loading && <Spinner className="mx-2" size="sm"/>}
                                                    </button>
                                                    <button
                                                        onClick={handleGoogleLogin}
                                                        type="submit"
                                                        disabled={loading}
                                                        className="btn light-blue-gradient-btn py-2 fs-7 w-100 mt-2"
                                                    >
                                                        {t("Sign in with Google")}
                                                    </button>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane className="tab-pane fade" id="Mobile" eventKey="second">
                                            <div className="card">
                                                <div className="card-body p-4">
                                                    <form>
                                                        <label className="form-label fs-6">{t("mobile")}</label>
                                                        <Dropdown as="a" className="input-group mb-3">
                                                            <Dropdown.Toggle
                                                                className="btn btn-outline-secondary dropdown-toggle"
                                                                type="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                +91 India
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu">
                                                                <li>
                                                                    <Dropdown.Item className="dropdown-item" href="#!">
                                                                        +376 Andora
                                                                    </Dropdown.Item>
                                                                </li>
                                                                <li>
                                                                    <Dropdown.Item className="dropdown-item" href="#!">
                                                                        +61 Australia
                                                                    </Dropdown.Item>
                                                                </li>
                                                                <li>
                                                                    <Dropdown.Item className="dropdown-item" href="#!">
                                                                        +55 Brazil
                                                                    </Dropdown.Item>
                                                                </li>
                                                            </Dropdown.Menu>
                                                            <input type="text" className="form-control" />
                                                        </Dropdown>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6">{t("password")}</label>
                                                            <input type="password" className="form-control" />
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2"
                                                        >
                                                            {t("logIn")}
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                        <AuthRight />
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column gap-3 mt-3">
                    <Link
                        to={process.env.PUBLIC_URL + "/forgot-password"}
                        title="#"
                        className="orange-red-gradient-text text-decoration-none"
                    >
                        {t("forgotPassword")}
                    </Link>
                    <Link
                        to={process.env.PUBLIC_URL + "/sign-up"}
                        title="#"
                        className="orange-red-gradient-text text-decoration-none"
                    >
                        {t("registerNow")}
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Signin;
