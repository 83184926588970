import React from "react";
import { Nav, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";

function Transaction({ deposits, withdraws, updateEvent, cancelWithdraw, cancelLoading }) {
    const TransactionData = {
        deposit: {
            columns: [
                {
                    name: "DATE",
                    selector: (row) => dayjs.unix(row.createdAt).format("MMMM DD, YYYY h:mm A"),
                    sortable: true
                },
                {
                    name: "AMOUNT",
                    selector: (row) => parseFloat(row?.amount || 0).toFixed(6),
                    sortable: true
                },
                {
                    name: "TOKEN",
                    selector: (row) => row?.chain
                },
                {
                    name: "TRANSACTION HASH",
                    selector: (row) => row.txHash
                }
            ]
        },
        withdraw: {
            columns: [
                {
                    name: "DATE",
                    selector: (row) => dayjs.unix(row.createdAt).format("MMMM DD, YYYY h:mm A"),
                    sortable: true
                },
                {
                    name: "AMOUNT",
                    selector: (row) => parseFloat(row?.amount || 0).toFixed(6),
                    sortable: true
                },
                {
                    name: "TOKEN",
                    selector: (row) => row?.chain === "ETH" ? row?.tokenInfo?.name ? row?.tokenInfo?.name : row?.chain : row?.chain
                },
                {
                    name: "RECIPIENT",
                    selector: (row) => row.recipient
                },
                {
                    name: "STATUS",
                    selector: (row) => row.status
                },
                {
                    name: "TRANSACTION HASH",
                    selector: (row) => row.txHash
                },
                {
                    name: "Action",
                    selector: (row) =>
                        row.status === "PENDING" &&
                        row.transactionId && (
                            <div className="d-flex flex-row">
                                <button
                                    disabled={cancelLoading}
                                    className="btn btn-light me-2"
                                    onClick={() =>
                                        updateEvent({
                                            transactionId: row.transactionId,
                                            tokenAddress: row?.token_address,
                                            modalIsOpen: true
                                        })
                                    }
                                >
                                    Continue
                                </button>
                                <button
                                    disabled={cancelLoading}
                                    className="btn btn-danger"
                                    onClick={() => cancelWithdraw(row.transactionId, row?.chain)}
                                >
                                    Cancel
                                </button>
                            </div>
                        )
                }
            ]
        }
    };

    return (
        <div className="col-xl-12">
            <div className="card">
                <Tab.Container defaultActiveKey="first">
                    <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom align-items-center flex-wrap">
                        <h6 className="mb-0 fw-bold">Recent Transactions</h6>
                        <Nav className="nav nav-tabs tab-body-header rounded d-inline-flex mt-2 mt-md-0" role="tablist">
                            <Nav.Item className="nav-item">
                                <Nav.Link
                                    className="nav-link "
                                    eventKey="first"
                                    data-bs-toggle="tab"
                                    href="#deposits"
                                    role="tab"
                                >
                                    Deposits
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="nav-item">
                                <Nav.Link
                                    className="nav-link"
                                    eventKey="second"
                                    data-bs-toggle="tab"
                                    href="#withdraws"
                                    role="tab"
                                >
                                    Withdraws
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="card-body">
                        <Tab.Content className="tab-content">
                            <Tab.Pane className="tab-pane fade show" id="deposits" eventKey="first">
                                <div id="ordertabthree_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <DataTable
                                                //title={TransactionData.title}
                                                columns={TransactionData.deposit.columns}
                                                data={deposits}
                                                defaultSortField="title"
                                                selectableRows={false}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3 ">
                                        <div className="col-sm-12 col-md-5" style={{ float: "left" }}>
                                            <div
                                                className="dataTables_info"
                                                id="myDataTable_info"
                                                role="status"
                                                aria-live="polite"
                                            >
                                                Showing 1 to 7 of 7 entries
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-7 ">
                                            <div
                                                className="dataTables_paginate paging_simple_numbers"
                                                id="myDataTable_paginate"
                                                style={{ float: "right" }}
                                            >
                                                <ul className="pagination">
                                                    <li
                                                        className="paginate_button page-item previous disabled"
                                                        id="myDataTable_previous"
                                                    >
                                                        <a href="#!" className="page-link">
                                                            Previous
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item active">
                                                        <a href="#!" className="page-link">
                                                            1
                                                        </a>
                                                    </li>
                                                    <li
                                                        className="paginate_button page-item next disabled"
                                                        id="myDataTable_next"
                                                    >
                                                        <a href="#!" className="page-link">
                                                            Next
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane className="tab-pane fade show" id="withdraws" eventKey="second">
                                <div id="ordertabthree_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <DataTable
                                                //title={TransactionData.title}
                                                columns={TransactionData.withdraw.columns}
                                                data={withdraws}
                                                defaultSortField="title"
                                                selectableRows={false}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3 ">
                                        <div className="col-sm-12 col-md-5" style={{ float: "left" }}>
                                            <div
                                                className="dataTables_info"
                                                id="myDataTable_info"
                                                role="status"
                                                aria-live="polite"
                                            >
                                                Showing 1 to 7 of 7 entries
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-7 ">
                                            <div
                                                className="dataTables_paginate paging_simple_numbers"
                                                id="myDataTable_paginate"
                                                style={{ float: "right" }}
                                            >
                                                <ul className="pagination">
                                                    <li
                                                        className="paginate_button page-item previous disabled"
                                                        id="myDataTable_previous"
                                                    >
                                                        <a href="#!" className="page-link">
                                                            Previous
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item active">
                                                        <a href="#!" className="page-link">
                                                            1
                                                        </a>
                                                    </li>
                                                    <li
                                                        className="paginate_button page-item next disabled"
                                                        id="myDataTable_next"
                                                    >
                                                        <a href="#!" className="page-link">
                                                            Next
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </div>
    );
}
export default Transaction;
