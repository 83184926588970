import React from "react";
import Avatar from "../../../Component/Comman/Avatar";
import { getUserImageUrl } from "../../../utils/images";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import { Button } from "react-bootstrap";

export default function Referral({earning}) {
    const { username, avatar, totalEarnings, currency, payout_id, action, withdrawn, timeStamp } = earning;
    return (
        <div style={{width: '450px'}} className="referral-earning-card d-flex flex-column p-3 gap-2">
            <div className="d-flex flex-row gap-2 align-items-center">
                <Avatar avatarUrl={getUserImageUrl(avatar)} className="referral-avatar"/>
                <span>{username}</span>
            </div>
            <div className="d-flex flex-row gap-5">
                <span>Earnings: {totalEarnings} {currency}</span>
                <span>Earned by: {formatDate(timeStamp)}</span>
            </div>
            
            {/* <Button className="withdraw-btn">
                Withdraw
            </Button> */}
        </div>
    );
}
