const EVENTS = {
    CONNECTION: "connection",
    MESSAGE: "message",
    CLOSE: "close",
    CLIENT: {
        ORDERBOOK: {
            SUBSCRIBE: "orderbook.client.subscribe",
            ADD: "orderbook.client.add",
            REMOVE: "orderbook.client.remove",
            GET: "orderbook.client.get"
        },
        CHANGESTREAM: {
            SUBSCRIBE: "changestream.client.subscribe"
        },
        CUSTOM_AI: {
            GET: "custom_ai.client.get_admin_miners_count"
        }
    },
    SERVER: {
        ORDERBOOK: {
            SUBSCRIBE: "orderbook.server.subscribe",
            GET: "orderbook.server.get"
        },
        CHANGESTREAM: {
            SUBSCRIBE: "changestream.server.subscribe",
            BALANCE: {
                UPDATE: "changestream.server.balance.update"
            }
        },
        CUSTOM_AI: {
            GET: "custom_ai.server.receive_admin_miners_count"
        }
    }
};

export default EVENTS;
