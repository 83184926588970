import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import orangeLogo from "../../../assets/images/logo/orangeMark.png";
import redchatLogo from "../../../assets/images/logo/redbit.png";
import { getBrowserCurrentUrl } from "../../../utils/getCurrentUrl";
import './sidebar.css';

function Sidebar(props) {
    const { t } = useTranslation("menu");

    const [isSidebarMini, setIsSidebarMini] = useState(false);
    const [topLogo, setTopLogo] = useState(orangeLogo);
    const baseUrl = process.env.PUBLIC_URL;
    const currentUrl = getBrowserCurrentUrl();

    useEffect(() => {
        if (currentUrl.includes("redchat")) {
            setTopLogo(redchatLogo);
        } else {
            setTopLogo(orangeLogo);
        }
    }, [currentUrl]);

    const toggleMenu = () => {
        var sidebar = document.getElementById("mainsidemenu");
                            if (sidebar) {
                                if (sidebar.classList.contains("open")) {
                                    sidebar.classList.remove("open");
                                } else {
                                    sidebar.classList.add("open");
                                }
                            }
    };

    const { activekey, menuData, GotoChangeMenu } = props;

    const getMenuItemClass = (routerLink) => {
        let _activeKey = activekey === '/' ? 'explore': activekey;
        return ("/" + routerLink).includes(_activeKey) ? "active" : "";
    };

    const getMenuIconClass = (routerLink) => {
        let _activeKey = activekey === '/' ? 'explore': activekey;
        return ("/" + routerLink).includes(_activeKey) ? "selected-icon" : "unselected-icon";
    };

    return (
        <div id="mainsidemenu" className={`sidebar me-0 border-end ${isSidebarMini ? "sidebar-mini" : ""}`}>
            <div className="d-flex flex-column h-100">
                <div className="p-4 m-0 border-bottom" style={{}}>
                    <a href={process.env.PUBLIC_URL + "/"} className="mb-0 brand-icon">
                        <span className="logo-text " style={{ color: "var(--bs-orange)" }}>
                        <img className="orangeLogo" src={topLogo} alt="" />
                        <span className="orangeLogo-text" >{currentUrl}</span>
                        </span>
                    </a>
                    
                </div>
              
                <ul className="menu-list flex-grow-1 px-4 py-3 gap-2 d-flex flex-column">
                    {menuData.map((d, i) => {
                        if (d.children.length === 0) {
                            return (
                                <li key={"sidebar-item-" + i} className="collapsed">
                                    <Link
                                        to={"/" + d.routerLink[0]}
                                        onClick={() => {toggleMenu();}}
                                        className={`m-link sidebar-item-container ${getMenuItemClass(d.routerLink[0])}`}
                                    >
                                        <div className={`sidebar-icon ${d.name === "Explore" ? "home-icon" : ""}`}>
                                            <img src={d.iconPath} alt="icon" className={getMenuIconClass(d.routerLink[0])} />
                                        </div>
                                        <div className="menu-item-text">
                                            <h6 className="mb-0">{t(`menu:${d.name.replace(/\s+/g, "_")}`)}</h6>
                                        </div>
                                    </Link>
                                </li>
                            );
                        }
                    })}
                </ul>
                <button
                    type="button"
                    className="btn btn-link sidebar-mini-btn text-muted pb-4"
                    onClick={() => {
                        setIsSidebarMini(!isSidebarMini);
                    }}
                >
                    <span className="ms-2">
                        <i className="icofont-bubble-right"></i>
                    </span>
                </button>
            </div>
        </div>
    );
}

export default Sidebar;
