import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useParams } from "react-router";
import * as Yup from "yup";
import { AIApiInstance } from "../../../apis/AIAPI";

export function YoutubeDialog({ isOpen, onRequestClose, onAddYoutube, editingVideo, isEditing }) {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    youtubeTitle: "",
    youtubeLink: "",
  });

  useEffect(() => {
    if (isEditing) {
      setFormData({
        youtubeTitle: editingVideo.youtubeTitle || "",
        youtubeLink: editingVideo.youtubeLink || "",
      });
    } else {
      // Reset formData for adding a new video
      setFormData({
        youtubeTitle: "",
        youtubeLink: "",
      });
    }
  }, [isEditing, editingVideo]);


  const [errors, setErrors] = useState({
    youtubeTitle: "",
    youtubeLink: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault()
      let response;
      if (isEditing) {
        response = await AIApiInstance.updateYoutubeLink(
          formData.youtubeLink,
          formData.youtubeTitle,
          id,
          editingVideo._id
        );
      } else {
        response = await AIApiInstance.createYoutubeLink(
          formData.youtubeLink,
          formData.youtubeTitle,
          id
        );
      }
  
      if (response.status === 201) {
        onAddYoutube(response.data.data.aiYoutubeLink);
        onRequestClose();
      } else {
        alert("Failed to add/update the YouTube video");
      }
    } catch (error) {
      console.error(error);
      alert("Failed to add/update the YouTube video");
    }
  };
  
  

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add YouTube Video</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <Form.Group controlId="youtubeTitle">
            <Form.Label>Youtube Title</Form.Label>
            <Form.Control
              type="text"
              name="youtubeTitle"
              onChange={handleChange}
              value={formData.youtubeTitle}
              placeholder="Enter Youtube Title"
              isInvalid={errors.youtubeTitle}
            />
            <Form.Control.Feedback type="invalid">
              {errors.youtubeTitle}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="youtubeLink">
            <Form.Label>Enter Youtube Link</Form.Label>
            <Form.Control
              type="text"
              name="youtubeLink"
              onChange={handleChange}
              value={formData.youtubeLink}
              placeholder="Paste your Youtube link here"
              isInvalid={errors.youtubeLink}
            />
            <Form.Control.Feedback type="invalid">
              {errors.youtubeLink}
            </Form.Control.Feedback>
          </Form.Group>
          <Modal.Footer>
            <Button type="submit" style={{ width: "100px" }}>
              Save
            </Button>
            <Button variant="secondary" onClick={onRequestClose}>
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
}
