import React, { useState } from "react";
import { Button, OverlayTrigger } from "react-bootstrap";
import { AIApiInstance } from "../../../apis/AIAPI";
import { removeChatPrefix, removeYouPrefix } from "../../../utils/customCharacter";
import { languageLookup } from "../../../utils/language";
import { USER_LANGUAGE_KEY } from "../../../utils/localStorage";
import { Modal } from "../../Modal/Modal";

export const MoreOverlay = React.memo(({ message, onUpdateMessage, onRegenerateMessage, handleEditPress = () => {}  }) => {
    const { messageContent, sentByUser } = message;
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeletingMessage, setDeletingMessage] = useState(false);

    const handleCopy = async () => {
        document.body.click();
        await navigator.clipboard.writeText(removeChatPrefix(messageContent));
        alert("Copied to clipboard");
    };

    const handleTranslatePress = async () => {
        document.body.click();
        const language = localStorage.getItem(USER_LANGUAGE_KEY);
        const { data } = await AIApiInstance.translate(
            message?.messageContent ?? "",
            language in languageLookup ? languageLookup[language] : "English"
        );
        if (data?.data?.translation) {
            onUpdateMessage(message?._id, removeYouPrefix(data?.data?.translation));
        }
    };

    const _handleEditPress = async () => {
        document.body.click();
        handleEditPress(message?.messageContent ?? "");
    };

    const handleRegenerateMessage = () => {
        document.body.click();
        onRegenerateMessage();
    };

    const handleDeleteMessage = async () => {
        try {
            setDeletingMessage(true);
            await AIApiInstance.deleteAiChatMessage(message._id);
            onUpdateMessage(message._id, "");
        } catch (error) {
            console.error(error);
            alert("Failed to delete message");
        } finally {
            setShowDeleteModal(false);
            setDeletingMessage(false);
        }
    };

    return (
        <>
            <OverlayTrigger
                rootClose
                rootCloseEvent="click"
                trigger="click"
                placement="top"
                overlay={
                    <div className="d-flex flex-row bg-white py-2 rounded">
                        <Button variant="ghost" onClick={handleCopy}>
                            Copy
                        </Button>
                        <Button variant="ghost" onClick={handleTranslatePress}>
                            Translate
                        </Button>
                        <Button variant="ghost" onClick={_handleEditPress}>
                            Edit
                        </Button>
                        {!sentByUser && (
                            <Button variant="ghost" onClick={handleRegenerateMessage}>
                                Regenerate
                            </Button>
                        )}
                        <Button
                            variant="ghost"
                            className="text-danger"
                            disabled={isDeletingMessage}
                            onClick={() => {
                                document.body.click();
                                setShowDeleteModal(true);
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                }
            >
                {({ ref, ...triggerHandler }) => (
                    <Button
                        ref={ref}
                        size="sm"
                        variant="ghost"
                        className="d-inline-flex align-items-center px-0 text-light"
                        {...triggerHandler}
                    >
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </Button>
                )}
            </OverlayTrigger>
            <Modal
                modalIsOpen={showDeleteModal}
                setIsOpen={setShowDeleteModal}
                body={
                    <div className="d-flex flex-column">
                        <div className="mt-2">Are you sure you want to delete this message?</div>
                        <div className="d-flex flex-row align-self-end mt-4">
                            <Button variant="danger" className="me-2 text-light" onClick={handleDeleteMessage}>
                                Delete
                            </Button>
                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                }
            />
        </>
    );
});