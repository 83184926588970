import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { authAPIInstance } from "../../apis/AuthAPI";

export default function PhoneVerification({ phoneVerificationModal, setPhoneVerificationModal, currentUser }) {
    const dispatch = useDispatch();
    const [phoneVerification, setPhoneVerification] = useState({
        code: "",
        loading: false,
        code_sent: false
    });

    const handlePhoneCodeChange = (e) => {
        const value = e.target.value;
        setPhoneVerification((prev) => ({ ...prev, code: value }));
    };

    const handlePhoneVerificationSubmit = async () => {
        try {
            setPhoneVerification((prev) => ({ ...prev, loading: true }));
            if (phoneVerification.code_sent) {
                await authAPIInstance.completePhoneVerification(currentUser?.phone?.phoneNumber, phoneVerification.code);
                setPhoneVerification((prev) => ({ ...prev, loading: false }));
                dispatch({
                    type: "UPDATE",
                    payload: {
                        user: {
                            key: "phone",
                            value: {
                              ...currentUser?.phone,
                              isVerified: true,
                            }
                        }
                    }
                });
                alert("Phone verified successfully.");
                setPhoneVerificationModal(false);
            } else {
                await authAPIInstance.requestPhoneVerification(currentUser?.phone?.phoneNumber);
                setPhoneVerification((prev) => ({ ...prev, code_sent: true, loading: false }));
                alert("Verification Code has been sent to your phone.");
            }
        } catch (error) {
            setPhoneVerification((prev) => ({ ...prev, loading: false }));
            alert(error?.response?.data?.message ?? "Internal Server Error.");
        }
    };
    return (
        <Modal
            className="modal"
            id="ChangeModal"
            style={{ display: "block" }}
            show={phoneVerificationModal}
            onHide={() => {
                setPhoneVerificationModal(true);
            }}
        >
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title">Verify Phone</h5>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Verification Code</label>
                            <input
                                value={phoneVerification.code}
                                onChange={handlePhoneCodeChange}
                                type="text"
                                className="form-control"
                                disabled={!phoneVerification.code_sent}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={() => {
                            setPhoneVerificationModal(false);
                        }}
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handlePhoneVerificationSubmit}
                        disabled={phoneVerification.loading}
                    >
                        {phoneVerification.code_sent ? `Submit` : `Request Verification Code`}
                        {phoneVerification.loading && <Spinner className="mx-1" size="sm" />}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}
