import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
/* import { getCurrentUserMerchant } from '../../Redux/Actions/MerchantActions'; */
import { selectCurrentUser } from "../../Redux/selectors/auth";
import Profile from "../../assets/images/profile_av.svg";
import { readCookie } from '../../utils/cookie';
import { getUserImageUrl } from "../../utils/images";
import BottomNavbar from "./BottomNavbar/BottomNav";
import LanguageDropDown from "./LanguageDropDown";
import './header.css';

function Header(props) {
    const dispatch = useDispatch();
    const token = readCookie('token');
    const merchant = useSelector((state) => {
        return state?.merchant?.currentUserMerchant;
    });
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate()
    const baseUrl = process.env.PUBLIC_URL;
    const location = useLocation();
    const { onModalOpen, GotoChangeMenu } = props;
    const [collapsebig, setCollapsebig] = useState(false);
    const {t} = useTranslation('header');

    const handleLogout = () => {
        props.logout();
    };

    const toggleMenu = () => {
        const sidebar = document.getElementById("mainsidemenu");
        if (sidebar) {
            if (sidebar.classList.contains("open")) {
                sidebar.classList.remove("open");
            } else {
                sidebar.classList.add("open");
            }
        }
    };

    /* const getMerchant = useCallback(async () => {
        dispatch(getCurrentUserMerchant(token));
    }, [dispatch, token]); */

    useEffect(() => {
       /*  if(token) {
            getMerchant();
        } */
    }, []);

    const getUserAvatar = () => {
        return user?.avatar_url ? getUserImageUrl(user?.avatar_url) : Profile
    }

    const isMyStoreRoute = location.pathname === baseUrl + "/my-store";

    return (
        <div className="header">
            <nav className="navbar main-nav">
                <div className="container-xxl">
                    {isMyStoreRoute && (
                        <div
                            className="h-left d-flex align-items-center fs-5 font-weight-bold"
                            style={{ paddingLeft: "20px" }}
                        >
                            {merchant && merchant.storeName}
                        </div>
                    )}
                    <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
                        {/* //notifications */}
                        <LanguageDropDown />
                        {token ? (
                            <Dropdown className="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center mx-2 mx-lg-3">
                                <Dropdown.Toggle
                                    as="dd"
                                    className="nav-link dropdown-toggle pulse p-0"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    data-bs-display="static"
                                >
                                    <img className="avatar lg rounded-circle img-thumbnail" src={getUserAvatar()} alt="profile" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0"
                                    style={{ marginTop: "65px" }}
                                >
                                    <div className="card border-0 w280">
                                        <div className="list-group m-2 ">
                                            <button
                                                onClick={() => navigate("/my-profile")}
                                                className="list-group-item list-group-item-action border-0"
                                            >
                                                My Profile
                                            </button>
                                            <button
                                                onClick={() => navigate("/settings")}
                                                className="list-group-item list-group-item-action border-0"
                                            >
                                                Settings
                                            </button>
                                            {/* <button
                                                onClick={() => onModalOpen(true)}
                                                className="list-group-item list-group-item-action border-0"
                                            >
                                                Design
                                            </button> */}
                                            <button
                                                onClick={() => navigate("/premium-price")}
                                                className="list-group-item list-group-item-action border-0 "
                                            >
                                                Pricing
                                            </button>
                                            <button
                                                onClick={handleLogout}
                                                className="list-group-item list-group-item-action border-0 "
                                            >
                                                Sign out
                                            </button>
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                                <BottomNavbar />
                            </Dropdown>
                        ) : (
                            <div className="d-flex ">
                                {/* <span
                                    onClick={() => {
                                        navigate("/premium-price");
                                    }}
                                    className="btn btn-link px-3"
                                >
                                    {t("pricing")}
                                </span> */}

                                <span
                                    onClick={() => {
                                        navigate("/sign-up");
                                    }}
                                    className="btn btn-success px-2 me-2"
                                >
                                    Register
                                </span>
                                <span
                                    onClick={() => {
                                        navigate("/sign-in");
                                    }}
                                    className="btn btn-link px-3"
                                >
                                    Login
                                </span>
                            </div>
                        )}
                    </div>
                    <button
                        className="navbar-toggler p-0 border-0 menu-toggle order-3"
                        type="button"
                        onClick={() => {
                            toggleMenu();
                        }}
                    >
                        <span className="fa fa-bars"></span>
                    </button>
                    <div className="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 mb-md-0 d-flex align-items-center">
                        <span
                            className="menu-toggle-option me-3 text-primary d-flex align-items-center"
                            title="Menu Option"
                            onClick={() => {
                                setCollapsebig(!collapsebig);
                            }}
                        ></span>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Header;
