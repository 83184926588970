import axios from "axios";
import { config } from "../utils/config";
import { readCookie } from "../utils/cookie";
import { createFormData } from "../utils/createFormData";
import { httpTokenInterceptor } from "./common";

class AIAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/ai"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    translate(text, language) {
        return this.axios.post(
            "/translate/",
            { text, language },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    createCharacter(data) {
        return this.axios.post("/create_ai_character", data, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`,
                "Content-Type": "multipart/form-data"
            }
        });
    }

    createYoutubeLink(youtubeLink, youtubeTitle, characterUUID) {
        return this.axios.post(
            "/save_youtube_link",
            { youtubeLink, youtubeTitle, characterUUID },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    updateYoutubeLink(youtubeLink, youtubeTitle, characterUUID, youtubeLinkId) {
        return this.axios.post(
            `/update_ai_youtubeLink/${youtubeLinkId}`,
            { youtubeLink, youtubeTitle, characterUUID },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    deleteYoutubeLink(youtubeId) {
        return this.axios.delete(`/delete_ai_youtubeLink/${youtubeId}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getAllMyAiYoutubeLinks() {
        return this.axios.get("/get_all_custom_ai_youtube_links", {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getAllMyCustomAIChars() {
        return this.axios.get("/get_all_custom_ai_chats", {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getAllPublicCharacters({ getCreatorData, search , adultContent , pageNumber }) {
        return this.axios.get(
            `/get_all_public_characters?getCreatorData=${getCreatorData}&search=${search}&adultContent=${adultContent}&page=${pageNumber}`,
            {
                headers: {}
            }
        );
    }

    getTopConversations({adultContent = false, pageNumber = ""}) {
        return this.axios.get(`/top_chats?adultContent=${adultContent}&page=${pageNumber}`, {
            headers: {}
        });
    }

    updateCharacter(characterId, data) {
        return this.axios.put(`/update_ai_character/${characterId}`, data, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    deleteCharacter(characterId) {
        return this.axios.delete(`/delete_ai_character/${characterId}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    deleteCharacterImage(characterID, imageUrl) {
        return this.axios.delete(`/delete_ai_character_image/${characterID}/${imageUrl}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    deleteCharacterCover(characterID, imageUrl) {
        return this.axios.delete(`/delete_ai_character_cover/${characterID}/${imageUrl}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getCharacterPublicChatsFromUsers(characterId) {
        return this.axios.get(`/get_ai_character_public_chats_from_users/${characterId}`, {
            headers: {}
        });
    }

    getCharacterPublicChatMessages(characterId, userId, page = 1) {
        return this.axios.get(`${characterId}/public_chat_messages/${userId}?page=${page}`, {
            headers: {}
        });
    }

    getCharacterInfo(characterId) {
        return this.axios.get(`/ai_character/${characterId}`, {
            headers: {}
        });
    }

    publishCharacterChats(characterId, isPublished) {
        return this.axios.patch(
            `/publish_character_chats/${characterId}`,
            { isPublished },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    publishGroupChat(groupId, status = false) {
        console.log(groupId, status)
        return this.axios.post(
            `/group_chat/publish`,
            { groupId, status },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    deleteAiChatMessage(messageId) {
        return this.axios.delete(`/assistant/chat_history/${messageId}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    updateAiChatMessage(messageId, messageContent) {
        return this.axios.patch(
            "/assistant/chat_history",
            {
                messageId,
                messageContent
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getCharacterChatHistory(characterId, page = 1) {
        return this.axios.get(`/get_current_user_chat_history_with_reactions/${characterId}?page=${page}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    voteMessage(characterId, voteType, messageId) {
        return this.axios.post(
            "/chat/message/vote",
            {
                messageId,
                characterId,
                voteType
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    createCommentAIMessage(body) {
        return this.axios.post(
            "/chat/message/comment",
            { ...body },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getSchedueledMessages(characterId) {
        return this.axios.get(`/ai_character/${characterId}/schedueled_messages`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    deleteSchedueledMessage(characterId, messageId) {
        return this.axios.delete(`/ai_character/${characterId}/schedueled_messages/${messageId}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    deleteSchedueledMessageImage(characterId, messageId, image) {
        return this.axios.delete(`/ai_character/${characterId}/schedueled_messages/${messageId}/${image}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    updateSchedueledMessages(characterId, formData) {
        return this.axios.patch(`/ai_character/${characterId}/schedueled_messages/`, formData, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getSuggestedCoupon(recentMessage, userId, customAIModelId) {
        return this.axios.post(
            "/suggest_coupon/",
            {
                recentMessage,
                userId,
                customAIModelId
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getCharacterSubscriptionStatus(characterId) {
        return this.axios.get(`/ai_character/${characterId}/subscription_status`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    createSubscription(characterId) {
        return this.axios.post(
            `/ai_character/subscribe`,
            { characterId },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    completeCreateSubscription(characterId, paymentId, subscriptionId) {
        return this.axios.post(
            `/ai_character/subscribe/complete`,
            { characterId, paymentId, subscriptionId },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    sendCharacterMessage(characterId, 
        message, 
        lang = "en", 
        autoTranslate = false, 
        rolePlay = false, 
        isChatPublic = false,
        spicyEnabled = false,) {
        return this.axios.post(
            `/character_chat/message`,
            { characterId, message, lang, autoTranslate, rolePlay, isChatPublic, spicyEnabled },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    trySendCharacterMessage(
        characterId,
        message,
        previous_messages = [],
        lang = "en",
        autoTranslate = false,
        rolePlay = false
    ) {
        return this.axios.post(`/try_character_chat/message`, {
            characterId,
            message,
            previous_messages,
            lang,
            autoTranslate,
            rolePlay
        });
    }

    getCreatedCharacters(isRedbit = false, userId = "") {
        return this.axios.get(`/ai_characters/public-created-characters?isRedbit=${isRedbit}&userId=${userId}`);
    }

    getUserPublicChats(userId = "") {
        return this.axios.get(`/user_character_chats?userId=${userId}`);
    }

    deleteCharacterChat(characterId) {
        return this.axios.delete(`/chat/${characterId}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    saveReviewCharacter(rating = 0, ratingDescription = "", characterId) {
        return this.axios.post(
            `/character/rating`,
            {
                rating,
                ratingDescription,
                characterId
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    updateReviewCharacter(rating = 0, ratingDescription = "", reviewId) {
        return this.axios.patch(
            `/character/rating`,
            {
                rating,
                ratingDescription,
                reviewId
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getCharacterReviews(characterId) {
        return this.axios.get(`/character_ratings/${characterId}`);
    }

    getCharacterFavoriteStatus(characterId) {
        return this.axios.get(`/is_character_favorite/?characterId=${characterId}`,  {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    saveCharacterToFavorites(characterId, status) {
        return this.axios.post(
            `/add_favorite_character/`,
            {
                characterId,
                status
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getFavoriteCharacters() {
        return this.axios.get(`/favorite_characters`,   {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    createGroupChat(name, userMembers = [], aiMembers = []) {
        return this.axios.post(
            `/create_group_chat/`,
            {
                name, userMembers, aiMembers,
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getGroupChats() {
        return this.axios.get(`/group_chats`,   {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getGroupChatsByCharacter(id = '') {
        return this.axios.get(`/group_chats?characterId=${id}`,   {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getGroupChatMessages(group_id) {
        return this.axios.get(`/group_chat_messages/${group_id}`,   {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    updateGroupChat(id, userMembers = [], aiMembers = []) {
        return this.axios.patch(
            `/group/`,
            {
                userMembers, aiMembers, groupId: id
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    regenerateAIMessage(characterId, messageId, autoTranslate = false, rolePlay = false) {
        return this.axios.patch(
            `/character_chat/regenerate_message`,
            {
                characterId, messageId, autoTranslate, rolePlay
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getTopGroupChats(adultContent = false) {
        return this.axios.get(`/top_group_chats?adultContent=${adultContent}`);
    }

    reportCharacter(characterId, status) {
        return this.axios.post(
            `/report_character_adult/`,
            {
                characterId,
                status
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    chat_message_translate(text, message_id, type ) {
        return this.axios.post(
            `/chat_message_translate/`,
            {
                text, message_id, type 
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }
}

export const AIApiInstance = new AIAPI();

export default AIAPI;
