import * as Yup from 'yup';

export const AddCouponSchema = Yup.object({
  couponName: Yup.string().label('Coupon name').required(),
  couponDescription: Yup.string().label('Coupon description').required(),
  priceInPoints: Yup.number().min(0).nullable().label('Point price'),
  priceInDollars: Yup.number().min(0).nullable().label('Price per coupon in dollars'),
  priceInBTC: Yup.number().min(0).nullable().label('Price per coupon in BTC'),
  category: Yup.string().label('Coupon category'),
  quantity: Yup.number().min(1).max(9999).label('Coupon quantity').required(),
});
