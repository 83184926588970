import { Button, Form } from "react-bootstrap";
import { adminAPIInstance } from "../../../apis/AdminAPI";
import { ethers } from "ethers";
import React, { useRef, useState } from "react";
import { ethLiveNetworks, ethTestNetworks, btcNetworks, blockchains, tokenTypes } from "../../../utils/config";

export const CryptoToCrypto = () => {
    const token1InputRef = useRef(null);
    const token2InputRef = useRef(null);

    const [token1Chain, setToken1Chain] = useState("");
    const [token1Network, setToken1Network] = useState("");

    const [token2Chain, setToken2Chain] = useState("");
    const [token2Network, setToken2Network] = useState("");

    const [submitLoading, setSubmitLoading] = useState(false);

    const getCompleteChainName = (chain) => {
        const idx = blockchains.findIndex((_chain) => _chain.value === chain);
        if (idx !== -1) {
            return blockchains[idx].completeChainName;
        }
        return "";
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            const token1Address = token1InputRef.current?.value ?? "";
            const token2Address = token2InputRef.current?.value ?? "";

            if (!token1Network || !token2Network) {
                alert("Please select network");
                return false;
            }

            if (token1Chain === "ETH" && token1Address !== "" && !ethers.utils.isAddress(token1Address)) {
                alert("Invalid erc20 address for token 1");
                return false;
            }

            if (token2Address === "ETH" && token2Address !== "" && !ethers.utils.isAddress(token2Address)) {
                alert("Invalid erc20 address for token 2");
                return false;
            }

            let token1Type = "";
            let token2Type = "";

            if (token1Chain === "ETH" && ethers.utils.isAddress(token1Address)) {
                token1Type = tokenTypes.ERC20;
            } else if (token1Chain === "ETH" && token1Address === "") {
                token1Type = tokenTypes.NATIVE_ETH;
            } else if (token1Chain === "BTC") {
                token1Type = tokenTypes.BTC;
            } else {
                // pass
            }

            if (token2Chain === "ETH" && ethers.utils.isAddress(token2Address)) {
                token1Type = tokenTypes.ERC20;
            } else if (token2Chain === "ETH" && token2Address === "") {
                token2Type = tokenTypes.NATIVE_ETH;
            } else if (token2Chain === "BTC") {
                token2Type = tokenTypes.BTC;
            } else {
                // pass
            }

            if (!token1Type || !token2Type) {
                alert("Invalid type");
                return false;
            }

            const body = {
                token1: {
                    name: getCompleteChainName(token1Chain),
                    symbol: token1Chain,
                    type: token1Type,
                    ...(token1Chain === "ETH"
                        ? {
                              erc20Data: {
                                  address: token1Address,
                                  network: token1Network
                              }
                          }
                        : {})
                },
                token2: {
                    name: getCompleteChainName(token2Chain),
                    symbol: token2Chain,
                    type: token2Type,
                    ...(token2Chain === "ETH"
                        ? {
                              erc20Data: {
                                  address: token2Address,
                                  network: token2Network
                              }
                          }
                        : {})
                }
            };

            setSubmitLoading(true);
            await adminAPIInstance.addTokenPair({ body });
            setSubmitLoading(false);

            alert("Successfully added pair");
        } catch (error) {
            console.error(error?.message);
            alert("Error adding pair");
            return false;
        }
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Label htmlFor="token1Input">Token 1</Form.Label>
                <div className="d-flex flex-row mb-4">
                    <Form.Select
                        aria-label="Token1 select chain"
                        className="me-2"
                        style={{ flex: 1 }}
                        value={token1Chain}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setToken1Network("");
                            }
                            if (e.target.value === "BTC") {
                                token1InputRef.current.value = "Bitcoin";
                                token1InputRef.current.disabled = true;
                            } else {
                                token1InputRef.current.value = "";

                                token1InputRef.current.disabled = false;
                            }
                            setToken1Chain(e.target.value);
                        }}
                    >
                        <option value="">--Select token--</option>
                        {blockchains.map((chain) => (
                            <option key={`token1-${chain.value}`} value={chain.value}>
                                {chain.value}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select
                        aria-label="Token1 select network"
                        className="me-2"
                        style={{ flex: 1 }}
                        disabled={token1Chain === ""}
                        value={token1Network}
                        onChange={(e) => setToken1Network(e.target.value)}
                    >
                        <option value="">--Select network--</option>
                        {[
                            ...(token1Chain === "ETH"
                                ? [...ethLiveNetworks, ...ethTestNetworks]
                                : token1Chain === "BTC"
                                ? btcNetworks
                                : [])
                        ].map((network) => (
                            <option key={`token1-network-${network.value}`} value={network.value}>
                                {network.label}
                            </option>
                        ))}
                    </Form.Select>
                    <div style={{ flex: 3 }}>
                        <Form.Control
                            ref={token1InputRef}
                            type="text"
                            id="token1Input"
                            aria-describedby="token1InputHelpBlock"
                            placeholder="Input token 1 or leave black if native token"
                        />
                    </div>
                </div>
                <Form.Label htmlFor="token2Input">Token 2</Form.Label>
                <div className="d-flex flex-row mb-4">
                    <Form.Select
                        aria-label="Token2 select chain"
                        className="me-2"
                        style={{ flex: 1 }}
                        value={token2Chain}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setToken2Network("");
                            }

                            if (e.target.value === "BTC") {
                                token2InputRef.current.value = "Bitcoin";
                                token2InputRef.current.disabled = true;
                            } else {
                                token2InputRef.current.value = "";

                                token2InputRef.current.disabled = false;
                            }
                            setToken2Chain(e.target.value);
                        }}
                    >
                        <option value="">--Select token--</option>
                        {blockchains.map((chain) => (
                            <option key={`token2-${chain.value}`} value={chain.value}>
                                {chain.value}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select
                        aria-label="Token2 select network"
                        className="me-2"
                        style={{ flex: 1 }}
                        disabled={token2Chain === ""}
                        value={token2Network}
                        onChange={(e) => setToken2Network(e.target.value)}
                    >
                        <option value="">--Select network--</option>
                        {[
                            ...(token2Chain === "ETH"
                                ? [...ethLiveNetworks, ...ethTestNetworks]
                                : token2Chain === "BTC"
                                ? btcNetworks
                                : [])
                        ].map((network) => (
                            <option key={`token2-network-${network.value}`} value={network.value}>
                                {network.label}
                            </option>
                        ))}
                    </Form.Select>
                    <div style={{ flex: 3 }}>
                        <Form.Control
                            ref={token2InputRef}
                            type="text"
                            id="token2Input"
                            aria-describedby="token2InputHelpBlock"
                            placeholder="Input token 2 or leave black if native token"
                        />
                    </div>
                </div>
                <Button type="submit" variant="primary" disabled={submitLoading}>
                    Submit
                </Button>
            </Form>
        </div>
    );
};
