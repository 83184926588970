import React from "react";
import Button from "react-bootstrap/Button";

export default function ApiPlan({ plan, handlePackageUpgrade, callsLeftPerMonth = 0 }) {
    return (
        <div className="api-keys-container">
            <div className="top-container">
                <h6>Current API plans</h6>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            <div className="api-keys-table">
                <div className="header">
                    <span id="app-name">My API plan</span>
                    <span id="api-key">{plan.plan.toLocaleUpperCase()} API PLAN</span>
                    <span>
                        <Button onClick={handlePackageUpgrade} id="upgrade-plan-btn">{`Upgrade plan`}</Button>
                    </span>
                </div>
                <div className="api-plan-table-row">
                    <span className="api-calls-per-second">API calls per month</span>
                    <span>{plan.callsPerMonth} calls</span>
                    <span>{callsLeftPerMonth} calls left</span>
                </div>
            </div>
        </div>
    );
}
