import { config, imagePathConfig } from "./config";
import NO_IMAGE from '../assets/images/NO_IMAGE.png';
import WHITE_IMAGE from '../assets/images/WHITE.jpg';

export const getUserImageUrl = (imageName) => {
    return `${config[config.environment].host}${imagePathConfig.userImagePath}/${imageName}`;
};

export const getAiCharacterImageUrl = (imageName) => {
    return `${config[config.environment].host}${imagePathConfig.aiCharacterImagePath}/${imageName}`;
};

export const getAiCharacterImageUrlV2 = (character) => {
    let avatarUrl = "";
    if (character?.avatarUrls?.length > 0) {
        avatarUrl = character.avatarUrls[0];
    }
    if (character?.avatarUrl) {
        avatarUrl = character.avatarUrl;
    }
    if (avatarUrl.startsWith("http")) {
        return avatarUrl;
    }
    return getAiCharacterImageUrl(avatarUrl);
};

export const getMerchantImageUrl = (imageName) => {
    return `${config[config.environment].host}/${
      imagePathConfig.merchantStoreImagePath
    }/${imageName}`;
  };

  export const getCouponQRImageUrl = (_id) => {
    return `${config[config.environment].host}/scan_qr_code?qrType=coupon&couponUUID=${_id}`;
  }; 
  
  export const getCouponImageUrl = (imageName) => {
    return `${config[config.environment].host}/${
      imagePathConfig.couponImagePath
    }/${imageName}`;
  };

  export const getMessageAudioUrl = (audioName) => {
    return `${config[config.environment].host}${
      imagePathConfig.messageAudioPath
    }/${audioName}`;
  };

  export const getSchedueledImageUrl = (imageName) => {
    return `${config[config.environment].host}/public${
      imagePathConfig.schedueledImagePath
    }/${imageName}`;
  }; 

export const getStoreDisplayableImage = (merchant) => {
    if (merchant?.storePhoto) {
      return getMerchantImageUrl(merchant.storePhoto);
    } else if (merchant?.storePhotos?.[0]) {
      return getMerchantImageUrl(merchant.storePhotos[0]);
    } else {
      return null;
    }
  };


export { NO_IMAGE, WHITE_IMAGE };