export const urlParser = (url, searchQueries) => {
    const parsedUrl = new URL(url, window.location);

    Object.entries(searchQueries).forEach((entry) => {
        if (typeof entry[1] !== "undefined") {
            parsedUrl.searchParams.set(entry[0].toString(), entry[1].toString());
        }
    });

    return parsedUrl.pathname + parsedUrl.search;
};
