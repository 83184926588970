/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { orderAPIInstance } from "../../apis/OrderAPI";

const useOrders = ({ type, status, me }) => {
    const [orders, setOrders] = useState([]);

    const fetchMyPointOrders = useCallback(async () => {
        try {
            const myOrders = await orderAPIInstance.getOrders({ type, status, me });
            if (myOrders.status === 200) {
                setOrders(myOrders.data?.data?.orders ?? []);
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        fetchMyPointOrders();
    }, [fetchMyPointOrders]);

    return orders;
};

export default useOrders;
