import * as Yup from "yup";

export const AddStoreSchema = Yup.object({
    storeName: Yup.string().label("Store name").required(),
    businessAddress: Yup.string().label("Business address").required(),
    businessID: Yup.string().label("Business ID").required(),
    storeDescription: Yup.string().max(128).label("Store description").required(),
    pricePerPoint: Yup.number().nullable().min(0).label("Point price"),
    numberOfPoints: Yup.number().nullable().min(0).label("Number of points"),
    points: Yup.number().nullable().label("Points"),
    airdropPoints: Yup.number().nullable().min(0).label("Points to airdrop"),
    allowPointsOnExchange: Yup.bool().label("Allow points on Exchange"),
    allowFollowersRecommendation: Yup.bool().label("Allow followers recommendation"),
    followersRecommendationPoints: Yup.number().nullable().label("Followers recommendation points")
});
