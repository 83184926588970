import * as Yup from 'yup';

export const CreatorProfileSchema = Yup.object({
  creatorName: Yup.string().label('Creator Name').required(),
  creatorPlatform: Yup.string().label('Creator Platform').required(),
  creatorDescription: Yup.string().label('Creator Description').required(),
  pricePerPost: Yup.number().moreThan(0).label('Price Per Post').required(),
  isAdultContent: Yup.boolean().required(),
  socialMediaLinks: Yup.array(Yup.string().label('Social Media Links').required())
});
