import React, { useState } from "react";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import { Form, Button } from "react-bootstrap";
import { Modal } from "../../../Component/Modal/Modal";
import "./index.css";
import { adminAPIInstance } from "../../../apis/AdminAPI";

const PayoutNotesModalBody = ({ notes, handleNotesChange, handlePayoutUpdate, payout_id }) => {
    
    const handleSave = () => {
      handlePayoutUpdate({ updates: { note: notes }, payout_id });
    };

    return (
        <div className="payoutNotesModalBody-container">
            <Form.Control value={notes} as="textarea" placeholder="Leave a comment here" onChange={handleNotesChange} />
            <Button onClick={handleSave} variant="primary">Save</Button>
        </div>
    );
};

export default function PayoutRow({ payout, i }) {
    const [status, setStatus] = useState(payout?.status ?? "null");
    const [notes, setNotes] = useState(payout?.note ?? "");
    const [modalIsOpen, setIsOpen] = useState(false);

    const handleStatusChange = (e) => {
        const value = e.target.value;
        handlePayoutUpdate({ updates: { status: value }, payout_id: payout._id });
        setStatus(value);
    };

    const handleNotesChange = (e) => {
        const value = e.target.value;
        setNotes(value);
    };

    const handleShowNotes = () => {
        setIsOpen(true);
    };

    const handlePayoutUpdate = (body) => {
        adminAPIInstance
            .updatePayout({ body })
            .then((_) => {
                alert("Updated.");
            })
            .catch(e => {
              alert("Something went wrong.");
            });
    };

    return (
        <>
            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                body={<PayoutNotesModalBody notes={notes} handleNotesChange={handleNotesChange}
                 handlePayoutUpdate={handlePayoutUpdate} payout_id={payout._id}/>}
            />
            {
                <tr key={payout?._id ?? i}>
                    <td>{i + 1}</td>
                    <td>{payout?.userInfo?.username}</td>
                    <td>{payout?.amount}</td>
                    <td>{payout?.userInfo?.bankName ?? "-"}</td>
                    <td>{payout?.userInfo?.bankAccountNumber ?? "-"}</td>
                    <td>{payout?.userInfo?.bankRoutingNumber ?? "-"}</td>
                    <td>
                        <Form.Select value={status} onChange={handleStatusChange} aria-label="Select Payout Status">
                            <option disabled={true} value={"null"}>
                                Select Payout Status
                            </option>
                            <option value="REQUESTED">REQUESTED</option>
                            <option value="COMPLETE">COMPLETE</option>
                        </Form.Select>
                    </td>
                    <td>
                        <div className="notes-row">
                            <span>{notes?.substring(0, 5)}</span>
                            <span className="show-modal" onClick={handleShowNotes}>
                                Show
                            </span>
                        </div>
                    </td>
                    <td>{formatDate(payout?.createdAt)}</td>
                </tr>
            }
        </>
    );
}
