import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ChatTab from "./ChatTab";
import { Button, Spinner } from "react-bootstrap";
import EmptyPage from "./EmptyChatTab";
import { AIApiInstance } from "../../../apis/AIAPI";
import { getAiCharacterImageUrl, getAiCharacterImageUrlV2 } from "../../../utils/images";
import { removeChatPrefix } from "../../../utils/customCharacter";
import { useNavigate } from "react-router";
import { Modal } from "../../Modal/Modal";
import GroupCreateModal from "./GroupCreateModal";

const groupModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: "50vh",
        width: "50vh",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#57389C"
    },
};

export default function AIChatTab({ setSelectedId, selectedId, selectedType, status, chatsDrawerOpen, toggleDrawer, currentGroupChat = {}, isGroup =  false}) {
    const dispatch = useDispatch();
    const [characters, setCharacters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    const fetchAIChats = useCallback(async () => {
        setLoading(true);

        const res = await AIApiInstance.getAllMyCustomAIChars();
        // const res = [];
        const groupChats_res = await AIApiInstance.getGroupChats();
        // const groupChats_res = [];

        if (res?.data?.data?.aiCharacters) {
            const aiCustomCharacters = res?.data?.data?.aiCharacters;
            const groupChats = groupChats_res?.data?.data?.groups?.map(g => {
                let _group = {...g, characterName: g?.name, isGroup: true};
                if (g?.groupMessages?.length > 0) {
                    _group = {
                        ..._group,
                        lastMessage: {
                            messageContent: {
                                encrypted_text: g?.groupMessages[0]?.message
                            }
                        }
                    };
                } else {
                    let usernames = '';
                    g?.userMembers?.forEach((um) => {
                        usernames += um?.username + ', ' ;
                    })
                    if(usernames.length > 20){
                        usernames = usernames.slice(0, 17) + '...';
                    }
                    _group = {
                        ..._group,
                        lastMessage: {
                            messageContent: {
                                encrypted_text: usernames
                            }
                        }
                    };
                }
                return _group;
            }) ?? [];
            const sorted = [...groupChats, ...aiCustomCharacters];
            //bug in set Characters
            setCharacters(sorted); //TODO: change to dispatch

            console.log(groupChats.length, selectedId, aiCustomCharacters.length)
            console.log('selectedId', selectedId, 'isGroup', isGroup)

            if(selectedId){
                if(isGroup){ // this second if is intentionally made nested
                    const selectedGroup = groupChats.find(group => group?._id === selectedId);
                    console.log('selectedGroup', selectedGroup)
                    if(selectedGroup){
                        setSelectedId(selectedGroup, "group");
                    }
                }
            } else {
                console.log("not selectedId")
                if(groupChats.length > 0){
                    setSelectedId(groupChats[0], "group");
                } else if (aiCustomCharacters.length > 0) {
                    setSelectedId(sorted[0], "ai");
                }  
            }
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        //bug fix here 
        try {
            if (selectedType === "ai" || !selectedType) {
                fetchAIChats();
            }
        }
        catch (e) {
            console.log("fetchAIChats error: ",e);
        }
    }, []);

    const handleSelectChat = (character) => {
        if(chatsDrawerOpen){
            toggleDrawer();
        }
        if(!character?.isGroup){
            setSelectedId(character, "ai")
        } else {
            setSelectedId(character, "group")
        }
    }

    const handleCreateCharacterClick = () => {
        navigate('/create-character');
    }

    const handleCreateGroupClick = () => {
        //setModalOpen(true)
        navigate('/create-groupchat');
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }

    const getCharacterAvatar = (character) => {
        if(character?.aiMembers){
            return getAiCharacterImageUrlV2(character?.aiMembers?.[0]);
        } else {
            return getAiCharacterImageUrlV2(character);
        }
    }

    const handleGroupSave = (name, selectedUsers, selectedChars) => {
        AIApiInstance.createGroupChat(name, selectedUsers, selectedChars).then(res => {
            alert("Group chat created.");
            groupChatAdded(res.data?.data?.group);
            // closeModal();
        }).catch(console.error);
    }

    const groupChatAdded = (chat) => {
        setCharacters((prev) => {
            const new_chats = [...prev];
                    let usernames = '';
                    chat?.userMembers?.forEach((um) => {
                        usernames += um?.username + ', ' ;
                    })
                    if(usernames.length > 20){
                        usernames = usernames.slice(0, 17) + '...';
                    }
                    chat = {
                        ...chat,
                        lastMessage: {
                            messageContent: {
                                encrypted_text: usernames
                            }
                        }
                    };

            new_chats.unshift({...chat, characterName: chat?.name, message: "No messages", isGroup: true });
            return new_chats;
        });
    };

    return (
        <div className="d-flex flex-column flex-grow-1">
            <Modal styles={groupModalStyles} modalIsOpen={modalOpen} setIsOpen={setModalOpen} body={<GroupCreateModal closeModal={handleModalClose} handleGroupSave={handleGroupSave}/>}/>
            <Button
                className="rounded align-items-center justify-content-center d-flex my-1 mx-3"
                variant="light"
                onClick={handleCreateCharacterClick}
            >
                <i className="bi bi-plus mb-1"></i>
                <p className="mb-0 ms-1">Create Character</p>
            </Button>

            <Button
                className="rounded align-items-center justify-content-center d-flex mx-3"
                variant="light"
                onClick={handleCreateGroupClick}
            >
                <i className="bi bi-plus mb-1"></i>
                <p className="mb-0 ms-1">Create Group Chat</p>
            </Button>
            <div className="mx-4 my-2">
                Latest Chats
            </div>
            {loading ? (
                <div className="justify-content-center align-items-center d-flex my-4">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : characters.length === 0 ? (
                <EmptyPage />
            ) : (
                <>
                    <ul
                        className="list-unstyled list-group list-group-custom list-group-flush px-3 gap-3 pt-2"
                        style={{ maxHeight: "85vh" }}
                    >
                        {characters.length > 0 &&
                            characters.map((character, i) => {
                                return (
                                    <ChatTab
                                        key={character._id ?? `ChatTab${i}`}
                                        id={character._id}
                                        name={character.characterName}
                                        avatarUrl={
                                            getCharacterAvatar(character)
                                            // getAiCharacterImageUrl(getCharacterAvatar(character))
                                        }
                                        // timestamp={character.createdAt}
                                        message={removeChatPrefix(character.lastMessage?.messageContent?.encrypted_text)}
                                        onClick={() => handleSelectChat(character)}
                                        selectedId={selectedId}
                                        status={status}
                                        isGroup={character?.isGroup}
                                    />
                                );
                            })}
                    </ul>
                </>
            )}
        </div>
    );
}
