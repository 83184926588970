import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Landing from "../../Screen/Landing/Landing";
import Signup from "../Auth/Signup";

const pagesToIgnore = ['', 'character', 'coupon', 'explore', 'premium-price'];


function ProtectedPage(props) {
    const path = useLocation().pathname.split('/')[1];

    if (props.AuthReducer.authLoading) {
        return (
            <div className="d-flex" style={{ height: "100vh", width: "100%" }}>
                <span className="m-auto">
                    <i className="fas fa-circle-notch fa-spin text-warning" style={{ fontSize: "2rem" }}></i>
                </span>
            </div>
        );
    }
    // unprotected pages, no need to check auth
    if (pagesToIgnore.indexOf(path) !== -1 || path.startsWith('@')) {
        return props.children;
    }

    if (props.AuthReducer.authenticated && props.AuthReducer.user) {
        return props.children;
    }

    return <Signup />;
}

const mapStateToProps = ({ Mainreducer, AuthReducer }) => ({
    Mainreducer,
    AuthReducer
});

export default connect(mapStateToProps, {})(ProtectedPage);
