import { useEffect, useMemo, useRef, useState } from "react";
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import { useNavigate, useParams } from "react-router";
import Avatar from "../../../Component/Comman/Avatar";
import { AIApiInstance } from "../../../apis/AIAPI";
import { getAiCharacterImageUrlV2, getSchedueledImageUrl, getUserImageUrl } from "../../../utils/images";
import HiddenImage from '../../../assets/images/hide-image.png';
import { formatDate } from "../../../utils/formatter/dateFormatter";
import { MessageComments } from "./Comments";
import { MessageReactions } from "./MessageReaction";

const messageViewType = {
    CHATS: "CHATS",
    MESSAGES: "MESSAGES",
    COMMENTS: "COMMENTS",
};

export const PublicGroupChats = ({ chats = [], characterName = "", selectedChatUserId = "", character = {} }) => {
    const [view, setview] = useState(messageViewType.CHATS);
    const [selectedChat, setSelectedChat] = useState(-1);
    const selectedMessageIndex = useRef(-1);
    const [currentMessages, setcurrentMessages] = useState([]);
    const { id } = useParams();
    const [pages, setpages] = useState([1]);
    const [startPage, setStartPage] = useState(0);
    const [endPage, setEndPage] = useState();
    const [selectedPage, setSelectedPage] = useState(1);
    const increasePageStart = useRef(null);
    const chatUserId = useRef();
    const navigate = useNavigate();

    const authorInfo = useMemo(() => {
        return chats[selectedChat]?.userMembers?.find((um) => um?._id === chats[selectedChat]?.createdBy);
    }, [chats, selectedChat]);

    const handleChatClick = (i) => {
        const groupChatId = chats?.[i]?._id;
        setSelectedChat(i);
        if(groupChatId){
            chatUserId.current = groupChatId;
            AIApiInstance.getGroupChatMessages(groupChatId)
            .then(res => {
                setcurrentMessages(res.data?.data?.messages);
                // setpages(() => {
                //     const arr = [];
                //     for (let i = 1; i <= res.data?.data?.totalPages; i++) {
                //         arr.push(i);                       
                //     }
                //     return arr;
                // });
            })
            .catch(console.error);
        }
        setview(messageViewType.MESSAGES);
    };

    useEffect(() => {
        if(chats.length > 0 && selectedChatUserId){
            const foundIndex = chats.findIndex(ch => ch?.user?._id === selectedChatUserId)
            if(foundIndex > -1){
                handleChatClick(foundIndex); 
            }
        }
    }, [chats, selectedChatUserId]);

    useEffect(() => {
        setStartPage(0)
        setEndPage(pages.length > 9 ? 9 : pages.length - 1)
    }, [pages]);

    useEffect(() => {
        // if (id && chatUserId.current) {
        //     AIApiInstance.getCharacterPublicChatMessages(id, chatUserId.current, selectedPage + 1)
        //         .then((res) => {
        //             setcurrentMessages(res.data?.data?.messages);
        //         })
        //         .catch(console.error);
        // }
    }, [id, selectedPage]);

    const handlePageClick = (pg) => {
        if (pg === "prev") {
            increasePageStart.current = true;
            setStartPage(prev => {
               let nextStartPage = prev - 10;
               if (nextStartPage < 0) {
                   nextStartPage = 0;
               }
               return nextStartPage;
            });
            return;
        }
        if (pg === "next") {
            increasePageStart.current = true;
            setStartPage(prev => {
                let nextStartPage = prev + 10;
                if (nextStartPage > pages.length - 1) {
                    nextStartPage = pages.length - 1;
                }
                return nextStartPage;
             });
            return;
        }
        setSelectedPage(pg);
    };

    useEffect(() => {
        setSelectedPage(startPage);
        if (increasePageStart.current !== null) {
            if (increasePageStart.current === true) {
                setEndPage(_ => {
                    let nextEndPage = startPage + 10;
                    if (nextEndPage > pages.length - 1) {
                        nextEndPage = pages.length - 1;
                    }
                    return nextEndPage;
                 });
            } else {
                setEndPage(_ => {
                    let nextEndPage = startPage - 10;
                    if (nextEndPage < 0) {
                        nextEndPage = 0;
                    }
                    return nextEndPage;
                 });
            }
        }
    }, [pages.length, startPage]);

    const handleBackClick = () => {
        setview(messageViewType.CHATS);
        setcurrentMessages([]);
    }

    const handleSelectedMessage = (i) => {
        selectedMessageIndex.current = i;
        setview(messageViewType.COMMENTS);
    }

    const handleBackFromComments = () => {
        selectedMessageIndex.current = -1;
        setview(messageViewType.MESSAGES);
    };

    const handleAvatarClick = () => {
        navigate(`/@${authorInfo?.username}`);
    };


    return (
        <>
            {view === messageViewType.MESSAGES && (
                <div className="d-flex justify-content-between align-items-center my-2">
                    {view === messageViewType.MESSAGES && (
                        <Button onClick={handleBackClick} className="my-2">
                            Back
                        </Button>
                    )}
                    <div className="d-flex flex-row gap-2 align-items-center mx-4" onClick={handleAvatarClick}>
                        <span>Author:</span>
                        <Avatar
                            avatarUrl={getUserImageUrl(authorInfo?.avatar_url)}
                            name={authorInfo?.username}
                            className="br-50 public-chat-avatar"
                        />
                        <span>
                            {authorInfo?.username}
                        </span>
                    </div>
                </div>
            )}
            {view === messageViewType.COMMENTS && (
                <Button onClick={handleBackFromComments} className="my-2">
                    Back
                </Button>
            )}
            <div className={`flex-row flex-wrap gap-2 my-4 ${view === messageViewType.CHATS ? "d-flex" : "d-none"}`}>
                {chats.map((chat, i) => {
                    return (
                        <PublicChatItem
                            handleClick={handleChatClick}
                            key={chat?._id ?? `publicChat${i}`}
                            i={i}
                            characterName={characterName}
                            info={chat}
                        />
                    );
                })}
            </div>
            {chats.length === 0 && <div className="d-flex flex-row justify-content-center">No public chats available</div>}
            <div
                className={`flex-column gap-2 public-pagination-messages-container ${
                    view === messageViewType.MESSAGES ? "d-flex" : "d-none"
                }`}
            >
                <div className={`flex-column gap-2 public-messages-container`}>
                    {currentMessages?.map((message, i) => {
                        return (
                            <PublicChatItem
                                containerClass="w-100"
                                characterName={characterName}
                                message={message}
                                key={message?._id ?? `publicChatMessage${i}`}
                                info={chats[selectedChat]}
                                sendReceivePosition={true}
                                handleSelectedMessage={handleSelectedMessage}
                                i={i}
                                character={character}
                            />
                        );
                    })}
                </div>
                <Pagination>
                    {startPage > pages?.[0] && (
                        <Pagination.Item key={`public_pg_start`} onClick={() => handlePageClick("prev")}>prev</Pagination.Item>
                    )}
                    {pages.map((pg, i) => {
                        if (i >= startPage && i <= endPage) {
                            return (
                                <Pagination.Item key={`public_pg_${i}`} onClick={() => handlePageClick(i)} active={i === selectedPage}>
                                    {pg}
                                </Pagination.Item>
                            );
                        }//no need to return anything here.
                    })}
                    {startPage < pages?.[pages.length - 1] && (
                        <Pagination.Item key={`public_pg_next`} onClick={() => handlePageClick("next")}>next</Pagination.Item>
                    )}
                </Pagination>
            </div>
            {view === messageViewType.COMMENTS && (
                <MessageComments message={currentMessages?.[selectedMessageIndex.current]} />
            )}
        </>
    );
};

const PublicChatItem = ({
    info,
    characterName = "",
    message = null,
    handleClick = () => {},
    i = null,
    containerClass = "w-100",
    sendReceivePosition = false,
    handleSelectedMessage = () => {},
    // character = {}
}) => {
    const navigate = useNavigate();

    let character = info?.aiMembers?.[0];

    const _message = message ?? info?.groupMessages?.[info?.groupMessages?.length - 1];
    const getContainerStyle = () => {
        if (sendReceivePosition) {
            return !_message?.sentByUser ? "justify-content-start" : "justify-content-end";
        }
        return "";
    };

    const getMessageStyle = () => {
        if (sendReceivePosition) {
            return !_message?.sentByUser ? "received-message-container" : "sent-message-container";
        }
        return "";
    };

    const getTextStyle = () => {
        return sendReceivePosition ? 'text-white' : '';
    };

    const getMessageImage = () => {
        return _message?.imageUrl !== "hidden" ? getSchedueledImageUrl(_message?.imageUrl) : HiddenImage;
    };

    const handleImageClick = () => {
        if (_message?.imageUrl === "hidden") {
            const confirm = window.confirm("Please Subscribe to see the Image.");
            if (confirm) {
                navigate("/premium-price");
            }
        }
    };

    const handleAvatarClick = () => {
        navigate(`/@${userInfo?.username}`);
    };

    const getGroupMessageUserInfo = (message, conversation) => {
        return conversation?.userMembers?.find(u => u?._id === message?.userId)
    };

    const userInfo = getGroupMessageUserInfo(_message, info);

    return (
        <>
            {info?.groupMessages?.length > 0 && (
                <div key={`public-chat-bubble${i}`} className={`d-flex ${getContainerStyle()} ${containerClass} public-chat-bubble`}>
                   <div
                        className={`card p-3 public-chat-item gap-2 ${getMessageStyle()}`}
                        onClick={() => {
                            handleClick(i);
                        }}
                    >
                        <div className="d-flex flex-row gap-2 align-items-center">
                            <Avatar
                                avatarUrl={_message?.sentByUser ? getUserImageUrl(userInfo?.avatar_url) : getAiCharacterImageUrlV2(character)}
                                name={userInfo?.username}
                                className="br-50 public-chat-avatar"
                                onClick={handleAvatarClick}
                            />
                            <span className={getTextStyle()}>
                                {_message?.sentByUser ? userInfo?.username : characterName}
                            </span>
                        </div>
                        {sendReceivePosition && _message?.imageUrl && (
                            <div onClick={handleImageClick} style={{ width: "100px", height: "100px", cursor: "pointer" }}>
                                <img src={getMessageImage()} style={{ width: "100%", height: "100p%" }} alt="" />
                            </div>
                        )}
                        <p className={`my-0 ${getTextStyle()}`}>{_message?.message}</p>
                        <div className="d-flex justify-content-end">
                            <span className={`public-message-date ${getTextStyle()}`}>
                                {formatDate(_message?.createdAt)}
                            </span>
                        </div>
                        <MessageReactions key={`reactions${i}`} message={_message} handleSelectedMessage={handleSelectedMessage} i={i} />
                    </div>
                </div>
            )}
        </>
    );
};
