import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { authAPIInstance } from "../../apis/AuthAPI";

export default function EmailVerification({
    emailVerificationModal,
    setemailVerificationModal,
    emailVerification,
    setEmailVerification
}) {
    const dispatch = useDispatch();

    const handleEmailCodeChange = (e) => {
        const value = e.target.value;
        setEmailVerification((prev) => ({ ...prev, code: value }));
    };

    const handleEmailVerificationSubmit = async () => {
        try {
            setEmailVerification((prev) => ({ ...prev, loading: true }));
            if (emailVerification.code_sent) {
                await authAPIInstance.completeEmailVerification(emailVerification.code);
                setEmailVerification((prev) => ({ ...prev, loading: false }));
                dispatch({
                    type: "UPDATE",
                    payload: {
                        user: {
                            key: "emailVerified",
                            value: true
                        }
                    }
                });
                alert("Email verified successfully.");
                setemailVerificationModal(false);
            } else {
                await authAPIInstance.requestEmailVerification();
                setEmailVerification((prev) => ({ ...prev, code_sent: true, loading: false }));
                alert("Verification Code has been sent to your email.");
            }
        } catch (error) {
            setEmailVerification((prev) => ({ ...prev, loading: false }));
            alert(error?.response?.data?.message ?? "Internal Server Error.");
        }
    };
    return (
        <Modal
            className="modal"
            id="ChangeModal"
            style={{ display: "block" }}
            show={emailVerificationModal}
            onHide={() => {
                setemailVerificationModal(true);
            }}
        >
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title">Verify Email</h5>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Verification Code</label>
                            <input
                                value={emailVerification.code}
                                onChange={handleEmailCodeChange}
                                type="text"
                                className="form-control"
                                disabled={!emailVerification.code_sent}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={() => {
                            setemailVerificationModal(false);
                        }}
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleEmailVerificationSubmit}
                        disabled={emailVerification.loading}
                    >
                        {emailVerification.code_sent ? `Submit` : `Request Verification Code`}
                        {emailVerification.loading && <Spinner className="mx-1" size="sm" />}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}
