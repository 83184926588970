import axios from "axios";
import { readCookie } from "../utils/cookie";
import { config } from "../utils/config";
import { urlParser } from "../utils/urlParser";
import { httpTokenInterceptor } from "./common";

class OrderAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/orders"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    async makeLimitOrder(orderData) {
        return this.axios.post(
            "/make_order",
            { ...orderData },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    async getOrders({ type, status, me }) {
        const apiUrl = urlParser("/get_orders", { type, status, me });
        return this.axios.get(apiUrl, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    async getOrderById(orderId) {
        return this.axios.get(`/get_order/${orderId}`, {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    async getTradeHistory() {
        return this.axios.get("/get_trade_history", {
            headers: {
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    async cancelOrder(orderId) {
        return this.axios.patch(
            `/cancel_order/${orderId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }
}

export const orderAPIInstance = new OrderAPI();

export default OrderAPI;
