import axios from "axios";
import { config } from "../utils/config";
import { readCookie } from "../utils/cookie";
import { httpTokenInterceptor } from "./common";

class AptosAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/apt"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    createAptosWallet(userId) {
        console.log("user create wallet ", userId);
        return this.axios.post(
            `/create_wallet/`,
            {
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    withdrawAptos(otp, token, user, receiverAddress, withdrawAmount) {
        console.log("user create wallet ", user, receiverAddress, withdrawAmount);
        return this.axios.post(
            `/withdraw_aptos/`,
            {
                otp,
                token,
                user,
                receiverAddress,
                withdrawAmount
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }
    
    create2FAToken(user) {
        return this.axios.post(
            `/create_two_factor_token/`,
            {
                user,
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }


    getWalletInfo(userId) {
        return this.axios.post(
            `get_wallet_info/`,
            {
            userId,
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getWithdrawTransaction(userId) {
        return this.axios.post(
            `get_withdraw_transaction/`,
            {
            userId,
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getDepositTransaction(userId) {
        return this.axios.post(
            `get_deposit_transaction/`,
            {
            userId,
            },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }
}

export const AptosAPIInstance = new AptosAPI();

export default AptosAPI;
