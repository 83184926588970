import { useState } from "react";
import PageTitle from "../../../Component/Comman/PageTitle";
import { CustomToast, ToastStatus } from "../../../Component/Comman/CustomToast";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllCoupons,
    getCurrentUserMerchant,
    getCurrentUserMerchantCoupons
} from "../../../Redux/Actions/MerchantActions";
import { MerchantApiInstance } from "../../../apis/MerchantsAPI";
import { useNavigate } from "react-router";
import DealForm from "./DealForm";

const AddBrandDeal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const merchant = useSelector((state) => {
        return state?.merchant?.currentUserMerchant;
    });

    const [toast, setToast] = useState({
        show: false,
        message: "",
        status: ""
    });
    const [isSubmitting, setSubmitting] = useState(false);

    const handleSubmit = async ({couponBody, token, couponUploadImages}) => {
        try {
            setSubmitting(true)
            if (!merchant._id) {
                throw new Error("Merchant id not found");
            }

            let couponResponse = null;

            let newImages = couponUploadImages
                .filter((image) => {
                    return image?.file !== undefined;
                })
                .map((image) => {
                    return image.file;
                });

            couponResponse = await MerchantApiInstance.addCoupon(couponBody, merchant._id, token, newImages);

            if (!couponResponse) {
                throw new Error("Error adding coupon");
            }

            dispatch(getCurrentUserMerchant(token));
            dispatch(getCurrentUserMerchantCoupons(merchant._id, token));
            dispatch(getAllCoupons(token));

            setSubmitting(false)

            setToast({
                show: true,
                message: "Successfully created coupon!",
                status: ToastStatus.SUCCESS
            });

            setTimeout(() => {
                navigate("/my-store");
            }, 2000);
            localStorage.removeItem('unsaved_coupon');
        } catch (error) {
            console.error(error?.message ?? "Error adding coupon");

            setSubmitting(false)

            setToast({
                show: true,
                message: "An error has occurred!",
                status: ToastStatus.ERROR
            });
        }
    };

    return (
        <div className="container-xxl px-4">
            <PageTitle pagetitle="Create Brand Deal for Creators" goBackLink="/creators-marketplace" />
            <DealForm onSubmit={handleSubmit} isSubmitting={isSubmitting}/>

            <CustomToast
                status={toast.status}
                show={toast.show}
                message={toast.message}
                onClose={() => setToast((prev) => ({ ...prev, show: false }))}
            />
        </div>
    );
};

export default AddBrandDeal;
