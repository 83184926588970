import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AuthRight from "./AuthRight";
import { authAPIInstance } from '../../apis/AuthAPI';
import { useTranslation } from 'react-i18next';

export function ResetPassword() {

    const [password, setPassword] = useState("");
    const [re_password, setre_password] = useState("");
    const [tOtp, settOtp] = useState("");
    const navigate = useNavigate();
    const {t} = useTranslation('reset_password_page');

    const onPasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
    }

    const onRePasswordChange = (e) => {
        const value = e.target.value;
        setre_password(value);
    }

    const onOtpChange = (e) => {
        const value = e.target.value;
        settOtp(value);
    }

    const onSubmit = async () => {
        try {
            if (password.length < 7) {
                alert("Password should be atleast of 7 characters.");
                return;
            }
            if (re_password !== password) {
                alert("Passwords don't match.");
                return;
            }
            // if (tOtp.length !== 6 || isNaN(parseInt(tOtp))) {
            //     alert("Otp is not valid.");
            //     return;
            // }
            await authAPIInstance.resetPasswordV2(password, tOtp);
            alert("Password has reset successfully.");
            navigate("/sign-in");
        } catch (e) {
            console.error(e);
            alert(e?.response?.data?.message ?? e?.response?.data?.error ?? "Request failed");
        }
    }

    return (
        <>
            <div className="body d-flex p-0 p-xl-2 justify-content-center align-items-center">
            <div className="d-flex align-items-center flex-column -3">
                    <h1>{t("resetPassword")}</h1>
                    <span className="text-muted w-75 p-3 mx-auto text-center">
                        {t("resetMessageDescription")}
                    </span>
                </div>
                <div className="container sign-up-container mt-5">
                    <div className="row g-3">
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="d-flex flex-column">
                                
                                <div className="card">
                                    <div className="card-body p-4">
                                        <div className="mb-3">
                                            <label className="form-label fs-6">{t("Email Otp")}</label>
                                            <input value={tOtp} onChange={onOtpChange} type="email" className="form-control" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label fs-6">{t("newPassword")}</label>
                                            <input value={password} onChange={onPasswordChange} type="password" className="form-control" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label fs-6">{t("retypePassword")}</label>
                                            <input value={re_password} onChange={onRePasswordChange} type="password" className="form-control" />
                                        </div>
                                        <button onClick={onSubmit} className="btn orange-gradient-btn  py-2 fs-7 w-100 mt-2">{t("submit")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AuthRight />
                    </div>
                </div>
            </div>
        </>
    )
}
