
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { authAPIInstance } from "../../apis/AuthAPI";
import { countryCodes } from "../../utils/countryCodes";
import { getUserIpInfo } from "../../utils/getUserCountry";

import './settings.css';
import StripePaymentForm from "../PaymentMethod/PaymentMethod";

export default function SettingsMenu() {
    const user = useSelector(state => state.AuthReducer.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isKoreanUserModal, setIsKoreanUserModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);

    const handleNsfwToggle = async () => {
        setLoading(true);
        const { country } = await getUserIpInfo();
        if(country === "KR"){
            setIsKoreanUserModal(true);
        } else {
            const value = user?.allowAdultContent ? false : true;
            authAPIInstance.updateAllowAdultContent(value).then((_) => {
                dispatch({ type: "UPDATE", payload: { user: { key: "allowAdultContent", value } } });
                setLoading(false);
            });
        }
    };

  useEffect(() => {
      authAPIInstance.getPaymentMethod().then((res) => {
          setPaymentMethod(res?.data?.data?.paymentMethod ?? null);
      });
  }, []);

    const handleCountryChange = (e) => {
        setLoading(true);
        const value = e.target.value;
        authAPIInstance.updateUserRegion(value).then((_) => {
            dispatch({
                type: "UPDATE",
                payload: {
                    user: {
                        key: "region",
                        value: {
                            ...user?.region,
                            countryCode: value
                        }
                    }
                }
            });
            setLoading(false);
        });
    };

    const closeKoreanUserModal = () => {
        setIsKoreanUserModal(false);
    }

    const closePaymentMethodModal = () => {
        setShowAddPaymentMethod(false);
    }

  const handlePaymentMethodDelete = () => {
      authAPIInstance.removePaymentMethod().then((res) => {
          setPaymentMethod(null);
      });
  };

  const handleAddPaymentMethodClick = () => {
    setShowAddPaymentMethod(true);
 };

 const handlePaymentMethodCreated = (token) => {
     authAPIInstance
         .addPaymentMethod(token)
         .then((res) => {
             closePaymentMethodModal();
             setPaymentMethod(res?.data?.data?.paymentMethod ?? null);
             alert("Payment method added.");
         })
         .catch((e) => {
             alert(e?.response?.data ?? "Something went wrong.");
         });
 };

    return (
        <div className="col-xl-12 align-item-center mb-3 card py-2 mx-3">
            <h6 className="mb-0 fw-bold py-3">General</h6>
            <div className="border-bottom py-2 mb-2">
                <div className="row justify-content-between py-2">
                    <div className="col-lg-4 col-xl-4">
                        <div className="d-flex">
                            <div className="d-flex flex-column px-2">
                                <span className="fw-bold">Country</span>
                                <span className="text-muted small">Set your country for personalised experience</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3">
                        <Form.Select disabled={loading} onChange={handleCountryChange} value={user?.region?.countryCode}>
                            {countryCodes.map((country) => (
                                <option value={country.code}>{country.name}</option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-lg-4 col-xl-4">
                        <div className="d-flex">
                            <div className="d-flex flex-column px-2 py-2">
                                <span className="fw-bold">NSFW Content</span>
                                <span className="text-muted small">Toggle to Enable / Disable</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xl-4">
                        <button
                            type="button"
                            className={`btn flex-fill py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end ${
                                !user?.allowAdultContent ? "btn-light-success" : "btn-light-danger"
                            }`}
                            disabled={loading}
                            onClick={handleNsfwToggle}
                        >
                            {user?.allowAdultContent ? "DISABLE" : "ENABLE"}
                        </button>
                    </div>
                </div>
                {/* payment method */}
                <div className="row d-flex justify-content-between">
                    <div className="col-lg-4 col-xl-4">
                        <div className="d-flex">
                            <div className="d-flex flex-column px-2 py-2">
                                <span className="fw-bold">Payment Method</span>
                                <span className="text-muted small">Modify Payment Method</span>
                            </div>
                        </div>
                    </div>

                    {paymentMethod ? (
                        <div className="col-lg-4 col-xl-4">
                            <span>Card ending with {paymentMethod?.last4}</span>
                        </div>
                    ) : (
                        <div className="col-lg-4 col-xl-4">
                            <span>No Payment Method Available</span>
                        </div>
                    )}
                    {paymentMethod ? (
                        <div className="col-lg-4 col-xl-4">
                            <button
                                type="button"
                                className={`btn flex-fill py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end btn-light-danger`}
                                disabled={loading}
                                onClick={handlePaymentMethodDelete}
                            >
                                {"DELETE"}
                            </button>
                        </div>
                    ) : (
                        <div className="col-lg-4 col-xl-4">
                            <button
                                type="button"
                                className={`btn flex-fill py-2 fs-6 text-uppercase px-3 mt-2 mt-lg-0 float-lg-end btn-light`}
                                disabled={loading}
                                onClick={handleAddPaymentMethodClick}
                            >
                                {"ADD New Payment Method"}
                            </button>
                        </div>
                    )}
                </div>
                {/* payment method end */}
            </div>
            <Modal show={isKoreanUserModal}>
                <Modal.Body
                    className="p-5"
                    style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
                >
                    <h5>
                        Your country is not supported for enabling NSFW Content. Refer to this video tutorial on how to use
                        VPN.
                    </h5>
                    {
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/EcNjazSau88?si=hyzLtffKXbmg65dN"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        ></iframe>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeKoreanUserModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* payment method modal */}
            <Modal show={showAddPaymentMethod}>
                <Modal.Body
                    className="p-3"
                >
                    <StripePaymentForm onSucess={handlePaymentMethodCreated}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closePaymentMethodModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {/*  */}
        </div>
    );
}
