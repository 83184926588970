import React, { useEffect } from "react";
import { config } from "../../utils/config";

export default function GoogleAd({
    adSlot = config[config.environment].googleAdSense.adSlot,
    style = {},
    closeFunction = () => {},
    autoCloseMs = null
}) {
    useEffect(() => {
        if (typeof autoCloseMs === "number" && autoCloseMs > 1) {
            setTimeout(() => {
                closeFunction();
            }, autoCloseMs);
        }
    }, [autoCloseMs, closeFunction]);

    return (
        <ins
            className="adsbygoogle"
            style={{ display: "block", ...style }}
            data-ad-client={config[config.environment].googleAdSense.adClient}
            data-ad-slot={adSlot}
            data-ad-format="auto"
            data-adtest={config.environment !== "prod" ? "on" : "off"}
            data-full-width-responsive="true"
        ></ins>
    );
}
