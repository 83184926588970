import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import "./developerPortal.css";
import { Modal } from "../../Component/Modal/Modal";
import { developerAPIInstance } from "../../apis/DeveloperAPI";
import { useNavigate } from "react-router";
import { CreateApiModal } from "./Components/CreateApiModal";
import { ApiKeyStatsModal } from "./Components/ApiStatsModal";
import ApiPlan from "./Components/ApiPlan";
import AiSetting from "./Components/AISettings";
import AiSettingsModal from "./Components/AiSettingsModal";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: "80vh",
        width: "60%"
    },
};

export default function DeveloperPortal() {
    const [apiKeys, setapiKeys] = useState([]);
    const [createModalIsOpen, setcreateModalIsOpen] = useState(false);
    const [statsModalIsOpen, setStatsModalIsOpenModalIsOpen] = useState(false);
    const [settingsModalIsOpen, setSettingsModalIsOpen] = useState(false);
    const [plan, setPlan] = useState({ plan: "free", callsPerMonth: 3000 });
    const [callsLeftPerMonth, setCallsLeftPerMonth] =  useState(0);
    const currentEditApiKey = useRef();
    const navigate = useNavigate();

    const handleCreateButtonClick = () => {
        setcreateModalIsOpen(true);
    }

    useEffect(() => {
        developerAPIInstance.getAllApiKeys()
        .then(res => {
            setapiKeys(prev => {
                return res.data.data.api_calls
            });
            setCallsLeftPerMonth(res.data.data.calls_left);
            setcreateModalIsOpen(false);
        }).catch(e => {
           console.log(e);
        })
    }, []);

    useEffect(() => {
        developerAPIInstance.getCurrentPlan()
        .then(res => {
            if (res.data.data.tier.name) {
                setPlan((_) => {
                    return { plan: res.data.data.tier.name, callsPerMonth: res.data.data.tier.apiCallsAllowed };
                });
            }
        }).catch(e => {
           console.log(e);
        })
    }, []);

    const handleCreateApiKey = (name) => {
        developerAPIInstance.createdApiKey(name)
        .then(res => {
            setapiKeys(prev => {
                return [...prev, {name, ...res.data.data}]
            });
            setcreateModalIsOpen(false);
        }).catch(e => {
            alert('Error creating api key')
            setcreateModalIsOpen(false);
        })
    }

    const handlePackageUpgrade = () => {
        navigate("/api-pricing");
    }
    
    const handleSettingsUpdateClick = () => {
        setSettingsModalIsOpen(true);
    }

    const handleDeleteApiKey = (apiKey) => {
        const response = window.confirm("Are you sure you want to delete this API key?");
        if (!response) {
            return;
        }
        developerAPIInstance
            .deleteApiKey(apiKey)
            .then((_) => {
                setapiKeys((prev) => {
                    return prev.filter((key) => key.apiKey !== apiKey);
                });
                alert("Api key deleted.");
            })
            .catch((e) => {
                alert("Error deleting api key");
            });
    };

    const handleUpdateApiKey = (name) => {
        developerAPIInstance
            .updateApiKey(currentEditApiKey.current, name)
            .then((_) => {
                setapiKeys((prev) => {
                    return prev.map((key) => {
                        if (key.apiKey === currentEditApiKey.current) {
                            return {...key, name};
                        } else {
                            return key;
                        }
                    });
                });
                alert("Api key updated.");
                setcreateModalIsOpen(false);
                currentEditApiKey.current = null;
            })
            .catch((e) => {
                alert("Error updating api key");
                setcreateModalIsOpen(false);
                currentEditApiKey.current = null;
            });
    };

    const handleUpdateApiClick = (api_key) => {
        currentEditApiKey.current = api_key;
        setcreateModalIsOpen(true);
    }

    const handleViewStatsClick = (api_key) => {
        currentEditApiKey.current = api_key;
        setStatsModalIsOpenModalIsOpen(true);
    }

    const onModalClose = () => {
        currentEditApiKey.current = null;
    };

    const handleApiKeySettingsUpdateClick = (apiKey) => {
        setcreateModalIsOpen(false);
        currentEditApiKey.current = apiKey;
        setSettingsModalIsOpen(true);
    };

    return (
        <div className="main-container">
            {createModalIsOpen && (
                <Modal
                    modalIsOpen={createModalIsOpen}
                    setIsOpen={setcreateModalIsOpen}
                    title="Create API key"
                    onAfterClose={onModalClose}
                    body={<CreateApiModal handleUpdateSettingsClick={handleApiKeySettingsUpdateClick} 
                    apiKey={currentEditApiKey.current} onAction={currentEditApiKey.current ? handleUpdateApiKey : handleCreateApiKey} />}
                />
            )}
            {statsModalIsOpen && (
                <Modal
                    modalIsOpen={statsModalIsOpen}
                    setIsOpen={setStatsModalIsOpenModalIsOpen}
                    title="API stats"
                    onAfterClose={onModalClose}
                    body={<ApiKeyStatsModal apiKey={currentEditApiKey.current} />}
                />
            )}
            <Modal
                modalIsOpen={settingsModalIsOpen}
                setIsOpen={setSettingsModalIsOpen}
                title="AI Settings"
                onAfterClose={onModalClose}
                body={<AiSettingsModal apiKey={currentEditApiKey.current}/>}
                styles={customStyles}
            />
            <div className="api-keys-container">
                <div className="top-container">
                    <h6>API keys</h6>
                    <Button onClick={handleCreateButtonClick}>+ Add</Button>
                </div>
                <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s dummy text of the
                </p>
                <div className="api-keys-table">
                    <div className="header">
                        <span id="app-name">App name</span>
                        <span id="api-key">API key token</span>
                        <span>API Statistics</span>
                    </div>
                    {apiKeys.map((key, i) => (
                        <div key={key?._id ?? `keys${i}`} className="api-keys-table-row">
                            <span className="api-key-name">{key?.name ?? "unamed key"}</span>
                            <span className="api-key-value">{key?.apiKey ?? ""}</span>
                            <span>
                                <Button onClick={() => handleViewStatsClick(key?.apiKey)} className="view-stats-btn">
                                    Visit stats
                                </Button>
                            </span>
                            <span>
                                <i onClick={() => handleDeleteApiKey(key?.apiKey)} class="fa-solid fa-trash delete-icon"></i>
                                <Button onClick={() => handleUpdateApiClick(key?.apiKey)} className="edit-btn">
                                    Edit
                                </Button>
                            </span>
                        </div>
                    ))}
                    {apiKeys.length === 0 && "No keys created"}
                </div>
            </div>

            <ApiPlan plan={plan} callsLeftPerMonth={callsLeftPerMonth} handlePackageUpgrade={handlePackageUpgrade} />
            <AiSetting handleSettingsUpdateClick={handleSettingsUpdateClick} />
        </div>
    );
}
