import React from "react";
import { Dropdown } from "react-bootstrap";
import { Modal } from "../Modal/Modal";
import ThreeFactorAuth from "./ThreeFactorAuth/ThreeFactorAuth";
import { transactionAPIInstance } from "../../apis/TransactionAPI";
import { ethers } from "ethers";

function WithdrawCrypto({ tokens, handleWithdraw, updateEvent, event }) {
    const { tokenAddress, tokenName, amount, recipient, loading, modalIsOpen, transactionId } = event;

    const setisModalOpen = (status) => {
        updateEvent({ modalIsOpen: status });
    }

    const onSubmit = async ({password, tOtp, emailOtp}) => {
        let body = {
            transaction_id: transactionId
        };
        if(password){
            body = {
                ...body, password
            };
        }
        if(tOtp){
            body = {
                ...body, tOtp
            };
        }
        if(emailOtp){
            body = {
                ...body, emailOtp
            };
        }
        try {
            let res = null;
            if (tokenAddress === "BTC") {
                res = await transactionAPIInstance.completebtcWithdraw({ body });
            } else if (ethers.utils.isAddress(tokenAddress)) {
                res = await transactionAPIInstance.completeEthWithdraw({ body });
            } else {
                throw new Error("Invalid token address");
            }
            if(res?.data?.data?.hash){
                alert(`Withdraw successful. Hash: ${res?.data?.data?.hash}`);
                setisModalOpen(false);
                return;
            }
            alert(`${res?.data?.message}`);
            return { message: res?.data?.message, code: res.status };
        } catch (e) {
            console.error(e);
            alert(e?.response?.data?.message ?? e?.response?.data?.error ?? "Withdraw failed");
            return { message: e?.response?.data?.message ?? e?.response?.data?.error ?? "Withdraw failed", code: e?.response?.status };
        }
    }

    return (
        <div className="col-xl-12 col-xxl-5">
            <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent align-items-center">
                    <h6 className="mb-0 fw-bold">Withdraw Crypto</h6>
                </div>
                <div className="card-body">
                    <div className="row g-3 mb-3">
                        <Dropdown className="col-sm-12">
                            <label className="form-label">Select coin</label>
                            <div className="input-group">
                                <input disabled value={tokenAddress} type="text" className="form-control" />
                                <Dropdown.Toggle
                                    className="btn btn-outline-secondary dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{ width: 125 }}
                                >
                                    {tokenName || "Select Token"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                    {tokens.map(
                                        (token) =>
                                            token?.tokenInfo.length > 0 && (
                                                <li key={token?.tokenInfo[0]?.address}>
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#!"
                                                        onClick={() => {
                                                            updateEvent({ tokenAddress: token?.tokenInfo[0]?.address });
                                                            updateEvent({ tokenName: token?.tokenInfo[0]?.name });
                                                            updateEvent({ network: token?.network });
                                                        }}
                                                    >
                                                        {token?.tokenInfo[0]?.name}
                                                    </Dropdown.Item>
                                                </li>
                                            )
                                    )}
                                    <li key={"btc"}>
                                        <Dropdown.Item
                                            className="dropdown-item"
                                            href="#!"
                                            onClick={() => {
                                                updateEvent({ tokenAddress: "BTC" });
                                                updateEvent({ tokenName: "BTC" });
                                            }}
                                        >
                                            BTC
                                        </Dropdown.Item>
                                    </li>
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>
                        <div className="col-sm-12">
                            <label className="form-label">Amount</label>
                            <input
                                value={amount}
                                type="number"
                                className="form-control"
                                onChange={(e) => updateEvent({ amount: e.target.valueAsNumber })}
                            />
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Withdraw Address</label>
                            <input
                                value={recipient}
                                type="text"
                                className="form-control"
                                onChange={(e) => updateEvent({ recipient: e.target.value })}
                            />
                        </div>
                        <button
                            disabled={loading}
                            className="btn flex-fill btn-light-warning py-2 fs-5 text-uppercase px-5"
                            onClick={handleWithdraw}
                        >
                            {loading ? <i className="fas fa-circle-notch fa-spin text-warning"></i> : "Withdraw"}
                        </button>
                        {/* <div className="col-sm-12">
                            <label className="form-label">Select Withdraw Network</label>
                            <select className="form-select" aria-label="Default select example">
                                <option> BNB (Arrival time ≈ 2 mins)</option>
                                <option value="1">BTC (Arrival time ≈ 1 mins)</option>
                                <option value="2">BSC (Arrival time ≈ 2 mins)</option>
                                <option value="3">ETH (Arrival time ≈ 3 mins)</option>
                            </select>
                        </div>
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-between flex-wrap">
                                <div>
                                    <div className="truncated">BTC spot balance</div>
                                    <div className="text-muted truncated"> 0 BTC</div>
                                </div>
                                <div>
                                    <div className="truncated">Minimum withdrawal</div>
                                    <div className="text-muted  truncated"> 0.0000086 BTC </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-between flex-wrap">
                                <div>
                                    <div className="truncated">Network fee</div>
                                    <div className="text-muted truncated"> 0.0000043 ~ 0.00055 BTC</div>
                                </div>
                                <div>
                                    <div className="truncated">24h remaining limit</div>
                                    <div className="text-muted  truncated"> 100 BTC/100 BTC </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Modal 
                title={"Complete Withdraw"}
                modalIsOpen={modalIsOpen}
                setIsOpen={setisModalOpen} 
                body={<ThreeFactorAuth onSubmit={onSubmit} transaction_id={transactionId}/>} 
            />
        </div>
    );
}

export default WithdrawCrypto;
