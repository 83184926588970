import React from "react";

export default function CharacterImages({ images = [] }) {
    return (
        <div className="character-multi-image-container">
            {images.map((img) => (
                <CharacterImage imgSrc={img} />
            ))}
        </div>
    );
}

const CharacterImage = ({ imgSrc = "" }) => {
    return (
        <div className="character-multi-image">
            <img src={imgSrc} />
        </div>
    );
};
