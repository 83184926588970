import axios from "axios";

let data = null;

export const getUserIpInfo = async () => {
    if (!data) {
        const getIpResponse = await axios.get("https://api64.ipify.org?format=json");
        const ipInfoResponse = await axios.get("https://ipinfo.io/" + getIpResponse.data.ip + "?token=506ced6d806256");
        data = ipInfoResponse.data;
    }
    return data;
};

/*
{
  "ip": "",
  "city": "Lahore",
  "region": "Punjab",
  "country": "PK",
  "loc": "",
  "org": "AS9541 Cyber Internet Services (Pvt) Ltd.",
  "postal": "54000",
  "timezone": "Asia/Karachi"
}
*/
