import React, { useEffect, useState } from "react";
import { Container, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { adminAPIInstance } from "../../../apis/AdminAPI";
import PayoutRow from "./PayoutRow";

export function PayoutsTable() {
    const { t } = useTranslation("profile_screen");
    const [payouts, setPayouts] = useState([]);


    useEffect(() => {
        adminAPIInstance
            .getAllPayouts()
            .then((res) => {
                setPayouts(res?.data?.data?.payouts);
            })
            .catch(console.error);
    }, []);

    return (
        <Container style={{ paddingLeft: "0em" }}>
            <Row className="mt-5">
                <Col md={12}>
                    <h4>{t("Payouts")}</h4>
                    {payouts.length > 0 ? (
                        <Table striped={true} bordered={true} hover={true}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Username</th>
                                    <th>{t("Amount $")}</th>
                                    <th>Bank Name</th>
                                    <th>Bank Account</th>
                                    <th>Bank Routing Number</th>
                                    <th>{t("Withdrawn Status")}</th>
                                    <th>{t("Notes")}</th>
                                    <th>{t("Date/time")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payouts.map((payout, i) => (
                                    <PayoutRow payout={payout} i={i}/>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div>No earnings available</div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
