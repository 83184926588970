import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from "react-bootstrap";
import { Modal } from "../../Component/Modal/Modal";
import { PaddleLoader } from "../../Component/PaddleCheckout/PaddleLoader";
import StripeCheckout from "../../Component/StripeCheckout/StripeCheckout";
import PaymentMethod from "../../Component/PaymentMethod/PaymentMethod.jsx";
import { authAPIInstance } from "../../apis/AuthAPI";
import {config} from "../../utils/config.js";
import './index.css';
import { selectCurrentUser } from "../../Redux/selectors/auth.js";
import { useNavigate } from "react-router";
import { userTierAPIInstance } from "../../apis/TierAPI.js";
import diamondIcon from "../../assets/images/diamond-icon.png"
import checkIcon from "../../assets/images/check-icon.png"

const paymentTypes = {subscription: "subscription",oneTimePurchase:"oneTimePurchase"};

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "45vh",
        background: "radial-gradient(circle, rgba(14, 14, 14, 1) 60%, rgba(26, 26, 26, 1) 100%)",
        borderColor: "transparent",
        borderRadius: "12px",
        height: "40vh",
        overflowY: "auto"
    },
    overlay: {
        backgroundColor: "rgba(4, 4, 35, .5)",
        backdropFilter: "blur(3px)"
    }
};

export default function ChatTier() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [showPaymentMethodModal, setShowPaymentMethodModal] =  useState(false);
    const [showGemsCheckoutModal, setshowGemsCheckoutModal] =  useState(false);
    const [package_name, setPackage_name] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [selectedOption, setSelectedOption] = useState("stripeOption"); //default stripe
    const [loading, setLoading] =  useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState(undefined);
    const [selectedPackage, setSelectedPackage] = useState(null);

    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const Paddle = window.Paddle;
    const navigate = useNavigate();

    const packages = [
        {
            title: "FREE!",
            name: "Free",
            price: 0,
            paymentType: paymentTypes.subscription,
            pointLabel:'View images in Public Chats',
            points: [
                'Chat Safe or Uncensored AI Coaches',
                'Privacy Protected Advertisement'
            ],
            paddlePriceId: config[config.environment].paddlepay.premiumPriceId,
            action: (pkg) => handleCheckout(pkg),
            buttonText: subscriptionStatus ? 'Unsubscribe' : 'Start Now'
        },
    
        {
            title: "Premium",
            name: "silver",
            price: 5,
            paymentType: paymentTypes.subscription,
            pointLabel:'View images in Public Chats',
            points: [
                'No Coupon Ads',
                'Faster Response Times',
                'Early Access to new features',
            ],
            paddlePriceId: config[config.environment].paddlepay.premiumPriceId,
            action: (pkg) => handleCheckout(pkg),
            buttonText: subscriptionStatus ? 'Unsubscribe' : 'Start Now'
        },
        {
            title: '1000 Gems', //one time purchase
            name: "gems",
            price: 10,
            paymentType: paymentTypes.oneTimePurchase,
            pointLabel: 'Use Gems to purchase Characters, Stickers, and more...',
            points: [
                'Tip your favorite creators',
                'Generate Images and make Video Requests to Creators',
            ],
            paddlePriceId: config[config.environment].paddlepay.gemPriceId,
            action: (pkg) => handleCheckout(pkg),
            buttonText: 'Checkout'
        },
        {
            title: '1000 OrangePoints',
            name: "orangePoints",
            price: 1,
            paymentType: paymentTypes.oneTimePurchase,
            pointLabel: 'Use OrangePoints to purchase Coupons, Stickers, and more...',
            points: [
                'Level up and get experience points',
                'Generate Images in chat',
            ],
            paddlePriceId: config[config.environment].paddlepay.orangePriceId,
            action : (pkg) => handleCheckout(pkg),
            buttonText: 'Checkout'
        },
    ];


    const loadPaddleScript = async() => {
        //console.log("user: ",user);
        let paddleCustomerId = user?.paddleCustomerId;
        //console.log("paddleCustomerId: ",paddleCustomerId);
        if (!paddleCustomerId) {
            const { data } = await authAPIInstance.savePaddleCustomer();
            paddleCustomerId = data?.data?.paddleCustomerId;
            dispatch({
                type: "UPDATE",
                payload: {
                    user: {
                        key: "paddleCustomerId",
                        value: paddleCustomerId
                    }
                }
            });
        }
    }

    const openPaddleCheckout  = (priceId) => {
        //Read paddle docs for more info https://developer.paddle.com/build/checkout/build-overlay-checkout

        let paddleCustomerId = user?.paddleCustomerId;
        //console.log("paddleCustomerId: ",paddleCustomerId);
        
        Paddle?.Checkout?.open({
            settings: {
                theme: "light"
            },
            items: [
                {
                    // to do check and change price id by price
                    priceId: priceId,
                    quantity: 1
                }
            ],
            customer: {
                id: paddleCustomerId
            },
            onSuccess: () => {
                alert('Payment success paddle');
            }
        });
    }

    const getSubscriptionStatus = () => {
        userTierAPIInstance.getSubscriptionStatus()
            .then(res => {
                setSubscriptionStatus(res?.data?.data?.userChatTier);
            })
            .catch(console.error)
    }

    useEffect(() => {
        getSubscriptionStatus();
    }, []);

    useEffect(() => {
        if(user) { //user is logged in and check duplicate user email in paddle

            //loadPaddleScript();
        }
    }, [selectedOption]); //selectedOption

    const handleCheckout = (pkg) => {
        if (!user) {
            alert("Please login to upgrade your chat tier.");
            navigate("/sign-in");
            return;
        }
        if (pkg.name === packages[0].name && subscriptionStatus) {
            const confirm = window.confirm("Are you sure you want to unsubscribe");
            if (confirm) {
                userTierAPIInstance
                    .cancelSubscription()
                    .then((_) => {
                        alert("Unsubscribed to service. You will not be charged at the end of this billing cycle.");
                        getSubscriptionStatus();
                    })
                    .catch(console.error);
            }
            return;
        }
        setPackage_name(pkg);
        setIsOpen(true);
    };

    const handleDropdownChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
    };

    const subscribeWithStripe = async () => {
        try {
            if (package_name && package_name?.name !== packages[0].name) {
                setIsOpen(false);
                fetchCheckoutClientSecret();
                return;
            }
            if (user?.stripePaymentMethod) {
                setLoading(true);
                await userTierAPIInstance.subscribeChatTierStripe(package_name.name, config[config.environment].stripeTiers.premiumPriceId);
                setLoading(false);
                alert("Plan upgraded.");
                setIsOpen(false);
                getSubscriptionStatus();
            } else {
                setIsOpen(false);
                alert("Please add a payment method to subscribe.");
                setShowPaymentMethodModal(true);
            }
        } catch (error) {
            console.error(error);
            alert("Something went wrong.");
            setIsOpen(false);
        }
    };

    const handlePaymentMethodSucess = (token) => {
        setLoading(true);
        authAPIInstance
            .addPaymentMethod(token)
            .then((_) => {
                dispatch({
                    type: "UPDATE",
                    payload: {
                        user: {
                            key: "stripePaymentMethod",
                            value: true
                        }
                    }
                });
                alert("Payment method added successfully.");
                setLoading(false);
                setIsOpen(true);
            })
            .catch((e) => {
                console.error(e);
                setLoading(false);
            });
        setShowPaymentMethodModal(false);
    };

    useEffect(() => {
        if(clientSecret){
            setshowGemsCheckoutModal(true);
        }
    }, [clientSecret]);

    const fetchCheckoutClientSecret = async () => {
        try {
            let res;
            if(package_name?.name === packages[1].name){
                res = await userTierAPIInstance.gemsCheckout(1000);
            }
            else if(package_name?.name === packages[2].name){
                res = await userTierAPIInstance.orangePointsCheckout(1000);
            }
            else {
                alert('Something went wrong!');
                return;
            }
            setClientSecret(res.data.data.paymentIntent);
        } catch (error) {
            alert('Something went wrong.');
            setshowGemsCheckoutModal(false);
        }
    };

    const onPaymentSucessCallback = async (paymentIntent) => {
        try {
            if (package_name?.name === packages[1].name) {
                await userTierAPIInstance.gemsCheckoutCapture(1000, paymentIntent);
                dispatch({
                    type: "UPDATE",
                    payload: {
                        user: {
                            key: "gemsCount",
                            value: user?.gemsCount ? user?.gemsCount + 1000 : 1000
                        }
                    }
                });
                alert("Gems credited to your count.");
            } else if (package_name?.name === packages[2].name) {
                await userTierAPIInstance.orangePointsCheckoutCapture(1000, paymentIntent);
                dispatch({
                    type: "UPDATE",
                    payload: {
                        user: {
                            key: "orangePointsCount",
                            value: user?.orangePointsCount ? user?.orangePointsCount + 1000 : 1000
                        }
                    }
                });
                alert("Orangepoints credited to your count.");
            }
            setshowGemsCheckoutModal(false);
            alert("Navigating to wallet...");
            setTimeout(() => {
                navigate("/coupon-wallet");
            }, 5000);
        } catch (error) {
            console.error(error);
            alert("Something went wrong.");
            setshowGemsCheckoutModal(false);
        }
    };
    const handlePackageClick = (pkg) => {
        setSelectedPackage(pkg);
    };
    
    return (
        <div className="pricing-main-container m-1 mt-3">
            <h2>Pricing</h2>
            <h4>Your Wallet</h4>
            {user && <UserAppTokens user={user}/>}
            <h4 className="px-4">Support our vision for freedom of speech, commerce, and ownership.</h4>
            <div className="packages-container">
                <div className="packages-cards-container">
                    {packages.map((pkg, i) => (
                        <div className={`package-card mx-3 ${selectedPackage === pkg ? 'clicked' : ''}`} key={`dev_package${i}`}>
                            <div className="package-first-point p-2">
                                <h4 className="package-heading"><img src={diamondIcon} alt=""/>  {pkg.title}</h4>
                                <h2 className="package-pricing">
                                    {pkg.paymentType == paymentTypes.subscription ? `$${pkg.price} USD / Month` : `$${pkg.price} USD`}
                                </h2>
                                <button
                                    type="button"
                                    hidden={pkg.name === "Free"}
                                    className="btn orange-gradient-btn w-100"
                                    onClick={() => pkg.action(pkg)}
                                >
                                    {pkg.buttonText}
                                </button>
                            </div>
                            <h6 className="px-2">{pkg.pointLabel}</h6>
                            <div className="package-points-container mt-3">
                                {pkg.points.map((point, i) => (
                                    <div className="d-flex flex-row align-items-center ps-3">
                                        <img src={checkIcon} alt="" style={{width: "18px", height: '18px'}}/>
                                        <div key={`pointText${i}`} className="package-point-containers mb-0">
                                            {point}
                                        </div>
                                    </div>
                                   
                                ))}
                            </div>
                            {/* <div className="package-point-containers card-grey-bg empty">
                                <button
                                    type="button"
                                    className="btn btn-primary start-btn"
                                    onClick={() => handleCheckout(pkg)}
                                >
                                    Start Now
                                </button>
                            </div> */}
                        </div>
                    ))}
                </div>
            </div>
                       {/* <PaddleLoader/> */}
                <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                title="Checkout"
                styles={customStyles}
                body={
                    <div className="d-flex flex-column align-items-center justify-content-between h-75">
                        <h5 className="package-heading">Price ${package_name.price}/Month</h5>
                        <label>Select Payment</label>
                        <select onChange={handleDropdownChange} className="form-select">
                            <option value="stripeOption">Stripe Credit Card</option>
                            {/* <option value="coingateOption">CoinGate</option> */}
                            {/* <option value="paddleOption">Paddle</option> */}
                        </select>
                        {selectedOption === "stripeOption" && (
                            <Button className="my-2 mt-2 orange-gradient-btn w-100" onClick={subscribeWithStripe}>
                                Pay with saved payment method
                            </Button>
                        )}
                        {selectedOption === "paddleOption" && (
                            openPaddleCheckout(package_name.paddlePriceId)
                        )}

                        {selectedOption === "coingateOption" && (
                            {/* Render CoinGate*/}
                            // <CoinGateCheckout />
                        )}
                        {loading && <Spinner className="mx-2" size="sm" />}
                    </div>
                }
            /> {/** end modal */}
                {/* // add payment method modal */}
            <Modal
                styles={customStyles}
                modalIsOpen={showPaymentMethodModal}
                setIsOpen={setShowPaymentMethodModal}
                body={<PaymentMethod onSucess={handlePaymentMethodSucess} />}
            />
            <Modal
                styles={customStyles}
                modalIsOpen={showGemsCheckoutModal}
                setIsOpen={setshowGemsCheckoutModal}
                body={<StripeCheckout onPaymentSucessCallback={onPaymentSucessCallback} clientSecret={clientSecret}/>}
            />
        </div>
    );
}

const UserAppTokens = ({ user }) => {
    return (
        <div className="d-flex flex-row px-5 py-1 gap-5 rounded-2 mx-2 gem-count-container">
            <h5 className="m-0 px-3">{user?.gemsCount} Gems</h5>
            <h5 className="m-0 px-3">{user?.orangePointsCount} OP</h5>
        </div>
    );
};
