import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import chest from '../../assets/images/closed-chest.png';

export default function RewardAdBox({ onSelectBox = () => {}, numberOfBoxes = 3 }) {
    const [boxes, setBoxes] = useState([]);

    useEffect(() => {
        const temp = [];
        for (let i = 1; i <= numberOfBoxes; i++) {
            temp.push(Math.floor((Math.random() * 10) % 5));
        }
        setBoxes(temp);
    }, [numberOfBoxes]);

    const handleBoxSelect = (value) => {
        onSelectBox(value);
    };

    return (
        <div className="d-flex flex-column gap-1">
            <h6>Select a Box to win a random points prize. <a href={process.env.PUBLIC_URL +"/premium-price"} target="_blank" className="btn btn-primary">Get More Points</a></h6>
            
            <div className="d-flex flex-row justify-content-between p-3 gap-2">
                {boxes.map((box, i) => (
                    <div
                        className="p-2"
                        style={{ background: "rgba(47, 47, 47, 0.5)", color: "white", cursor: "pointer" }}
                        onClick={() => handleBoxSelect(box)}
                        key={`boxReward${i}`}
                    >
                        Box {i}
                        <img src={chest} alt="#" className="img-fluid my-4" />
                    </div>
                ))}
            </div>
        </div>
    );
}
