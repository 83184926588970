import React from "react";
import { Container, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getAiCharacterImageUrl, getCouponImageUrl } from "../../utils/images";
import Avatar from "../../Component/Comman/Avatar";
import { formatDate } from "../../utils/formatter/dateFormatter";

export function CharacterEarningsTable({ earnings = []}) {
    const { t } = useTranslation("profile_screen");

    return (
        <Container style={{ paddingLeft: "0em" }}>
            <Row className="mt-5">
                <Col md={6}>
                    <h4>{t("Character Subscription Earnings")}</h4>
                    {earnings.length > 0 ? (
                        <Table striped={true} bordered={true} hover={true}>
                            <thead>
                                <tr>
                                    <th>{t("No.")}</th>
                                    <th>{t("Character Name")}</th>
                                    <th>{t("Character Avatar")}</th>
                                    <th>{t("Buyer Name")}</th>
                                    <th>{t("Price Paid")}</th>
                                    <th>{t("Date")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {earnings.map((earning, i) => (
                                    <tr key={earning?._id ?? i}>
                                        <td>{i + 1}</td>
                                        <td>{earning?.characterName}</td>
                                        <td className="d-flex flex-row justify-content-center">
                                            <Avatar name={earning?.avatarUrl} avatarUrl={getAiCharacterImageUrl(earning?.avatarUrl)}/>
                                        </td>
                                        <td>{earning?.subscriber_info?.username}</td>
                                        <td>{`${earning?.price} ${earning?.currency}`}</td>
                                        <td>{formatDate(earning?.createdAt)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div>No Sales Available</div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
