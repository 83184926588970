import React from "react";
import { Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { PointRewardApiInstance } from "../../../apis/PointRewardApi";
import { readCookie } from "../../../utils/cookie";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../Redux/selectors/auth";
import StripeCheckout from "../../../Component/StripeCheckout/StripeCheckout";
import { useNavigate } from "react-router";

export default function BuyCoupon({ coupon, pointToken, pointWallet }) {
    const navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = React.useState('stripe');
    const [loading, setLoading] = React.useState(false);
    const token = readCookie("token");
    const currentUser = useSelector(selectCurrentUser);
    const [view, setView] = React.useState("app");
    const [clientSecret, setClientSecret] = React.useState(null);

    const handlePaymentOptionChange = (e) => {
        const value = e.target.value;
        setPaymentMethod(value);
    };

    const handleBuyClick = async () => {
        try {
            setLoading(true);
            if (paymentMethod === pointToken.pointRewardName) {
                if (
                    !pointWallet?.totalAmount ||
                    !coupon?.priceInPoints ||
                    pointWallet?.totalAmount < coupon?.priceInPoints
                ) {
                    setLoading(false);
                    alert("Insufficient points");
                    return;
                }
                buyCouponInPoints();
            } else if (paymentMethod === 'stripe') {
                const res = await PointRewardApiInstance.checkoutCouponsPurchase(token, coupon._id);
                const { paymentIntent } = res.data.data;
                setClientSecret(paymentIntent);
                setView('checkout');
            }
        } catch (error) {
            console.error(error?.message);
            setLoading(false);
            if (error.response.status === 409) {
                if (paymentMethod === pointToken.pointRewardName) {
                    alert("Insufficient points");
                } else if (paymentMethod === "btc") {
                    alert("Insufficient Bitcoin balance.");
                }
            } else if (error.response.status === 422) {
                alert("Merchant does not accept this Payment method.");
            } else {
                alert("Error buying coupon!");
            }
        }
    };

    const buyCouponInPoints = async () => {
        const body = {
            customerUserID: currentUser?.userId,
            couponUUID: coupon._id,
            storeOwnerID: pointToken.merchantStoreID,
            pointTokenID: pointToken._id,
            tokenName: pointToken.pointRewardName
        };
        await PointRewardApiInstance.buyCoupon(body, token);
    };


    const onPaymentSucessCallback = (
        paymentId,
        error,
      ) => {
        if (!error) {
          PointRewardApiInstance.captureCouponsPurchase(
            token,
            coupon._id,
            paymentId,
          )
            .then(_ => {
              setView('app');
              alert('Payment Successful.');
              //Send to my purchases page coupon-wallet
              navigate('/coupon-wallet');

              setLoading(false);
            })
            .catch(e => {
              console.error(e);
              setView('app');
              alert('Payment not processed');
              setLoading(false);
            });
        } else {
          console.error(error);
          setView('app');
          alert('Payment not processed');
          setLoading(false);
        }
      };

    return (
        <>
            {view === "app" ? (
                <div className="buy-coupon-main-container">
                    <div className="buy-coupon-info-container">
                        <div className="coupon-deal-img-container">
                            <img src={coupon?.couponPhotos?.[0]} alt="store" className="br-50" />
                        </div>
                        <span>
                            <strong>{coupon?.couponName}</strong>
                        </span>
                    </div>
                    <div className="coupon-price-container">
                        <div className="coupon-price-row">
                            <span>Price in points</span>
                            <span className="heading">
                                {coupon?.priceInPoints} {pointToken?.pointRewardName}
                            </span>
                        </div>
                        <div className="coupon-price-row">
                            <span>Points in wallet</span>
                            <span className="heading">
                                {pointWallet?.totalAmount ?? 0} {pointToken?.pointRewardName}
                            </span>
                        </div>
                        <div className="coupon-price-row">
                            <span>Points left after purchase</span>
                            <span className="heading">
                                {pointWallet?.totalAmount > coupon?.priceInPoints
                                    ? pointWallet?.totalAmount - coupon?.priceInPoints
                                    : 0}{" "}
                                {pointToken?.pointRewardName}
                            </span>
                        </div>
                        <div className="coupon-price-row">
                            <span>Total</span>
                            <span className="heading">
                                <strong>
                                    {coupon?.priceInPoints} {pointToken?.pointRewardName}
                                </strong>
                            </span>
                        </div>
                    </div>
                    <Form.Select
                        value={paymentMethod}
                        onChange={handlePaymentOptionChange}
                        aria-label="Select your payment method"
                    >
                        <option disabled={true}>Select your payment method</option>
                        {/* <option value={pointToken.pointRewardName}>{pointToken.pointRewardName}</option> */}
                        <option value="stripe">Pay by Credit Card</option>
                    </Form.Select>
                    <Button className="theme-bg" disabled={loading} onClick={handleBuyClick}>
                        Buy now
                        {loading && <Spinner size="sm" className="buy-coupon-btn-spinner" />}
                    </Button>
                </div>
            ) : (
                <StripeCheckout
                    title="Complete Checkout"
                    clientSecret={clientSecret}
                    onPaymentSucessCallback={onPaymentSucessCallback}
                />
            )}
        </>
    );
}
