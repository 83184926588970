import React from "react";
import { useNavigate } from "react-router";
import { NO_IMAGE, getCouponImageUrl, getStoreDisplayableImage } from "../../../utils/images";
import Avatar from "../../../Component/Comman/Avatar";

export default function CouponCard({ coupon, merchantInfo }) {

    const navigate = useNavigate();

    const handleImageError = (e) => {
        const target = e.target;
        target.src = NO_IMAGE;
    }

    const handleCouponClick = () => {
        navigate(`/coupon/${coupon?._id}`);
    }

    const handleStoreClick = () => {
        navigate(`/store/${coupon?.merchantInfo?._id}`);
    }

    return (
        <div className="coupon-card-container">
            <div className="coupon-card-img-container" onClick={handleCouponClick}>
                <img src={getCouponImageUrl(coupon?.couponPhotos?.[0])} alt="coupon" onError={handleImageError}/>
                <div className="overlay-opacity"></div>
                <div className="coupon-merchant-name">
                    <span className="card-coupon-name">{coupon?.couponName}</span>
                    <span className="card-merchant-name">{coupon?.merchantInfo?.storeName}</span>
                </div>
            </div>
            <div className="store-info-container" onClick={handleStoreClick}>
                <div>
                    <Avatar avatarUrl={getStoreDisplayableImage(merchantInfo)}/>
                </div>
                <span>{merchantInfo?.storeName}</span>
            </div>
        </div>
    );
}
