import React from "react";
import { useLocation } from "react-router";
import { Tab, Nav } from "react-bootstrap";
import "./viewBrandDeal.css";
import LinkTrackingTable from "./LinkTrackingTable";
import Reviews from "./Reviews";
import { NO_IMAGE } from "../../../utils/images";

const mock_data = {
    deal_name: "200$ dollars youtube tiktok post",
    deal_budget: "200$",
    description: "lorem impsum 200$ dollars youtube tiktok post 200$ dollars youtube tiktok post 200$ dollars youtube tiktok post 200$ dollars youtube tiktok post"
};

export default function ViewBrandDeal() {
    const { state } = useLocation();

    return (
        <div className="main-container">
            <div className="deal-info-merchant-container">
                <div className="merchant-image-container">
                    <img src={NO_IMAGE} alt="store"/>
                </div>
                <div className="deal-info-container">
                    <span>{mock_data.deal_name}</span>
                    <span><strong>Budget</strong>: {mock_data.deal_budget}</span>
                    <p><strong>Description</strong>: {mock_data.description}</p>
                </div>
            </div>
            <Tab.Container defaultActiveKey="coupons">
                <div className="card-header pb-3 d-flex justify-content-between bg-transparent align-items-center flex-wrap">
                    <Nav className="nav nav-pills rounded d-inline-flex mt-2 mt-md-0 rounded-0" role="tablist">
                        <Nav.Item className="nav-item">
                            <Nav.Link
                                eventKey="coupons"
                                className="nav-link"
                                data-bs-toggle="tab"
                                role="tab"
                                aria-selected="true"
                            >
                                Tracking Links
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item">
                            <Nav.Link
                                eventKey="other"
                                className="nav-link "
                                data-bs-toggle="tab"
                                role="tab"
                                aria-selected="false"
                            >
                                Reviews
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className="card-body">
                    <Tab.Content className="tab-content">
                        <Tab.Pane className="tab-pane fade show" id="coupons" eventKey="coupons">
                            <LinkTrackingTable/>
                        </Tab.Pane>
                        <Tab.Pane className="tab-pane fade" id="other" eventKey="other">
                            <Reviews/>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    );
}
