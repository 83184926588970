import React from "react";
import { Container, Col, Row, Table, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/formatter/dateFormatter";
import { PointRewardApiInstance } from "../../apis/PointRewardApi";

export function CouponEarningsTable({ earnings = [], handleCouponStatusUpdate = () => {} }) {
    const { t } = useTranslation("profile_screen");

    const handleUpdate = (txId, currentStatus) => {
        const response = window.confirm(
            `Are you sure you want to set this coupon${!currentStatus ? " to Used?" : " to Unused?"}`
        );
        if (response) {
            PointRewardApiInstance.updateCouponRedeemStatus(txId, !currentStatus)
                .then((_) => {
                    handleCouponStatusUpdate(txId, currentStatus);
                    alert("Status updated");
                })
                .catch(console.error);
        }
    };

    return (
        <Container style={{ paddingLeft: "0em" }}>
            <Row className="mt-5">
                <Col md={6}>
                    <h4>{t("Coupon Sale Earnings")}</h4>
                    {earnings.length > 0 ? (
                        <Table striped={true} bordered={true} hover={true}>
                            <thead>
                                <tr>
                                    <th>{t("No.")}</th>
                                    <th>{t("Coupon Name")}</th>
                                    <th>{t("Buyer Name")}</th>
                                    <th>{t("Amount")}</th>
                                    <th>{t("Status")}</th>
                                    <th>{t("Date")}</th>
                                    <th>{t("Redeemed")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {earnings.map((earning, i) => (
                                    <tr key={earning?._id ?? i}>
                                        <td>{i + 1}</td>
                                        <td>{earning?.couponInfo?.couponName}</td>
                                        <td>{earning?.buyerInfo?.username}</td>
                                        <td>{Number(earning?.paymentInfo?.amount).toFixed(2)}</td>
                                        <td>{earning?.paymentInfo?.status}</td>
                                        <td>{formatDate(earning?.paymentInfo?.createdAt)}</td>
                                        <th>
                                            <Button onClick={() => handleUpdate(earning?._id, earning?.isCouponRedeem)}>
                                                {earning?.isCouponRedeem ? "Used" : "Set to Used"}
                                            </Button>
                                        </th>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div>No Earnings Available</div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
