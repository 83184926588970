import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUserMerchant: null,
  currentUserMerchantCoupons: [],
  currentUserCouponsHistory: [],
  currentUserMerchantTotalCouponSales: 0,
  coupons: [],
  purchasedCoupons: [],
  allMyPoints: [],
  loadingPurchasedCoupons: false,
  loadingCoupons: false,
  unsavedCoupon: null,
};

const MerchantReducer = createSlice({
  name: 'merchantReducer',
  initialState,
  reducers: {
    setCurrentUserMerchant: (state, action) => {
      state.currentUserMerchant = action.payload;
    },
    setCurrentUserMerchantCoupons: (state, action) => {
      state.currentUserMerchantCoupons = action.payload;
    },
    setCoupons: (state, action) => {
      state.coupons = action.payload;
    },
    setPurchasedCoupons: (state, action) => {
      state.purchasedCoupons = action.payload;
    },
    setAllMyPoints: (state, action) => {
      state.allMyPoints = action.payload;
    },
    setCurrentUserCouponsHistory: (state, action) => {
      state.currentUserCouponsHistory = action.payload;
    },
    setCurrentUserMerchantTotalCouponSales: (state, action) => {
      state.currentUserMerchantTotalCouponSales = action.payload;
    },
    setLoadingPurchasedCoupons: (state, action) => {
      state.loadingPurchasedCoupons = action.payload;
    },
    setLoadingCoupons: (state, action) => {
      state.loadingCoupons = action.payload;
    },
    clearCurrentUserMerchantData: (state) => {
      state.currentUserMerchant = null;
      state.currentUserMerchantCoupons = [];
      state.currentUserCouponsHistory = [];
      state.currentUserMerchantTotalCouponSales = 0;
    },
    decrementPoints: (state, action) => {
      const tempAllMyPoints = [...state.allMyPoints];
      const idx = tempAllMyPoints.findIndex(
        (point) => point._id === action.payload.pointId
      );
      if (idx !== -1) {
        tempAllMyPoints[idx].totalAmount -= action.payload.amount;
        state.allMyPoints = [...tempAllMyPoints];
      }
    },
    incrementPoints: (state, action) => {
      const tempAllMyPoints = [...state.allMyPoints];
      const idx = tempAllMyPoints.findIndex(
        (point) => point._id === action.payload.pointId
      );
      if (idx !== -1) {
        tempAllMyPoints[idx].totalAmount += action.payload.amount;
        state.allMyPoints = [...tempAllMyPoints];
      }
    },
    setUnsavedCoupon: (state, action) => {
      state.unsavedCoupon = action.payload.coupon;
    }
  },
});

export const {
  setCurrentUserMerchant,
  setCurrentUserMerchantCoupons,
  setCoupons,
  setPurchasedCoupons,
  setAllMyPoints,
  setCurrentUserCouponsHistory,
  setCurrentUserMerchantTotalCouponSales,
  setLoadingPurchasedCoupons,
  setLoadingCoupons,
  clearCurrentUserMerchantData,
  decrementPoints,
  incrementPoints,
  setUnsavedCoupon
} = MerchantReducer.actions;

export default MerchantReducer;
