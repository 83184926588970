import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MerchantApiInstance } from "../../../apis/MerchantsAPI";
import { AddStoreSchema } from "../schema/AddStoreSchema";
import { getAllCoupons, getCurrentUserMerchant } from "../../../Redux/Actions/MerchantActions";
import { selectCurrentUser } from "../../../Redux/selectors/auth";
import { theme } from "../../../utils/config";
import { getMerchantImageUrl } from "../../../utils/images";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router";
import { readCookie } from "../../../utils/cookie";
import "../index.css";
import { BiSolidImageAdd } from "react-icons/bi";
import { IoTrash } from "react-icons/io5";
import { ErrorMsg } from "../../../Component/Comman/Typography";
import { Spinner } from "react-bootstrap";

const allowedCurrencies = {
    USD: {
        value: "USD",
        name: "United States Dollar",
        symbol: "$"
    },
    PHP: {
        value: "PHP",
        name: "Phillipines Peso",
        symbol: "₱"
    },
    KRW: {
        value: "KRW",
        name: "Korean Won",
        symbol: "₩"
    },
    PKR: {
        value: "PKR",
        name: "Pakistan Rupee",
        symbol: "₨"
    }
};

const initialValues = {
    storeName: "",
    businessAddress: "",
    businessID: "",
    storeDescription: "",
    pricePerPoint: "",
    numberOfPoints: "",
    airdropPoints: "",
    allowPointsOnExchange: false,
    allowFollowersRecommendation: false,
    followersRecommendationPoints: "",
    pricePerPointCurrency: allowedCurrencies.USD.value
};

// `StoreForm` is same as with `MyStoreAndCoupons`
const StoreForm = ({ onSubmit }) => {
    const { t } = useTranslation("store_and_coupon");

    const formRef = useRef(null);
    const storeImageInput = useRef(null);
    const dispatch = useDispatch();
    const token = readCookie("token");
    const currentUser = useSelector(selectCurrentUser);
    const merchant = useSelector((state) => state?.merchant?.currentUserMerchant);
    const navigate = useNavigate();

    const updatingMerchant = merchant;

    const [allowFollowersRecommendation, setAllowFollowersRecommendation] = useState(
        updatingMerchant?.allowFollowersRecommendation ?? false
    );

    const [merchantUploadImages, setMerchantUploadImages] = useState(() => {
        if (updatingMerchant?.storePhoto) {
            return [{ uri: getMerchantImageUrl(updatingMerchant?.storePhoto) }];
        } else if (updatingMerchant?.storePhotos) {
            return updatingMerchant?.storePhotos.map((photo) => ({
                uri: getMerchantImageUrl(photo)
            }));
        } else {
            return [];
        }
    });

    const handleSubmitStore = async (values) => {
        const merchantValues = {
            ...values,
            ...(updatingMerchant && { merchantUUID: updatingMerchant._id }),
            airdropPoints: values.airdropPoints || 0,
            pricePerPoint: values.pricePerPoint || 0,
            numberOfPoints: values.numberOfPoints || 0,
            allowFollowersRecommendation,
            followersRecommendationPoints: values.followersRecommendationPoints || 0
        };

        let newImages = merchantUploadImages
            .filter((image) => {
                return image?.new !== undefined; // upload images not existing in server
            })
            .map((image) => {
                return image.file; // get file data only
            });

        await onSubmit(merchantValues, token, newImages);
    };

    const handleImagePicker = () => {
        storeImageInput?.current?.click();
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setMerchantUploadImages((prev) => {
                    const newImage = { file: file, uri: reader.result, new: true };
                    return [...prev, newImage];
                });
            };
            reader.readAsDataURL(file);
        }
    };

    // const checkNavigateCouponScreen = () => {
    //     if (updatingMerchant._id) {
    //         navigate("/my-store/coupons", {
    //             state: { merchantUUID: updatingMerchant._id, isAddingMerchant: false }
    //         });
    //     }
    // };

    // const navigateUpdateCouponScreen = (updatingCoupon) => {
    //     if (updatingMerchant._id) {
    //         navigate("/my-store/coupons", {
    //             state: {
    //                 merchantUUID: updatingMerchant._id,
    //                 isAddingMerchant: false,
    //                 updatingCoupon
    //             }
    //         });
    //     }
    // };

    const getInitialValues = () => {
        if (updatingMerchant) {
            return {
                ...initialValues,
                storeName: updatingMerchant?.storeName,
                businessAddress: updatingMerchant?.businessAddress,
                businessID: updatingMerchant?.businessID,
                storeDescription: updatingMerchant?.storeDescription,
                pricePerPoint: updatingMerchant?.pricePerPoint,
                numberOfPoints: updatingMerchant?.numberOfPoints,
                airdropPoints: updatingMerchant?.airdropPoints,
                allowPointsOnExchange: updatingMerchant?.allowPointsOnExchange,
                allowFollowersRecommendation: updatingMerchant?.allowFollowersRecommendation,
                followersRecommendationPoints: updatingMerchant?.followersRecommendationPoints,
                pricePerPointCurrency: updatingMerchant?.pricePerPointCurrency ?? allowedCurrencies.USD.value
            };
        }
        return initialValues;
    };

    const isHighTier = () => {
        const tier = currentUser?.tierStatus?.tier;
        if (tier === "silver" || tier === "gold") {
            return true;
        }
        return false;
    };

    const removeImage = (uri) => {
        setMerchantUploadImages((prev) => {
            const newImages = prev.filter((image) => {
                return image.uri !== uri;
            });
            return [...newImages];
        });
        if (updatingMerchant) {
            MerchantApiInstance.deleteStoreImage(token, uri?.split("/")?.pop()).then(() => {
                dispatch(getCurrentUserMerchant(token));
                dispatch(getAllCoupons(token));
            });
        }
    };

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={AddStoreSchema}
            innerRef={formRef}
            onSubmit={handleSubmitStore}
        >
            {({ handleChange, handleSubmit, values, errors, setFieldValue, isSubmitting, touched }) => (
                <div className="row gy-3">
                    <div className="col-12 col-md-6">
                        <div className="card card-body gap-3">
                            <div>
                                <Form.Label htmlFor="taxtno200" className="form-label">
                                    Store Profile Image
                                </Form.Label>
                                <div className="d-flex justify-content-center mt-2">
                                    <Carousel className="store_image_container rounded" showThumbs={false}>
                                        {[...merchantUploadImages, { type: "upload" }]?.map((image, index) =>
                                            image?.type !== "upload" ? (
                                                <div className="" key={"img" + index}>
                                                    <div>
                                                        <Button
                                                            variant="dark"
                                                            className="position-absolute top-0 start-0 ms-4 my-2 rounded px-2 py-1"
                                                            onClick={() => removeImage(image.uri)}
                                                        >
                                                            <IoTrash size={16} color="white" />
                                                        </Button>
                                                        <div className="">
                                                            <img
                                                                src={image.uri}
                                                                alt="carousel_img"
                                                                className="object-fit-cover rounded"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div onClick={handleImagePicker} className="" key={"img" + index}>
                                                    <div className="image_upload_container rounded">
                                                        <span color={theme.color}>
                                                            <BiSolidImageAdd size={28} className="me-2" />
                                                            Upload Image
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </Carousel>
                                    <input
                                        id="storeImageInput"
                                        type="file"
                                        ref={storeImageInput}
                                        onChange={handleImageUpload}
                                        accept="image/*"
                                    />
                                </div>
                            </div>

                            <Form.Group>
                                <Form.Label className="required">{t("Store_Name")}</Form.Label>
                                <Form.Control
                                    value={values.storeName}
                                    onChange={handleChange("storeName")}
                                    type="text"
                                    placeholder="Enter store name"
                                />
                                {touched.storeName && errors.storeName && <ErrorMsg msg={errors.storeName} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">{t("Store_description")}</Form.Label>
                                <Form.Control
                                    value={values.storeDescription}
                                    onChange={handleChange("storeDescription")}
                                    type="text"
                                    placeholder="Enter store description"
                                    max={128}
                                    as="textarea"
                                    rows={2}
                                />
                                {touched.storeDescription && errors.storeDescription && (
                                    <ErrorMsg msg={errors.storeDescription} />
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">{t("Business_Id")}</Form.Label>
                                <Form.Control
                                    value={values.businessID}
                                    onChange={handleChange("businessID")}
                                    type="text"
                                    placeholder="Enter business ID (e.g. tax ID)"
                                />
                                {touched.businessID && errors.businessID && <ErrorMsg msg={errors.businessID} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="required">{t("Business_Address")}</Form.Label>
                                <Form.Control
                                    value={values.businessAddress}
                                    onChange={handleChange("businessAddress")}
                                    type="text"
                                    placeholder="Enter business address"
                                />
                                {touched.businessAddress && errors.businessAddress && (
                                    <ErrorMsg msg={errors.businessAddress} />
                                )}
                            </Form.Group>

                            {/* <div mb="10">
                                        <span color="gray.400" fontSize="xs">
                                            {t('didnt_upgrade')}
                                        </span>
                                    </div> */}

                            {/* {updatingMerchant && (
                                            <CouponsList
                                                navigateToAddCoupon={checkNavigateCouponScreen}
                                                navigateUpdateCouponScreen={navigateUpdateCouponScreen}
                                                coupons={coupons !== null ? coupons : []}
                                            />
                                        )} */}
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="card card-body gap-3 h-100">
                            <Form.Group>
                                <Form.Label>{t("Number_of_points")}</Form.Label>
                                <Form.Control
                                    value={values.numberOfPoints}
                                    onChange={handleChange("numberOfPoints")}
                                    type="number"
                                    placeholder="Enter number of points"
                                />
                                {touched.numberOfPoints && errors.numberOfPoints && <ErrorMsg msg={errors.numberOfPoints} />}
                            </Form.Group>

                            <div className="row gy-3">
                                <div className="col-12 col-md-6">
                                    <Form.Group>
                                        <Form.Label>{t("Currency")}</Form.Label>
                                        <Form.Select
                                            aria-label="Currency"
                                            onChange={handleChange("pricePerPointCurrency")}
                                            defaultValue={values.pricePerPointCurrency}
                                        >
                                            <option value={allowedCurrencies.USD.value}>USD</option>
                                            <option value={allowedCurrencies.KRW.value}>KRW</option>
                                            <option value={allowedCurrencies.PHP.value}>PHP</option>
                                            <option value={allowedCurrencies.PKR.value}>PKR</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-12 col-md-6">
                                    <Form.Group>
                                        <Form.Label>{`${allowedCurrencies[values.pricePerPointCurrency].symbol} ${
                                            allowedCurrencies[values.pricePerPointCurrency].value
                                        }`}</Form.Label>
                                        <Form.Control
                                            value={values.pricePerPoint ?? ""}
                                            onChange={handleChange("pricePerPoint")}
                                            type="number"
                                            placeholder="Enter price per point"
                                        />
                                        {touched.pricePerPoint && errors.pricePerPoint && (
                                            <ErrorMsg msg={errors.pricePerPoint} />
                                        )}
                                    </Form.Group>
                                </div>
                            </div>

                            <Form.Group>
                                <Form.Label>{t("Airdrop_points")}</Form.Label>
                                <Form.Control
                                    value={values.airdropPoints !== null ? values.airdropPoints : ""}
                                    onChange={handleChange("airdropPoints")}
                                    type="number"
                                    placeholder="Enter airdrop points"
                                />
                                {touched.airdropPoints && errors.airdropPoints && <ErrorMsg msg={errors.airdropPoints} />}
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{t("Followers_Recommendation_Points")}</Form.Label>
                                <Form.Control
                                    value={
                                        values.followersRecommendationPoints !== null
                                            ? values.followersRecommendationPoints
                                            : ""
                                    }
                                    onChange={handleChange("followersRecommendationPoints")}
                                    type="number"
                                    disabled={!allowFollowersRecommendation}
                                    placeholder="Enter followers recommendation points"
                                />
                                {touched.followersRecommendationPoints && errors.followersRecommendationPoints && (
                                    <ErrorMsg msg={errors.followersRecommendationPoints} />
                                )}
                            </Form.Group>

                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={t("Allow_Followers_to_Recommend_during_checkin")}
                                disabled={!isHighTier()}
                                checked={allowFollowersRecommendation}
                                onChange={() => {
                                    setAllowFollowersRecommendation(!allowFollowersRecommendation);
                                    setFieldValue("followersRecommendationPoints", "");
                                }}
                                className="mb-2"
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                        <Button onClick={handleSubmit} disabled={isSubmitting} style={{ width: "100px" }}>
                            {isSubmitting && <Spinner animation="border" variant="light" size="sm" className="me-2" />}
                            {updatingMerchant ? t("Save") : t("Continue")}
                        </Button>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default StoreForm;
