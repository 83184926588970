export const createFormData = (body, photo) => {
  const data = new FormData();

  if (photo && photo.length > 0) {
    data.append('file', photo[0]);
  }

  Object.keys(body).forEach(key => {
    const parsedKey = key;
    data.append(key, body[parsedKey]);
  });

  return data;
};

export const createFormDataV2 = (body, photos) => {
  const data = new FormData();
  photos?.forEach((photo, i) => {
    data.append(`file${i}`, photo);
  });

  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });

  return data;
};
