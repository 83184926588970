import axios from "axios";
import { readCookie } from "../utils/cookie";
import { config } from "../utils/config";
import { httpTokenInterceptor } from "./common";

class DeveloperAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: `${config[config.environment].host}/api/dev/v1`
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    createdApiKey(name) {
        return this.axios.post(
            "/api_key",
            { name },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getAllApiKeys() {
        return this.axios.get(
            "/api_keys",
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    upgradePlan(name = "gold") {
        return this.axios.post(
            "/upgrade_plan",
            { package_name: name },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    upgradePlanCapture(name = "gold", paymentIntent) {
        return this.axios.post(
            "/upgrade_plan/capture",
            { package_name: name, paymentIntent },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    getCurrentPlan() {
        return this.axios.get(
            "/current_plan",
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`
                }
            }
        );
    }

    deleteApiKey(apiKey) {
        return this.axios.delete(
            "/api_key",
            {
                headers: {
                    'x-api-key': apiKey
                }
            }
        );
    }

    updateApiKey(apiKey, apiKeyName) {
        return this.axios.patch(
            "/api_key",
            { name: apiKeyName },
            {
                headers: {
                    "x-api-key": apiKey
                }
            }
        );
    }

    getApiKeyStats(apiKey) {
        return this.axios.get(
            "/api_key_stats",
            {
                headers: {
                    "x-api-key": apiKey
                }
            }
        );
    }

    getAiSettings(apiKey) {
        return this.axios.get(
            "/ai_settings",
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`,
                    "x-api-key": apiKey
                }
            }
        );
    }

    updateAiSettings(settings, apiKey) {
        return this.axios.patch(
            "/ai_settings",
            { settings },
            {
                headers: {
                    Authorization: `Bearer ${readCookie("token")}`,
                    "x-api-key": apiKey
                }
            }
        );
    }
}

export const developerAPIInstance = new DeveloperAPI();

export default DeveloperAPI;
