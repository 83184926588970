import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useMemo, useRef } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthActions } from '../../Redux/Actions';
import { countryCodes } from '../../utils/countryCodes';
import { getFingerPrint } from '../../utils/fingerPrint';
import { eventActions, eventCategories, sendFormActionEvent } from '../../utils/googleAnalytics';
import AuthRight from './AuthRight';
import './index.css';

const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required.'),
    email: Yup.string().email('Invalid email format.').required('Email is required.'),
    /* phoneNumber: Yup.string().required('Phone number is required.'), */
    verificationCode: Yup.string(),
    password: Yup.string().required('Password is required.'),
    inviteCode: Yup.string(),
});

function Signup({ register }) {
    const {t} = useTranslation('sign_up_page');
    const formRef = useRef();
    const location = useLocation();
    const inviteCode = useMemo(() => {
        if (location.pathname.includes("/sign-up/")) {
            const [, code] = location.pathname.split("/sign-up/");
            return code;
        } else {
            return "";
        }
    }, [location.pathname]);

    const errorCb = (e) => {
        alert(e?.response?.data?.message ?? "Something went wrong.");
    }

    /* const handleSendVerificationCode = (phoneNumber) => {
        authAPIInstance
            .sendPhoneVerificationCode(phoneNumber)
            .then((_) => {
                alert("Code sent to your phone.");
            })
            .catch((e) => {
                errorCb(e);
            });
    }; */

    const handleSubmit = async (values) => {
        /* const phoneNumber = `${values.dialCode}${values.phoneNumber}`; */
        /* if (!values?.verificationCode) {
           return handleSendVerificationCode(phoneNumber);
        } */
        const fp = await getFingerPrint();
        register(
            values.password,
            values.username,
            values.email,
            fp,
            values.inviteCode,
            errorCb
        );
    }

    const handleFocusEvent = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_focus_event, fieldName, fieldValue);
    };

    const handleChangeEvent = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_change_event, fieldName, fieldValue);
    };

    const _handleSubmitEvent = () => {
        sendFormActionEvent(eventCategories.USER_ACTION, eventActions.form_submit_event, 'register');
    };

    const initialValues = {
        username: "",
        email: "",
        phoneNumber: "",
        dialCode: countryCodes[0].dial_code,
        verificationCode: "",
        password: "",
        inviteCode: inviteCode ? inviteCode : ""
    };

    return (
        <>
            <div className="">
                <div className="d-flex align-items-center flex-column -3">
                    <h1>{t("createAccount")}</h1>
                    <span className="text-muted w-75 p-3 mx-auto text-center">
                    {t("registerWithEmail")}
                    </span>
                </div>
                <div className="container sign-up-container mt-3">
                    <div className="row g-3">
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="d-flex flex-column auth-form-container">
                                <Formik
                                    innerRef={formRef}
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    <Form
                                        onFocus={handleFocusEvent}
                                        onChange={handleChangeEvent}
                                        className="d-flex flex-column"
                                    >
                                        <Tab.Container defaultActiveKey="first">
                                            <Nav className="nav nav-pills mt-4" role="tablist">
                                                <Nav.Item className="nav-item">
                                                    <Nav.Link className="nav-link" eventKey="first">
                                                        {t("email")}
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {/* <Nav.Item className="nav-item">
                                                    <Nav.Link className="nav-link disabled" eventKey="second">
                                                        {t("phone")}
                                                    </Nav.Link>
                                                </Nav.Item> */}
                                            </Nav>
                                            <Tab.Content className="tab-content mt-0 mb-2">
                                                <Tab.Pane className="tab-pane fade" id="Email" eventKey="first">
                                                    <div className="card">
                                                        <div className="card-body sign-up-card-body p-0">
                                                            <div className="mb-0">
                                                                <label className="form-label fs-6">{t("username")}</label>
                                                                <Field
                                                                    name="username"
                                                                    type="text"
                                                                    className="form-control mb-3"
                                                                    placeholder="Add username here"
                                                                />
                                                                <ErrorMessage
                                                                    name="username"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                            <div className="mb-0">
                                                                <label className="form-label fs-6">{t("email")}</label>
                                                                <Field
                                                                    name="email"
                                                                    type="email"
                                                                    className="form-control mb-3"
                                                                    placeholder="Add email here"
                                                                />
                                                                <ErrorMessage
                                                                    name="email"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                            {/* <div className="mb-3">
                                                                <label className="form-label fs-6">{t("phone")}</label>
                                                                <div id="phone-container" className="mb-3">
                                                                    <Field
                                                                        as="select"
                                                                        id="country-code-select"
                                                                        name="dialCode"
                                                                        className="form-select"
                                                                    >
                                                                        {countryCodes.map((code, i) => (
                                                                            <option
                                                                                key={`code.dial_code${i}`}
                                                                                value={code.dial_code}
                                                                            >
                                                                                {`${code.name} ${code.dial_code}`}
                                                                            </option>
                                                                        ))}
                                                                    </Field>
                                                                    <Field
                                                                        name="phoneNumber"
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Phone number"
                                                                    />
                                                                </div>
                                                                <ErrorMessage
                                                                    name="phoneNumber"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div> */}

                                                            {/* <div className="mb-3">
                                                                <label className="form-label fs-6">Verification Code</label>
                                                                <Field
                                                                    name="verificationCode"
                                                                    type="text"
                                                                    className="form-control mb-3"
                                                                    placeholder="Verification code"
                                                                />
                                                                <ErrorMessage
                                                                    name="verificationCode"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div> */}

                                                            <div className="mb-0">
                                                                <label className="form-label fs-6">{t("password")}</label>
                                                                <Field
                                                                    name="password"
                                                                    type="password"
                                                                    className="form-control mb-3"
                                                                    placeholder="Add Password here"
                                                                />
                                                                <ErrorMessage
                                                                    name="password"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                            <div className="mb-0">
                                                                <label className="form-label fs-6">{t("confirmPassword")}</label>
                                                                <Field
                                                                    name="confirmPassword"
                                                                    type="password"
                                                                    className="form-control mb-3"
                                                                    placeholder="Add Password here"
                                                                />
                                                                <ErrorMessage
                                                                    name="password"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                            <div className="mb-0">
                                                                <label className="form-label fs-6">{t("inviteCode")}</label>
                                                                <Field
                                                                    name="inviteCode"
                                                                    type="text"
                                                                    className="form-control mb-3"
                                                                    placeholder="Username of referrer"
                                                                />
                                                                <ErrorMessage
                                                                    name="inviteCode"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                            <button
                                                                type="submit"
                                                                onClick={_handleSubmitEvent}
                                                                className="btn orange-gradient-btn  py-2 fs-7 w-100 mt-2"
                                                            >
                                                                {t("createAccountBtn")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane className="tab-pane fade" id="Mobile" eventKey="second">
                                                    <div className="card">
                                                        <div className="card-body p-4">
                                                            {/* Mobile registration content */}
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                        <AuthRight />
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column gap-3 mt-3">
                    {t("alreadyRegistered")}
                    <Link to={process.env.PUBLIC_URL + "/sign-in"} title="#" className="d-flex flex-column justify-content-center align-items-center text-center">
                        
                        <span className="orange-red-gradient-text text-decoration-none">
                            {t("logIn")}
                        </span>
                    </Link>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({ AuthReducer }) => ({
    AuthReducer
});

export default connect(mapStateToProps, {
    register: AuthActions.register
})(Signup);
