import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { getMerchantCouponHistory } from "../../Redux/Actions/MerchantActions";
import { readCookie } from "../../utils/cookie";
import "./index.css";

const StoreStatisticsHistoryScreen = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState("All Sales");
    const [selectedHistoryItem, setSelectedHistoryItem] = useState(null);
    const dispatch = useDispatch();
    const merchant = useSelector(state => state.merchant.currentUserMerchant);
    const token = readCookie("token");

    const couponsHistory = useSelector(state => state.merchant.currentUserCouponsHistory);

    useEffect(() => {
        dispatch(getMerchantCouponHistory(merchant?._id, token));
    }, [dispatch, merchant?._id, token]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = (item) => {
        setSelectedHistoryItem(item);
        setShowModal(true);
    };

    const handleSelectItem = (item) => {
        setSelectedItem(item);
    };

    const salesHistory = couponsHistory.map(item => {
        const date = new Date(parseInt(item.createdAt, 10) * 1000);
        const formattedDate = `${date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })} / ${date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short'
        })}`;
    
        return {
        _id: item._id,
        couponName: item.couponID.couponName,
        payment: item.totalAmount,
        userId: item.senderID.username,
        date: formattedDate
        };
    });

    const renderSalesHistoryItem = (item) => {
        return (
            <Row
                key={item._id}
                className="rounded p-4 mt-4"
                style={{ position: 'relative', borderRadius: '20px', border: '1px solid var(--color-400)' }}
                onClick={() => handleOpenModal(item)}
            >
                <Col className="d-flex flex-column">
                    <span className="mb-2 fs-5" style={{ fontWeight: 'bold' }}>{item.couponName}</span>
                    <div className="d-flex flex-column">
                        <span className="mb-2 fs-6 text-muted">Payment: {item.payment}</span>
                        <span className="mb-2 fs-6 text-muted">User ID: {item.userId}</span>
                        <span className="mb-2 fs-6 text-muted">Date: {item.date}</span>
                    </div>
                </Col>
                <Col xs={1} className="d-flex align-items-center justify-content-center">
                    <i className="fas fa-chevron-right" style={{ color: 'var(--bs-gray-500)' }}></i>
                </Col>
            </Row>
        );
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
            <div style={{ width: '30%' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <DropdownButton
                        title={selectedItem}
                        onSelect={handleSelectItem}
                        className="custom-dropdown"
                        style={{ backgroundColor: 'white', color: 'black' }}
                    >
                        <Dropdown.Item eventKey="All Sales">All Sales</Dropdown.Item>
                        <Dropdown.Item eventKey="Point Sales">Point Sales</Dropdown.Item>
                        <Dropdown.Item eventKey="Coupon Sales">Coupon Sales</Dropdown.Item>
                    </DropdownButton>
                </div>

                {salesHistory.map((item) => (
                    <React.Fragment key={item._id}>
                        {renderSalesHistoryItem(item)}
                    </React.Fragment>
                ))}

                <Modal show={showModal} onHide={handleCloseModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        {selectedHistoryItem && (
                            <div
                                className="rounded p-4 mx-5"
                                style={{ position: 'relative', borderRadius: '20px', border: '1px solid var(--bs-gray-500)' }}
                            >
                                <div className="d-flex flex-column">
                                    <span className="mb-2 fs-6 text-muted">Coupon Name</span>
                                    <span className="mb-2 fs-6" style={{ fontWeight: 'bold' }}>{selectedHistoryItem.couponName}</span>
                                    <div className="d-flex flex-column">
                                        <Row>
                                            <Col className="d-flex justify-content-start">
                                                <span className="mb-2 text-muted">Price</span>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <span className="mb-2 font-weight-500">{selectedHistoryItem.payment}</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="d-flex justify-content-start">
                                                <span className="mb-2 text-muted">Number Bought</span>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <span className="mb-2 font-weight-500">2</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="d-flex justify-content-start">
                                                <span className="mb-2 text-muted">Date</span>
                                            </Col>
                                            <Col className="d-flex justify-content-end align-items-end">
                                                <span className="mb-2 font-weight-500 text-end">
                                                {selectedHistoryItem.date}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="d-flex justify-content-start">
                                                <span className="mb-2 text-muted">Buyer ID</span>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <span className="mb-2 font-weight-500">{selectedHistoryItem.userId}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center">
                        <Button className="rounded-sm" style={{ width: '40%', backgroundColor: 'var(--bs-orange)', color: 'white', padding: '10px', fontWeight: 'bold' }} onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default StoreStatisticsHistoryScreen;
