//for automatic 1111 stable diffusion ui
export const imageGenDefaultParams = {
    "prompt": "castle, garden, fortress, forest, green, medieval, 8k, clouds, garden, beautiful",
    "negative_prompt": "nsfw, easynegative, lowres, bad anatomy, bad hands, text, error, missing fingers, extra digit, fewer digits, cropped, worstquality, low quality, normal quality, jpegartifacts, signature, watermark, username, blurry, bad feet, cropped, poorly drawn hands, poorly drawn face, mutation, deformed, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, extra fingers, fewer digits, extra limbs, extra arms,extra legs, malformed limbs, fused fingers, too many fingers, long neck, cross-eyed,mutated hands, polar lowres, bad body, bad proportions, gross proportions, text, error, missing fingers, missing arms, missing legs, extra digit, extra arms, extra leg, extra foot",
    "steps": 55,
    "width": 1024,
    "height": 1024,
    "cfg_scale": 7,
    "sampler_index": "Euler a",
};
/* 
export const imageGenDefaultParams = {
    "width": 1024,
    "height": 1024,
    "steps": 20,
    "prompt": "chicken",
    "negative_prompt": "human",
    "denoising_strength": 0,
    "firstphase_width": 0,
    "firstphase_height": 0,
    "styles": [],
    "seed": -1,
    "subseed": -1,
    "subseed_strength": 0,
    "seed_resize_from_h": -1,
    "seed_resize_from_w": -1,
    "batch_size": 1,
    "n_iter": 1,
    "cfg_scale": 7,
    "eta": 0,
    "s_churn": 0,
    "s_tmax": 0,
    "s_tmin": 0,
    "s_noise": 1,
    "sampler_index": "Euler a",
};
 */